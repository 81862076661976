import { ActionTypes } from "../constants/Types";
import server from "./apis";
import { marineTerminalListMessage } from "./MessageAction";
import { stopLoading, startMarineTerminalListLoading } from "./LoadingAction";
import { marineTerninalListStatusCode } from "./StatusCodeAction";
import { GET_MARINE_TERMINAL_URL } from "../../url/Url";
import { languageJson } from "src/StringVariable";

const marineTerminalListSuccess = (data) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_LIST_DATA_SUCCESS,
        payload: data
    }
}

export const getMarineTerminalListData = () => {
    return async(dispatch) => {
        dispatch(startMarineTerminalListLoading());
       await server.get(`${GET_MARINE_TERMINAL_URL}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(marineTerminalListSuccess(response.data.marineTerminals))
                    dispatch(marineTerninalListStatusCode(response.data.customcode));
                } else {
                    dispatch(marineTerminalListSuccess([]))
                    dispatch(marineTerninalListStatusCode(response.data.customcode));
                    dispatch(marineTerminalListMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(marineTerminalListMessage(message))
            });;
    };
};

export const getMarineTerminalData = () => {
    return async(dispatch) => {
        dispatch(startMarineTerminalListLoading());
       await server.get(`${GET_MARINE_TERMINAL_URL}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(marineTerminalListSuccess(response.data.marineTerminals))
                    dispatch(marineTerninalListStatusCode(response.data.customcode));
                } else {
                    dispatch(marineTerninalListStatusCode(response.data.customcode));
                    dispatch(marineTerminalListMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(marineTerminalListMessage(message))
            });;
    };
};


