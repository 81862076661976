import React, { useState, useEffect } from "react";
import downloadmobileimage from "../assets/mobile-icon-new.png";
import appstoreicon from "../assets/appstore-icon.png";
import googleplayicon from "../assets/playstore-icon.png";
import newsplayvideo from "../assets/videos/downloadSessionVideo.mp4";
import axios from "src/redux/actions/apis";
import { GET_NEWS_UPDATE } from "../url/Url";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import videoBgImage from "../assets/ship_bg.jpg";
import videoPlayBtn from "../assets/playBtn.png";
import { languageJson } from "../StringVariable";
import DataNotFound from "src/Components/DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import noImage from "src/assets/no-image.png";
import moment from "moment";
moment.suppressDeprecationWarnings = true;

function NewsUpdate() {
  let history = useHistory();
  let singleUrls = useSelector((state) => state.publicUrlState);
  const [newsAndUpdatesList, setNewsAndsUpdatesList] = useState(null);
  const [playStoreUrl, setPlayStoreUrl] = useState(null);
  const [appleStoreUrl, setAppleStoreUrl] = useState(null);
  const [videoButton, setVideoButton] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);

  const getNewsAndUpdate = async (page, limit) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(GET_NEWS_UPDATE + `/${page}/${limit}`, requestObj)
        .then((res) => {
          const { listData, customcode } = res.data;
          if (customcode === 200) {
            setNewsAndsUpdatesList(listData);
          } else {
            setNewsAndsUpdatesList([]);
          }
        });
    } catch (err) { }
  };

  useEffect(() => {
    getNewsAndUpdate(1, 10);
  }, []);
  useEffect(() => {
    if (
      singleUrls &&
      singleUrls.singleUrls &&
      singleUrls.singleUrls.length > 0
    ) {
      let urlObj = singleUrls.singleUrls.find(
        ({ url_id }) => url_id === "play_store_url"
      );
      setPlayStoreUrl(urlObj ? urlObj.url_link : null);
      let appleUrl = singleUrls.singleUrls.find(
        ({ url_id }) => url_id === "app_store_url"
      );
      setAppleStoreUrl(appleUrl ? appleUrl.url_link : null);

      let videoUrl = singleUrls.singleUrls.find(
        ({ url_id }) => url_id === "promotion_video"
      );
      setVideoUrl(videoUrl ? videoUrl.url_link : newsplayvideo);
    }
  }, [singleUrls]);
  const sendToDetailPage = (id) => {
    history.push(`/news-Details/${id}`);
  };

  const onClickVideo = () => {
    var video = document.getElementById("video");
    if (video.paused) {
      video.play();
      setVideoButton(false);
    } else {
      video.pause();
      setVideoButton(true);
    }
  };

  return (
    <React.Fragment>
      <section className="newsupdates-section">
        <div className="container pt-5">
          <div className="row newsDivBox ">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-8 center-head-underline d-flex justify-content-center py-4 headingTxt">
              <h1 className="text-center  py-2 f-head">
                {languageJson.sectionName.newsupdates}
              </h1>
            </div>
            {newsAndUpdatesList != null &&
              newsAndUpdatesList &&
              newsAndUpdatesList.length > 0 && (
                <div className="col-lg-4 col-md-6 col-sm-4 col-xs-4 d-flex justify-content-end  align-items-center viewAllBtn">
                  <div>
                    <Link to="/NewsAndUpdatesDetails">
                      {" "}
                      <p className="viewall-clr text-end">
                        {languageJson.viewall_txt} &#62;
                      </p>{" "}
                    </Link>
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>
      {/* -------------------------------tools and services section one----------------------------------------- */}
      <section className="home-tools-section">
        <div className="container pb-5">
          <div className="row">
            {newsAndUpdatesList !== null &&
              newsAndUpdatesList &&
              newsAndUpdatesList.length > 0 ? (
              newsAndUpdatesList.slice(0, 2).map((list) => (
                <div key={list.news_id} className="col-lg-6 ">
                  <div className="row newsInnerBoxDiv">
                    <div className="col-lg-5 col-md-6 col-sm-12 imgDivBox ">
                      <img
                        className="newsupdate-sec-img"
                        alt="newsUpdate"
                        src={list.new_image !== null && !list.new_image.includes(null) ? list.new_image : noImage}
                        onError={(e) => {
                          e.currentTarget.src = noImage;
                        }}
                        onClick={() => sendToDetailPage(list.news_id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 textDescpDiv">
                      <p className="news-date">
                        {list.news_published_on.toLowerCase() !== "null" ||
                          list.news_published_on !== null
                          ? moment(list.news_published_on).format("Do MMM YYYY")
                          : "-"}
                      </p>
                      <p
                        className="news-head"
                        onClick={() => sendToDetailPage(list.news_id)}
                        style={{ cursor: "pointer" }}
                      >
                        {list.news_heading.toLowerCase() !== "null" ||
                          list.news_heading !== null
                          ? list.news_heading.slice(0, 50)
                          : "-"}
                        {list.news_heading && list.news_heading.length > 50 ? "..." : ""}
                      </p>
                      <p className="txt-overflow">
                        {list.news_description.toLowerCase() !== "null" ||
                          list.news_description !== null
                          ? list.news_description.replace(/<[^>]+>/g, "")
                          : "-"}
                      </p>
                      <Link
                        className="viewall-clr"
                        to={`/news-Details/${list.news_id}`}
                      >
                        {languageJson.readmore_txt} <span>&#62;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 col-md-12 col-md-12 mb-3">
                {newsAndUpdatesList != null &&
                  newsAndUpdatesList &&
                  newsAndUpdatesList.length === 0 ? (
                  <DataNotFound />
                ) : (
                  <SpinerLoader />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      {/* -------------------------------tools and services section two----------------------------------------- */}
      <section className="home-tools-sectiontwo container">
        <div className="row newsupdate-bg">
          <div className="col-lg-7 news-sec1 d-flex justify-content-center align-items-center">
            <div className="news-video-div">
              {/* Video play button add here add new css for this button */}
              {videoButton ? (
                <img
                  src={videoPlayBtn}
                  alt="videoPlayBtn"
                  className="videoPlayBtn"
                ></img>
              ) : (
                ""
              )}
              <video
                id="video"
                className="newsvid"
                poster={videoBgImage}
                onClick={onClickVideo}
              >
                <source src={videoUrl} type="video/mp4" />
                <source src={videoUrl} type="video/ogg" />
              </video>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row newsupdatebgimage py-5">
              <div className="col-lg-5"></div>
              <div className="col-lg-7">
                <h2 className="text-center mobile-app-head">
                  {languageJson.download_txt} <br></br>
                  {languageJson.ourmobileapp_txt}
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 p-1">
                  <img
                    src={downloadmobileimage}
                    className="downloadmobileimage mx-auto d-block"
                    alt="downmobile"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="row">
                    <div className="col-lg-12 py-5 ">
                      <a href={appleStoreUrl} target="_blank" rel="noreferrer">
                        {" "}
                        <img
                          src={appstoreicon}
                          className="appstoreicon mx-auto d-block"
                          alt="appstoreicon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <a href={playStoreUrl} target="_blank" rel="noreferrer">
                        {" "}
                        <img
                          src={googleplayicon}
                          className="appstoreicon mx-auto d-block"
                          alt="googleplayicon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default NewsUpdate;
