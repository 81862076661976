import React, { useEffect, useState } from "react";
import axios from "src/redux/actions/apis";
import { GET_DATA_ABOUT_US_PAGE_URL } from "src/url/Url";
// import Findus from "src/Containers/Findus";
import FrequentlyAskedQuestions from "src/Containers/FrequentlyAskedQuestions";
import Header from "src/Containers/Header"; 
// import portimg1 from "../../assets/AboutView/1.jpg";
// import portimg2 from "../../assets/AboutView/2.jpg";
// import portimg3 from "../../assets/AboutView/3.jpg"; 
import "./Aboutus.css";
import POAKPanorama from "../../assets/POAKPanorama.jpg";

import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "src/firebaseConfig";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import { submitGAEvent } from "src/utils/GAEvents";

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Aboutus() {
  const [aboutUsData, setAboutUsData] = useState(null);
  const getUserReportListData = async () => {
    try {
      const result = await axios
        .get(GET_DATA_ABOUT_US_PAGE_URL)
        .then((res) => res.data);
      if (result.customcode === 200) {
        setAboutUsData(result.about);
      } else {
        setAboutUsData([]);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getUserReportListData();
    submitGAEvent("ABOUT");
  }, []);

 

  useEffect(() => {
    logEvent(analytics, "About us page_view");
  });


  
  return (
    <>
      <Header />
      <div className="headerBannerMainDiv">
        <img className="banner_img" src={POAKPanorama} alt=""/> 
      </div>  
      {/* <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg3} alt=""/>    
             <img className="banner_img" src={portimg2} alt=""/> 
              
      </div> */}
      <section className="aboutus-section">
        <div className="row">
          <div className="container ">
            {aboutUsData != null &&
              aboutUsData.length &&
              aboutUsData.length > 0 ? (
              aboutUsData.map((resource) => (
                <>
                  <div className="col-lg-12 text-center aboutus-underline">
                    <h2 className="text-center pt-5">
                      {resource.title !== "" ||
                        resource.title.toLowerCase() !== "null" ||
                        resource.title !== null
                        ? resource.title
                        : "-"}
                    </h2>
                  </div>
                  <div className="container">
                    <div className="row ">
                      <p className="aboutus_para text-center mt-4">
                        {resource.description !== "" ||
                          resource.description.toLowerCase() !== "null" ||
                          resource.description !== null
                          ? resource.description
                          : "-"}
                      </p>
                      {resource.subtopic && resource.subtopic.length > 0
                        ? resource.subtopic.map((module) => (
                          <div
                            key={module.moduleDescription}
                            className="col-lg-4 col-sm-6 col-md-6 modules_rectangle "
                          >
                            <div className="modules_first">
                              <h2 className="modules_head text-left mt-3">
                                {module.moduleName !== "" ||
                                  module.moduleName.toLowerCase() !== "null" ||
                                  module.moduleName !== null
                                  ? module.moduleName
                                  : "-"}
                              </h2>
                              <p className="modules_para" id="moudle_text1">
                                {module.moduleDescription !== "" ||
                                  module.moduleDescription.toLowerCase() !==
                                  "null" ||
                                  module.moduleDescription !== null
                                  ? module.moduleDescription
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div className="col-lg-12 col-md-12 col-md-12 mb-3">
                {aboutUsData !== null &&
                  aboutUsData &&
                  aboutUsData.length === 0 ? (
                  <DataNotFound />
                ) : (
                  <section className="aboutus-section">
                    <div className="row">
                      <div className="container ">
                        <SpinerLoader />
                      </div>
                    </div>
                  </section>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <FrequentlyAskedQuestions />
      {/* <Findus /> */}
    </>
  );
}
export default Aboutus;
