import React from 'react';
import Lottie from "react-lottie";
import animationData from "src/assets/json/no_data_found.json";

export default function DataNotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="text-center notfoundMainDiv">
      <Lottie
        options={defaultOptions}
      />
    </div>
  )
}
