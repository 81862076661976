import React from 'react';
import Lottie from "react-lottie";
import animationData from "../assets/json/pageNotFound.json";

export default function PageNotFoundComponent() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="text-center notfound404MainDiv" style={{pointerEvents:"none"}}>
      <Lottie
        options={defaultOptions}
      />
    </div>
  )
}

