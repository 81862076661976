import { ActionTypes } from "../../constants/Types";
const initialState = {
  searchResult: null,
  reservationResponse: null,
  waitinglistResponse: null,
  activeListResponse: null,
  inventoryListResponse: null,
  parkingHistory:null
};

export const ParkingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PARKING_LIST_BY_USER:
      return { ...state, ...action.payload };
    case ActionTypes.SEARCH_PARKING_ZONE_SUCCESS:
      return { ...state, searchResult: action.payload };
    case ActionTypes.RESERVATION_BOOKING_SUCCESS:
      return { ...state, reservationResponse: action.payload };
    case ActionTypes.PARKING_WAITING_LIST_BY_USER:
      return { ...state, waitinglistResponse: action.payload };
    case ActionTypes.PARKING_ACTIVITY_LIST_BY_USER:
      return { ...state, activeListResponse: action.payload };
    case ActionTypes.PARKING_INVENTORY_LIST_BY_USER:
      return { ...state, inventoryListResponse: action.payload };
      case ActionTypes.PARKING_HISTORY_LIST_BY_USER:
        return { ...state, parkingHistory: action.payload };
    default:
      return state;
  }
};
