import { ActionTypes } from "../constants/Types";
const initialState = {};

export const userAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case ActionTypes.LOGOUT_SUCCESS:
      return { ...state,...action.payload };
    case ActionTypes.USER_DETAIL_LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
