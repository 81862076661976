import React, { useEffect, useState } from "react";
import {
  GET_VESSEL_REPORT_URL,
  GET_MARINE_TERMINAL_URL,
  GET_ON_TIME_REPORT
} from "../../url/Url";
import NoteInstruction from "../MarineTerminalsDetail/NoteInstruction";
import { languageJson } from "../../StringVariable";
import portimg1 from "../../assets/1.jpg";
import portimg2 from "../../assets/2.jpg"; 
import portimg5 from "../../assets/5.jpg"; 
import Header from "../../Containers/Header";
import axios from "src/redux/actions/apis";
import moment from "moment";
import "./report.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faFileCode
} from "@fortawesome/free-solid-svg-icons";
import DataNotFound from "../DataNotFound/DataNotFound";
import { TimeFormate, DataFormate } from "../../utils/DataFormate";
import exportFromJSON from "export-from-json";
import { CSVLink } from "react-csv";
import "jspdf-autotable";
import PdfGenerater from "./PdfGenerater";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "src/firebaseConfig";
import SpinerLoader from "src/utils/SpinerLoader";
 import SearchComponent from "./SearchComponent";
import { submitGAEvent } from "src/utils/GAEvents";
import { Link } from "react-router-dom";
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
moment.suppressDeprecationWarnings = true;

function OperationalReports() {
  const {
    export_PDF_txt, export_Excel_txt, export_CSV_txt, export_XML_txt
  } = languageJson
  const [list, setList] = useState(null);
  const [listCopy, setListCopy] = useState(null);
  const [marineList, setmarineList] = useState(null);
  const [marineListCopy, setmarineListCopy] = useState(null);
  const [getonTimeReportData, setgetonTimeReport] = useState(null);
  const [getonTimeReportDataCopy, setgetonTimeReportCopy] = useState(null);
  const [searchValue, setSearchValue] = useState("");


  const getVesselReport = async () => {
    submitGAEvent("REPORTS-VESSEL OPERATIONS")
    setSearchValue("")
    try {
      const result = await axios
        .get(GET_VESSEL_REPORT_URL)
        .then((res) => res.data);
      if (result.customcode === 200 && result.vesselReports.length > 0) {
        setList(result.vesselReports);
        setListCopy(result.vesselReports);
      } else {
        setList([]);
        setListCopy([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getMerineReport = async () => {
    submitGAEvent("REPORTS-Ingate/Outgate")
    setSearchValue("")
    try {
      const result = await axios
        .get(GET_MARINE_TERMINAL_URL)
        .then((res) => res.data);
      if (result.customcode === 200 && result.marineTerminals.length > 0) {
        let gateListArray = [];
       for(let i=0; i<result.marineTerminals.length; i++){
        gateListArray.push(...result.marineTerminals[i].gates)
       }
       gateListArray.sort((a, b) => a.terminal_gate_id - b.terminal_gate_id);
       setmarineList(gateListArray);
       setmarineListCopy(gateListArray);
      } else {
        setmarineList([]);
        setmarineListCopy([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getonTimeReport = async () => {
    submitGAEvent("REPORTS-VESSEL ON-TIME PERFORMANCE REPORT")
    setSearchValue("")
    try {
      await axios.get(GET_ON_TIME_REPORT).then((res) => {
        const { onTimeReport, customcode } = res.data;
        if (customcode === 200 && onTimeReport.length > 0) {
          setgetonTimeReport(onTimeReport);
          setgetonTimeReportCopy(onTimeReport);
        } else {
          setgetonTimeReport([]);
          setgetonTimeReportCopy([]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getMerineReport();
  }, []);
  useEffect(() => {
    logEvent(analytics, "Report page_view");
  });

  const convertMetersToMiles = (value) => {
    const df = value * 0.00062137;
    return parseFloat(df).toFixed(2);
  }

  const getDate = (d) => {
    let time = new Date(parseInt(d));
    if (moment(time).format("MM/DD/YYYY") !== "Invalid date") {
      return moment(time).format("MM/DD/YYYY");
    } else {
      return <b>{"-"}</b>;
    }
  };

  //searching functionlity
  const onClickSearch = (type) => {
    let value = searchValue.toLocaleLowerCase();
    switch (type) {
      case "marin": {
        let temp = marineListCopy
        let filterData = temp.filter((item) =>
          item["terminal_name"].toLowerCase().includes(value) ? true : false
        );
        setmarineList(filterData);
        break;
      }
      case "vessel": {
        let temp = listCopy
        let filterData = temp.filter((item) =>
          item["vesselName"].toLowerCase().includes(value) ? true : false
        );
        setList(filterData);
        break;
      }
      case "report": {
        let temp = getonTimeReportDataCopy
        let filterData = temp.filter((item) =>
          item["vesselName"].toLowerCase().includes(value) ? true : false
        );
        setgetonTimeReport(filterData);
        break;
      }
      default:
        break
    }
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 1) {
      setList(listCopy);
      setmarineList(marineListCopy);
      setgetonTimeReport(getonTimeReportDataCopy);
    }
  };

  //For CSV generate From Terminal Data
  let terminalCSVData =
    marineList != null &&
    marineList.length > 0 &&
    marineList.map((elt) => {
      return {
        ...elt,
        current_street_wait_time: elt.current_street_wait_time !== null && elt.current_street_wait_time !==
        "null" && elt.current_street_wait_time && elt.current_street_wait_time !== ""
        ? elt.current_street_wait_time : 0,
        current_trucks_in_queue: elt.current_trucks_in_queue !== null && elt.current_trucks_in_queue !==
        "null" && elt.current_trucks_in_queue && elt.current_trucks_in_queue !== ""
        ? elt.current_trucks_in_queue : 0,
        current_queue_length: convertMetersToMiles(elt.current_queue_length),
        average_today_turn_time: elt.today_turn_time + elt.today_street_wait_time,
        today_volume: elt.today_volume !== null && elt.today_volume !==
        "null" && elt.today_volume && elt.today_volume !== ""
        ? elt.today_volume : 0,
        today_street_wait_time: elt.today_street_wait_time !== null && elt.today_street_wait_time !==
        "null" && elt.today_street_wait_time && elt.today_street_wait_time !== ""
        ? elt.today_street_wait_time : 0,
        today_turn_time: elt.today_turn_time !== null && elt.today_turn_time !==
        "null" && elt.today_turn_time && elt.today_turn_time !== ""
        ? elt.today_turn_time : 0,
      };
    });

    const terminealheaders = [
      { label: languageJson.Report_Export_IngateOutgate_Lable.Id, key: "terminal_gate_id"} ,
      { label: languageJson.Report_Export_IngateOutgate_Lable.Name, key: "gate_name"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TotalWaitTime, key: "current_street_wait_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TrucksInQueue, key: "current_trucks_in_queue"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.CurrentTrucks, key: "current_trucks_in_terminal"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.QueueLength, key: "current_queue_length"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.AvgTurnTime, key: "average_today_turn_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TotalTrucks, key: "today_volume"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.WaitTime, key: "today_street_wait_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.InOutTime, key: "today_turn_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TruckException, key: "-"}
    ];

  const terminealcsvReport = {
    data: terminalCSVData,
    headers: terminealheaders,
    filename: languageJson.terminal_reportCSV_txt,
  };

  //for PDF generate Terminal Data
  let headerMarine = [[
    "Id",
    "Terminal Name",
    "Total Wait Time (In Minute(s))",
    "Trucks in Street Queue",
    "Current Trucks in Terminal",
    "Street Queue Length (In Mile(s))",
    "Today’s Average Total Turn Time (In Minute(s))",
    "Total Trucks",
    "Street Wait Time (In Minute(s))",
    "Ingate/Outgate Time (In Minute(s))",
    "Truck in-gate out-gate exception",
  ]]
  let Terminaldata =
    marineList != null &&
    marineList.length &&
    marineList.map((elt) => [
      elt.terminal_gate_id,
      elt.gate_name,
      elt.current_street_wait_time !== null && elt.current_street_wait_time !== "null" && elt.current_street_wait_time && elt.current_street_wait_time !== "" ? elt.current_street_wait_time : 0, 
      elt.current_trucks_in_queue !== null && elt.current_trucks_in_queue !== "null" && elt.current_trucks_in_queue && elt.current_trucks_in_queue !== "" ? elt.current_trucks_in_queue : 0, 
      elt.current_trucks_in_terminal,
      convertMetersToMiles(elt.current_queue_length),
      elt.today_turn_time + elt.today_street_wait_time,
      elt.today_volume !== null && elt.today_volume !== "null" && elt.today_volume && elt.today_volume !== "" ? elt.today_volume : 0, 
      elt.today_street_wait_time !== null && elt.today_street_wait_time !== "null" && elt.today_street_wait_time && elt.today_street_wait_time !== "" ? elt.today_street_wait_time : 0, 
      elt.today_turn_time !== null && elt.today_turn_time !== "null" && elt.today_turn_time && elt.today_turn_time !== "" ? elt.today_turn_time : 0,
      "-"
    ]);

  // for CSV generate from  vessel operation data
  const [show, setShow] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [newList, setNewList] = useState([]);
  const [newOnReportData, setnewOnReportData] = useState([]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (type) => {
    setShow(true);
    switch (type) {
      case "modal1":
        setModal1(true);
        setModal2(false);
        setModal3(false);
        break;

      case "modal2":
        {
          setModal1(false);
          setModal2(true);
          setModal3(false);
          let newList = list.map((item, index) => {
            return {
              scheduleId: item.scheduleId,
              movementId: item.movementId,
              vesselName: item.vesselName,
              flag: item.flag,
              vesselType: item.vesselType,   
              lloydsNumber: item.lloydsNumber,   
              mmsi: item.mmsi,
              callSign: item.callSign,
              owner: item.owner,
              draft: item.draft,
              netWeight: item.netWeight,
              grossWeight: item.grossWeight,
              length: item.length,
              breadth: item.breadth,
              deadWeight: item.deadWeight,
              portOfRegistry: item.portOfRegistry,
              yearBuilt: item.yearBuilt,
              berth: item.berth,
              terminal: item.terminal,
              fromPort: item.fromPort ? item.fromPort : '-',
              toPort: item.toPort ? item.toPort : '-',
              fromBerth: item.fromBerth ? item.fromBerth : '-',
              toBerth: item.toBerth ? item.toBerth : '-',
              actualArrival: DataFormate(item.actualArrival),
              actualDeparture: DataFormate(item.actualDeparture),
              estimatedArrival: DataFormate(item.estimatedArrival),
              estimatedtimeArrival: TimeFormate(item.estimatedArrival),
              estimatedDeparture: DataFormate(item.estimatedDeparture),
              estimatedtimeDeparture: TimeFormate(item.estimatedDeparture),
              earliestReceivingDate: DataFormate(item.earliestReceivingDate),
              earliestReceivingTime: TimeFormate(item.earliestReceivingDate),
              cutoffDate: item.cutoffDate ? getDate(item.cutoffDate) : "-",
              cutoffTime: TimeFormate(item.cutoffDate),
              draftIn: item.draftIn !== null && item.draftIn !== 
              "" ? item.draftIn : '-',
              draftOut: item.draftOut !== null && item.draftOut !== '' ? item.draftOut : '-',
              firstLine: item.firstLine !== null && item.firstLine !== '' ? item.firstLine : '-',
              lastLine: item.lastLine !== null && item.lastLine !== '' ? item.lastLine : '-',
              latitude: item.latitude,
              longitude: item.longitude,
              modifiedUser: item.modified  && item.modified.user ? item.modified.user : '-',
              modifiedDate: item.modified  && item.modified.date ? DataFormate(item.modified.date) : '-',
              
            };
          });
          setNewList(newList);
        }
        break;
      case "modal3":
        setModal1(false);
        setModal2(false);
        setModal3(true);
        let setnewOnReportData1 = getonTimeReportData.map((elt) => {
          let obj = {
            ...elt,
            vesselName: elt.vesselName || "-",
            navigationStatus: elt.navigationStatus || "-",
            vesselType: elt.vesselType || "-",
            destination: elt.destination || "-",
            estimatedArrival: DataFormate(elt.estimatedArrival),
            mmsi: elt.mmsi,
            modifiedUSer : elt.modified.user ? elt.modified.user : '-',
            modifiedDate:  elt.modified.date ?  DataFormate(elt.modified.date) : '-'
          };
          return obj;
        });
        setnewOnReportData(setnewOnReportData1);
        break;
      default:
        break;
    }
  };



  let headerVesselCvs = [
    { label: languageJson.Report_Export_Vessel_Operations_Lable.SchedId, key : "scheduleId"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.MoveId, key : "movementId"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.VesselName, key : "vesselName"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Flag, key : "flag"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.VesselType, key : "vesselType"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.LloydsNum, key : "lloydsNumber"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.MMSI, key : "mmsi"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CallSign, key : "callSign"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Owner, key : "owner"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Draft, key : "draft"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.NetWeight, key : "netWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.GrossWeight, key : "grossWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Length, key : "length"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Breadth, key : "breadth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DeadWeight, key : "deadWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.PortRegistry, key : "portOfRegistry"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.YearBuilt, key : "yearBuilt"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Berth, key : "berth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Terminal, key : "terminal"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FromPort, key : "fromPort"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ToPort, key : "toPort"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FromBerth, key : "fromBerth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ToBerth, key : "toBerth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ActualArrival, key : "actualArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ActualDeparture, key : "actualDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalDate, key : "estimatedArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalTime, key : "estimatedtimeArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureDate, key : "estimatedDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureTime, key : "estimatedtimeDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedDate, key : "earliestReceivingDate"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedTime, key : "earliestReceivingTime"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CutOffDate, key : "cutoffDate"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CutOffTime, key : "cutoffTime"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DraftIn, key : "draftIn"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DraftOut, key : "draftOut"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FirstLine, key : "firstLine"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.LastLine, key : "lastLine"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Lat, key : "latitude"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Long, key : "longitude"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ModUser, key : "modifiedUser"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ModDate, key : "modifiedDate"}
  ];
  const vesselOperationCVS = {
    data: newList,
    headers: headerVesselCvs,
    filename: languageJson.Vessel_OperationCSV_txt,
  };

  // for pdf generate from vessel operation

  let headerVesselOperationPDF = [[
    "Schedule Id",
    "Movement Id",
    "Vessel Name",
    "Flag",
    "Vessel Type"	,
    "Lloyds Number",
    "MMSI",
    "Call Sign",
    "Owner",
    "Draft",
    "Net Weight", 
    "Gross Weight",
    "Length",
    "Breadth",
    "Dead Weight",
    "Port Of Registry",
    "Year Built",
    "Berth",
    "Terminal",
    "From Port",
    "To Port",
    "From Berth",
    "To Berth",
    "Actual Arrival",
    "Actual Departure",
    "Estimated Arrival Date",
    "Estimated Arrival Time",
    "Estimated Departure Date",
    "Estimated Departure Time",
    "Earliest Received Date",
    "Earliest Received Time",
    "Cut-Off Date",
    "Cut-Off Time",
    "Draft In",
    "Draft Out",
    "First Line",
    "Last Line",
    "Latitude",
    "Longitude",
    "Modified User",
    "Modified Date"
   ]];
  let Vesseloperationdata =
    newList.length &&
    newList.map((elt) => [
      elt.scheduleId,
      elt.movementId,
      elt.vesselName,
      elt.flag,
      elt.vesselType,
      elt.lloydsNumber,
      elt.mmsi,
      elt.callSign,
      elt.owner,
      elt.draft,
      elt.netWeight,
      elt.grossWeight,
      elt.length,
      elt.breadth,
      elt.deadWeight,
      elt.portOfRegistry,
      elt.yearBuilt,
      elt.berth,
      elt.terminal,
      elt.fromPort,
      elt.toPort,
      elt.fromBerth,
      elt.toBerth,
      elt.actualArrival,
      elt.actualDeparture,
      elt.estimatedArrival,
      elt.estimatedtimeArrival,
      elt.estimatedDeparture,
      elt.estimatedtimeDeparture,
      elt.earliestReceivingDate,
      elt.earliestReceivingTime,
      elt.cutoffDate,
      elt.cutoffTime,
      elt.draftIn,
      elt.draftOut,
      elt.firstLine,
      elt.lastLine,
      elt.latitude,
      elt.longitude,
      elt.modifiedUser,
      elt.modifiedDate,
  ]);

  // for pdf generate from report on time tab 3

  let headerReportOnTimePDF = [
    [
    "Location Id",
    "MMSI",
    "Call Sign",
    "Course Over Ground",
    "Destination",
    "Distance To Bow",
    "Distance To Port",
    "distance To Starboard",
    "Distance To Stern",
    "Draft",
    "Estimated Arrival",
    "IMO",
    "Latitude",
    "Longitude",
    "Vessel Name",
    "Navigation Status",
    "Rate Of Turn",
    "Vessel Type",
    "Speed Over Ground",
    "True Heading",
    "Modified by User",
    "Modified on Date"
    ],
  ];

  let getontimereportDataForPfd =
    getonTimeReportData !== null &&
    getonTimeReportData.length &&
    getonTimeReportData.map((elt) => [
      elt.locationId,
      elt.mmsi,
      elt.callSign,
      elt.courseOverGround,
      elt.destination || "-",
      elt.distanceToBow,
      elt.distanceToPort,
      elt.distanceToStarboard,
      elt.distanceToStern,
      elt.draft,
      DataFormate(elt.estimatedArrival),
      elt.imo,
      elt.latitude,
      elt.longitude,
      elt.vesselName || "-",
      elt.navigationStatus || "-",
      elt.rateOfTurn,
      elt.vesselType || "-",
      elt.speedOverGround,
      elt.trueHeading,
      elt.modified.user ? elt.modified.user : '-',
      elt.modified.date ?  DataFormate(elt.modified.date) : '-'
    ]);
  //For CVS generate From report on time Data
  const reportonTabCVSheaders = [
    { label: languageJson.Report_Export_On_Time_Lable.LocId, key:"locationId"},
    { label: languageJson.Report_Export_On_Time_Lable.MMSI, key:"mmsi"},
    { label: languageJson.Report_Export_On_Time_Lable.CallSign, key:"callSign"},
    { label: languageJson.Report_Export_On_Time_Lable.COG, key:"courseOverGround"},
    { label: languageJson.Report_Export_On_Time_Lable.Dest, key:"destination"},
    { label: languageJson.Report_Export_On_Time_Lable.DistBow, key:"distanceToBow"},
    { label: languageJson.Report_Export_On_Time_Lable.DistPort, key:"distanceToPort"},
    { label: languageJson.Report_Export_On_Time_Lable.DistStarboard, key:"distanceToStarboard"},
    { label: languageJson.Report_Export_On_Time_Lable.DistStern, key:"distanceToStern"},
    { label: languageJson.Report_Export_On_Time_Lable.Draft, key:"draft"},
    { label: languageJson.Report_Export_On_Time_Lable.EstArrival, key:"estimatedArrival"},
    { label: languageJson.Report_Export_On_Time_Lable.IMO, key:"imo"},
    { label: languageJson.Report_Export_On_Time_Lable.Lat, key:"latitude"},
    { label: languageJson.Report_Export_On_Time_Lable.Long, key:"longitude"},
    { label: languageJson.Report_Export_On_Time_Lable.VesselName, key:"vesselName"},
    { label: languageJson.Report_Export_On_Time_Lable.NavStatus, key:"navigationStatus"},
    { label: languageJson.Report_Export_On_Time_Lable.RateOfTurn, key:"rateOfTurn"},
    { label: languageJson.Report_Export_On_Time_Lable.VesselType, key:"vesselType"},
    { label: languageJson.Report_Export_On_Time_Lable.SOG, key:"speedOverGround"},
    { label: languageJson.Report_Export_On_Time_Lable.TrueHeading, key:"trueHeading"},
    { label: languageJson.Report_Export_On_Time_Lable.ModUser, key:"modifiedUSer"},
    { label: languageJson.Report_Export_On_Time_Lable.ModDate, key:"modifiedDate"}
  ];

  const reportOnTimecsvReport = {
    data: newOnReportData,
    headers: reportonTabCVSheaders,
    filename: languageJson.Vessel_OnTimePerformanceCSV_txt,
  };

  // xml file fomate dowload

  const downloadXml = (type) => {
    // xml file download in terminal data
    console.log("DataSetForTerminal", DataSetForTerminal)
    if (type === "marin") {
      exportFromJSON({
        data: DataSetForTerminal,
        fileName: languageJson.terminal_report_txt,
        exportType: "xml",
      });
    }
    ///For xml generate From report on vessel operation
    if (type === "vessel") {
      exportFromJSON({
        data: DataSetForVessel,
        fileName: languageJson.Vessel_Operation_txt,
        exportType: "xml",
      });
    }
    ///For xml generate From report on time Data
    if (type === "VesselOnTimePerformanceReport") {
      exportFromJSON({
        data: DataSetForTimeReport,
        fileName: languageJson.Vessel_OnTimePerformance_txt,
        exportType: "xml",
      });
    }
  };

  //Excel formate download

  const DataSetForTerminal =
    marineList !== null &&
    marineList.map((data) => {
      return {
        [languageJson.Report_Export_IngateOutgate_Lable.Id] : data.terminal_gate_id,
        [languageJson.Report_Export_IngateOutgate_Lable.Name] : data.gate_name,
        [languageJson.Report_Export_IngateOutgate_Lable.TotalWaitTime] : data.current_street_wait_time !== null && data.current_street_wait_time !==
        "null" && data.current_street_wait_time && data.current_street_wait_time !== ""
        ? data.current_street_wait_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.TrucksInQueue] : data.current_trucks_in_queue !== null && data.current_trucks_in_queue !=="null" && data.current_trucks_in_queue && data.current_trucks_in_queue !== ""? data.current_trucks_in_queue : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.CurrentTrucks] : data.current_trucks_in_terminal,
        [languageJson.Report_Export_IngateOutgate_Lable.QueueLength] : convertMetersToMiles(data.current_queue_length),
        [languageJson.Report_Export_IngateOutgate_Lable.AvgTurnTime] : data.today_turn_time + data.today_street_wait_time,
        [languageJson.Report_Export_IngateOutgate_Lable.TotalTrucks] :data.today_volume !== null && data.today_volume !==
        "null" && data.today_volume && data.today_volume !== ""? data.today_volume : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.WaitTime] : data.today_street_wait_time !== null && data.today_street_wait_time !==
        "null" && data.today_street_wait_time && data.today_street_wait_time !== ""? data.today_street_wait_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.InOutTime] : data.today_turn_time !== null && data.today_turn_time !==
        "null" && data.today_turn_time && data.today_turn_time !== ""? data.today_turn_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.TruckException] : "-"
      };
    });

  const DataSetForVessel = newList.map((data) => {
    return {
      [languageJson.Report_Export_Vessel_Operations_Lable.SchedId]: data.scheduleId,
      [languageJson.Report_Export_Vessel_Operations_Lable.MoveId]: data.movementId,
      [languageJson.Report_Export_Vessel_Operations_Lable.VesselName]: data.vesselName,
      [languageJson.Report_Export_Vessel_Operations_Lable.Flag]: data.flag,
      [languageJson.Report_Export_Vessel_Operations_Lable.VesselType]: data.vesselType,
      [languageJson.Report_Export_Vessel_Operations_Lable.LloydsNum]: data.lloydsNumber,
      [languageJson.Report_Export_Vessel_Operations_Lable.MMSI]: data.mmsi,
      [languageJson.Report_Export_Vessel_Operations_Lable.CallSign]: data.callSign,
      [languageJson.Report_Export_Vessel_Operations_Lable.Owner]: data.owner,
      [languageJson.Report_Export_Vessel_Operations_Lable.Draft]: data.draft,
      [languageJson.Report_Export_Vessel_Operations_Lable.NetWeight]: data.netWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.GrossWeight]: data.grossWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.Length]: data.length,
      [languageJson.Report_Export_Vessel_Operations_Lable.Breadth]: data.breadth,
      [languageJson.Report_Export_Vessel_Operations_Lable.DeadWeight]: data.deadWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.PortRegistry]: data.portOfRegistry,
      [languageJson.Report_Export_Vessel_Operations_Lable.YearBuilt]: data.yearBuilt,
      [languageJson.Report_Export_Vessel_Operations_Lable.Berth]: data.berth,
      [languageJson.Report_Export_Vessel_Operations_Lable.Terminal]: data.terminal,
      [languageJson.Report_Export_Vessel_Operations_Lable.FromPort]: data.fromPort,
      [languageJson.Report_Export_Vessel_Operations_Lable.ToPort]: data.toPort,
      [languageJson.Report_Export_Vessel_Operations_Lable.FromBerth]: data.fromBerth,
      [languageJson.Report_Export_Vessel_Operations_Lable.ToBerth]: data.toBerth,
      [languageJson.Report_Export_Vessel_Operations_Lable.ActualArrival]: data.actualArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.ActualDeparture]: data.actualDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalDate]: data.estimatedArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalTime]: data.estimatedtimeArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureDate]: data.estimatedDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureTime]: data.estimatedtimeDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedDate]: data.earliestReceivingDate,
      [languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedTime]: data.earliestReceivingTime,
      [languageJson.Report_Export_Vessel_Operations_Lable.CutOffDate]: data.cutoffDate,
      [languageJson.Report_Export_Vessel_Operations_Lable.CutOffTime]: data.cutoffTime,
      [languageJson.Report_Export_Vessel_Operations_Lable.DraftIn]: data.draftIn,
      [languageJson.Report_Export_Vessel_Operations_Lable.DraftOut]: data.draftOut,
      [languageJson.Report_Export_Vessel_Operations_Lable.FirstLine]: data.firstLine,
      [languageJson.Report_Export_Vessel_Operations_Lable.LastLine]: data.lastLine,
      [languageJson.Report_Export_Vessel_Operations_Lable.Lat]: data.latitude,
      [languageJson.Report_Export_Vessel_Operations_Lable.Long]: data.longitude,
      [languageJson.Report_Export_Vessel_Operations_Lable.ModUser]: data.modifiedUser,
      [languageJson.Report_Export_Vessel_Operations_Lable.ModDate]: data.modifiedDate
    };
  });

  const DataSetForTimeReport = newOnReportData.map((data) => {
    return {
      [languageJson.Report_Export_On_Time_Lable.LocId]: data.locationId,
      [languageJson.Report_Export_On_Time_Lable.MMSI]: data.mmsi,
      [languageJson.Report_Export_On_Time_Lable.CallSign]: data.callSign,
      [languageJson.Report_Export_On_Time_Lable.COG]: data.courseOverGround,
      [languageJson.Report_Export_On_Time_Lable.Dest]: data.destination || "-",
      [languageJson.Report_Export_On_Time_Lable.DistBow]: data.distanceToBow,
      [languageJson.Report_Export_On_Time_Lable.DistPort]: data.distanceToPort,
      [languageJson.Report_Export_On_Time_Lable.DistStarboard]: data.distanceToStarboard,
      [languageJson.Report_Export_On_Time_Lable.DistStern]: data.distanceToStern,
      [languageJson.Report_Export_On_Time_Lable.Draft]: data.draft,
      [languageJson.Report_Export_On_Time_Lable.EstArrival]: data.estimatedArrival,
      [languageJson.Report_Export_On_Time_Lable.IMO]: data.imo,
      [languageJson.Report_Export_On_Time_Lable.Lat]: data.latitude,
      [languageJson.Report_Export_On_Time_Lable.Long]: data.longitude,
      [languageJson.Report_Export_On_Time_Lable.VesselName]: data.vesselName || "-",
      [languageJson.Report_Export_On_Time_Lable.NavStatus]: data.navigationStatus || "-",
      [languageJson.Report_Export_On_Time_Lable.RateOfTurn]: data.rateOfTurn,
      [languageJson.Report_Export_On_Time_Lable.VesselType]: data.vesselType || "-",
      [languageJson.Report_Export_On_Time_Lable.SOG]: data.speedOverGround,
      [languageJson.Report_Export_On_Time_Lable.TrueHeading]: data.trueHeading,
      [languageJson.Report_Export_On_Time_Lable.ModUser]: data.modified.user ? data.modified.user : '-',
      [languageJson.Report_Export_On_Time_Lable.ModDate]: data.modified.date ?  DataFormate(data.modified.date) : '-'
    };
  });

  return (
   
   
   <div>
      <Header />
      {/* <div className="headerBannerMainDiv">
        <img className="vessel_img" src={trafficCameraBanner} alt="vessel" />
      </div>
       */}
        <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg5} alt=""/>
             <img className="banner_img" src={portimg2} alt=""/>      
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              {" "}
              <span className="vo-menu pe-2">
                {languageJson.home_txt}{" "}
              </span>{" "}
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.operation_reports_text_txt}
            </span>
          </div>
        </div>
      </div>

      <div className="reportTabsMainDiv">
        <div className="container">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#tabs-1"
                role="tab"
                onClick={getMerineReport}
              >
                {languageJson.terminal_turn_time_txt}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#tabs-2"
                role="tab"
                onClick={getVesselReport}
              >
                {languageJson.vessel_operations_txt}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#tabs-3"
                role="tab"
                onClick={getonTimeReport}
              >
                {languageJson.vessel_performance_txt}
              </a>
            </li>
          </ul>

          <div className="tab-content">
          
            <div className="tab-pane active" id="tabs-1" role="tabpanel">
          
            {marineList !== null && marineList.length > 0 && (
                <button
                  className="downloadBtnLeft"
                  onClick={() => handleShow("modal1")}
                >
                  {languageJson.download_txt}
                </button>
              )}
              <SearchComponent
                placeholder={languageJson.search_using_terminal_name}
                onChange={handleChange}
                onClickSearch={onClickSearch}
                type={"marin"}
                value={searchValue}
              />
              {marineList !== null &&
                marineList.length &&
                marineList.length > 0 ? (
                <>
                  <div className="tableOverFlow">
                    <table>
                      <thead>
                        <tr>
                          <td>{languageJson.terminal_gate_name_txt}</td>
                          <td>{languageJson.total_wait_time_txt}</td>
                          <td>{languageJson.Average_Today_txt}</td>
                          <td>{languageJson.Street_Queue_Length_txt}</td>
                          <td>{languageJson.Trucks_in_Queue_txt}</td>
                          <td>{languageJson.Street_Wait_Time_txt}</td>
                          <td>{languageJson.Terminal_Turn_Time_txt}</td>
                          <td>{languageJson.total_trucks_txt}</td>
                          {/* <td>{languageJson.truck_inGate_outGate_txt}</td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {marineList.map((temp, index) => {
                          return (
                            <tr>
                              <td>
                                {temp.gate_name !== null &&
                                  temp.gate_name.toLowerCase() !== "null" &&
                                  temp.gate_name !== ""
                                  ? temp.gate_name
                                  : "-"}
                              </td>
                              <td>
                                {temp.current_street_wait_time !== null &&
                                  temp.current_street_wait_time !==
                                  "null" &&
                                  temp.current_street_wait_time !== ""
                                  ? temp.current_street_wait_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {(temp.today_turn_time !== null &&
                                  temp.today_turn_time !== "null" &&
                                  temp.today_turn_time !== ""
                                  ? temp.today_turn_time
                                  : 0) +
                                  (temp.today_street_wait_time !== null &&
                                    temp.today_street_wait_time !==
                                    "null" &&
                                    temp.today_street_wait_time !== ""
                                    ? temp.today_street_wait_time
                                    : 0)}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.current_queue_length !== null &&
                                  temp.current_queue_length !==
                                  "null" &&
                                  temp.current_queue_length !== ""
                                  ? convertMetersToMiles(temp.current_queue_length)
                                  : 0}{" "}
                                {languageJson.miles_txt}
                              </td>
                              <td>
                                {temp.current_trucks_in_queue !== null && temp.current_trucks_in_queue !==
                                  "null" &&
                                  temp.current_trucks_in_queue !== ""
                                  ? temp.current_trucks_in_queue
                                  : 0}{" "}{languageJson.trucks_txt}
                              </td>
                              <td>
                                {temp.today_street_wait_time !== null &&
                                  temp.today_street_wait_time !==
                                  "null" &&
                                  temp.today_street_wait_time !== ""
                                  ? temp.today_street_wait_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.today_turn_time !== null &&
                                  temp.today_turn_tim !== "null" &&
                                  temp.today_turn_time !== ""
                                  ? temp.today_turn_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.today_volume !== null &&
                                  temp.today_volume !== "null" &&
                                  temp.today_volume !== ""
                                  ? temp.today_volume
                                  : 0}
                              </td>
                              {/* <td>-</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  
                    <div className="container">
          {/* <div className="titleDescpDiv" style={{marginTop:"25px"}}>
            <h6>Note: This report is based on the marine terminal's present position for the previous 30 minutes worth 
              of present Ingate/Outgate Time (Terminal Turn Time).It could change depending on the most recent updates 
              following the subsequent update.</h6>
          </div> */}
          <div>
          <NoteInstruction />
          </div>
          </div>
                  </div>
                </>
              ) : marineList != null && marineList.length === 0 ? (
                <DataNotFound />
              ) : (
                <SpinerLoader />
              )}

              <br />
              <br />
             
            </div>
            <div className="tab-pane" id="tabs-2" role="tabpanel">
            {list && list.length > 0 && (
                <button
                  className="downloadBtnLeft"
                  onClick={() => handleShow("modal2")}
                >
                  {languageJson.download_txt}
                </button>
              )}
              <SearchComponent
                placeholder={languageJson.search_using_vessel_name}
              // placeholder={languageJson.search_using_terminal_name}
                onChange={handleChange}
                onClickSearch={onClickSearch}
                type={"vessel"}
                value={searchValue}
              />
              {list != null && list.length && list.length > 0 ? (
                <>
                  <div className="tableOverFlow">
                    <table>
                      <thead>
                        <tr>
                          <td>{languageJson.Vessel_Name_txt}</td>
                          <td>{languageJson.estimated_arrivalDate_txt}</td>
                          <td>{languageJson.estimated_arrivalTime_txt}</td>
                          <td>{languageJson.berth_txt}</td>
                          <td>{languageJson.estimated_departureDate_txt}</td>
                          <td>{languageJson.estimated_departureTime_txt}</td>
                          <td>{languageJson.earliest_receivedDate_txt}</td>
                          <td>{languageJson.earliest_receivedTime_txt}</td>
                          <td>{languageJson.cutOffDate_txt}</td>
                          <td>{languageJson.cutOffTime_txt}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {item.vesselName === "" ||
                                  item.vesselName.toLowerCase() === "null" ||
                                  item.vesselName === null
                                  ? "-"
                                  : item.vesselName}
                              </td>
                              <td>
                                {item.estimatedArrival === "" ||
                                  item.estimatedArrival ===
                                  "null" ||
                                  item.estimatedArrival === null
                                  ? "-"
                                  : DataFormate(item.estimatedArrival)}
                              </td>
                              <td>
                                {item.estimatedArrival === "" ||
                                  item.estimatedArrival ===
                                  "null" ||
                                  item.estimatedArrival === null
                                  ? "-"
                                  : TimeFormate(item.estimatedArrival)}
                              </td>
                              <td>{item.berth || "-"}</td>
                              <td>
                                {item.estimatedDeparture === "" ||
                                  item.estimatedDeparture ===
                                  "null" ||
                                  item.estimatedDeparture === null
                                  ? "-"
                                  : DataFormate(item.estimatedDeparture)}
                              </td>
                              <td>
                                {item.estimatedDeparture === "" ||
                                  item.estimatedDeparture ===
                                  "null" ||
                                  item.estimatedDeparture === null
                                  ? "-"
                                  : TimeFormate(item.estimatedDeparture)}
                              </td>
                              <td>
                                {item.earliestReceivingDate === "" ||
                                  item.earliestReceivingDate ===
                                  "null" ||
                                  item.earliestReceivingDate === null
                                  ? "-"
                                  : DataFormate(item.earliestReceivingDate)}
                              </td>
                              <td>
                                {item.earliestReceivingDate === "" ||
                                  item.earliestReceivingDate ===
                                  "null" ||
                                  item.earliestReceivingDate === null
                                  ? "-"
                                  : TimeFormate(item.earliestReceivingDate)}
                              </td>

                              <td>
                                {item.cutoffDate === "" ||
                                  item.cutoffDate === "null" ||
                                  item.cutoffDate === null
                                  ? "-"
                                  : getDate(item.cutoffDate)}
                              </td>
                              <td>
                                {item.cutoffDate === "" ||
                                  item.cutoffDate === "null" ||
                                  item.cutoffDate === null
                                  ? "-"
                                  : TimeFormate(item.cutoffDate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : list != null && list.length === 0 ? (
                <DataNotFound />
              ) : (
                <SpinerLoader />
              )}
             
            </div>

            <div className="tab-pane " id="tabs-3" role="tabpanel">
            {getonTimeReportData != null && getonTimeReportData.length > 0 && (
                <button
                  className="downloadBtnLeft"
                  onClick={() => handleShow("modal3")}
                >
                  {languageJson.download_txt}
                </button>
              )}
              <SearchComponent
             placeholder={languageJson.search_using_vessel_name}
                onChange={handleChange}
                onClickSearch={onClickSearch}
                type={"report"}
                value={searchValue}
              />
              {getonTimeReportData != null &&
                getonTimeReportData.length &&
                getonTimeReportData.length > 0 ? (
                <>
                  <div className="tableOverFlow vesselPerformanceReportDiv">
                    <table>
                      <thead>
                        <tr>
                          <td>{languageJson.Vessel_Name_txt}</td>
                          <td>{languageJson.Navigation_Status_txt}</td>
                          <td>{languageJson.Vessel_type_txt}</td>
                          <td>{languageJson.enter_destination_txt}</td>
                          <td>{languageJson.estimated_arrivalDate_txt}</td>
                          <td>{languageJson.MMSI_txt}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {getonTimeReportData.map((temp, index) => {
                          return (
                            <tr>
                              <td>
                                {temp.vesselName === "" ||
                                  temp.vesselName.toLowerCase() === "null" ||
                                  temp.vesselName === null
                                  ? "-"
                                  : temp.vesselName}
                              </td>
                              <td>
                                {temp.navigationStatus === "" ||
                                  temp.navigationStatus.toLowerCase() ===
                                  "null" ||
                                  temp.navigationStatus === null
                                  ? "-"
                                  : temp.navigationStatus}
                              </td>
                              <td>
                                {temp.vesselType === "" ||
                                  temp.vesselType.toLowerCase() === "null" ||
                                  temp.vesselType === null
                                  ? "-"
                                  : temp.vesselType}
                              </td>
                              <td>
                                {temp.destination === "" ||
                                  temp.destination.toLowerCase() === "null" ||
                                  temp.destination === null
                                  ? "-"
                                  : temp.destination}
                              </td>
                              <td>
                                {temp.estimatedArrival === "" ||
                                  temp.estimatedArrival.toLowerCase() ===
                                  "null" ||
                                  temp.estimatedArrival === null
                                  ? "-"
                                  : getDate(temp.estimatedArrival)}
                              </td>
                              <td>
                                {temp.mmsi === "" ||
                                  temp.mmsi === "null" ||
                                  temp.mmsi === null
                                  ? "-"
                                  : temp.mmsi}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : getonTimeReportData != null &&
                getonTimeReportData.length === 0 ? (
                <DataNotFound />
              ) : (
                <SpinerLoader />
              )}
              <br />
              <br />
             
            </div>
          </div>
        </div>
      </div>
      <>
        {" "}
        <Modal
          show={show}
          centered
          backdrop="static"
          onHide={handleClose}
          animation={true}
          className="reportModalClass"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <h5>{languageJson.select_format_txt}</h5>
            <div className="downloadOptDiv">
              {modal1 && (
                <div className="downloadOptInnerList">
                  <span title={export_CSV_txt}>
                    <CSVLink
                      {...terminealcsvReport}
                      className="downloadBtny btn btn-primary downloadbutton"
                    >
                      <FontAwesomeIcon icon={faFileCsv} size="lg" />
                    </CSVLink>
                  </span>
                  <span title={export_PDF_txt}>
                    <PdfGenerater
                      data={Terminaldata}
                      buttonclassName={"pdfbutton"}
                      header={headerMarine}
                      buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                      title={languageJson.Terminal_Turn_Time_txt}
                      fileName={languageJson.terminal_report_txt}
                      size={'A1'}
                    />
                  </span>
                  <span title={export_Excel_txt}>
                    <JsonToExcel
                      title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                      data={DataSetForTerminal}
                      fileName={languageJson.terminal_report_txt}
                      btnClassName="downloadBtny btn btn-primary downloadbutton"
                    />
                  </span>
                  <div title={export_XML_txt}>
                    <button
                      className="downloadBtny btn btn-primary downloadbutton"
                      onClick={() => downloadXml("marin")}
                    >
                      <FontAwesomeIcon icon={faFileCode} size="lg" />
                    </button>
                  </div>
                </div>
              )}
              {modal2 && (
                <div className="downloadOptInnerList">
                  <span title={export_CSV_txt}>
                    <CSVLink
                      {...vesselOperationCVS}
                      className="downloadBtny btn btn-primary downloadbutton"
                    >
                      <FontAwesomeIcon icon={faFileCsv} size="lg" />
                    </CSVLink>
                  </span>
                  <span title={export_PDF_txt}>
                    <PdfGenerater
                      data={Vesseloperationdata}
                      buttonclassName={"pdfbutton"}
                      buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                      title={languageJson.vesselOperation_txt}
                      fileName={languageJson.Vessel_Operation_txt}
                      header={headerVesselOperationPDF}
                      size={'A1'}
                    />
                  </span>
                  <span title={export_Excel_txt}>
                    <JsonToExcel
                      title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                      data={DataSetForVessel}
                      fileName={languageJson.Vessel_Operation_txt}
                      btnClassName="downloadBtny btn btn-primary downloadbutton"
                    />
                  </span>
                  <div title={export_XML_txt}>
                    <button
                      className="downloadBtny btn btn-primary downloadbutton"
                      onClick={() => downloadXml("vessel")}
                    >
                      {<FontAwesomeIcon icon={faFileCode} size="lg" />}
                    </button>
                  </div>
                </div>
              )}

              {modal3 && (
                <div className="downloadOptInnerList">
                  <span title={export_CSV_txt}>
                    <CSVLink
                      {...reportOnTimecsvReport}
                      className="downloadBtny btn btn-primary downloadbutton"
                    >
                      <FontAwesomeIcon icon={faFileCsv} size="lg" />
                    </CSVLink>
                  </span>
                  <span title={export_PDF_txt}>
                    <PdfGenerater
                      data={getontimereportDataForPfd}
                      buttonclassName={"pdfbutton"}
                      buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                      title={languageJson.on_time_txt}
                      fileName={languageJson.Vessel_OnTimePerformance_txt}
                      header={headerReportOnTimePDF}
                      size={'A1'}
                    />
                  </span>
                  <span title={export_Excel_txt}>
                    <JsonToExcel
                      title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                      data={DataSetForTimeReport}
                      fileName={languageJson.Vessel_OnTimePerformance_txt}
                      btnClassName="downloadBtny btn btn-primary downloadbutton"
                    />
                  </span>
                  <div title={export_XML_txt}>
                    <button
                      className="downloadBtny btn btn-primary downloadbutton"
                      onClick={() =>
                        downloadXml("VesselOnTimePerformanceReport")
                      }
                    >
                      <FontAwesomeIcon icon={faFileCode} size="lg" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
      {/* <Findus /> */}
    </div>
  );
}

export default OperationalReports;
