/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect } from "react";
import Header from "src/Containers/Header";
 import { Link } from "react-router-dom";
import trafficCamera_image from "src/assets/trafficcamera-image.png";
import axios from "src/redux/actions/apis";
import { GET_SECURITY_AND_SAFETY_API } from "src/url/Url";
import { languageJson } from 'src/StringVariable';
import "./CamerasPreview.css";
import OaklandCamera from "./OaklandCamera";
import RegionalCamerasOnMap from "./RegionalCamerasOnMap";
import { submitGAEvent } from "src/utils/GAEvents";
 
import portimg1 from "../../assets/CameraSnapShots/1.jpg";
import portimg2 from "../../assets/CameraSnapShots/2.jpg";
import portimg3 from "../../assets/CameraSnapShots/3.jpg"; 
// safetyandsecurity/osowinfo
function CamerasPreview() {
  const [regionalCamera, setRegionalCamera] = useState(false);
  const [oaklandCamera, setOoaklandCamera] = useState(true);
  const onVesselLocations = () => {
    submitGAEvent("VESSEL SCHEDULE");
    setOoaklandCamera(true);
    setRegionalCamera(false);
  };

  const onRegionalCamera = () => {
    setOoaklandCamera(false);
    setRegionalCamera(true);
  };

  const getOwOsInfo = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(GET_SECURITY_AND_SAFETY_API, requestObj)
        .then((res) => res.data);
    } catch (err) { }
  };

  useEffect(() => {
    getOwOsInfo();
  }, []);

  return (
    <div>
      <Header />
    
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg3} alt=""/>
             <img className="banner_img" src={portimg2} alt=""/>      
      </div>
      {/* <div className="headerBannerMainDiv">
        <img className="vessel_img" src={vessel_image} alt="vessel" />
      </div> */}
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.Cameras_Information_txt}</span>
            </p>
          </div>
        </div>
      </div>
      {/* #0d6efd */}

      <div className="container vesselMainPage securitySafetyMain CamerasInfoDiv">
        <div className="row container-fluid py-5 tabVesselDiv">
        <div className="col-6 col-sm-6 center-head-underline titleTabOption" >
            <a
              onClick={() => {
                onVesselLocations();
              }}
              className={`text-decoration-none text-dark ${oaklandCamera
                ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } cursor-pointer`}
            >
              <h2
                className={`${oaklandCamera ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={trafficCamera_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.OaklandPortalCameras_txt} </b>
              </h2>{" "}
            </a>
          </div>
          <div className="col-6 col-sm-6 titleTabOption "  >
            <a
              onClick={() => {
                onRegionalCamera();
              }}
              className={`text-decoration-none ${regionalCamera
                ? "center-head-underline-clr vo-headtxt py-2 "
                : ""
                } cursor-pointer vesselOptDiv`}
            >
              <h2
                className={`${regionalCamera ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={trafficCamera_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.Regional_Cameras_txt} </b>
              </h2>{" "}
            </a>
          </div>
        </div>
        {regionalCamera && <RegionalCamerasOnMap />}
        {oaklandCamera && oaklandCamera ? (
          <div className="vesselMapTab osowInfoDiv oaklandPortalCamerasMapMain">
            <div className="osowInfoInnerDiv human-resources-section border-0">
              <div className="container-fuild">
                <div className=" homeresources-div">
                  <OaklandCamera />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        

      </div>
    </div>
  );
}

export default CamerasPreview;
