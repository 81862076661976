import "./ReleaseNote.css";
import DataNotFound from "../DataNotFound/DataNotFound";
import moment from "moment";

export default function Website(props) {
  let { data } = props;
  return (
    <>
      {data.length > 0 ? (
        <div className="classesnavright">
          <ul>
            {data.map((item)=>(
            <div key={item.id} className="divComponent">
              <h3>{item.release_version}</h3>
              <p>{item.published_on.toLowerCase() !== "null" ||
                          item.published_on !== null
                          ? moment(item.published_on).format("Do MMM YYYY")
                          : "-"}
                </p>
              <h4>{item.release_title}</h4>
              <pre className="contentClassList" dangerouslySetInnerHTML={{ __html: item.description === null ||item.description.toLowerCase() === "null" ? "-" : item.description }}></pre>
            </div>
            ))}
          </ul>
        </div>
      ) : (
        <DataNotFound />
      )}
    </>
  );
}
