import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { languageJson } from 'src/StringVariable';


export default function PdfGenerater(props) {
  const exportPDF = () => {
    const unit = "pt";
    const size = props.size || "A4"; // Use A1, A2, A3 or A4
    const orientation = props.orientation || "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(props.fontSize || 15);

    const title = props.title || "report";
    const pdfheaders = props.header || [[]]

    let content = {
      startY: 50,
      head: pdfheaders,
      body: props.data || [],
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${props.fileName}.pdf` || "report.pdf");
  };
  return (
    <div>
      <button onClick={exportPDF} className={`btn btn-primary ${props.buttonClass}`}>{props.buttonTitle || languageJson.generate_pdf_txt}</button>
    </div>
  )
}
