/* eslint-disable no-unused-vars */
import { ActionTypes } from "../constants/Types";
import server from "./apis";
import { GET_GUEST_USER_ID } from "../../url/Url";
import { languageJson } from "src/StringVariable";

const getGuestUserSuccess = (data) => {
  return {
    type: ActionTypes.GUEST_USER_ID_SUCCESS,
    payload: data,
  };
};

export const getGuestUser = (data) => {
  return (dispatch) => {
    server
      .post(`${GET_GUEST_USER_ID}`, data)
      .then((response) => {
        if (response.data.customcode === 200) {
          dispatch(getGuestUserSuccess(response.data));
          localStorage.setItem("firebaseToken", response.data.token)
        } else {
          dispatch(getGuestUserSuccess({ data: [] }));
        }
      })
      .catch((error) => {
        let message = languageJson.something_went_wrong_txt;
        if (!error.response) {
          message = languageJson.network_error_txt;
        } else {
          message = error.response.message;
        }
      });
  };
};