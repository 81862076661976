import { ActionTypes } from "../constants/Types";
const initialState = {
  isLoginSuccess: false,
  isLoginError: false,
  loginMessage: null,
  otpSendMessage: null,
  driverRegisterMessage: null,
  lmcRegisterMessage: null,
  otpVerifyMessage: null,
  contactUsMessage: null,
  forgotPasswordMessage: null,
  changePasswordMessage: null,
  vasselAlertsDataMessage: null,
  railAlertsDataMessage: null,
  alertsAnnouncementsMessage: null,
  marineTerminalAlertsDataMessage: null,
  resourceDataMessage: null,
  portMessage: null,
  portConstructionEventsMessage: null,
  portIncidentClosuresMessage: null,
  regionalMessageSignsMessage: null,
  regionalConstructionEventsMessage: null,
  regionalIncidentClosuresMessage: null,
  trafficAndCamerasAlertsDataMessage: null,
  marineTerminalListMessage: null,
  securityAndSafetyAlertsMessage: null,
};

export const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS_MESSAGE:
      return { ...state, isLoginSuccess: true, loginMessage: action.payload };
    case ActionTypes.LOGIN_ERROR_MESSAGE:
      return { ...state, isLoginError: true, loginMessage: action.payload };
    case ActionTypes.OTP_SEND_MESSAGE:
      return { ...state, otpSendMessage: action.payload };
    case ActionTypes.DRIVER_REGISTER_MESSAGE:
      return { ...state, driverRegisterMessage: action.payload };
    case ActionTypes.LMC_REGISTER_MESSAGE:
      return { ...state, lmcRegisterMessage: action.payload };
    case ActionTypes.OTP_VERIFY_MESSAGE:
      return { ...state, otpVerifyMessage: action.payload };
    case ActionTypes.CONTACT_US_MESSAGE:
      return { ...state, contactUsMessage: action.payload };
    case ActionTypes.FORGOT_PASSWORD_MESSAGE:
      return { ...state, forgotPasswordMessage: action.payload };
    case ActionTypes.CHANGE_PASSWORD_MESSAGE:
      return { ...state, changePasswordMessage: action.payload };
    case ActionTypes.VASSEL_ALERTS_DATA_MESSAGE:
      return { ...state, vasselAlertsDataMessage: action.payload };
    case ActionTypes.RAIL_CROSSING_ALERTS_DATA_MESSAGE:
      return { ...state, railAlertsDataMessage: action.payload };
    case ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_MESSAGE:
      return { ...state, alertsAnnouncementsMessage: action.payload };
    case ActionTypes.MARINE_TERMINAL_ALERTS_DATA_MESSAGE:
      return { ...state, marineTerminalAlertsDataMessage: action.payload };
    case ActionTypes.TRAFFIC_AND_CAMERAS_DATA_MESSAGE:
      return { ...state, trafficAndCamerasAlertsDataMessage: action.payload };
    case ActionTypes.RESOURCE_DATA_MESSAGE:
      return { ...state, resourceDataMessage: action.payload };
    case ActionTypes.PORT_MESSAGE:
      return { ...state, portMessage: action.payload };
    case ActionTypes.PORT_CONSTRUCTION_EVENTS_MESSAGE:
      return { ...state, portConstructionEventsMessage: action.payload };
    case ActionTypes.PORT_INCIDENT_CLOSURES_MESSAGE:
      return { ...state, portIncidentClosuresMessage: action.payload };
    case ActionTypes.REGIONAL_MESSAGE_SIGNS_MESSAGE:
      return { ...state, regionalMessageSignsMessage: action.payload };
    case ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_MESSAGE:
      return { ...state, regionalConstructionEventsMessage: action.payload };
    case ActionTypes.REGIONAL_INCIDENT_CLOSURES_MESSAGE:
      return { ...state, regionalIncidentClosuresMessage: action.payload };
    case ActionTypes.MARINE_TERMINAL_LIST_DATA_MESSAGE:
      return { ...state, marineTerminalListMessage: action.payload };
    case ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_MESSAGE:
      return { ...state, securityAndSafetyAlertsMessage: action.payload };
    case ActionTypes.LOAD_INTIAL_STATE:
      return initialState;
    default:
      return state;
  }
};
