/* eslint-disable*/
import React, { useState, useEffect } from "react";

import axios from "src/redux/actions/apis";
import { VESSEL_SCHEDULES_ADVENT } from "../../url/Url";
import { DateTimeFormateSeconds } from "../../utils/DataFormate";
import DetailVesselOperation from "./DetailVesselOperation";
import "./VesselOperations.css";
import DataNotFound from "../DataNotFound/DataNotFound";
import { languageJson } from "../../StringVariable";
import SpinerLoader from "src/utils/SpinerLoader";
import SearchComponent from "./SearchComponentVessel";
import DetailVesselOperationAdvent from "./DetailVesselOperationAdvent";

function VesselScheduleAdvent(props) {
  // console.log("props-------", props)
  const [VesselOperationsList, setVesselOperationsList] = useState(null);
  const [VesselOperationsListCopy, setVesselOperationsListCopy] =
    useState(null);
  const [mapModal, setMapModal] = useState(false);
  const [marinMapDetail, setMarinMapDetail] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [allMarineTerminals, setAllMarineTerminals] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [isSearch, seIsSearch] = useState(null);


  const setModalOpen = (item) => {
    setMarinMapDetail(item);
    setMapModal(true);
  };
  const setModalClose = () => {
    setMapModal(false);
  };

  const createArrayOfObjects = (data) => {
    const uniqueMap = new Map();
    // Loop through the data array
    data.forEach((item) => {
      // Check if the id is already in the map
      if (!uniqueMap.has(item.terminalName)) {
        // If not, add the object to the map with the id as the key
        uniqueMap.set(item.terminalName, item);
      }
    });
    // Convert the map values back to an array
    const result = Array.from(uniqueMap.values());
    setAllMarineTerminals(result);
    return result;
  };

  const getVesselOperationsData = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setSearchValue("");
    try {
      let {terminalName} = props
      let gateName = terminalName ? terminalName : 'all'
      await axios
        .get(VESSEL_SCHEDULES_ADVENT(gateName), requestObj)
        .then((res) => {
          const { vessels, customcode } = res.data;
          if (customcode === 200 && vessels.length > 0) {
            createArrayOfObjects(vessels);
            setVesselOperationsList(vessels);
            setVesselOperationsListCopy(vessels);
          } else {
            setVesselOperationsList([]);
            setVesselOperationsListCopy([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onSearchClick = async (terminalName) => {
    try {
      setFilterValue(terminalName)
      setSearchValue("")
      const requestObj = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let gateName
      if(terminalName === ""){
       gateName="All"
     }else{
       gateName=terminalName
     }
      await axios
        .get(VESSEL_SCHEDULES_ADVENT(gateName), requestObj)
        .then((res) => {
          const { vessels, customcode } = res.data;
          if (customcode === 200 && vessels.length > 0) {
            //  if(searchValue && searchValue.length>0){
            //   let value = searchValue.toLocaleLowerCase();
            //   let temp = vessels;
            //       let filterData = temp.filter((item) =>
            //         item["vesselName"].toLowerCase().includes(value) ||
            //         item["terminalCode"].toLocaleLowerCase().includes(value) ||
            //         item["voyage"].toLocaleLowerCase().includes(value)
            //           ? true
            //           : false
            //       );
            //   setVesselOperationsList(filterData);
            //  }else{
            //   setVesselOperationsList(vessels);
            //  }
            setVesselOperationsList(vessels);
            setVesselOperationsListCopy(vessels);

          } else {
            setVesselOperationsList([]);
            setVesselOperationsListCopy([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };


  const onSort = (event, sortKey, index) => {
    const data = VesselOperationsList;
    if (
      JSON.stringify(VesselOperationsList) ===
      JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setVesselOperationsList([...VesselOperationsList]);
  };

  const onSortDate = (event, sortKey, index) => {
    const data = VesselOperationsList;
    if (
      JSON.stringify(VesselOperationsList) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - a[sortKey]))
    ) {
      data.sort((a, b) => a[sortKey] - b[sortKey]);
    } else {
      data.sort((a, b) => b[sortKey] - a[sortKey]);
    }
    setVesselOperationsList([...VesselOperationsList]);
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 1) {
      setVesselOperationsList(VesselOperationsListCopy);
    }
  };

  //searching functionlity
  const onClickSearch = (type) => {
    let value = searchValue.toLocaleLowerCase();
    switch (type) {
      case "vesselData": {
        let temp = VesselOperationsListCopy;
        let filterData = temp.filter((item) =>
          item["vesselName"].toLowerCase().includes(value) ||
          //  item["terminalName"].toLocaleLowerCase().includes(value) ||
          item["terminalCode"].toLocaleLowerCase().includes(value) ||
          item["voyage"].toLocaleLowerCase().includes(value)
            ? true
            : false
        );
        setVesselOperationsList(filterData);
        break;
      }
      default:
        break;
    }
  };
  useEffect(() => {
    seIsSearch(props.terminalName)
    getVesselOperationsData();
  }, [props.terminalName]);

  return (
    <div className="reportTabsMainDiv">
    <div className="container">
    <div className="tab-content">
      <div className="row">
        <div className="col-md-6 searchTerminalNameDiv">
          {" "}
          {(VesselOperationsList != null &&
          ((VesselOperationsList.length && VesselOperationsList.length > 0) ||
            (filterValue.length && filterValue.length > 0))) && (isSearch == null) ? (
          <React.Fragment>
          <label className="terminalLabel">{languageJson.select_gate_txt}</label>
          <select
            selected={"All"}
            onChange={(e) => onSearchClick(e.target.value)}
            className="browser-default custom-select searchTerminalNameSelect"
          >
            <option value="">{languageJson.all_txt}</option>
            {allMarineTerminals != null &&
              allMarineTerminals &&
              allMarineTerminals.map((terminal, index) => {
                return (
                  <option value={terminal.terminalName} key={index}>
                    {terminal.terminalName}{" "}
                  </option>
                );
              })}
          </select>
          </React.Fragment>) : (
            <React.Fragment></React.Fragment>
          )}
        </div>

        <div className="col-md-6 advantSearchClass">
          {VesselOperationsList != null &&
          ((VesselOperationsList.length && VesselOperationsList.length > 0) ||
            (searchValue.length && searchValue.length > 0)) ? (
            <SearchComponent
              placeholder={languageJson.search_using_vessel_name}
              onChange={handleChange}
              onClickSearch={onClickSearch}
              type={"vesselData"}
              value={searchValue}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {VesselOperationsList && VesselOperationsList.length > 0 ? (
        <div className="container-fluid vesselTabOptionDiv p-0">
          <div className="innerVesselDiv" style={{padding:"8px"}}>
            <div className="container-fluid vesselTabOptionDiv p-0">
              <div className="vesselinportclass">
                <div className="vessel_estimated zeroBorderRadius row"></div>

                <div className="row vo-namecard-head ">
                  <div
                    className="col-3 text-center text-white cursor-pointer pr-3"
                    onClick={(e) => onSort(e, "vesselName")}
                  >
                    {languageJson.name_txt}{" "}
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  </div>

                  <div
                    className="col-3 text-center text-white cursor-pointer pr-3"
                    onClick={(e) => onSort(e, "terminalName")}
                  >
                    {"Terminal Name"}{" "}
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  </div>

                  <div
                    className="col-2 text-center  text-light  cursor-pointer"
                    onClick={(e) => onSortDate(e, "estimatedDeparture")}
                  >
                    {"ETD"} {/* {"Estimated Departure"}{" "} */}
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  </div>
                  <div
                    className="col-2 text-center  text-light cursor-pointer"
                    onClick={(e) => onSortDate(e, "estimatedArrival")}
                  >
                    {"ETA"}{" "}
                    {/* {languageJson.vessel_estimated_time_on_arrival_txt}{" "} */}
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  </div>
                  <div
                    className="col-2 text-center  text-light cursor-pointer"
                    onClick={(e) => onSortDate(e, "actualArrival")}
                  >
                    {"ATA"} {/* {"Actual Arrival"}{" "} */}
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  </div>
                </div>

                {VesselOperationsList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="row  borderDashed rowDivData cursor-pointer"
                        onClick={() => setModalOpen(item)}
                      >
                        <div className="col-3 text-center   cursor-pointer">
                          {item.vesselName === "" || item.vesselName === null
                            ? "-"
                            : item.vesselName}
                        </div>
                        <div className="col-3 text-center  cursor-pointer">
                          {item.terminalName === "" ||
                          item.terminalName === null
                            ? "-"
                            : item.terminalName}
                        </div>
                        <div className="col-2 text-center  cursor-pointer">
                          {item.estimatedDeparture === "" ||
                          item.estimatedDeparture === null
                            ? "-"
                            : DateTimeFormateSeconds(item.estimatedDeparture)}
                        </div>
                        <div className="col-2 text-center   cursor-pointer">
                          {item.estimatedArrival === "" ||
                          item.estimatedArrival === null
                            ? "-"
                            : DateTimeFormateSeconds(item.estimatedArrival)}
                        </div>
                        <div className="col-2 text-center  cursor-pointer">
                          {item.actualArrival === "" ||
                          item.actualArrival === null
                            ? "-"
                            : DateTimeFormateSeconds(item.actualArrival)}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : VesselOperationsList != null && VesselOperationsList.length === 0 ? (
        <div className="">
          <div className="  text-center border-0">
            {" "}
            <DataNotFound />
          </div>
        </div>
      ) : (
        <div className="  text-center border-0">
          <SpinerLoader />
        </div>
      )}
      {mapModal && (
        <DetailVesselOperationAdvent
          open={mapModal}
          closeModal={setModalClose}
          marinMapDetail={marinMapDetail}
        />
      )}
    </div>
    </div>
    </div>
  );
}
export default VesselScheduleAdvent;
