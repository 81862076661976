import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

function Speedometer({ time }) {
    return (
        <div>
            <ReactSpeedometer
                maxValue={150}
                value={time}
                needleColor="black"
                startColor="#1b9506"
                endColor="#f40909"
                needleHeightRatio={0.5}
                ringWidth={8}
                minValue={0}
                segmentColors={[
                    "#1b9506",
                    "#ffba00",
                    "#f40909",
                ]}
                needleTransitionDuration={4000}
                needleTransition="easeElastic"
                currentValueText={''}
                currentValuePlaceholderStyle={'none'}
                customSegmentStops={[0, 75, 120, 150]}
                paddingVertical={1}
                paddingHorizontal={1}
            />
        </div>
    )
}

export default Speedometer;
