import { ActionTypes } from "../constants/Types";
const initialState = {
    forgotPasswordEmail: null,
};

export const tempStorageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FORGOT_PASSWORD_EMAIL:
            return { ...state, forgotPasswordEmail: action.payload }
        case ActionTypes.CLEAR_DATA:
            return initialState
        default:
            return state;
    }
}