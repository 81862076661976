import { ActionTypes } from "../constants/Types";
import server from "./apis";
import { resourceDataMessage} from "./MessageAction";
import { stopLoading, startResourceDataLoading } from "./LoadingAction";
import { resourceDataStatusCode } from "./StatusCodeAction";
import { GET_USER_RESOURCES_DATA_API} from "../../url/Url";
import { languageJson } from "src/StringVariable";

const resourceDataSuccess = (alerts) => {
    return {
        type: ActionTypes.RESOURCE_DATA_SUCCESS,
        payload: alerts
    }
}

export const resourceData = () => {
    return (dispatch) => {
        dispatch(startResourceDataLoading());
        server.get(`${GET_USER_RESOURCES_DATA_API}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(resourceDataSuccess(response.data.resources))
                    dispatch(resourceDataStatusCode(response.data.customcode));
                } else {
                    dispatch(resourceDataStatusCode(response.data.customcode));
                    dispatch(resourceDataMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(resourceDataMessage(message))
            });;
    };
};
