import React from 'react';
import { Link } from 'react-router-dom';
import pageNotFound from 'src/assets/pageNotFound.png';
import { languageJson } from 'src/StringVariable';
import Header from "../../Containers/Header";
import PageNotFoundComponent from '../../utils/PageNotFound';

const PageNotFound = () => {
    return (

        <React.Fragment>
            <Header />
            <section className="bg-white   pageNotFound404MainDiv">
                <div className="container d-none">
                    <b className="underLineClass"></b>
                    <span><img src={pageNotFound} className="ing-fluid" alt="page not found" /></span>
                    <span className="pageNotTxt">
                        <h1>{languageJson.page_not_found_txt}</h1>
                        <Link to="/" className="backToHomeBtn registerNowBtn">{languageJson.back_to_home_txt}</Link>
                    </span>
                </div>
                <Link to="/" > <PageNotFoundComponent /> </Link>
            </section>
        </React.Fragment>
    )
}

export default PageNotFound;
