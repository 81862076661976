import { ActionTypes } from "../constants/Types";
import server from "./apis";
import { alertsAnnouncementsDataMessage } from "./MessageAction";
import { stopLoading, startAlertsAnnouncementsDataLoading } from "./LoadingAction";
import { alertsAnnouncementsDataStatusCode } from "./StatusCodeAction";
import { GET_ALERT_ANNOUNCEMENTS } from "../../url/Url";
import { languageJson } from "src/StringVariable";

const alertsAnnouncementsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_SUCCESS,
        payload: alerts
    }
}

export const alertsAnnouncementsData = () => {
    return (dispatch) => {
        dispatch(startAlertsAnnouncementsDataLoading());
        server.get(`${GET_ALERT_ANNOUNCEMENTS}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(alertsAnnouncementsDataSuccess(response.data));
                    dispatch(alertsAnnouncementsDataStatusCode(response.data.customcode));
                } else {
                    dispatch(alertsAnnouncementsDataStatusCode(response.data.customcode));
                    dispatch(alertsAnnouncementsDataMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(alertsAnnouncementsDataMessage(message));
            });
    };
};


