import React from "react";
import Header from "src/Containers/Header";
import underConstructionIconImg from "src/assets/underConstructionIcon.png";
import AlertsPage from "./AlertsPage";
//  import portimg1 from "../../assets/1.jpg"; 
// import portimg5 from "../../assets/5.jpg";
// import portimg6 from "../../assets/6.jpg";
import POAKPanorama from "../../assets/POAKPanorama.jpg";

const Alerts = () => {
    return (
        <div>
            <Header />
            {/* <div className="image-row-banner">
             <img className="banner_img" src={portimg5} alt=""/>
             <img className="banner_img" src={portimg1} alt=""/> 
             <img className="banner_img" src={portimg6} alt=""/>      
      </div> */}
            <div className="headerBannerMainDiv">
                <img className="vessel_img" src={POAKPanorama} alt="vessel" />
            </div>
            <div className="col underContPageDiv d-none">
                <div className="container">
                    <div className="underContMainDiv">
                        <img src={underConstructionIconImg} alt="underConstruction"></img>
                    </div>
                </div>
            </div>
            <div className="alertsMainDivPage">
                <div className="container">
                    <div className="allAlertsMainDiv">
                        <div id="accordion">
                            <div className="card parentDivCard">
                                <AlertsPage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Findus /> */}
        </div>
    )
}

export default Alerts;
