import React, { useState, useEffect } from "react";
import { languageJson } from '../../StringVariable';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { GOOGLE_MAP_URL } from "../../url/Url";
import Opcamera from "../../assets/opCamera.png";
import oaklandlogo from "../../assets/new_logo.png";
import { Modal } from 'react-bootstrap';

function CameraModal(props) {
  const [selectedCamera, setSelectedCamera] = useState(null);
  const lat = 37.78929;
  const lng = -122.27405;

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedCamera(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [selectedCamera]);

  useEffect(() => {
  }, []);

  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: lat, lng: lng }}
        options={{ streetViewControl: false }}
      >
        <Marker
          position={{
            lat: parseFloat(props.marinMapDetail.latitude),
            lng: parseFloat(props.marinMapDetail.longitude)
          }}
          onClick={() => {
            setSelectedCamera(props.marinMapDetail);
          }}
          icon={{
            url: Opcamera,
            scaledSize: new window.google.maps.Size(25, 25),
          }}
        />
        {selectedCamera !== null && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedCamera(null);
            }}
            position={{
              lat: parseFloat(selectedCamera.latitude),
              lng: parseFloat(selectedCamera.longitude),
            }}
            defaultOptions={false}
          >
            <div className="mapPopup popupCaltransCamerasMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  setSelectedCamera(null);
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label>{selectedCamera.location_name} </label>
                  </span>
                  <span className="cameraLiveImg">
                    <img src={selectedCamera.camera_preview_url} alt="logo" onError={e => { e.currentTarget.src = oaklandlogo }} ></img>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(CameraModal));

export default function MaponModal(props) {
  return (
    <Modal
      className="marineCameraModal"
      show={props.open}
      onHide={props.closeModal}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
    >
      <Modal.Body>
        <div className="vesselMapTab " style={{ width: "100%" }}>
          {navigator.onLine && <MapWrapped
            {...props}
            googleMapURL={GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `100` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%`, borderRadius: '10px 10px 0 0' }} />}
          />}
          <button type="button" className="btnClose" onClick={() => props.closeModal()} aria-label="Close">{languageJson.close_txt}</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
