import * as firebase from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

/********************* For AWS-Production SERVER ********************/
export const firebaseConfig = {

  apiKey: "AIzaSyBCp99Wq3QMfK0vfv9V8pw6l_1Ml2AE2DE",

  authDomain: "oakland-portal-4e070.firebaseapp.com",

  projectId: "oakland-portal-4e070",

  storageBucket: "oakland-portal-4e070.appspot.com",

  messagingSenderId: "124935199857",

  appId: "1:124935199857:web:c5dae5f785395d26f14fd0",

  measurementId: "G-J735EJTP04"

};
    firebase.initializeApp(firebaseConfig);
 
    let messaging = getMessaging();


export const gettoken = async () => {
  let currentToken = "";
  try {
    // currentToken;
    currentToken = await getToken(messaging,{vapidKey:messaging.vapidKey});
  } catch (error) {
    /* console.log("An error occurred while retrieving token. ", error); */
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging,(payload) => {
      resolve(payload);
    });
  });