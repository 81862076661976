import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import bannervideo from "../../assets/videos/banner_video.mp4";
import ScrollPageWithScreen from 'src/Containers/ScrollPageResponsive'

const Header = () => {
  const [banner, setbanner] = useState(bannervideo);
  let singleUrls = useSelector((state) => state.publicUrlState);

  useEffect(() => {
    if (
      singleUrls &&
      singleUrls.singleUrls &&
      singleUrls.singleUrls.length > 0
    ) {
      let urlObj = singleUrls.singleUrls.find(
        ({ url_id }) => url_id === "banner_video"
      );
      setbanner(urlObj ? urlObj.url_link : bannervideo);
    }
  }, [singleUrls]);


  return (
    <header className="headposition">
      <ScrollPageWithScreen page={'home'}/>
      <div className="overlay"></div>
      {banner !== null && (
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src={banner} type="video/mp4" />
        </video>
      )}
      <div className="container h-100">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="w-100 text-white"></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
