// Layout.js

import React, {useEffect, useState} from 'react';
import FloatingButton from './FloatingButton'; // Import the FloatingButton component
import { useSelector } from "react-redux";
const footer_contact_url = "https://www.portofoakland.com/contact"; // Replace this with the actual URL

const FloatingLayout = ({ children }) => {
  let singleUrls = useSelector((state) => state.publicUrlState);
  const [contactUsUrl, setContactUsUrl] = useState(null);
  const handleClick = () => {
    console.log("Button clicked");
    // You can add any additional functionality here
  };

  useEffect(() => {
    if (
      singleUrls &&
      singleUrls.singleUrls &&
      singleUrls.singleUrls.length > 0
    ) {
      singleUrls.singleUrls.map((ele) => {
        switch (ele.url_id) {
          case "footer_contact_url":
            return setContactUsUrl(ele.url_link);
          default:
            return null;
        }
      });
    }
  }, [singleUrls.singleUrls]); // eslint-disable-line react-hooks/exhaustive-deps
   
 
  return (
    <div>
      {/* Include the floating button */}
      <FloatingButton onClick={handleClick} url={contactUsUrl ? contactUsUrl : footer_contact_url} />
      
      {/* Render the child components */}
      {children}
    </div>
  );
};

export default FloatingLayout;
