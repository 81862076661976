import React, { useEffect, useState } from "react";
import Header from "../../Containers/Header";
 import noImage from "../../assets/no-image.png";
import { NEWS_GET_BY_ID } from "../../url/Url";
import axios from "src/redux/actions/apis";
import { Link ,Redirect } from "react-router-dom";
import DataNotFound from '../DataNotFound/DataNotFound';

import portimg1 from "../../assets/NewsandUpdates/1.jpg"; 
import portimg2 from "../../assets/NewsandUpdates/2.jpg";
import portimg3 from "../../assets/NewsandUpdates/3.jpg";

import moment from "moment";
import { languageJson } from '../../StringVariable';
import SpinerLoader from "src/utils/SpinerLoader";
moment.suppressDeprecationWarnings = true;


export default function NewsDetailPage() {
  const [newsData, setNewsdata] = useState(null);
  const [redirectState, setRedirect] = useState(false);
  const getNewsById = async (id) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios.get(NEWS_GET_BY_ID + id, requestObj).then((res) => {
        const { news } = res.data;
        if (!news) {
          setRedirect(true);
        } else {
          setNewsdata(news);
        }
      });
    } catch (err) { }
  };

  useEffect(() => {
    /******* If # is not in browser routes ************/
    // let url = window.location.pathname;
    // const value = url.split("/");

    /******* If # is in browser routes ************/
    let url = window.location.hash;
    const value = url.split("/");

    getNewsById(value[2]);
    
  }, []);
  
  if (redirectState) return <Redirect to="/pageNotFound" />;

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg2} alt=""/> 
             <img className="banner_img" src={portimg3} alt=""/>      
      </div>

      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / <Link to="/NewsAndUpdatesDetails">{languageJson.News_And_Update_txt}</Link> / {newsData != null ? newsData["news_heading"].slice(0, 50) : ""} {newsData && newsData["news_heading"].length > 50 ? "..." : ""} </span>
            </p>
          </div>
        </div>
      </div>
      <section className="newsupdates-section">
        {newsData != null && Object.keys(newsData).length > 0 ? (
          <div className="newsDetailsMainDiv">
            <div className="container">
              <div className="newsUpdateContentMain">
                <h1>{newsData.news_heading === null || newsData.news_heading.toLowerCase() === "null" ? "-" : newsData["news_heading"]}</h1>
                <h2>{newsData.news_sub_heading === null || newsData.news_sub_heading.toLowerCase() === "null" ? "-" : newsData["news_sub_heading"]}</h2>
                <div className="d-none">{newsData["news_status"]}</div>
                <div className="news-category-title">{newsData.news_authority === null || newsData.news_authority.toLowerCase() === "null" ? "-" : newsData["news_authority"]}</div>
                <div className="news-date">{moment(newsData["news_published_on"]).format('Do MMM YYYY')}</div>
                {newsData["new_image"] !== null && !newsData["new_image"].includes('null') ? <div className="news-Img"><img src={newsData["new_image"] ? newsData["new_image"] : noImage} onError={e => { e.currentTarget.src = noImage }} alt="img"></img></div> : ""}
                <pre className="news-content" dangerouslySetInnerHTML={{ __html: newsData.news_description === null || newsData.news_description.toLowerCase() === "null" ? "-" : newsData["news_description"] }}></pre>
                <div className="news-content_info" dangerouslySetInnerHTML={{ __html: newsData.news_extra_information === null || newsData.news_extra_information.toLowerCase() === "null" ? "-" : newsData["news_extra_information"] }} ></div>
                <div className="news-content d-none">{newsData["news_published_on"]}</div>
                <pre className="news-contacts" dangerouslySetInnerHTML={{ __html: newsData.news_contacts === null || newsData.news_contacts.toLowerCase() === "null" ? "-" : newsData["news_contacts"] }}></pre>
              </div>
            </div>
          </div>
        ) : (<div className="newsDetailsMainDiv">
          <div className="container ">
            {newsData && Object.keys(newsData).length === 0 ? <DataNotFound /> : <SpinerLoader />}
          </div>
        </div>
        )}
      </section>
    </div>
  );
}
