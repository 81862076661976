import React from 'react';
import moment from "moment-timezone";

export const DateTimeFormate =(timeStemp)=> {
    let d = new Date(parseInt(timeStemp));
    let output = moment.tz(d,"America/Los_Angeles").format("MM/DD/YYYY, h:mm A")
    if (output !== "Invalid date") {
      return output;
    }else{
      return <b className='text-center'>-</b>
    }
  };


  export const DateTimeFormateSeconds =(timeStemp)=> {
    let d = new Date(parseInt(timeStemp * 1000));
    let output = moment.tz(d,"America/Los_Angeles").format("MM/DD/YYYY, h:mm A")
    if (output !== "Invalid date") {
      return output;
    }else{
      return <b className='text-center'>-</b>
    }
  };



export const DataFormate = (timeStemp) => {
    let d = new Date(parseInt(timeStemp));
    let output = moment.tz(d,"America/Los_Angeles").format("MM/DD/YYYY")
    if (output !== "Invalid date") {
      return output;
    }else{
      return "-"
    }
  };

export const TimeFormate = (timeStemp) => {
    let d = new Date(parseInt(timeStemp));
    let output = moment.tz(d,"America/Los_Angeles").format("h:mm A")
    if (output !== "Invalid date") {
      return output
    }else{
      return  "-"
    }
  };