import React, { useEffect, useState } from "react";
import {
  GET_VESSEL_REPORT_URL
} from "../../url/Url";
import { languageJson } from "../../StringVariable";
import axios from "src/redux/actions/apis";
import moment from "moment";
import "./report.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faFileCode
} from "@fortawesome/free-solid-svg-icons";
import DataNotFound from "../DataNotFound/DataNotFound";
import { TimeFormate, DataFormate } from "../../utils/DataFormate";
import exportFromJSON from "export-from-json";
import { CSVLink } from "react-csv";
import "jspdf-autotable";
import PdfGenerater from "./PdfGenerater";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
import SpinerLoader from "src/utils/SpinerLoader";
import SearchComponent from "./SearchComponent";
import { submitGAEvent } from "src/utils/GAEvents";
moment.suppressDeprecationWarnings = true;
 
function VesselReportsData() {
  const {
    export_PDF_txt, export_Excel_txt, export_CSV_txt, export_XML_txt
  } = languageJson
  const [list, setList] = useState(null);
  const [listCopy, setListCopy] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const getVesselReport = async () => {
    submitGAEvent("REPORTS-VESSEL OPERATIONS")
    setSearchValue("")
    try {
      const result = await axios
        .get(GET_VESSEL_REPORT_URL)
        .then((res) => res.data);
      if (result.customcode === 200 && result.vesselReports.length > 0) {
        setList(result.vesselReports);
        setListCopy(result.vesselReports);
      } else {
        setList([]);
        setListCopy([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
 

   
  
  const getDate = (d) => {
    let time = new Date(parseInt(d));
    if (moment(time).format("MM/DD/YYYY") !== "Invalid date") {
      return moment(time).format("MM/DD/YYYY");
    } else {
      return <b>{"-"}</b>;
    }
  };

  //searching functionlity
  const onClickSearch = (type) => {
    let value = searchValue.toLocaleLowerCase();
    switch (type) {
   
      case "vessel": {
        let temp = listCopy
        let filterData = temp.filter((item) =>
          item["vesselName"].toLowerCase().includes(value) ? true : false
        );
        setList(filterData);
        break;
      }
      default:
        break
    }
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 1) {
      setList(listCopy);
    }
  };
 
  

  // for CSV generate from  vessel operation data
  const [show, setShow] = useState(false);
   const [modal2, setModal2] = useState(false);
   const [newList, setNewList] = useState([]); 

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (type) => {
    setShow(true);
    switch (type) {
      
      case "modal2":
        {
          
          setModal2(true); 
          let newList = list.map((item, index) => {
            return {
              scheduleId: item.scheduleId,
              movementId: item.movementId,
              vesselName: item.vesselName,
              flag: item.flag,
              vesselType: item.vesselType,   
              lloydsNumber: item.lloydsNumber,   
              mmsi: item.mmsi,
              callSign: item.callSign,
              owner: item.owner,
              draft: item.draft,
              netWeight: item.netWeight,
              grossWeight: item.grossWeight,
              length: item.length,
              breadth: item.breadth,
              deadWeight: item.deadWeight,
              portOfRegistry: item.portOfRegistry,
              yearBuilt: item.yearBuilt,
              berth: item.berth,
              terminal: item.terminal,
              fromPort: item.fromPort ? item.fromPort : '-',
              toPort: item.toPort ? item.toPort : '-',
              fromBerth: item.fromBerth ? item.fromBerth : '-',
              toBerth: item.toBerth ? item.toBerth : '-',
              actualArrival: DataFormate(item.actualArrival),
              actualDeparture: DataFormate(item.actualDeparture),
              estimatedArrival: DataFormate(item.estimatedArrival),
              estimatedtimeArrival: TimeFormate(item.estimatedArrival),
              estimatedDeparture: DataFormate(item.estimatedDeparture),
              estimatedtimeDeparture: TimeFormate(item.estimatedDeparture),
              earliestReceivingDate: DataFormate(item.earliestReceivingDate),
              earliestReceivingTime: TimeFormate(item.earliestReceivingDate),
              cutoffDate: item.cutoffDate ? getDate(item.cutoffDate) : "-",
              cutoffTime: TimeFormate(item.cutoffDate),
              draftIn: item.draftIn !== null && item.draftIn !== 
              "" ? item.draftIn : '-',
              draftOut: item.draftOut !== null && item.draftOut !== '' ? item.draftOut : '-',
              firstLine: item.firstLine !== null && item.firstLine !== '' ? item.firstLine : '-',
              lastLine: item.lastLine !== null && item.lastLine !== '' ? item.lastLine : '-',
              latitude: item.latitude,
              longitude: item.longitude,
              modifiedUser: item.modified  && item.modified.user ? item.modified.user : '-',
              modifiedDate: item.modified  && item.modified.date ? DataFormate(item.modified.date) : '-',
              
            };
          });
          setNewList(newList);
        }
        break;
      
      default:
        break;
    }
  };



  let headerVesselCvs = [
    { label: languageJson.Report_Export_Vessel_Operations_Lable.SchedId, key : "scheduleId"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.MoveId, key : "movementId"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.VesselName, key : "vesselName"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Flag, key : "flag"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.VesselType, key : "vesselType"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.LloydsNum, key : "lloydsNumber"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.MMSI, key : "mmsi"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CallSign, key : "callSign"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Owner, key : "owner"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Draft, key : "draft"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.NetWeight, key : "netWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.GrossWeight, key : "grossWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Length, key : "length"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Breadth, key : "breadth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DeadWeight, key : "deadWeight"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.PortRegistry, key : "portOfRegistry"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.YearBuilt, key : "yearBuilt"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Berth, key : "berth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Terminal, key : "terminal"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FromPort, key : "fromPort"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ToPort, key : "toPort"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FromBerth, key : "fromBerth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ToBerth, key : "toBerth"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ActualArrival, key : "actualArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ActualDeparture, key : "actualDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalDate, key : "estimatedArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalTime, key : "estimatedtimeArrival"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureDate, key : "estimatedDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureTime, key : "estimatedtimeDeparture"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedDate, key : "earliestReceivingDate"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedTime, key : "earliestReceivingTime"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CutOffDate, key : "cutoffDate"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.CutOffTime, key : "cutoffTime"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DraftIn, key : "draftIn"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.DraftOut, key : "draftOut"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.FirstLine, key : "firstLine"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.LastLine, key : "lastLine"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Lat, key : "latitude"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.Long, key : "longitude"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ModUser, key : "modifiedUser"},
    { label :languageJson.Report_Export_Vessel_Operations_Lable.ModDate, key : "modifiedDate"}
  ];
  const vesselOperationCVS = {
    data: newList,
    headers: headerVesselCvs,
    filename: languageJson.Vessel_OperationCSV_txt,
  };

  // for pdf generate from vessel operation

  let headerVesselOperationPDF = [[
    "Schedule Id",
    "Movement Id",
    "Vessel Name",
    "Flag",
    "Vessel Type"	,
    "Lloyds Number",
    "MMSI",
    "Call Sign",
    "Owner",
    "Draft",
    "Net Weight", 
    "Gross Weight",
    "Length",
    "Breadth",
    "Dead Weight",
    "Port Of Registry",
    "Year Built",
    "Berth",
    "Terminal",
    "From Port",
    "To Port",
    "From Berth",
    "To Berth",
    "Actual Arrival",
    "Actual Departure",
    "Estimated Arrival Date",
    "Estimated Arrival Time",
    "Estimated Departure Date",
    "Estimated Departure Time",
    "Earliest Received Date",
    "Earliest Received Time",
    "Cut-Off Date",
    "Cut-Off Time",
    "Draft In",
    "Draft Out",
    "First Line",
    "Last Line",
    "Latitude",
    "Longitude",
    "Modified User",
    "Modified Date"
   ]];
  let Vesseloperationdata =
    newList.length &&
    newList.map((elt) => [
      elt.scheduleId,
      elt.movementId,
      elt.vesselName,
      elt.flag,
      elt.vesselType,
      elt.lloydsNumber,
      elt.mmsi,
      elt.callSign,
      elt.owner,
      elt.draft,
      elt.netWeight,
      elt.grossWeight,
      elt.length,
      elt.breadth,
      elt.deadWeight,
      elt.portOfRegistry,
      elt.yearBuilt,
      elt.berth,
      elt.terminal,
      elt.fromPort,
      elt.toPort,
      elt.fromBerth,
      elt.toBerth,
      elt.actualArrival,
      elt.actualDeparture,
      elt.estimatedArrival,
      elt.estimatedtimeArrival,
      elt.estimatedDeparture,
      elt.estimatedtimeDeparture,
      elt.earliestReceivingDate,
      elt.earliestReceivingTime,
      elt.cutoffDate,
      elt.cutoffTime,
      elt.draftIn,
      elt.draftOut,
      elt.firstLine,
      elt.lastLine,
      elt.latitude,
      elt.longitude,
      elt.modifiedUser,
      elt.modifiedDate,
  ]);
 
   

  const downloadXml = (type) => {
  
   
    if (type === "vessel") {
      exportFromJSON({
        data: DataSetForVessel,
        fileName: languageJson.Vessel_Operation_txt,
        exportType: "xml",
      });
    }
  
  };

   

  const DataSetForVessel = newList.map((data) => {
    return {
      [languageJson.Report_Export_Vessel_Operations_Lable.SchedId]: data.scheduleId,
      [languageJson.Report_Export_Vessel_Operations_Lable.MoveId]: data.movementId,
      [languageJson.Report_Export_Vessel_Operations_Lable.VesselName]: data.vesselName,
      [languageJson.Report_Export_Vessel_Operations_Lable.Flag]: data.flag,
      [languageJson.Report_Export_Vessel_Operations_Lable.VesselType]: data.vesselType,
      [languageJson.Report_Export_Vessel_Operations_Lable.LloydsNum]: data.lloydsNumber,
      [languageJson.Report_Export_Vessel_Operations_Lable.MMSI]: data.mmsi,
      [languageJson.Report_Export_Vessel_Operations_Lable.CallSign]: data.callSign,
      [languageJson.Report_Export_Vessel_Operations_Lable.Owner]: data.owner,
      [languageJson.Report_Export_Vessel_Operations_Lable.Draft]: data.draft,
      [languageJson.Report_Export_Vessel_Operations_Lable.NetWeight]: data.netWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.GrossWeight]: data.grossWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.Length]: data.length,
      [languageJson.Report_Export_Vessel_Operations_Lable.Breadth]: data.breadth,
      [languageJson.Report_Export_Vessel_Operations_Lable.DeadWeight]: data.deadWeight,
      [languageJson.Report_Export_Vessel_Operations_Lable.PortRegistry]: data.portOfRegistry,
      [languageJson.Report_Export_Vessel_Operations_Lable.YearBuilt]: data.yearBuilt,
      [languageJson.Report_Export_Vessel_Operations_Lable.Berth]: data.berth,
      [languageJson.Report_Export_Vessel_Operations_Lable.Terminal]: data.terminal,
      [languageJson.Report_Export_Vessel_Operations_Lable.FromPort]: data.fromPort,
      [languageJson.Report_Export_Vessel_Operations_Lable.ToPort]: data.toPort,
      [languageJson.Report_Export_Vessel_Operations_Lable.FromBerth]: data.fromBerth,
      [languageJson.Report_Export_Vessel_Operations_Lable.ToBerth]: data.toBerth,
      [languageJson.Report_Export_Vessel_Operations_Lable.ActualArrival]: data.actualArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.ActualDeparture]: data.actualDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalDate]: data.estimatedArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstArrivalTime]: data.estimatedtimeArrival,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureDate]: data.estimatedDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EstDepartureTime]: data.estimatedtimeDeparture,
      [languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedDate]: data.earliestReceivingDate,
      [languageJson.Report_Export_Vessel_Operations_Lable.EarliestReceivedTime]: data.earliestReceivingTime,
      [languageJson.Report_Export_Vessel_Operations_Lable.CutOffDate]: data.cutoffDate,
      [languageJson.Report_Export_Vessel_Operations_Lable.CutOffTime]: data.cutoffTime,
      [languageJson.Report_Export_Vessel_Operations_Lable.DraftIn]: data.draftIn,
      [languageJson.Report_Export_Vessel_Operations_Lable.DraftOut]: data.draftOut,
      [languageJson.Report_Export_Vessel_Operations_Lable.FirstLine]: data.firstLine,
      [languageJson.Report_Export_Vessel_Operations_Lable.LastLine]: data.lastLine,
      [languageJson.Report_Export_Vessel_Operations_Lable.Lat]: data.latitude,
      [languageJson.Report_Export_Vessel_Operations_Lable.Long]: data.longitude,
      [languageJson.Report_Export_Vessel_Operations_Lable.ModUser]: data.modifiedUser,
      [languageJson.Report_Export_Vessel_Operations_Lable.ModDate]: data.modifiedDate
    };
  });

   
  useEffect(() => {
    getVesselReport();
  }, []);
  useEffect(()=>{},[list])

  return (
   
   
   <div>
      
      <div className="reportTabsMainDiv">
        <div className="container">
          <div className="tab-content">
            <div>
            {list && list.length > 0 && (
                <button
                  className="downloadBtnLeft"
                  onClick={() => handleShow("modal2")}
                >
                  {languageJson.download_txt}
                </button>
              )}
 
             
             { list != null && ((list.length && list.length > 0) || (searchValue.length && searchValue.length>0)) ? (
                    <SearchComponent
                    placeholder={languageJson.search_using_vessel_name}
                     onChange={handleChange}
                    onClickSearch={onClickSearch}
                    type={"vessel"}
                    value={searchValue}
                  /> ) : (<></>)
             }
              
              {list != null && list.length && list.length > 0 ? (
                <>
                  <div className="tableOverFlow">
                    <table>
                      <thead>
                        <tr>
                          <td>{languageJson.Vessel_Name_txt}</td>
                          <td>{languageJson.estimated_arrivalDate_txt}</td>
                          <td>{languageJson.estimated_arrivalTime_txt}</td>
                          <td>{languageJson.berth_txt}</td>
                          <td>{languageJson.estimated_departureDate_txt}</td>
                          <td>{languageJson.estimated_departureTime_txt}</td>
                          <td>{languageJson.earliest_receivedDate_txt}</td>
                          <td>{languageJson.earliest_receivedTime_txt}</td>
                          <td>{languageJson.cutOffDate_txt}</td>
                          <td>{languageJson.cutOffTime_txt}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {item.vesselName === "" ||
                                  item.vesselName.toLowerCase() === "null" ||
                                  item.vesselName === null
                                  ? "-"
                                  : item.vesselName}
                              </td>
                              <td>
                                {item.estimatedArrival === "" ||
                                  item.estimatedArrival ===
                                  "null" ||
                                  item.estimatedArrival === null
                                  ? "-"
                                  : DataFormate(item.estimatedArrival)}
                              </td>
                              <td>
                                {item.estimatedArrival === "" ||
                                  item.estimatedArrival ===
                                  "null" ||
                                  item.estimatedArrival === null
                                  ? "-"
                                  : TimeFormate(item.estimatedArrival)}
                              </td>
                              <td>{item.berth || "-"}</td>
                              <td>
                                {item.estimatedDeparture === "" ||
                                  item.estimatedDeparture ===
                                  "null" ||
                                  item.estimatedDeparture === null
                                  ? "-"
                                  : DataFormate(item.estimatedDeparture)}
                              </td>
                              <td>
                                {item.estimatedDeparture === "" ||
                                  item.estimatedDeparture ===
                                  "null" ||
                                  item.estimatedDeparture === null
                                  ? "-"
                                  : TimeFormate(item.estimatedDeparture)}
                              </td>
                              <td>
                                {item.earliestReceivingDate === "" ||
                                  item.earliestReceivingDate ===
                                  "null" ||
                                  item.earliestReceivingDate === null
                                  ? "-"
                                  : DataFormate(item.earliestReceivingDate)}
                              </td>
                              <td>
                                {item.earliestReceivingDate === "" ||
                                  item.earliestReceivingDate ===
                                  "null" ||
                                  item.earliestReceivingDate === null
                                  ? "-"
                                  : TimeFormate(item.earliestReceivingDate)}
                              </td>

                              <td>
                                {item.cutoffDate === "" ||
                                  item.cutoffDate === "null" ||
                                  item.cutoffDate === null
                                  ? "-"
                                  : getDate(item.cutoffDate)}
                              </td>
                              <td>
                                {item.cutoffDate === "" ||
                                  item.cutoffDate === "null" ||
                                  item.cutoffDate === null
                                  ? "-"
                                  : TimeFormate(item.cutoffDate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : list != null && list.length === 0  ? (
                <DataNotFound />
              ) : (
                <SpinerLoader />
              )}
             
         </div>
  
        {" "}
        <Modal
          show={show}
          centered
          backdrop="static"
          onHide={handleClose}
          animation={true}
          className="reportModalClass">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <h5>{languageJson.select_format_txt}</h5>
            <div className="downloadOptDiv">
             
              {modal2 && (
                <div className="downloadOptInnerList">
                  <span title={export_CSV_txt}>
                    <CSVLink
                      {...vesselOperationCVS}
                      className="downloadBtny btn btn-primary downloadbutton"
                    >
                      <FontAwesomeIcon icon={faFileCsv} size="lg" />
                    </CSVLink>
                  </span>
                  <span title={export_PDF_txt}>
                    <PdfGenerater
                      data={Vesseloperationdata}
                      buttonclassName={"pdfbutton"}
                      buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                      title={languageJson.vesselOperation_txt}
                      fileName={languageJson.Vessel_Operation_txt}
                      header={headerVesselOperationPDF}
                      size={'A1'}
                    />
                  </span>
                  <span title={export_Excel_txt}>
                    <JsonToExcel
                      title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                      data={DataSetForVessel}
                      fileName={languageJson.Vessel_Operation_txt}
                      btnClassName="downloadBtny btn btn-primary downloadbutton"
                    />
                  </span>
                  <div title={export_XML_txt}>
                    <button
                      className="downloadBtny btn btn-primary downloadbutton"
                      onClick={() => downloadXml("vessel")}
                    >
                      {<FontAwesomeIcon icon={faFileCode} size="lg" />}
                    </button>
                  </div>
                </div>
              )}

             
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
       </div></div></div>
       </div>
  )}
export default VesselReportsData;
