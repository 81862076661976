
import './OtpModal.css';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
import { otpVerify, clearLoadingMessageStatus } from '../../redux/actions/UserAccount';
import { useHistory } from 'react-router-dom';
import { languageJson } from '../../StringVariable';

const OtpModal = (props) => {
  let history = useHistory();
  const [otpValue, setOtpValue] = useState();
  const [otpRequire, setOtpRequire] = useState(false);
  const { loadingState, messageState, statusCodeState } = useSelector(state => state);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(clearLoadingMessageStatus());
    setOtpRequire(false)
    setOtpValue(e)
  }

  const handleSubmit = () => {
    if (otpValue && otpValue.length === 6) {
      const obj = {
        email: props.email,
        otp: otpValue
      }
      dispatch(clearLoadingMessageStatus());
      dispatch(otpVerify(obj, history));
    } else {
      setOtpRequire(true)
      dispatch(clearLoadingMessageStatus());
    }
  }

  const handleResendOTP = () => {
    setOtpRequire(false)
    dispatch(clearLoadingMessageStatus());
    props.resendOTP()
    setOtpValue()
  }


  return (
    <div className='custom-modal driverregistration-popup pb-3'>
      <div className={`${props.status} driverregistration-popup-head driverregistrationOTPCodeDiv py-3`}>
        <i className={`${props.icon}`} style={{ fontSize: "30px" }} aria-hidden="true"></i>
      </div>
      <p className="py-3 fw-bold">{props.text}</p>
      {otpRequire ? <p>{languageJson.Please_OTP_txt}</p> : null}
      {
        messageState.otpSendMessage !== null ? <p>{messageState.otpSendMessage}</p> : null
      }
      {
        messageState.otpVerifyMessage !== null ? <p>{messageState.otpVerifyMessage}</p> : null
      }
      {
        messageState.forgotPasswordMessage !== null ? <p>{messageState.forgotPasswordMessage}</p> : null
      }
      {statusCodeState.otpVerifyStatusCode !== 200 ? (
        <div className="otpInput" style={{ marginBottom: '10px' }}>
          <OtpInput
            containerStyle={'otp__text-center'}
            inputStyle={'otp__fs-25'}
            value={otpValue}
            isInputNum={true}
            onChange={handleChange}
            numInputs={6}
          />
        </div>
      ) : null}
      {
        statusCodeState.otpVerifyStatusCode !== 200 ?
          <React.Fragment>
            <button className={`${props.btnColor}`} onClick={() => handleResendOTP()} disabled={loadingState.isOtpSendLoading || loadingState.isforgotPasswordLoading ? true : false}>
              {loadingState.isOtpSendLoading || loadingState.isforgotPasswordLoading ? languageJson.processing_txt : languageJson.resend_txt}
            </button>
            <button className={`${props.btnColor}`} onClick={() => handleSubmit()} disabled={loadingState.isOtpVerifyLoading ? true : false}>
              {loadingState.isOtpVerifyLoading ? languageJson.processing_txt : languageJson.submit_txt}
            </button>
          </React.Fragment> :
          <Link to="/userLogin"> <button className={`${props.btnColor}`} onClick={props.onClose}>{languageJson.close_txt}</button></Link>
      }
    </div>
  );
}

export default OtpModal;
