import { ActionTypes } from "../constants/Types";
const initialState = {dashboard:null,dashboard1:null,dashboard2:null};

export const DashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
            case ActionTypes.GET_DASHBOARD_DATA_SUCCESS:
            return { ...state,dashboard: action.payload }
            case ActionTypes.GET_DASHBOARD_DATA_SUCCESS1:
            return { ...state,dashboard1: action.payload }
            case ActionTypes.GET_DASHBOARD_DATA_SUCCESS2:
                return { ...state,dashboard2: action.payload }
            case ActionTypes.GET_DASHBOARD_LMC_DATA_SUCCESS:
                return { ...state,dashboardLmc: action.payload }
        default:
            return state;
    }
}