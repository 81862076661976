/* eslint-disable no-unused-vars */
import { ActionTypes } from "../constants/Types";
import server from "./apis";
import {
    vasselAlertsDataMessage,
    railCrossingAlertsDataMessage,
    marineTerminalAlertsDataMessage,
    portMessage,
    portConstructionEventsMessage,
    portIncidentClosuresMessage,
    regionalMessageSignsMessage,
    regionalConstructionEventsMessage,
    regionalIncidentClosuresMessage,
    trafficAndCamerasAlertsDataMessage,
    securityAndSafetyAlertsDataMessage,
    districtMessageSignsMsg
} from "./MessageAction";
import {
    stopLoading,
    startVesselAlertsDataLoading,
    startRailCrossingAlertsDataLoading,
    startMarineTerminalAlertsDataLoading,
    startPortMessageLoading,
    startPortConstructionEventsLoading,
    startPortIncidentClosuresLoading,
    startRegionalMessageSignsLoading,
    startRegionalConstructionEventsLoading,
    startRegionalIncidentClosuresLoading,
    startTrafficAndCamersAlertsDataLoading,
    securityAndSafetyAlertsDataLoading,
    districtMessageSignsLoading
} from "./LoadingAction";
import {
    vasselAlertsDataStatusCode,
    railCrossingAlertsDataStatusCode,
    marineTerminalAlertsDataStatusCode,
    portMessageStatusCode,
    portConstructionEventsStatusCode,
    portIncidentClosuresStatusCode,
    regionalMessageSignsStatusCode,
    regionalConstructionEventsStatusCode,
    regionalIncidentClosuresStatusCode,
    trafficAndCamerasAlertsDataStatusCode, securityAndSafetyDataStatusCode
} from "./StatusCodeAction";
import {
    GET_ALERT_LIST_FOR_VESSEL_OPERATION,
    GET_RAILWAY_ALERT_LIST, GET_ALERT_LIST_FOR_MARINE,
    ROAD_CAMERA_LIST_DATA, GET_TRAFFIC_AND_CAMERA_ALERT,
    SECURITY_AND_SAFETY_ALERT_API,
    DISTRICT_MESSAGE_SIGNS_URL
} from "../../url/Url";
import { languageJson } from "src/StringVariable";
import { submitGAEvent } from "src/utils/GAEvents";

const vesselAlertsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.VESSEL_ALERTS_DATA_SUCCESS,
        payload: alerts
    }
}

const railCrossingAlertsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.RAIL_CROSSING_ALERTS_DATA_SUCCESS,
        payload: alerts
    }
}

const marineTerminalAlertsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_ALERTS_DATA_SUCCESS,
        payload: alerts
    }
}

const trafficAndCamerasAlertsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.TRAFFIC_AND_CAMERAS_DATA_SUCCESS,
        payload: alerts
    }
}
const securityAndSafetyAlertsDataSuccess = (alerts) => {
    return {
        type: ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_SUCCESS,
        payload: alerts
    }
}

const portMessageSuccess = (alerts) => {
    return {
        type: ActionTypes.PORT_MESSAGE_SUCCESS,
        payload: alerts
    }
}

const portConstructionEventsSuccess = (alerts) => {
    return {
        type: ActionTypes.PORT_CONSTRUCTION_EVENTS_SUCCESS,
        payload: alerts
    }
}

const portIncidentClosuresSuccess = (alerts) => {
    return {
        type: ActionTypes.PORT_INCIDENT_CLOSURES_SUCCESS,
        payload: alerts
    }
}

const regionalMessageSignsSuccess = (alerts) => {
    return {
        type: ActionTypes.REGIONAL_MESSAGE_SIGNS_SUCCESS,
        payload: alerts
    }
}

const regionalConstructionEventsSuccess = (alerts) => {
    return {
        type: ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_SUCCESS,
        payload: alerts
    }
}

const regionalIncidentClosuresSuccess = (alerts) => {
    return {
        type: ActionTypes.REGIONAL_INCIDENT_COUSURES_SUCCESS,
        payload: alerts
    }
}

const districtMessageSignsSuccess = (data) => {
    return {
        type: ActionTypes.DISTRICT_MESSAGE_SIGNS_SUCCESS,
        payload: data
    }
}

export const vesselAlertsData = (page, limit) => {
    return (dispatch) => {
        dispatch(startVesselAlertsDataLoading());
        server.get(`${GET_ALERT_LIST_FOR_VESSEL_OPERATION}/${page}/${limit}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    submitGAEvent("ALERT-VESSEL OPERATIONS");
                    dispatch(vesselAlertsDataSuccess(response.data));
                    dispatch(vasselAlertsDataStatusCode(response.data.customcode));
                } else {
                    dispatch(vasselAlertsDataStatusCode(response.data.customcode));
                    dispatch(vasselAlertsDataMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(vasselAlertsDataMessage(message));
            });
    };
};

export const railCrossingAlertsData = (page, limit) => {
    return (dispatch) => {
        dispatch(startRailCrossingAlertsDataLoading());
        server.get(`${GET_RAILWAY_ALERT_LIST}/${page}/${limit}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    submitGAEvent("ALERT-RAIL CROSSING");
                    dispatch(railCrossingAlertsDataSuccess(response.data));
                    dispatch(railCrossingAlertsDataStatusCode(response.data.customcode));
                } else {
                    dispatch(railCrossingAlertsDataStatusCode(response.data.customcode));
                    dispatch(railCrossingAlertsDataMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(railCrossingAlertsDataMessage(message));
            });
    };
};

export const marineTerminalAlertsData = () => {
    return (dispatch) => {
        dispatch(startMarineTerminalAlertsDataLoading());
        server.get(`${GET_ALERT_LIST_FOR_MARINE}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(marineTerminalAlertsDataSuccess(response.data.trafficandcameras))
                    dispatch(marineTerminalAlertsDataStatusCode(response.data.customcode));
                } else {
                    dispatch(marineTerminalAlertsDataStatusCode(response.data.customcode));
                    dispatch(marineTerminalAlertsDataMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(marineTerminalAlertsDataMessage(message));
            });
    };
};

export const trafficAndCamerasAlertsData = () => {
    return (dispatch) => {
        dispatch(startTrafficAndCamersAlertsDataLoading());
        server.get(`${GET_TRAFFIC_AND_CAMERA_ALERT}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(trafficAndCamerasAlertsDataSuccess(response.data.trafficandcameras));
                    dispatch(trafficAndCamerasAlertsDataStatusCode(response.data.customcode));
                } else {
                    dispatch(trafficAndCamerasAlertsDataStatusCode(response.data.customcode));
                    dispatch(trafficAndCamerasAlertsDataMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(trafficAndCamerasAlertsDataMessage(message));
            });
    };
};
export const securityAndSafetyAlertsData = () => {
    return (dispatch) => {
        dispatch(securityAndSafetyAlertsDataLoading());
        server.get(`${SECURITY_AND_SAFETY_ALERT_API}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    submitGAEvent("ALERT-SECURITY & SAFETY");
                    dispatch(securityAndSafetyAlertsDataSuccess(response.data.trafficandcameras));
                    dispatch(securityAndSafetyDataStatusCode(response.data.customcode));
                } else {
                    dispatch(securityAndSafetyDataStatusCode(response.data.customcode));
                    dispatch(securityAndSafetyAlertsDataMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(securityAndSafetyAlertsDataMessage(message));
            });
    };
};


export const portMessageSigns = () => {
    return (dispatch) => {
        dispatch(startPortMessageLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${1}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(portMessageSuccess(mapdata));
                    dispatch(portMessageStatusCode(response.data.customcode));
                } else {
                    dispatch(portMessageStatusCode(response.data.customcode));
                    dispatch(portMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(portMessage(message));
            });
    };
};

export const portConstructionEvents = () => {
    return (dispatch) => {
        dispatch(startPortConstructionEventsLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${2}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(portConstructionEventsSuccess(mapdata));
                    dispatch(portConstructionEventsStatusCode(response.data.customcode));
                } else {
                    dispatch(portConstructionEventsStatusCode(response.data.customcode));
                    dispatch(portConstructionEventsMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(portConstructionEventsMessage(message));
            });
    };
};

export const portIncidentClosures = () => {
    return (dispatch) => {
        dispatch(startPortIncidentClosuresLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${3}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(portIncidentClosuresSuccess(mapdata));
                    dispatch(portIncidentClosuresStatusCode(response.data.customcode));
                } else {
                    dispatch(portIncidentClosuresStatusCode(response.data.customcode));
                    dispatch(portIncidentClosuresMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(portIncidentClosuresMessage(message));
            });
    };
};

export const regionalMessageSigns = () => {
    return (dispatch) => {
        dispatch(startRegionalMessageSignsLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${11}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(regionalMessageSignsSuccess(mapdata));
                    dispatch(regionalMessageSignsStatusCode(response.data.customcode));
                } else {
                    dispatch(regionalMessageSignsStatusCode(response.data.customcode));
                    dispatch(regionalMessageSignsMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(regionalMessageSignsMessage(message));
            });
    };
};

export const regionalConstructionEvents = () => {
    return (dispatch) => {
        dispatch(startRegionalConstructionEventsLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${22}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(regionalConstructionEventsSuccess(mapdata));
                    dispatch(regionalConstructionEventsStatusCode(response.data.customcode));
                } else {
                    dispatch(regionalConstructionEventsStatusCode(response.data.customcode));
                    dispatch(regionalConstructionEventsMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(regionalConstructionEventsMessage(message));
            });
    };
};

export const regionalIncidentClosures = () => {
    return (dispatch) => {
        dispatch(startRegionalIncidentClosuresLoading());
        server.get(`${ROAD_CAMERA_LIST_DATA}${33}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data.trafficandcameras && response.data.trafficandcameras && response.data.trafficandcameras.IncidentList ? response.data.trafficandcameras.IncidentList : null;
                    dispatch(regionalIncidentClosuresSuccess(mapdata));
                    dispatch(regionalIncidentClosuresStatusCode(response.data.customcode));
                } else {
                    dispatch(regionalIncidentClosuresStatusCode(response.data.customcode));
                    dispatch(regionalIncidentClosuresMessage(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
                dispatch(regionalIncidentClosuresMessage(message));
            });
    };
};
export const districtMessageSigns11 = () => {
    return (dispatch) => {
        dispatch(districtMessageSignsLoading());
        server.get(`${DISTRICT_MESSAGE_SIGNS_URL}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    let mapdata = response.data && response.data.data && response.data.data.length>0 ? response.data.data : null;
                    dispatch(districtMessageSignsSuccess(mapdata));
                    dispatch(regionalIncidentClosuresStatusCode(response.data.customcode));
                } else {
                    dispatch(districtMessageSignsSuccess([]));
                    dispatch(districtMessageSignsMsg(response.data.message));
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt;
                if (!error.response) {
                    message = languageJson.network_error_txt;
                } else {
                    message = error.response.message;
                }
            });
    };
};