import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PdfGenerater from "src/Components/Reports/PdfGenerater";
import { JsonToExcel } from "react-json-to-excel";
import { faFilePdf, faFileCsv, faFileExcel, faFileCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import exportFromJSON from "export-from-json";
import { languageJson } from "../../StringVariable";

export default function ExportHistoricalData(props) {
  const {
    hour_txt, minutes_txt, hours_txt, trucks_txt, miles_txt,
    close_txt, export_text, download_export_file_txt, historical_title_txt, export_PDF_txt,
    export_Excel_txt, export_CSV_txt, export_XML_txt
  } = languageJson
  const {
    Terminal_Gate_Id,Terminal_Name,Terminal_Gate,Avg_Ingate_Outgate,Avg_Street_Wait_Time,Trucks_Count,Trucks_Count_Hour_0,Trucks_Count_Hour_1,Trucks_Count_Hour_2_Plus,Avg_Truck_In_Queue,Avg_Queue_Length,Exceptions,Date,Year_of_Date,Month_of_Date,Week_of_Year,Day_of_Month,Day_of_Week,Hour_of_Day
  } = languageJson.Historical_Export_Label
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { data,aggregate } = props;
  const getTerminalCurrentWaitTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }
    
    if (hours === 1 && minutes > 1) {
      return `${hours} ${hour_txt}   ${minutes} ${minutes_txt}`;
    }
    if (hours === 0 && minutes > 1) {
      return `${minutes} ${minutes_txt}`;
    }
    if (hours === 0 && minutes === 1) {
      return `${minutes} ${minutes_txt}`;
    }
    if (minutes === 0) {
      if (hours > 1) {
        return `${hours} ${hours_txt}`;
      } else {
        return `${hours} ${hour_txt}`;
      }
    } else {
      return `${hours} ${hours_txt}  ${minutes} ${minutes_txt}`;
    }
  }

  const convertMetersToMiles = (value) => {
    const df = value * 0.00062137;
    return parseFloat(df).toFixed(2);
  }

  const newReconstrucList = data.map((elt, index) => {
    return {
      terminal_name: elt.terminal_name || "-",
      gate_name: elt.gate_name || "-",
      terminal_turn_time:elt.terminal_turn_time !== null? getTerminalCurrentWaitTime(elt.terminal_turn_time): "-",
      street_wait_time:elt.street_wait_time !== null? getTerminalCurrentWaitTime(elt.street_wait_time): "-",
      volume: elt.volume || "-",
      avg_trucks_in_queue:elt.avg_trucks_in_queue !== null? (elt.avg_trucks_in_queue === 0 ? "-" : (elt.avg_trucks_in_queue + " " + trucks_txt)): "-",
      avg_queue_length:elt.avg_queue_length !== null? elt.avg_queue_length === 0 ? "-" : (convertMetersToMiles(elt.avg_queue_length) + " " + miles_txt): "-",
      exceptions: elt.exceptions !== null ? (elt.exceptions === 0 ? "-" : elt.exceptions) : "-",
      terminal_gate_id: elt.terminal_gate_id,
      volume_hour0: elt.volume_hour0,
      volume_hour1: elt.volume_hour1,
      volume_hour2plus: elt.volume_hour2plus,
      daystamp_year: elt.daystamp_year,
      record_key: elt.record_key !== null && elt.record_key !== undefined ? elt.record_key : '-',
      daystamp: elt.daystamp !== null && elt.daystamp !== undefined ? elt.daystamp : '-',
      daystamp_month: elt.daystamp_month !== null && elt.daystamp_month !== undefined ? elt.daystamp_month : '-',
      day_of_month: elt.day_of_month !== null && elt.day_of_month !== undefined ? elt.day_of_month : '-',
      day_of_week: elt.day_of_week !== null && elt.day_of_week !== undefined ? elt.day_of_week : '-',
      week_of_year: elt.week_of_year !== null && elt.week_of_year !== undefined ? elt.week_of_year : '-',
      hour_of_day: elt.hour_of_day !== null && elt.hour_of_day !== undefined ? elt.hour_of_day : '-',
    };
  });
  
  //Export data in Pdf
  let pdfHeader = [[]];
  let pdfData = [[]];
  let excelDataSet=[];
  let historicalCVSHeader=[];

  let pdfHeaderYear =[[ 
    Terminal_Gate_Id,
    Terminal_Name,
    Terminal_Gate,
    Avg_Ingate_Outgate,
    Avg_Street_Wait_Time,
    Trucks_Count,
    Trucks_Count_Hour_0,
    Trucks_Count_Hour_1,
    Trucks_Count_Hour_2_Plus,
    Avg_Truck_In_Queue,
    Avg_Queue_Length,
    Exceptions,
    Year_of_Date,
  ]];
  let pdfHeaderMonth =[[ 
    Terminal_Gate_Id,
    Terminal_Name,
    Terminal_Gate,
    Avg_Ingate_Outgate,
    Avg_Street_Wait_Time,
    Trucks_Count,
    Trucks_Count_Hour_0,
    Trucks_Count_Hour_1,
    Trucks_Count_Hour_2_Plus,
    Avg_Truck_In_Queue,
    Avg_Queue_Length,
    Exceptions,
    Year_of_Date,
    Month_of_Date
  ]];
  let pdfHeaderWeek =[[ 
    Terminal_Gate_Id,
    Terminal_Name,
    Terminal_Gate,
    Avg_Ingate_Outgate,
    Avg_Street_Wait_Time,
    Trucks_Count,
    Trucks_Count_Hour_0,
    Trucks_Count_Hour_1,
    Trucks_Count_Hour_2_Plus,
    Avg_Truck_In_Queue,
    Avg_Queue_Length,
    Exceptions,
    Year_of_Date,
    Week_of_Year
  ]];
  let pdfHeaderDay =[[ 
    Terminal_Gate_Id,
    Terminal_Name,
    Terminal_Gate,
    Avg_Ingate_Outgate,
    Avg_Street_Wait_Time,
    Trucks_Count,
    Trucks_Count_Hour_0,
    Trucks_Count_Hour_1,
    Trucks_Count_Hour_2_Plus,
    Avg_Truck_In_Queue,
    Avg_Queue_Length,
    Exceptions,
    Year_of_Date,
    Month_of_Date,
    Week_of_Year,
    Day_of_Month,
    Day_of_Week
  ]];
  let pdfHeaderHour =[[ 
    Terminal_Gate_Id,
    Terminal_Name,
    Terminal_Gate,
    Avg_Ingate_Outgate,
    Avg_Street_Wait_Time,
    Trucks_Count,
    Trucks_Count_Hour_0,
    Trucks_Count_Hour_1,
    Trucks_Count_Hour_2_Plus,
    Avg_Truck_In_Queue,
    Avg_Queue_Length,
    Exceptions,
    Date,
    Year_of_Date,
    Month_of_Date,
    Week_of_Year,
    Day_of_Month,
    Day_of_Week,
    Hour_of_Day
  ]];;

  if(aggregate==='Month'){
    pdfHeader = pdfHeaderMonth;
    let dataRecords =
    data != null &&
    data.length &&
    newReconstrucList.map((elt) => [
      elt.terminal_gate_id,
      elt.terminal_name,
      elt.gate_name,
      elt.terminal_turn_time,
      elt.street_wait_time,
      elt.volume,
      elt.volume_hour0,
      elt.volume_hour1,
      elt.volume_hour2plus,
      elt.avg_trucks_in_queue,
      elt.avg_queue_length,
      elt.exceptions,
      elt.daystamp_year,
      elt.daystamp_month,
    ]);
    pdfData = dataRecords;
    let excelData =
    data != null &&
    newReconstrucList.map((temp) => {
      return {
        [Terminal_Gate_Id]:temp.terminal_gate_id,
        [Terminal_Name]:temp.terminal_name,
        [Terminal_Gate]:temp.gate_name,
        [Avg_Ingate_Outgate]:temp.terminal_turn_time,
        [Avg_Street_Wait_Time]:temp.street_wait_time,
        [Trucks_Count]:temp.volume,
        [Trucks_Count_Hour_0]:temp.volume_hour0,
        [Trucks_Count_Hour_1]:temp.volume_hour1,
        [Trucks_Count_Hour_2_Plus]:temp.volume_hour2plus,
        [Avg_Truck_In_Queue]:temp.avg_trucks_in_queue,
        [Avg_Queue_Length]:temp.avg_queue_length,
        [Exceptions]:temp.exceptions,
        [Year_of_Date]:temp.daystamp_year,
        [Month_of_Date]:temp.daystamp_month
      };
    });
    excelDataSet = excelData;
    let historicalCVSdata = [
      { label: Terminal_Gate_Id, key: "terminal_gate_id"},
      { label: Terminal_Name, key: "terminal_name"},
      { label: Terminal_Gate, key: "gate_name"},
      { label: Avg_Ingate_Outgate, key: "terminal_turn_time"},
      { label: Avg_Street_Wait_Time, key: "street_wait_time"},
      { label: Trucks_Count, key: "volume"},
      { label: Trucks_Count_Hour_0, key: "volume_hour0"},
      { label: Trucks_Count_Hour_1, key: "volume_hour1"},
      { label: Trucks_Count_Hour_2_Plus, key: "volume_hour2plus"},
      { label: Avg_Truck_In_Queue, key: "avg_trucks_in_queue"},
      { label: Avg_Queue_Length, key: "avg_queue_length"},
      { label: Exceptions, key: "exceptions"},
      { label: Year_of_Date, key: "daystamp_year"},
      {label:Month_of_Date,key: "daystamp_month"}
    ];
    historicalCVSHeader = historicalCVSdata
  }else if(aggregate==='Week'){
    pdfHeader = pdfHeaderWeek;
    let dataRecords =
    data != null &&
    data.length &&
    newReconstrucList.map((elt) => [
      elt.terminal_gate_id,
      elt.terminal_name,
      elt.gate_name,
      elt.terminal_turn_time,
      elt.street_wait_time,
      elt.volume,
      elt.volume_hour0,
      elt.volume_hour1,
      elt.volume_hour2plus,
      elt.avg_trucks_in_queue,
      elt.avg_queue_length,
      elt.exceptions,
      elt.daystamp_year,
      elt.week_of_year,
    ]);
    pdfData = dataRecords;
    let excelData =
    data != null &&
    newReconstrucList.map((temp) => {
      return {
        [Terminal_Gate_Id]:temp.terminal_gate_id,
        [Terminal_Name]:temp.terminal_name,
        [Terminal_Gate]:temp.gate_name,
        [Avg_Ingate_Outgate]:temp.terminal_turn_time,
        [Avg_Street_Wait_Time]:temp.street_wait_time,
        [Trucks_Count]:temp.volume,
        [Trucks_Count_Hour_0]:temp.volume_hour0,
        [Trucks_Count_Hour_1]:temp.volume_hour1,
        [Trucks_Count_Hour_2_Plus]:temp.volume_hour2plus,
        [Avg_Truck_In_Queue]:temp.avg_trucks_in_queue,
        [Avg_Queue_Length]:temp.avg_queue_length,
        [Exceptions]:temp.exceptions,
        [Year_of_Date]:temp.daystamp_year,
        [Week_of_Year]:temp.week_of_year
      };
    });
    excelDataSet = excelData;
    let historicalCVSdata = [
      { label: Terminal_Gate_Id, key: "terminal_gate_id"},
      { label: Terminal_Name, key: "terminal_name"},
      { label: Terminal_Gate, key: "gate_name"},
      { label: Avg_Ingate_Outgate, key: "terminal_turn_time"},
      { label: Avg_Street_Wait_Time, key: "street_wait_time"},
      { label: Trucks_Count, key: "volume"},
      { label: Trucks_Count_Hour_0, key: "volume_hour0"},
      { label: Trucks_Count_Hour_1, key: "volume_hour1"},
      { label: Trucks_Count_Hour_2_Plus, key: "volume_hour2plus"},
      { label: Avg_Truck_In_Queue, key: "avg_trucks_in_queue"},
      { label: Avg_Queue_Length, key: "avg_queue_length"},
      { label: Exceptions, key: "exceptions"},
      { label: Year_of_Date, key: "daystamp_year"},
      {label:Week_of_Year,key: "week_of_year"}
    ];
    historicalCVSHeader = historicalCVSdata
  }else if(aggregate==='Day'){
    pdfHeader = pdfHeaderDay;
    let dataRecords =
    data != null &&
    data.length &&
    newReconstrucList.map((elt) => [
      elt.terminal_gate_id,
      elt.terminal_name,
      elt.gate_name,
      elt.terminal_turn_time,
      elt.street_wait_time,
      elt.volume,
      elt.volume_hour0,
      elt.volume_hour1,
      elt.volume_hour2plus,
      elt.avg_trucks_in_queue,
      elt.avg_queue_length,
      elt.exceptions,
      elt.daystamp_year,
      elt.daystamp_month,
      elt.week_of_year,
      elt.day_of_month,
      elt.day_of_week,
    ]);
    pdfData = dataRecords;
    let excelData =
    data != null &&
    newReconstrucList.map((temp) => {
      return {
        [Terminal_Gate_Id]:temp.terminal_gate_id,
        [Terminal_Name]:temp.terminal_name,
        [Terminal_Gate]:temp.gate_name,
        [Avg_Ingate_Outgate]:temp.terminal_turn_time,
        [Avg_Street_Wait_Time]:temp.street_wait_time,
        [Trucks_Count]:temp.volume,
        [Trucks_Count_Hour_0]:temp.volume_hour0,
        [Trucks_Count_Hour_1]:temp.volume_hour1,
        [Trucks_Count_Hour_2_Plus]:temp.volume_hour2plus,
        [Avg_Truck_In_Queue]:temp.avg_trucks_in_queue,
        [Avg_Queue_Length]:temp.avg_queue_length,
        [Exceptions]:temp.exceptions,
        [Year_of_Date]:temp.daystamp_year,
        [Month_of_Date]:temp.daystamp_month,
        [Week_of_Year]:temp.week_of_year,
        [Day_of_Month]:temp.day_of_month,
        [Day_of_Week]:temp.day_of_week
      };
    });
    excelDataSet = excelData;
    let historicalCVSdata = [
      { label: Terminal_Gate_Id, key: "terminal_gate_id"},
      { label: Terminal_Name, key: "terminal_name"},
      { label: Terminal_Gate, key: "gate_name"},
      { label: Avg_Ingate_Outgate, key: "terminal_turn_time"},
      { label: Avg_Street_Wait_Time, key: "street_wait_time"},
      { label: Trucks_Count, key: "volume"},
      { label: Trucks_Count_Hour_0, key: "volume_hour0"},
      { label: Trucks_Count_Hour_1, key: "volume_hour1"},
      { label: Trucks_Count_Hour_2_Plus, key: "volume_hour2plus"},
      { label: Avg_Truck_In_Queue, key: "avg_trucks_in_queue"},
      { label: Avg_Queue_Length, key: "avg_queue_length"},
      { label: Exceptions, key: "exceptions"},
      { label: Year_of_Date, key: "daystamp_year"},
      { label: Month_of_Date, key: "daystamp_month"},
      {label:Week_of_Year,key: "week_of_year"},
      {label:Day_of_Month,key: "day_of_month"},
      {label:Day_of_Week,key: "day_of_week"},
    ];
    historicalCVSHeader = historicalCVSdata
  }else if(aggregate==='Hour'){
    pdfHeader = pdfHeaderHour;
    let dataRecords =
    data != null &&
    data.length &&
    newReconstrucList.map((elt) => [
      elt.terminal_gate_id,
      elt.terminal_name,
      elt.gate_name,
      elt.terminal_turn_time,
      elt.street_wait_time,
      elt.volume,
      elt.volume_hour0,
      elt.volume_hour1,
      elt.volume_hour2plus,
      elt.avg_trucks_in_queue,
      elt.avg_queue_length,
      elt.exceptions,
      elt.daystamp,
      elt.daystamp_year,
      elt.daystamp_month,
      elt.week_of_year,
      elt.day_of_month,
      elt.day_of_week,
      elt.hour_of_day,
    ]);
    pdfData = dataRecords;
    let excelData =
    data != null &&
    newReconstrucList.map((temp) => {
      return {
        [Terminal_Gate_Id]:temp.terminal_gate_id,
        [Terminal_Name]:temp.terminal_name,
        [Terminal_Gate]:temp.gate_name,
        [Avg_Ingate_Outgate]:temp.terminal_turn_time,
        [Avg_Street_Wait_Time]:temp.street_wait_time,
        [Trucks_Count]:temp.volume,
        [Trucks_Count_Hour_0]:temp.volume_hour0,
        [Trucks_Count_Hour_1]:temp.volume_hour1,
        [Trucks_Count_Hour_2_Plus]:temp.volume_hour2plus,
        [Avg_Truck_In_Queue]:temp.avg_trucks_in_queue,
        [Avg_Queue_Length]:temp.avg_queue_length,
        [Exceptions]:temp.exceptions,
        [Date]:temp.daystamp,
        [Year_of_Date]:temp.daystamp_year,
        [Month_of_Date]:temp.daystamp_month,
        [Week_of_Year]:temp.week_of_year,
        [Day_of_Month]:temp.day_of_month,
        [Day_of_Week]:temp.day_of_week,
        [Hour_of_Day]:temp.hour_of_day,
      };
    });
    excelDataSet = excelData;
    let historicalCVSdata = [
      { label: Terminal_Gate_Id, key: "terminal_gate_id"},
      { label: Terminal_Name, key: "terminal_name"},
      { label: Terminal_Gate, key: "gate_name"},
      { label: Avg_Ingate_Outgate, key: "terminal_turn_time"},
      { label: Avg_Street_Wait_Time, key: "street_wait_time"},
      { label: Trucks_Count, key: "volume"},
      { label: Trucks_Count_Hour_0, key: "volume_hour0"},
      { label: Trucks_Count_Hour_1, key: "volume_hour1"},
      { label: Trucks_Count_Hour_2_Plus, key: "volume_hour2plus"},
      { label: Avg_Truck_In_Queue, key: "avg_trucks_in_queue"},
      { label: Avg_Queue_Length, key: "avg_queue_length"},
      { label: Exceptions, key: "exceptions"},
      { label:Date, key: "daystamp"},
      { label: Year_of_Date, key: "daystamp_year"},
      { label: Month_of_Date, key: "daystamp_month"},
      {label:Week_of_Year,key: "week_of_year"},
      {label:Day_of_Month,key: "day_of_month"},
      {label:Day_of_Week,key: "day_of_week"},
      {label:Hour_of_Day,key: "hour_of_day"},
    ];
    historicalCVSHeader = historicalCVSdata
  }else{
    pdfHeader = pdfHeaderYear;
    let pdfDataYear =
    data != null &&
    data.length &&
    newReconstrucList.map((elt) => [
      elt.terminal_gate_id,
      elt.terminal_name,
      elt.gate_name,
      elt.terminal_turn_time,
      elt.street_wait_time,
      elt.volume,
      elt.volume_hour0,
      elt.volume_hour1,
      elt.volume_hour2plus,
      elt.avg_trucks_in_queue,
      elt.avg_queue_length,
      elt.exceptions,
      elt.daystamp_year
    ]);
    pdfData = pdfDataYear;
    //Excel File
    let excelData =
    data != null &&
    newReconstrucList.map((temp) => {
      return {
        [Terminal_Gate_Id]:temp.terminal_gate_id,
        [Terminal_Name]:temp.terminal_name,
        [Terminal_Gate]:temp.gate_name,
        [Avg_Ingate_Outgate]:temp.terminal_turn_time,
        [Avg_Street_Wait_Time]:temp.street_wait_time,
        [Trucks_Count]:temp.volume,
        [Trucks_Count_Hour_0]:temp.volume_hour0,
        [Trucks_Count_Hour_1]:temp.volume_hour1,
        [Trucks_Count_Hour_2_Plus]:temp.volume_hour2plus,
        [Avg_Truck_In_Queue]:temp.avg_trucks_in_queue,
        [Avg_Queue_Length]:temp.avg_queue_length,
        [Exceptions]:temp.exceptions,
        [Year_of_Date]:temp.daystamp_year
      };
    });
    excelDataSet = excelData;
    let historicalCVSdata = [
      { label: Terminal_Gate_Id, key: "terminal_gate_id"},
      { label: Terminal_Name, key: "terminal_name"},
      { label: Terminal_Gate, key: "gate_name"},
      { label: Avg_Ingate_Outgate, key: "terminal_turn_time"},
      { label: Avg_Street_Wait_Time, key: "street_wait_time"},
      { label: Trucks_Count, key: "volume"},
      { label: Trucks_Count_Hour_0, key: "volume_hour0"},
      { label: Trucks_Count_Hour_1, key: "volume_hour1"},
      { label: Trucks_Count_Hour_2_Plus, key: "volume_hour2plus"},
      { label: Avg_Truck_In_Queue, key: "avg_trucks_in_queue"},
      { label: Avg_Queue_Length, key: "avg_queue_length"},
      { label: Exceptions, key: "exceptions"},
      { label: Year_of_Date, key: "daystamp_year"}
    ];
    historicalCVSHeader = historicalCVSdata
  }
 

  const historicalcsv = {
    data: newReconstrucList,
    headers: historicalCVSHeader,
    filename: historical_title_txt,
  };

  //Export in xml
  const downloadXml = () => {
    // xml file download in terminal data
    exportFromJSON({
      data: newReconstrucList,
      fileName: historical_title_txt,
      exportType: "xml",
    });
  };



  return (
    <>
      <Button variant="primary exportBtnDiv" onClick={handleShow}>
        <i className="fa fa-download"> </i>
        {export_text}
      </Button>

      <Modal className="exportModalDiv_HTTT" centered size="sm" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{download_export_file_txt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="exportFileDiv">
            <span title={export_PDF_txt}>
              {" "}
              <PdfGenerater
                data={pdfData}
                buttonclassName={"pdfbutton"}
                header={pdfHeader}
                buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                title={historical_title_txt}
                size="A3"
                fileName={historical_title_txt}
              />
            </span>
            <span title={export_Excel_txt}>
              <JsonToExcel
                title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                data={excelDataSet}
                fileName={historical_title_txt}
                btnClassName="downloadBtny btn btn-primary downloadbutton"
              />
            </span>
            <span title={export_CSV_txt}>
              <CSVLink
                {...historicalcsv}
                className="downloadBtny btn btn-primary downloadbutton"
              >
                <FontAwesomeIcon icon={faFileCsv} size="lg" />
              </CSVLink>
            </span>
            <span title={export_XML_txt}>
              <button
                className="downloadBtny btn btn-primary downloadbutton"
                onClick={() => downloadXml()}
              >
                <FontAwesomeIcon icon={faFileCode} size="lg" />
              </button>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {close_txt}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
