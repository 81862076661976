import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import axios from "src/redux/actions/apis";
import { GET_LIST_OF_DATA_BY_MARINE_ID } from '../../url/Url';
import { languageJson } from '../../StringVariable';
import {
  HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, Legend, LineSeries, ColumnSeries
} from 'react-jsx-highcharts';
import DataNotFound from '../DataNotFound/DataNotFound';
import SpinerLoader from 'src/utils/SpinerLoader';
import { submitGAEvent } from 'src/utils/GAEvents';

const plotOptions = {
  series: {
    pointStart: 0,
    pointInterval: 1,
    type: 'column',
    categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8']
  }
};

const MarineTerminalGraph = (props) => {
  const [loader, setloader] = useState(false);
  const [list, setlist] = useState(null);

  const getGraphDataByMarinId = async (terminal_name,gate_name) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      setloader(true)
      await axios
        .get(`${GET_LIST_OF_DATA_BY_MARINE_ID}${gate_name+"/"+terminal_name}`, requestObj)
        .then((res) => {
          const { marine_graph, customcode } = res.data;
          if (customcode === 200 && marine_graph.length > 0) {
            setlist(marine_graph)
            setloader(false)
          } else if (customcode === 213) {
            setlist([])
          }
          else {
            setlist([])
          }
          setloader(false)
        });
    } catch (err) {
      // console.log(err);
    }
    setloader(false)
  };
  useEffect(() => {
    getGraphDataByMarinId(props.terminalName,props.marineGateName)
     submitGAEvent(`MARINE TERMINAL GRAPH - ${props.terminalName}`);
  }, [props.terminalName,props.marineGateName])

  if (loader) {
    return <SpinerLoader />
  } if (!loader && list !== null && list && list.length > 0) {
    return <div className="app">
      <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart plotOptions={plotOptions}>
        <Chart
          showAxes={true} export type csv={'csv'} selectionMarkerFill="rgba(51,92,173,0.25)"
        />
        <Legend layout="horizontal" align="center" backgroundColor="#fff" verticalAlign="top" />

        <XAxis min={0} max={24} >
          <XAxis.Title >{languageJson.hour_of_the_day_txt}</XAxis.Title>
        </XAxis>

        <YAxis labels={{ enabled: true }} >
          <YAxis.Title >{languageJson.number_of_hours_txt}</YAxis.Title>
          <LineSeries color={"green"} name={languageJson.Total_Turn_Time_txt} data={list.map(item => typeof (item.terminal_turn_time) === "number" ? item.terminal_turn_time / 60 : 0)} />

          <ColumnSeries color={"blue"} name={languageJson.Terminal_Turn_Time_txt} data={list.map(item => typeof (item.terminal_turn_time) === "number" ? item.terminal_turn_time / 60 : 0)} />

          <ColumnSeries color={"red"} name={languageJson.street_wait_time_txt} data={list.map(item => typeof (item.street_wait_time) === "number" ? item.street_wait_time / 60 : 0)} />
        </YAxis>
      </HighchartsChart>
      </HighchartsProvider>
    </div>
  }

  else if (!loader) {
    return list !== null && list && list.length === 0 && <DataNotFound />
  }
}

export default MarineTerminalGraph;