import { useState, useEffect } from "react";
import Header from "../../Containers/Header";
 import axios from "src/redux/actions/apis";
import portimg1 from "../../assets/NewsandUpdates/1.jpg"; 
import portimg2 from "../../assets/NewsandUpdates/2.jpg";
import portimg3 from "../../assets/NewsandUpdates/3.jpg";

import { Link, useHistory } from "react-router-dom";
import { NEWS_AND_UPDATE_API } from "../../url/Url";
import { languageJson } from "../../StringVariable";
import Pagination from "src/admin/views/components/custome/Pagination";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import noImage from "../../assets/no-image.png";
import moment from "moment";
import { submitGAEvent } from "src/utils/GAEvents";
moment.suppressDeprecationWarnings = true;

function NewsAndUpdatesDetails() {
  const [newsAndUpdatesList, setNewsAndsUpdatesList] = useState(null);
  const [pageDetails, setPageDetails] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const limit = 10;

  const setPage = ({ selected }) => {
    getNewsAndUpdate(selected + 1, limit);
    setSelectedPage(selected + 1);
  };

  let history = useHistory();
  const getNewsAndUpdate = async (page, limit) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(NEWS_AND_UPDATE_API + `/${page}/${limit}`, requestObj)
        .then((res) => {
          const { customcode, listData, limit, page, total } = res.data;
          if (customcode === 200) {
            setPageDetails({ per_page: limit, page, total });
            setNewsAndsUpdatesList(listData);
          } else {
            setNewsAndsUpdatesList([]);
          }
        });
    } catch (err) { }
  };
  useEffect(() => {
    getNewsAndUpdate(selectedPage, limit);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendToDetailPage = (id) => {
    history.push(`/news-Details/${id}`);
  };

  useEffect(() => {
    submitGAEvent("NEWS AND UPDATES");
  },[]);

  return (
    <div>
      <div>
        <Header />
      </div>
         {/* <img className="banner_img" src={Aboutusbanner} alt="banner" /> */}
        <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg2} alt=""/> 
             <img className="banner_img" src={portimg3} alt=""/>      
      </div>
       <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.News_And_Update_txt}</span>
            </p>
          </div>
        </div>
      </div>
      <section className="newsupdates-section">
        <div className="container pt-5">
          <div className="row newsDivBox ">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-8 center-head-underline d-flex justify-content-center py-4 headingTxt">
              <h1 className="text-center  py-2 f-head">
                {languageJson.sectionName.newsupdates}
              </h1>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-4 col-xs-4 d-flex justify-content-end  align-items-center viewAllBtn"></div>
          </div>
          <section className="home-tools-section">
            <div className="container pb-5">
              <div className="row">
                {newsAndUpdatesList != null &&
                  newsAndUpdatesList &&
                  newsAndUpdatesList.length > 0 ? (
                  newsAndUpdatesList.map((list) => (
                    <div key={list.news_id} className="col-lg-6 py-2 ">
                      <div className="row newsInnerBoxDiv">
                        <div className="col-lg-5 col-md-6 col-sm-12 imgDivBox ">
                          <img
                            className="newsupdate-sec-img"
                            style={{ cursor: "pointer" }}
                            onClick={() => sendToDetailPage(list.news_id)}
                            src={
                              list.new_image !== null && !list.new_image.includes(null) ? list.new_image : noImage
                            }
                            onError={(e) => {
                              e.currentTarget.src = noImage;
                            }}
                            alt="img"
                          />
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 textDescpDiv">
                          <p className="news-date">
                            {list.news_published_on.toLowerCase() !== "null" ||
                              list.news_published_on !== null
                              ? moment(list.news_published_on).format(
                                "Do MMM YYYY"
                              )
                              : "-"}
                          </p>
                          <p
                            className="news-head"
                            onClick={() => sendToDetailPage(list.news_id)}
                            style={{ cursor: "pointer" }}
                          >
                            {list.news_heading.toLowerCase() !== "null" ||
                              list.news_heading !== null
                              ? list.news_heading
                              : "-"}
                          </p>
                          <p className="txt-overflow">
                            {list.news_description.toLowerCase() !== "null" ||
                              list.news_description !== null
                              ? list.news_description.replace(/<[^>]+>/g, "")
                              : "-"}{" "}
                          </p>
                          <Link
                            className="viewall-clr"
                            to={`/news-Details/${list.news_id}`}
                          >
                            {languageJson.readmore_txt} <span>&#62;</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 col-md-12 col-md-12 mb-3">
                    {newsAndUpdatesList != null &&
                      newsAndUpdatesList.length === 0 ? (
                      <DataNotFound />
                    ) : (
                      <SpinerLoader />
                    )}
                  </div>
                )}
              </div>
              <div className="pagination-parent mt-2">
                {newsAndUpdatesList != null && newsAndUpdatesList.length ? (
                  <Pagination
                    public={true}
                    total={pageDetails.total}
                    per_page={pageDetails.per_page}
                    selectedPage={selectedPage}
                    setPage={setPage}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* <Findus /> */}
    </div>
  );
}

export default NewsAndUpdatesDetails;
