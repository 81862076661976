import React, { useState, useEffect } from "react";
import Header from "src/Containers/Header";
import Aboutusbanner from "src/assets/aboutus-banner.png";
import { Link } from "react-router-dom";
import axios from "src/redux/actions/apis";
import { GET_FAQ_DATA_API } from "src/url/Url";
import { languageJson } from "src/StringVariable";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import { submitGAEvent } from "src/utils/GAEvents";
import portimg1 from "../../assets/1.jpg"; 
import portimg5 from "../../assets/5.jpg";
function FrequentlyAskedQuestions() {
  const [faqs, setFaqs] = useState(null);
  const getFaqsData = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.get(GET_FAQ_DATA_API, requestObj).then((res) => {
        const { customcode, faqList } = res.data
        if (customcode === 200) {
          setFaqs(faqList);
        }
        if (customcode === 213) {
          setFaqs([])
        }
      });
    } catch (err) { }
  };
  useEffect(() => {
    getFaqsData();
  }, []);

  useEffect(() => {
    submitGAEvent("FAQS");
  }, []);

  return (
    <div>
      <Header />
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg5} alt=""/> 
             <img className="banner_img" src={Aboutusbanner} alt=""/>      
      </div>
      {/* <div className="headerBannerMainDiv">
        <img className="banner_img" src={Aboutusbanner} alt=""/> 
      </div> */}
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.frequently_Asked_Questions_txt}</span>
            </p>
          </div>
        </div>
      </div>
      <section className="faqs-section">
        <div className="container">
          <div className="row ">
            <div className="row postionRelative ">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 titleTxtDiv center-head-underline d-flex justify-content-center py-4">
                <h1 className="text-center  py-2 f-head">
                  {languageJson.sectionName.frequentlyAskedQuestions}{" "}
                </h1>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 viewBtnDiv  d-flex justify-content-end  align-items-center"></div>
            </div>
          </div>

          {/* first row */}
          <div className="row">
            {faqs != null && faqs.length && faqs.length > 0 ? faqs.map((faq, index) => (
              <div className="col-lg-6 col-md-6 col-md-12 mb-3" key={index}>
                <div className="row container d-flex align-items-start">
                  <div className="col-lg-4 col-xl-3 divBoxNumber">
                    <p className="faqs-num text-center ">
                      {" "}
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </p>
                  </div>
                  <div className="col-lg-8 col-xl-9">
                    <p className="faqs-head">{faq.question !== null || faq.question.toLowerCase() !== "null" ? faq.question : ""}</p>

                    <p> {faq.answer !== null || faq.answer.toLowerCase() !== "null" ? faq.answer : "-"}{faq.url && <a className="" target="_blank" rel="noreferrer" href={faq.url}>{languageJson.click_here_txt}</a>}</p>
                  </div>
                </div>
              </div>
            )) : <div className="col-lg-12 col-md-12 col-md-12 mb-3" >
              {faqs != null && faqs && faqs.length === 0 ? <DataNotFound /> : <SpinerLoader />}
            </div>}
          </div>
        </div>
      </section>
      {/* <Findus /> */}
    </div>
  );
}

export default FrequentlyAskedQuestions;
