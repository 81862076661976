import React from 'react';
import { languageJson } from 'src/StringVariable';

const HomeLanguageChange = ({ onchange, value, name }) => {
    return (
        <select
            className="languageSelectDiv"
            onChange={onchange}
            autoComplete="off"
            value={value}
            name={name}
        >
            <option value="en">{languageJson.english_txt}</option>
            <option value="es">{languageJson.spanish_txt}</option>
            <option value="pa">{languageJson.punjabi_txt}</option>
        </select>
    )
}

export default HomeLanguageChange;