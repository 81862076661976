import '../../Modal.css';
import { languageJson } from '../../StringVariable';

const Modal = (props) => {
  const showOTPModal = () => {
    props.otpButton();
    props.onClose()
  }

  return (
    <div className='custom-modal driverregistration-popup pb-3'>
      <div className={`${props.status} driverregistration-popup-head py-3`}>
        <i className={`${props.icon}`} style={{ fontSize: "30px" }} aria-hidden="true"></i>
      </div>
      <p className="py-3 fw-bold">{props.type}</p>
      <p>{props.text}</p>
      <button className={`${props.btnColor}`} onClick={() => props.otpButton ? showOTPModal() : props.onClose()}>
        {languageJson.ok_txt}
      </button>
    </div>
  );
}

export default Modal;
