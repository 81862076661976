import React, { useState, useEffect } from "react";
import Header from "src/Containers/Header";
import vessel_image from "src/assets/banner.jpg";
import Backdrop from "src/Containers/modal/BackDrop";
import Modal from "src/Containers/modal/Modal";
import OtpModal from "src/Containers/otp/OtpModal";
import '../DriverRegistration/DriverRegistration.css';
import { registerDriverSchema } from "src/utils/Validations";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { driverRegistration, clearLoadingMessageStatus, otpSend } from "src/redux/actions/UserAccount";
import InputMask from "react-input-mask";
import { useLocation } from 'react-router-dom';
import { languageJson } from "src/StringVariable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
moment.suppressDeprecationWarnings = true;


function DriverRegistration() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  let dispatch = useDispatch();
  let { loadingState, messageState, statusCodeState } = useSelector(state => state);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showOTP, setShowOTP] = useState(false)
  const [email, setEmail] = useState('')
  const location = useLocation();
  const { pathname } = location;
  const formOptions = { resolver: yupResolver(registerDriverSchema), defaultValues: { userAgreement: pathname === "/aggrement/driverRegistration" ? true : false } };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const [onModal, setOnModal] = useState(false);
  const { errors } = formState;
  const initialDob = moment(new Date("01/01/2000")).toDate();
  const initialExpirationDate = moment(new Date()).add(1, 'days').toDate();
  const initialTwicExpirationDate = moment(new Date()).add(1, 'days').toDate();
  const [dob, setDob] = useState(initialDob);
  const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
  const [twicExpirationDate, setTwicExpirationDate] = useState(initialTwicExpirationDate);

  const handleSubmitRegister = async (registerData) => {
    if (!onModal) {
      const { first_name, last_name, email, password, mobile, dl_or_companyid_number, company_name,state,twicCardNumber } = registerData;
      let user_type = 'Driver';
      let user_type_value = 'Driver';
      let dateOfBirth = moment(dob).format("MM/DD/YYYY")
      let expirationDateFinal = moment(expirationDate).format("MM/DD/YYYY")
      let twicExpirationDateFinal = moment(twicExpirationDate).format("MM/DD/YYYY")
      let formData = { first_name, last_name, user_type, user_type_value, email, password, mobile, dl_or_companyid_number, company_name,dob:dateOfBirth ,expirationDate:expirationDateFinal ,twicExpirationDate:twicExpirationDateFinal,state,twicCardNumber }
      setEmail(email)
      dispatch(driverRegistration({ ...formData }));
    }
  };

  const closeModal = () => {
    dispatch(clearLoadingMessageStatus())
    setOnModal(false);
  }

  const getEmailOTP = () => {
    const emailData = { 'email': email }
    dispatch(otpSend(emailData))
  }

  useEffect(() => {
    if (statusCodeState.driverRegisterCode !== null) {
      setOnModal(true);
    }
  }, [statusCodeState.driverRegisterCode])


  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const onchangeDob = (date) => {
    setDob(date);
  };

  const onchangeDate = (date) => {
    setExpirationDate(date);
  };

  const onchangeTwicExpirationDate = (date) => {
    setTwicExpirationDate(date);
  }

  
  const handleKeyPress = (e) => {
    e.preventDefault();
  };

  const DatePickerCustomInput = () => <div className="calendar_icon"><i className="fa fa-calendar" aria-hidden="true"></i></div>



  return (
    <div>
      <Header />
      <div className="headerBannerMainDiv">
        <img className="vessel_img drivenRegistrationBannerImg " src={vessel_image} alt="vessel" />
      </div>
      <section className="driverregistration-section driverRegistrationForm" id="driverRegistration">
        <div className="container my-5">
          <div className="row">
            <h2>{languageJson.driver_registration_txt}</h2>
            <div className="col-lg-12">
              <p>
                {languageJson.driver_registration_Desp_txt}
              </p>
            </div>
          </div>

          {/* -----------------form start------------ */}
          <form onSubmit={handleSubmit(handleSubmitRegister)} autoComplete="off">
            <div className="row mt-5">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt"> {languageJson.first_name_txt}</label>
                  <div className="col-sm-6 col-lg-8 mb-3">
                    <input className={`form-control form-control-lg form-placeholder`}
                      name="first_name" id="first_name" type="text" maxLength="25"
                      placeholder={languageJson.enter_first_name_msg_txt}
                      {...register('first_name')}
                    />
                    <p className="text-danger">{errors.first_name?.message}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.last_name_txt}</label>
                  <div className="col-sm-6 col-lg-8 mb-3">
                    <input className={`form-control form-control-lg form-placeholder`}
                      name="last_name" id="last_name" type="text" maxLength="25"
                      placeholder={languageJson.enter_last_name_msg_txt}
                      {...register('last_name')}
                    />
                    <p className="text-danger">{errors.last_name?.message}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.email_address_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="email" id="email" type="text" maxLength="50"
                        placeholder={languageJson.enter_email_address_txt}
                        autoComplete="off"
                        {...register('email')}
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.mobile_number_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3 ">
                      <InputMask
                        className={`form-control form-control-lg form-placeholder`}
                        name="mobile"
                        id="mobile"
                        type="text"
                        placeholder={languageJson.mobile_number_error_txt}
                        mask="(999) 999-9999"
                        maskChar=" "
                        onKeyDown={handleKeyDown}
                        {...register('mobile')}
                      />
                      <p className="text-danger">{errors.mobile?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.password_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="password" id="password" type={passwordShown ? "text" : "password"}
                        placeholder={languageJson.enterPwd_txt}
                        autoComplete="off"
                        {...register('password')}
                        onKeyDown={handleKeyDown}
                      />
                      <span toggle="#password-field" className={`${passwordShown ? "fa fa-fw fa-eye-slash" : "fa fa-fw fa-eye"} field-icon toggle-password `} onClick={() => setPasswordShown(!passwordShown)}></span>
                      <p className="text-danger">{errors.password?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label
                    id="colFormLabellg"
                    className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt"
                  >
                    {languageJson.confirm_password_txt}
                  </label>

                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="confirmPassword" id="confirmPassword" type={confirmPasswordShown ? "text" : "password"}
                        placeholder={languageJson.confirm_password_txt}
                        {...register('confirmPassword')}
                        onKeyDown={handleKeyDown}
                      />
                      <span id="togglePassword" toggle="#password-field" className={`${confirmPasswordShown ? "fa fa-fw fa-eye-slash" : "fa fa-fw fa-eye"} field-icon toggle-password eyeiconspan`} onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}></span>
                      <p className="text-danger">{errors.confirmPassword?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.CDLTWIC_number_txt_1}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="dl_or_companyid_number" id="dl_or_companyid_number" type="text" maxLength="20"
                        placeholder={languageJson.CDLTWIC_number_txt_1}
                        {...register('dl_or_companyid_number')}
                        onKeyDown={handleKeyDown}
                      />
                      <p className="text-danger">{errors.dl_or_companyid_number?.message}</p>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.company_name_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="company_name" id="company_name" type="text"
                        placeholder={languageJson.company_name_error_txt}
                        {...register('company_name')}
                      />
                      <p className="text-danger">{errors.company_name?.message}</p>

                    </div>
                  </div>
                </div>
              </div>


              {/* New Fields added */}
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.state_issue_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="state" id="state" type="text"
                        placeholder={languageJson.state_of_issuance_placeholder}
                        {...register('state')}
                      />
                      <p className="text-danger">{errors.state?.message}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                  <div className="form-group row">
                    <label  className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.date_of_birth_txt}</label>
                    <div className="col-sm-6 col-lg-8">
                      <div className="">
                        <DatePicker
                          selected={dob}
                          onChange={(date) => onchangeDob(date)}
                          selectsStart
                          onKeyDown={handleKeyPress}
                          maxDate={new Date()}
                          className="form-control form-control-lg form-placeholder"
                        />
                        <DatePickerCustomInput />
                        <p className="text-danger">
                          {errors.dob?.message}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.TWIC_txt_1}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="twicCardNumber" id="twicCardNumber" type="text"
                        placeholder={languageJson.TWICCardNo_placeholder}
                        {...register('twicCardNumber')}
                      />
                      <p className="text-danger">{errors.twicCardNumber?.message}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                  <div className="form-group row">
                    <label  className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.TWIC_expiration_txt_1}</label>
                    <div className="col-sm-6 col-lg-8">
                      <div className="">
                        <DatePicker
                          selected={twicExpirationDate}
                          onChange={(date) => onchangeTwicExpirationDate(date)}
                          onKeyDown={handleKeyPress}
                          placeholderText={languageJson.TWIC_expiration_date_placeholder}
                          className="form-control form-control-lg form-placeholder"
                        />
                        <DatePickerCustomInput />
                        <p className="text-danger">
                          {errors.twicExpirationDate?.message}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                  <div className="form-group row">
                    <label  className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt companyNameLabelTxt">{languageJson.expiration_date_txt_1}</label>
                    <div className="col-sm-6 col-lg-8">
                      <div className="">
                        <DatePicker
                          selected={expirationDate}
                          onChange={(date) => onchangeDate(date)}
                          selectsStart
                          onKeyDown={handleKeyPress}
                          placeholderText={languageJson.expiration_date_placeholder}
                          className="form-control form-control-lg form-placeholder"
                        />
                        <DatePickerCustomInput />
                        <p className="text-danger">
                          {errors.expirationDate?.message}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-12">
              <p>
              <br></br>  
              
              <ul>
              <h6>Acronyms- </h6>
    <li><b>1. {languageJson.CDLTWIC_number_txt_1}**  -</b> {languageJson.CDLTWIC_number_txt}</li>
    <li><b>2. {languageJson.TWIC_expiration_txt_1}**  -</b> {languageJson.TWIC_expiration_txt}</li>
    <li><b>3. {languageJson.TWIC_txt_1}**  -</b> {languageJson.TWIC_txt}</li>
    <li><b>4. {languageJson.expiration_date_txt_1}**  -</b> {languageJson.expiration_date_txt}</li>
</ul>
              </p>
              <br></br>  
            </div>
              <div className="col-12">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className={`form-check-input is invalid`}
                    name="userAgreement"
                    id="userAgreement"
                    {...register('userAgreement')}
                  />
                  <label htmlFor="userAgreement" className="form-check-label formlabeltxt">
                    {languageJson.driver_registration_clickAgree_txt} {" "}
                    <span><a href="/#/userAgreement" rel="noreferrer" className="aggrementLink"> {languageJson.footerList.userAgreement}</a></span>
                  </label>
                  <p className="text-danger">{errors.userAgreement?.message}</p>
                </div>
              </div>
            </div>
            <div className="text-center mt-4 ">
              <button className="btn btn-primary submitform" type="submit" disabled={loadingState.isDriverRegisterLoading ? true : false}>
                {loadingState.isDriverRegisterLoading ? languageJson.processing_txt : languageJson.submit_txt}
              </button>
            </div>
          </form>
          {messageState.driverRegisterMessage != null ? (
            <React.Fragment>
              <Backdrop onClick={() => closeModal()} />
              <Modal text={messageState.driverRegisterMessage} otpButton={() => statusCodeState.driverRegisterCode === 200 ? setShowOTP(true) : ''} btnColor={statusCodeState.driverRegisterCode === 200 ? 'modal-btn-success' : 'modal-btn-warning'} icon={statusCodeState.driverRegisterCode === 200 ? 'fa fa-check-circle' : 'fa fa-exclamation-circle'} status={statusCodeState.driverRegisterCode === 200 ? 'bg-custom-success' : 'bg-warning'} onClose={() => closeModal()} />
            </React.Fragment>
          )
            : null
          }
          {showOTP ? (
            <React.Fragment>
              <Backdrop onClick={() => closeModal()} />
              <OtpModal text={languageJson.enter_OTP_email_txt} resendOTP={() => getEmailOTP()} email={email} status={'bg-custom-success'} btnColor={'modal-btn-success'} icon={'fa fa-check-circle'} onClose={() => closeModal()} />
            </React.Fragment>
          ) : null
          }
        </div>
      </section>
    </div>
  );
}

export default DriverRegistration;
