import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import axios from "src/redux/actions/apis";
import { REGIONAL_CAMERAS_URL, GOOGLE_MAP_URL } from "src/url/Url";
import Opcamera from "src/assets/cameraNew.png";
import oaklandlogo from "src/assets/new_logo.png";
import { submitGAEvent } from "src/utils/GAEvents";
import icon from "../../assets/icon.png";
import { languageJson } from '../../StringVariable';

function Camera() {
  const [camersData, setCamersData] = useState(null);
  const lat = 37.797237;
  const lng = -122.288246;
  const [Resources, setResources] = useState([]);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setCamersData(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [camersData]);

  const getVesselData = async () => {
    try {
      await axios
        .get(REGIONAL_CAMERAS_URL)
        .then((res) => {
          const { customcode, data } = res.data;
          if (customcode === 200) {
            setResources(data);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVesselData();
  }, []);

  useEffect(() => {
    submitGAEvent("REGIONAL CAMERAS");
  }, []);

  let imagePopups = (value) => {
    setCamersData(null);
    setCamersData(value);
  }

  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: lat, lng: lng }}
        options={{ streetViewControl: false }}
      >
        {Resources.length > 0 &&
          Resources.map((park, index) => (
            park['cctv']['imageData']['static']['currentImageURL'] !== "" && <Marker
              key={index}
              position={{
                lat: parseFloat(park['cctv']['location'].latitude),
                lng: parseFloat(park['cctv']['location'].longitude)
              }}
              onClick={() => {
                imagePopups(park);
              }}
              icon={{
                url: Opcamera,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />
          )
          )}

        {camersData !== null && (
          <InfoWindow
            onCloseClick={() => {
              setCamersData(null);
            }}
            position={{
              lat: parseFloat(camersData['cctv']['location'].latitude),
              lng: parseFloat(camersData['cctv']['location'].longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  setCamersData(null);
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">

                <div className="dataDescpTxt">
                  <span>
                    <label>{camersData['cctv']['location'].locationName} </label>
                  </span>
                  <span className="cameraLiveImg">
                    <img src={camersData['cctv']['imageData']['static']['currentImageURL']} alt="img" onError={e => { e.currentTarget.src = oaklandlogo }} />
                  </span>
                </div>
                <a
                  title={languageJson.titleForRedirect}
                  href={camersData['cctv']['imageData']['static']['currentImageURL']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="googleMapImageRedirect" src={icon} alt="icon" />
                </a>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Camera));

export default function RegionalCamerasOnMap() {
  return (
    <div className="vesselMapTab" style={{ width: "100%", height: "100vh", border: '0' }}>
      {navigator.onLine && <MapWrapped
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%`, borderRadius: '10px', border: '1px solid #999' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '10px' }} />}
      />}
    </div>
  );
}
