import { ActionTypes } from "../constants/Types";

export const loginSuccessMessage = (message) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS_MESSAGE,
        payload: message
    };
};

export const loginErrorMessage = (message) => {
    return {
        type: ActionTypes.LOGIN_ERROR_MESSAGE,
        payload: message
    };
};

export const clearMessage = () => {
    return {
        type: ActionTypes.LOAD_INTIAL_STATE,
    }
}

export const otpSendMessage = (message) => {
    return {
        type: ActionTypes.OTP_SEND_MESSAGE,
        payload: message
    }
}

export const driverRegisterMessage = (message) => {
    return {
        type: ActionTypes.DRIVER_REGISTER_MESSAGE,
        payload: message
    }
}

export const otpVerifyMessage = (message) => {
    return {
        type: ActionTypes.OTP_VERIFY_MESSAGE,
        payload: message
    }
}

export const lmcRegisterMessage = (message) => {
    return {
        type: ActionTypes.LMC_REGISTER_MESSAGE,
        payload: message
    }
}

export const contactUsMessage = (message) => {
    return {
        type: ActionTypes.CONTACT_US_MESSAGE,
        payload: message
    }
}

export const forgotPasswordMessage = (message) => {
    return {
        type: ActionTypes.FORGOT_PASSWORD_MESSAGE,
        payload: message
    }
}

export const changePasswordMessage = (message) => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_MESSAGE,
        payload: message
    }
}

export const vasselAlertsDataMessage = (message) => {
    return {
        type: ActionTypes.VASSEL_ALERTS_DATA_MESSAGE,
        payload: message
    }
}

export const railCrossingAlertsDataMessage = (message) => {
    return {
        type: ActionTypes.RAIL_CROSSING_ALERTS_DATA_MESSAGE,
        payload: message
    }
}

export const alertsAnnouncementsDataMessage = (message) => {
    return {
        type: ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_MESSAGE,
        payload: message
    }
}

export const marineTerminalAlertsDataMessage = (message) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_ALERTS_DATA_MESSAGE,
        payload: message
    }
}
export const trafficAndCamerasAlertsDataMessage = (message) => {
    return {
        type: ActionTypes.TRAFFIC_AND_CAMERAS_DATA_MESSAGE,
        payload: message
    }
}
export const securityAndSafetyAlertsDataMessage = (message) => {
    return {
        type: ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_MESSAGE,
        payload: message
    }
}
export const resourceDataMessage = (message) => {
    return {
        type: ActionTypes.RESOURCE_DATA_MESSAGE,
        payload: message
    }
}

export const portMessage = (message) => {
    return {
        type: ActionTypes.PORT_MESSAGE,
        payload: message
    }
}

export const portConstructionEventsMessage = (message) => {
    return {
        type: ActionTypes.PORT_CONSTRUCTION_EVENTS_MESSAGE,
        payload: message
    }
}

export const portIncidentClosuresMessage = (message) => {
    return {
        type: ActionTypes.PORT_INCIDENT_CLOSURES_MESSAGE,
        payload: message
    }
}

export const regionalMessageSignsMessage = (message) => {
    return {
        type: ActionTypes.REGIONAL_MESSAGE_SIGNS_MESSAGE,
        payload: message
    }
}

export const regionalConstructionEventsMessage = (message) => {
    return {
        type: ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_MESSAGE,
        payload: message
    }
}

export const regionalIncidentClosuresMessage = (message) => {
    return {
        type: ActionTypes.REGIONAL_INCIDENT_CLOSURES_MESSAGE,
        payload: message
    }
}
export const marineTerminalListMessage = (message) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_LIST_DATA_MESSAGE,
        payload: message
    };
};

export const reservationBookingMessage = (message) => {
    return {
        type: ActionTypes.RESERVATION_BOOKING_MESSAGE,
        payload: message
    };
};
export const districtMessageSignsMsg = (message) => {
    return {
        type: ActionTypes.DISTRICT_MESSAGE_SIGNS__MESSAGE,
        payload: message
    }
}

export const deleteAccountMsg = (message) => {
    return {
        type: ActionTypes.DELETE_ACCOUNT_MESSAGE,
        payload: message
    }
}

export const cancelDeleteAccountMsg = (message) => {
    return {
        type: ActionTypes.CANCEL_DELETE_ACCOUNT_MESSAGE,
        payload: message
    }
}