/* eslint-disable*/
import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
 import { Link } from "react-router-dom";
import truckroute_image from "../../assets/truckroute-image.png";
import oswo_image from "../../assets/oswo-image.png";
 import icon from "../../assets/icon.png";
import axios from "src/redux/actions/apis";
import DataNotFound from "../DataNotFound/DataNotFound";
import { GET_SECURITY_AND_SAFETY_API } from "../../url/Url";
import { languageJson } from "../../StringVariable";
import "./SecuritySafety.css";
import SecuritySafetyAlerts from "../Alerts/SecuritySafetyAlerts";
 import { submitGAEvent } from "src/utils/GAEvents";
 import portimg1 from "../../assets/TruckRoutes/1.jpg"; 
 import portimg2 from "../../assets/TruckRoutes/2.jpg"; 
 import portimg3 from "../../assets/TruckRoutes/3.jpg"; 
import { useSelector } from "react-redux";
import {
  TRUCK_ROUTES_VIEW
} from "../../url/Url";

 // safetyandsecurity/osowinfo
function SecuritySafety() {
  const [vesselOperation, setVesselOperations] = useState(true);
  const [VesselLocations, setVesselLocations] = useState(false);
  const [VesselAlerts, setVesselAlerts] = useState(false);
  const [osowinfo, setOsOwInfo] = useState();
  const onVesselLocations = () => {
    submitGAEvent("OS/OW INFO")
    setVesselLocations(true);
    setVesselOperations(false);
    setVesselAlerts(false);
  };

  const onVesselOperation = () => {
    submitGAEvent("TRUCK ROUTES")
    setVesselLocations(false);
    setVesselOperations(true);
    setVesselAlerts(false);
  };
  const onAlerts = () => {
    setVesselLocations(false);
    setVesselOperations(false);
    setVesselAlerts(true);
  };
  let singleUrls = useSelector(state => state.publicUrlState);
  const [userCondition, setUserCondition] = useState(null)
  useEffect(() => {
    if (singleUrls && singleUrls.singleUrls && singleUrls.singleUrls.length > 0) {
      let userCondition = singleUrls.singleUrls.find(({ url_id }) => url_id === 'truck_routes_url');
      setUserCondition(userCondition ? userCondition.url_link : TRUCK_ROUTES_VIEW)
    }
  }, [singleUrls]);
  const getOwOsInfo = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      const result = await axios
        .get(GET_SECURITY_AND_SAFETY_API, requestObj)
        .then((res) => res.data);
      const { osowinformation, customcode } = result;
      if (customcode === 200) {
        setOsOwInfo(osowinformation);
      } else {
        setOsOwInfo([]);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getOwOsInfo();
  }, []);

  return (
    <div>
      <Header />
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg2} alt=""/>
             <img className="banner_img" src={portimg3} alt=""/>      
      </div>
      {/* <div className="headerBannerMainDiv"><img className="vessel_img" src={vessel_image} alt="vessel" /></div> */}
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark">
                {" "}
                / {languageJson.safety_security_text_txt_new}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container vesselMainPage securitySafetyMain">
        <div className="row container-fluid py-5 tabVesselDiv">
          <div className="col-5 center-head-underline titleTabOption">
            <a
              onClick={() => {
                onVesselOperation();
              }}
              className={`text-decoration-none ${vesselOperation
                ? "center-head-underline-clr vo-headtxt py-2 "
                : ""
                } cursor-pointer vesselOptDiv`}
            >
              <h2
                className={`${vesselOperation ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={truckroute_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b> {languageJson.truck_routes_txt}</b>
              </h2>{" "}
            </a>
          </div>
          <div className="col-7 titleTabOption">
            <a
              onClick={() => {
                onVesselLocations();
              }}
              className={`text-decoration-none text-dark ${VesselLocations
                ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } cursor-pointer`}
            >
              <h2
                className={`${VesselLocations ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={oswo_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.os_ow_information_txt}</b>
              </h2>{" "}
            </a>
          </div>
          {/* <div className="col-3 titleTabOption">
            <a
              onClick={() => {
                onAlerts();
              }}
              className={`text-decoration-none   ${VesselAlerts
                ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } text-dark cursor-pointer alertTxtDiv`}
            >
              <h2 className={`${VesselAlerts ? "text-primary" : "text-dark"}`}>
                <span>
                  <img
                    src={bluealarm}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.alerts_txt}</b>
              </h2>{" "}
            </a>
          </div> */}
          {VesselAlerts && <SecuritySafetyAlerts />}

        </div>

         
          <div className="container-fluid vesselTabOptionDiv truckRouteDiv p-0" style={{display: vesselOperation && vesselOperation ? "block":"none"}}>
            <div className="truckRouteInnerDiv">
              {/* <div className="legendLineColorDiv">
                <div><b className="lineBg blackBgColor"></b>{languageJson.prohibited_routes_txt}</div>
                <div><b className="lineBg orangeBgColor"></b>{languageJson.overweight_routes_txt}</div>
                <div><b className="lineBg blueBgColor"></b>{languageJson.permitted_routes_txt}</div>
              </div>
              <GoogleKMLfile /> */}
              <div style={{overflow: "hidden"}}>
              <iframe 
                  src={userCondition}>
              </iframe>
              </div>
            </div>
          </div>
        

        {VesselLocations && osowinfo && osowinfo.length > 0 ? (
          <div className="vesselMapTab osowInfoDiv">
            <div className="osowInfoInnerDiv human-resources-section border-0">
              <div className="container-fuild">
                <div className=" homeresources-div">
                  {/* OsOw */}
                  {osowinfo.map((info) => (
                    <div className="row border border-white resources">
                      <a
                        href={info.osow_url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark"
                      >
                        <div className="d-flex justify-content-between align-items-center px-4 listPointDiv">
                          <div className="font-weight-bold fsize">
                            {info.osowinfo_name || "-"}
                          </div>

                          <div>
                            <a href={info.osow_url} alt="name" target="_blank" rel="noreferrer">
                              <img
                                align="right"
                                className=""
                                src={icon}
                                alt="icon"
                              />
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          VesselLocations && osowinfo && osowinfo.length === 0 && <DataNotFound />
        )}
      </div>
    </div>
  );
}

export default SecuritySafety;
