/* eslint-disable*/
import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import vessel_image from "../../assets/banner.jpg";
import { Link } from "react-router-dom";
import blueship from "../../assets/blueship1.png";
import bluelocation from "../../assets/bluelocation1.png";
import bluealarm from "../../assets/moored-boat.png";
import axios from "src/redux/actions/apis";
import { VESSEL_OPERATIONS_URL } from "../../url/Url";
import { DateTimeFormate } from "../../utils/DataFormate";
import DetailVesselOperation from "./DetailVesselOperation";
import "./VesselOperations.css";
import VESSEL_ON_MAP from "./Vessel_On_Map";
import DataNotFound from "../DataNotFound/DataNotFound";
import { languageJson } from '../../StringVariable';
 import SpinerLoader from "src/utils/SpinerLoader";
import { submitGAEvent } from "src/utils/GAEvents";
import VesselReportsData from "../Reports/VesselReportsData";
import portimg2 from "../../assets/2.jpg";
import portimg6 from "../../assets/6.jpg";

import VesselImage1 from "../../assets/VesselOperations/1.jpg";
import VesselImage2 from "../../assets/VesselOperations/2.jpg";
import VesselImage3 from "../../assets/VesselOperations/3.jpg";
// import VesselScheduleAdvent from "./VesselScheduleAdvent";


function VesselOperations() {
  const [vesselOperation, setVesselOperations] = useState(true);
  const [VesselLocations, setVesselLocations] = useState(false);
  const [VesselAlerts, setVesselAlerts] = useState(false);
  const [VesselOperationsList, setVesselOperationsList] = useState();
  const [mapModal, setMapModal] = useState(false);
  const [marinMapDetail, setMarinMapDetail] = useState({});
  // const [advantVessalSchedules, setAdvantVessalSchedulesShow] = useState(false);

  const onVesselLocations = () => {
    submitGAEvent("VESSEL LOCATIONS");
    setVesselLocations(true);
    setVesselOperations(false);
    setVesselAlerts(false);
    // setAdvantVessalSchedulesShow(false)

  };

  const onVesselOperation = () => {
    submitGAEvent("VESSEL SCHEDULE");
    setVesselLocations(false);
    setVesselOperations(true);
    setVesselAlerts(false);
    // setAdvantVessalSchedulesShow(false)

  };
  const onAlerts = () => {
    setVesselLocations(false);
    setVesselOperations(false);
    setVesselAlerts(true);
    // setAdvantVessalSchedulesShow(false)

  };

  // const handleAdavntVessalSchedules = () => {
  //   getVesselOperationsData();
  //   setVesselLocations(false);
  //   setVesselOperations(false);
  //   setVesselAlerts(false);
  //   setAdvantVessalSchedulesShow(true)
  // };

  const setModalOpen = (item) => {
    setMarinMapDetail(item);
    setMapModal(true);
  };
  const setModalClose = () => {
    setMapModal(false);
  };


  const getVesselOperationsData = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await axios
        .get(VESSEL_OPERATIONS_URL, requestObj)
        .then((res) => {
          const { vessels, customcode } = res.data;
          if (customcode === 200 && vessels.length > 0) {
            setVesselOperationsList(vessels);
          } else {
            setVesselOperationsList([])
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onSort = (event, sortKey, index) => {
    const data = VesselOperationsList[index].vesselsList;
    if (
      JSON.stringify(VesselOperationsList[index].vesselsList) ===
      JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setVesselOperationsList([...VesselOperationsList]);
  };

  const onSortDate = (event, sortKey, index) => {
    const data = VesselOperationsList[index].vesselsList;
    if (
      JSON.stringify(VesselOperationsList[index].vesselsList) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
    ) {
      data.sort((a, b) => a[sortKey] - (b[sortKey]));
    } else {
      data.sort((a, b) => b[sortKey] - (a[sortKey]));
    }
    setVesselOperationsList([...VesselOperationsList]);
  };

  useEffect(() => {
    getVesselOperationsData();
  }, []);

  return (
    <div>
      <Header />
      <div className="image-row-banner">
             <img className="banner_img" src={VesselImage1} alt=""/>
             <img className="banner_img" src={VesselImage3} alt=""/> 
             <img className="banner_img" src={VesselImage2} alt=""/>      
      </div>
      {/* <div className="headerBannerMainDiv"> <img className="vessel_img" src={vessel_image} alt="vessel" /></div> */}

      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.vessel_schedules_txt}</span>
            </p>
          </div>
        </div>
      </div>
      {/* #0d6efd */}

      <div className="container vesselMainPage">
        <div className="row container-fluid py-5 tabVesselDiv vesselTableMainDiv newVesselTabMainDiv">
        <div className="col-lg-4 col-md-4 col-sm-4 titleTabOption"> 
          {/* <div className="col-lg-4 col-md-4 col-sm-4 center-head-underline titleTabOption titleTabAddClass"> */}
            <a
              onClick={() => {
                onVesselOperation();
              }}
              className={`text-decoration-none ${vesselOperation
                ? "center-head-underline-clr vo-headtxt py-2 "
                : ""
                } cursor-pointer vesselOptDiv`}
            >
              <h2
                className={`${vesselOperation ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={blueship}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b> {languageJson.vessel_schedules_txt} </b>
              </h2>{" "}
            </a>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4 titleTabOption">
          {/* <div className="col-lg-3 col-md-3 col-sm-3 center-head-underline titleTabOption titleTabAddClass"> */}

            <a
              onClick={() => {
                onAlerts();
              }}
              className={`text-decoration-none   ${VesselAlerts
                ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } text-dark cursor-pointer alertTxtDiv`}
            >
              <h2 className={`${VesselAlerts ? "text-primary" : "text-dark"}`}>
                <span>
                  <img
                    src={bluealarm}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.vessels_infomration}</b>
              </h2>{" "}
            </a>
          </div>  
          <div className="col-lg-4 col-md-4 col-sm-4 titleTabOption">
          {/* <div className="col-lg-3 col-md-3 col-sm-3 center-head-underline titleTabOption titleTabAddClass"> */}

            <a
              onClick={() => {
                onVesselLocations();
              }}
              className={`text-decoration-none text-dark ${VesselLocations
                ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } cursor-pointer`}
            >
              <h2
                className={`${VesselLocations ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={bluelocation}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.vessel_locations_txt}</b>
              </h2>{" "}
            </a>
          </div>

          {/* <div className="col-lg-3 col-md-3 col-sm-3 center-head-underline titleTabOption titleTabAddClass">

              <a
                 onClick={() => {
                  handleAdavntVessalSchedules();
                }}
                className={`text-decoration-none text-dark ${advantVessalSchedules
                  ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                  : ""
                  } cursor-pointer`}
              >
                <h2
                  className={`${advantVessalSchedules ? "text-primary" : "text-dark"}`} style={{paddingRight:"18px"}}
                >
                  <span>
                    <img
                      src={blueship}
                      className="operationicon-img"
                      alt="bluelocation-img"
                    />
                  </span>
                  <b>{languageJson.vessel_schedules_advant_txt}</b>
                </h2>{" "}
              </a>
           </div> */}
          
        </div>

        {vesselOperation && vesselOperation && VesselOperationsList &&
          VesselOperationsList.length > 0 ? (
          <div className="container-fluid vesselTabOptionDiv p-0">
            {/* ---------------------------------------------------------------------------------------------- */}
            <div className="innerVesselDiv">
              {/* ------------------------------------------------------------------------------- */}
              {VesselOperationsList &&
                VesselOperationsList.length > 0 &&
                VesselOperationsList.map((vessels, vesselsindex) => {
                  if (
                    vessels["category"] === "In Port" &&
                    vessels["vesselsList"].length
                  ) {
                    return (
                      <div className="vesselestimationdiv" key={vesselsindex}>
                        <div className="vessel_estimated row">
                          <h2 className="py-3"> {vessels["category"]}</h2>
                        </div>
                        <div className="row vo-namecard-head ">
                          <div
                            className="col-3 text-white   text-left cursor-pointer"
                            onClick={(e) =>
                              onSort(e, "vesselName", vesselsindex)
                            }
                          >
                            <b className="thTxt">{languageJson.name_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i>
                            </b>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer "
                            onClick={(e) => onSort(e, "terminal", vesselsindex)}
                          >
                            <b className="thTxt"> {languageJson.Terminal_Name_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i> </b>
                          </div>
                          <div
                            className="col-3 text-left  text-light  cursor-pointer  "
                            onClick={(e) =>
                              onSortDate(e, "estimatedArrival", vesselsindex)
                            }
                          >
                            <b className="thTxt"> {languageJson.vessel_estimated_time_on_arrival_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i> </b>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer "
                            onClick={(e) =>
                              onSort(e, "estimatedDeparture", vesselsindex)
                            }
                          >
                            <b className="thTxt"> {languageJson.erd_date_time_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i> </b>
                          </div>

                          
                        </div>

                        {vessels["vesselsList"].map((item, index) => {
                          return (
                            <React.Fragment>
                              <div
                                className="row borderDashed rowDivData cursor-pointer"
                                key={index}
                                onClick={() => setModalOpen(item)}
                              >
                                <div className="col-3">{item.vesselName === "" || item.vesselName.toLowerCase() === "null" || item.vesselName === null ? "-" : item.vesselName}</div>
                                <div className="col-3 text-left text-danger ">
                                  {item.terminal === "" || item.terminal.toLowerCase() === "null" || item.terminal === null ? "-" : item.terminal}
                                </div>
                                <div className="col-3 ">
                                  {item.estimatedArrival === "" || item.estimatedArrival.toLowerCase() === "null" || item.estimatedArrival === null ? "-" : DateTimeFormate(item.estimatedArrival)}
                                </div>
                                <div className="col-3 ">
                                  {item.estimatedDeparture === "" || item.estimatedDeparture.toLowerCase() === "null" || item.estimatedDeparture === null ? "-" : DateTimeFormate(item.estimatedDeparture)}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  } else if (
                    vessels["category"] === "Due to Depart" &&
                    vessels["vesselsList"].length
                  ) {
                    return (
                      <div className="vesselinportclass" key={vesselsindex}>
                        <div className="vessel_estimated zeroBorderRadius row">
                          <h2 className="py-3">{vessels["category"]}</h2>
                        </div>

                        <div className="row vo-namecard-head ">
                          <div
                            className="col-3 text-left text-white cursor-pointer"
                            onClick={(e) =>
                              onSort(e, "vesselName", vesselsindex)
                            }
                          >
                            <b className="thTxt">{languageJson.name_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i></b>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer "
                            onClick={(e) => onSort(e, "terminal", vesselsindex)}
                          >
                            <b className="thTxt">{languageJson.Terminal_Name_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i></b>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer"
                            onClick={(e) =>
                              onSortDate(e, "cutoffDate", vesselsindex)
                            }
                          >
                            <b className="thTxt"> {languageJson.cut_of_date_time_txt}
                              <i className="fa fa-sort" aria-hidden="true"></i></b>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer "
                            onClick={(e) =>
                              onSortDate(e, "estimatedArrival", vesselsindex)
                            }
                          >
                            <b className="thTxt">{languageJson.vessel_estimated_time_on_arrival_txt}{" "}
                              <i className="fa fa-sort" aria-hidden="true"></i></b>
                          </div>
                        </div>
                        {vessels["vesselsList"].map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                className="row  borderDashed rowDivData cursor-pointer"
                                onClick={() => setModalOpen(item)}
                              >
                                <div className="col-3 text-left">
                                  {item.vesselName === "" || item.vesselName.toLowerCase() === "null" || item.vesselName === null ? "-" : item.vesselName}
                                </div>
                                <div className="col-3 text-left text-danger ">
                                  {item.terminal === "" || item.terminal.toLowerCase() === "null" || item.terminal === null ? "-" : item.terminal}
                                </div>
                                <div className="col-3">
                                  {item.cutoffDate === "" || item.cutoffDate.toLowerCase() === "null" || item.cutoffDate === null ? "-" : DateTimeFormate(item.cutoffDate)}
                                </div>
                                <div className="col-3  ">
                                  {item.estimatedArrival === "" || item.estimatedArrival.toLowerCase() === "null" || item.estimatedArrival === null ? "-" : DateTimeFormate(item.estimatedArrival)}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  } else if (vessels["vesselsList"].length) {
                    return (
                      <div className="vesselinportclass">
                        <div className="vessel_estimated zeroBorderRadius row">
                          <h2 className="py-3">{vessels["category"]}</h2>
                        </div>

                        <div className="row vo-namecard-head ">
                          <div
                            className="col-4 text-left text-white cursor-pointer pr-3"
                            onClick={(e) =>
                              onSort(e, "vesselName", vesselsindex)
                            }
                          >
                            {languageJson.name_txt}{" "}
                            <i className="fa fa-sort" aria-hidden="true"></i>
                          </div>
                          <div
                            className="col-5 text-left  text-light  cursor-pointer"
                            onClick={(e) => onSort(e, "terminal", vesselsindex)}
                          >
                            {languageJson.Terminal_Name_txt}{" "}
                            <i className="fa fa-sort" aria-hidden="true"></i>
                          </div>
                          <div
                            className="col-3 text-left  text-light cursor-pointer"
                            onClick={(e) =>
                              onSortDate(e, "estimatedArrival", vesselsindex)
                            }
                          >
                            {languageJson.vessel_estimated_time_on_arrival_txt}{" "}
                            <i className="fa fa-sort" aria-hidden="true"></i>
                          </div>
                        </div>
                        {vessels["vesselsList"].map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                className="row  borderDashed rowDivData cursor-pointer"
                                onClick={() => setModalOpen(item)}
                              >
                                <div className="col-4 text-left pr-3">
                                  {item.vesselName === "" || item.vesselName.toLowerCase() === "null" || item.vesselName === null ? "-" : item.vesselName}
                                </div>
                                <div className="col-5 text-left text-danger pr-3 ">
                                  {item.terminal === "" || item.terminal.toLowerCase() === "null" || item.terminal === null ? "-" : item.terminal}
                                </div>
                                <div className="col-3 pr-3 ">
                                  {item.estimatedArrival === "" || item.estimatedArrival.toLowerCase() === "null" || item.estimatedArrival === null ? "-" : DateTimeFormate(item.estimatedArrival)}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }
                  return false;
                })
              }
              <br></br>
            </div>
          </div>
        ) : vesselOperation && VesselOperationsList != null && VesselOperationsList.length === 0 ? (
          <div className="">
            <div className="  text-center border-0">
              {" "}
              <DataNotFound />
            </div>
          </div>
        ) : vesselOperation && <div className="  text-center border-0"><SpinerLoader /></div>}
        {mapModal && (
          <DetailVesselOperation
            open={mapModal}
            closeModal={setModalClose}
            marinMapDetail={marinMapDetail}
          />
        )}
        {/* ---------vessel Location ----------------- */}
        {VesselLocations && <VESSEL_ON_MAP />}


        {/* -------------------------vessel alert------------------------- */}
        {VesselAlerts && <VesselReportsData/>}

        {/* {advantVessalSchedules && <React.Fragment> <VesselScheduleAdvent/> </React.Fragment> } */}

      </div>
    </div>
  );
}

export default VesselOperations;
