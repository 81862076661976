import { ActionTypes } from "../constants/Types";
const initialState = { userSetting: null };

export const userDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_DETAIL_LOADING:
      return { ...state, ...action.payload };
    case ActionTypes.LOGOUT_SUCCESS:
      return { initialState }
    case ActionTypes.USER_SETTING:
      return { ...state, userSetting: action.payload };
    default:
      return state;
  }
};
