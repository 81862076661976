/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
 import { Link } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import "./RailwayCrossing.css"; 
import RailCrossingAlert from "../Alerts/RailCrossingAlert";
import { GET_RAILWAY_ROUTE_LIST } from "src/url/Url";
import api from "src/redux/actions/apis";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import { DateTimeFormate } from "src/utils/DataFormate";
import { submitGAEvent } from "src/utils/GAEvents";
import portimg1 from "../../assets/RailCrossing/1.jpg"; 
import portimg2 from "../../assets/RailCrossing/2.jpg";
import portimg3 from "../../assets/RailCrossing/3.jpg";
 function RailwaysCrossing() {
  const [railwayCrossing, setRailwayCrossing] = useState(true);
  const [railwayAlerts, setRailwayAlerts] = useState(false);
  const [railwayCrossingList, setRailwayCrossingList] = useState(null);

  useEffect(() => {
    submitGAEvent("RAIL CROSSING");
  },[]);

  const onRailWayCrossing = () => {
    submitGAEvent("RAIL CROSSING");
    setRailwayCrossing(true);
    setRailwayAlerts(false);
    getRailwayCrossingData();
  };
  const onRailWayAlerts = () => {
    setRailwayCrossing(false);
    setRailwayAlerts(true);
  };

  const getRailwayCrossingData = async () => {
    try {
      await api.get(GET_RAILWAY_ROUTE_LIST).then((res) => {
        const { customcode, rail_crossing } = res.data;
        if (customcode === 200 && rail_crossing.length > 0) {
          setRailwayCrossingList(rail_crossing);
        } else {
          setRailwayCrossingList([]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRailwayCrossingData();
  }, []);
  return (
    <div>
      <Header />
      {/* <div className="headerBannerMainDiv">
        <img className="vessel_img" src={railcrossingbanner} alt="vessel" />
      </div> */}
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg3} alt=""/> 
             <img className="banner_img" src={portimg2} alt=""/>      
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              {" "}
              <span className="vo-menu pe-2">
                {languageJson.home_txt}{" "}
              </span>{" "}
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.rail_crossings_txt}

           
            </span>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="railCrossinglRoutesMainDiv" style={{marginTop:"-100px"}}>
        <section className="railcrossing-head container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
            <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
            <div className="container">
          <div className="titleDescpDiv">
            <h2>{languageJson.rail_crossings_txt}</h2>
          </div>
          </div>
          </div>
              {/* <div className="col-4   titleTabOption">
                <a
                  onClick={() => {
                    onRailWayCrossing();
                  }}
                  className={`text-decoration-none  cursor-pointer ${railwayCrossing ? "center-head-underline-clr" : ""
                    } `}
                >
                  <h2
                    className={`${railwayCrossing ? "text-primary " : "text-dark"
                      }`}
                  >
                    <span>
                      <img
                        src={railTabIcon}
                        className="operationicon-img"
                        alt="railTabIcon-img"
                      />
                    </span>
                    <b>{languageJson.Rail_Crossing_Routes_txt}</b>
                  </h2>
                </a>
              </div> */}
              {/* <div className="col-8 titleTabOption">
                <a
                  onClick={() => {
                    onRailWayAlerts();
                  }}
                  className={`text-decoration-none text-dark cursor-pointer ${railwayAlerts ? "center-head-underline-clr" : ""
                    }`}
                >
                  <h2
                    className={`${railwayAlerts ? "text-primary" : "text-dark"
                      }`}
                  >
                    <span>
                      <img
                        src={bluealarm}
                        className="operationicon-img"
                        alt="bluelocation-img"
                      />
                    </span>
                    <b>{languageJson.alerts_txt}</b>
                  </h2>
                </a>
              </div> */}
            </div>
          </div>
          {railwayCrossing &&
            railwayCrossingList !== null &&
            railwayCrossingList.length > 0 ? (
            <div className={`container`}>
              <div className="row railborder">
                {railwayCrossingList.map((data, index) => (
                  <div className="col-md-12 border border-grey crossingsty" key={index}>
                    <span className={`${data.railroad_color}` || "-"}></span>
                    <p className="tcolor font-weight-bold">
                      {`${data.railroad_name}` || "-"}
                    </p>

                    <p className="sty">
                      {" "}
                      <span>{data.railroad_status === "UNKNOWN" ? "Detection Unavailable" : data.railroad_status || "-"} {data.railroad_status === 'Closed' ? languageJson.since_capital_txt : ""}</span>{" "}
                      {data.railroad_status === 'Closed' ? DateTimeFormate(data.last_updated_time) : data.railroad_status === "UNKNOWN" ? "" : ""}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : railwayCrossing &&
            railwayCrossingList !== null &&
            railwayCrossingList.length === 0 ? (
            <DataNotFound />
          ) : (
            railwayCrossing && <SpinerLoader />
          )}
          {/* -----------------------railway alerts start------------------------------- */}
          {railwayAlerts && (
            <div className={`container`}>
              <RailCrossingAlert pagination={true} />
            </div>
          )}
        </section>
        {/* ----------------------------------------------------------------------------------------- */}
      </div>
      {/* ----------------------------------------------------------------------------------------- */}
    </div>
  );
}

export default RailwaysCrossing;
