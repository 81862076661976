/* eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useState } from "react";

const ScrollPageWithScreen = (props) => {
    let { page } = props;
/**************Scrolling Page ***************/
const [screenWidth, setScreenWidth] = useState(window.innerWidth);

function scrolling(screensize) {
    let scrollOffset = page === 'home' ? 480 : 400; // Default scroll offset
    if (screensize < 993) {
        if (screensize < 681) {
            if (screensize < 581) {
                scrollOffset = 170;
            }else{
                scrollOffset = 250; 
            }
        }else{
            scrollOffset = 300;
        }
      }
      window.scrollTo(0, scrollOffset);
}


const handleResize = () => {
  setScreenWidth(window.innerWidth);
};

useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

useEffect(() => {
    scrolling(screenWidth)
}, [screenWidth]);

  return (
    <React.Fragment></React.Fragment>
  );
};

export default ScrollPageWithScreen;
