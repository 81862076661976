import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import vessel_image from "../../assets/banner.jpg";
import Backdrop from "../../Containers/modal/BackDrop";
import Modal from "../../Containers/modal/Modal";
import OtpModal from "../../Containers/otp/OtpModal";
import '../DriverRegistration/DriverRegistration.css';
import { registerSchema } from "../../utils/Validations"
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { driverRegistration, clearLoadingMessageStatus, otpSend } from "../../redux/actions/UserAccount";
import InputMask from "react-input-mask";
import { useLocation } from 'react-router-dom';
import { languageJson } from '../../StringVariable';

function PublicRegistration() {
  let dispatch = useDispatch();
  let { loadingState, messageState, statusCodeState } = useSelector(state => state);
  let usertype = [
    { key: 'TMC', value: "TMC Operator" },
    { key: 'TMC', value: "EOC Operator" },
    { key: 'Port', value: "City of Oakland Staff" },
    { key: 'Port', value: "CalTrans Staff" },
    { key: 'Port', value: "MTC Traffic Management Staff" },
    { key: 'Port', value: "Port of Oakland Staff" },
    { key: 'Port', value: "Other" }
  ]
  const location = useLocation();
  const { pathname } = location;
  const formOptions = { resolver: yupResolver(registerSchema), defaultValues: { userAgreement: pathname === "/aggrement/publicregister" ? true : false } };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showOTP, setShowOTP] = useState(false)
  const [email, setEmail] = useState('')
  const [onModal, setOnModal] = useState(false);


  const handleSubmitRegister = async (registerData) => {
    if (!onModal) {
      const { first_name, last_name, user_type_value, email, password, mobile, job_title, dl_or_companyid_number, company_name } = registerData;
      let user_type = '';
      usertype.map(ele => {
        if (ele.value === user_type_value) {
          user_type = ele.key;
        }
        return ele;
      }
      )
      let formData = { first_name, last_name, user_type, user_type_value, email, password, mobile, job_title, dl_or_companyid_number, company_name }
      setEmail(email)
      dispatch(driverRegistration({ ...formData }));
    }
  };

  const closeModal = () => {
    dispatch(clearLoadingMessageStatus())
    setOnModal(false);
  }

  const getEmailOTP = () => {
    const emailData = { 'email': email }
    dispatch(otpSend(emailData))
  }
  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (statusCodeState && statusCodeState.driverRegisterCode !== null) {
      setOnModal(true);
    }
  }, [statusCodeState.driverRegisterCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Header />
      <div className="headerBannerMainDiv"><img className="vessel_img drivenRegistrationBannerImg " src={vessel_image} alt="vessel" /></div>
      <section className="driverregistration-section" id="publicregister">
        <div className="container my-5">
          <div className="row">
            <h2>{languageJson.public_registration_txt}</h2>
            <div className="col-lg-12">
              <p>
                {languageJson.driver_registration_Desp_txt}
              </p>
            </div>
          </div>

          {/* -----------------form start------------ */}
          <form onSubmit={handleSubmit(handleSubmitRegister)} autoComplete="off">
            <div className="row mt-5">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.first_name_txt}</label>
                  <div className="col-sm-6 col-lg-8 mb-3">
                    <input className={`form-control form-control-lg form-placeholder`}
                      name="first_name" id="first_name" type="text"
                      maxLength="25"
                      placeholder={languageJson.enter_first_name_msg_txt}
                      {...register('first_name')}
                    />
                    <p className="text-danger">{errors.first_name?.message}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.last_name_txt}</label>
                  <div className="col-sm-6 col-lg-8 mb-3">
                    <input className={`form-control form-control-lg form-placeholder`}
                      name="last_name" id="last_name" type="text"
                      maxLength="25"
                      placeholder={languageJson.enter_last_name_msg_txt}
                      {...register('last_name')}
                    />
                    <p className="text-danger">{errors.last_name?.message}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.email_address_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="email" id="email" type="text"
                        maxLength="50"
                        placeholder={languageJson.enter_email_address_msg_txt}
                        autoComplete="off"
                        {...register('email')}
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.mobile_number_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3 ">
                      <InputMask
                        className={`form-control form-control-lg form-placeholder`}
                        name="mobile" id="mobile" type="text"
                        placeholder={languageJson.mobile_number_error_txt}
                        mask="(999) 999-9999"
                        onKeyDown={handleKeyDown}
                        maskChar=" "
                        {...register('mobile')}
                      />
                      <p className="text-danger">{errors.mobile?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.password_small_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="password" id="password" type={passwordShown ? "text" : "password"}
                        placeholder={languageJson.enter_pwd_txt}
                        autoComplete="off"
                        {...register('password')}
                        onKeyDown={handleKeyDown}
                      />
                      <span toggle="#password-field" className={`${passwordShown ? "fa fa-fw fa-eye-slash" : "fa fa-fw fa-eye"} field-icon toggle-password `} onClick={() => setPasswordShown(!passwordShown)}></span>
                      <p className="text-danger">{errors.password?.message}</p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label
                    id="colFormLabellg"
                    className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt"
                  > {languageJson.confirm_password_txt}
                  </label>

                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="confirmPassword" id="confirmPassword" type={confirmPasswordShown ? "text" : "password"}
                        placeholder={languageJson.confirm_password_txt}
                        {...register('confirmPassword')}
                        onKeyDown={handleKeyDown}
                      />
                      <span id="togglePassword" toggle="#password-field" className={`${confirmPasswordShown ? "fa fa-fw fa-eye-slash" : "fa fa-fw fa-eye"} field-icon toggle-password eyeiconspan`} onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}></span>
                      <p className="text-danger">{errors.confirmPassword?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.selectusertype_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <select className="w-100" name="user_type_value" id="user_type_value" {...register('user_type_value')}>
                        <option selected value="">{languageJson.selectusertype_txt}</option>
                        <option value="TMC Operator">{languageJson.TMCOperator_txt}</option>
                        <option value="EOC Operator">{languageJson.EOCOperator_txt}</option>
                        <option value="City of Oakland Staff">{languageJson.CityOaklandStaff_txt}</option>
                        <option value="CalTrans Staff">{languageJson.CalTransStaff_txt}</option>
                        <option value="MTC Traffic Management Staff">{languageJson.MTCTrafficManagStaff_txt}</option>
                        <option value="Port of Oakland Staff">{languageJson.PortOaklandStaff_txt}</option>
                        <option value="Other">{languageJson.Other_txt}</option>
                      </select>
                      <p className="text-danger">{errors.user_type_value?.message}</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.CDLTWIC_number_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="dl_or_companyid_number" id="dl_or_companyid_number" type="text"
                        placeholder={languageJson.enter_DL_txt}
                        maxLength="20"
                        {...register('dl_or_companyid_number')}
                        onKeyDown={handleKeyDown}
                      />
                      <p className="text-danger">{errors.dl_or_companyid_number?.message}</p>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.company_name_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="company_name" id="company_name" type="text"
                        placeholder={languageJson.company_name_error_txt}
                        {...register('company_name')}
                      />
                      <p className="text-danger">{errors.company_name?.message}</p>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group row ">
                  <label className="col-sm-6 col-lg-4 col-form-label col-form-label-lg formlabeltxt">{languageJson.JobTitle_txt}</label>
                  <div className="col-sm-6 col-lg-8">
                    <div className="input-group mb-3">
                      <input className={`form-control form-control-lg form-placeholder`}
                        name="job_title" id="job_title" type="text"
                        placeholder={languageJson.enter_jobTitle_txt}
                        {...register('job_title')}
                      />
                      <p className="text-danger">{errors.job_title?.message}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input type="checkbox" className={`form-check-input is invalid`}
                    name="userAgreement" id="userAgreement"
                    {...register('userAgreement')}
                  />
                  <label htmlFor="userAgreement" className="form-check-label formlabeltxt">
                    {languageJson.driver_registration_clickAgree_txt} {" "}
                    <span><a href="/#/userAgreement" rel="noreferrer" className="aggrementLink"> {languageJson.registerOption.UserAgreement_txt}</a></span>
                  </label>
                  <p className="text-danger">{errors.userAgreement?.message}</p>
                </div>
              </div>
            </div>
            <div className="text-center mt-4 ">
              <button className="btn btn-primary submitform" type="submit" disabled={loadingState.isDriverRegisterLoading ? true : false}>
                {loadingState.isDriverRegisterLoading ? languageJson.processing_txt : languageJson.submit_txt}
              </button>
            </div>
          </form>
          {messageState.driverRegisterMessage != null ? (
            <React.Fragment>
              <Backdrop onClick={() => closeModal()} />
              <Modal text={messageState.driverRegisterMessage} otpButton={() => statusCodeState.driverRegisterCode === 200 ? setShowOTP(true) : ''} btnColor={statusCodeState.driverRegisterCode === 200 ? 'modal-btn-success' : 'modal-btn-warning'} icon={statusCodeState.driverRegisterCode === 200 ? 'fa fa-check-circle' : 'fa fa-exclamation-circle'} status={statusCodeState.driverRegisterCode === 200 ? 'bg-custom-success' : 'bg-warning'} onClose={() => closeModal()} />
            </React.Fragment>
          )
            : null
          }
          {showOTP ? (
            <React.Fragment>
              <Backdrop onClick={() => closeModal()} />
              <OtpModal text={languageJson.enter_OTP_email_txt} resendOTP={() => getEmailOTP()} email={email} status={'bg-custom-success'} btnColor={'modal-btn-success'} icon={'fa fa-check-circle'} onClose={() => closeModal()} />
            </React.Fragment>
          ) : null
          }
        </div>
      </section>
    </div>
  );
}

export default PublicRegistration;
