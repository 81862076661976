import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import icon from "../../assets/icon.png";
import "./Resources.css";
import ModalPopup from "../../Containers/modal/Modal";
import Backdrop from "../../Containers/modal/BackDrop";
import { Modal } from 'react-bootstrap';
import { contactUsSchema } from "../../utils/Validations"
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { resourceData } from "../../redux/actions/ResourceDataAction"
import { conctactUs, clearLoadingMessageStatus } from "../../redux/actions/UserAccount"
import { getAnalytics, logEvent } from "firebase/analytics";
import { languageJson } from '../../StringVariable';
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import { submitGAEvent } from "src/utils/GAEvents";

import POAKPanorama from "../../assets/POAKPanorama.jpg";

import FloatingLayout from './FloatingLayout'; // Import the Layout component
const analytics = getAnalytics();
function Resource() {
  let dispatch = useDispatch();
  let { loadingState, messageState, statusCodeState, resourceDataState } = useSelector(state => state);
  const formOptions = { resolver: yupResolver(contactUsSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const [update, setUpdate] = useState(true);
  const [onModal, setOnModal] = useState(false);

  const handleSubmitContact = async (registerData) => {
    if (!onModal) {
      const { first_name, last_name, email, description, contact_topic } = registerData;
      let contact_name = first_name + ' ' + last_name;
      let contact_email = email;
      await dispatch(conctactUs({ contact_name, contact_email, contact_topic, description }));
    }
  };
  useEffect(() => {
    setShowModal(false);
    if (statusCodeState.contactUsStatusCode !== null) {
      setOnModal(true);
    }
  }, [messageState.contactUsMessage, statusCodeState.contactUsStatusCode])

  useEffect(() => {
    submitGAEvent("RESOURCES");
  }, []);

  const closeModal = () => {
    dispatch(clearLoadingMessageStatus());
    setOnModal(false);
    reset();
    setShowModal(false)
  }
  const openModal = () => {
    setShowModal(true)
  }
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(resourceData())
    logEvent(analytics, 'Resource_view');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function validateURL(link) {
    if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
      return link
    }
    else {
      return "http://" + link
    }
  }

  const onClickField = () => {
    setUpdate(false);
  }

  let renderResource = (resource, index) => {
    return <section className="human-resources-section" key={index}>
      <div className="container">
        <h2 className="mt-5 mb-4">{resource.category === "" || resource.category === null || resource.category.toLowerCase() === "null" ? "-" : resource.category}</h2>
      </div>
      <div className="container px-4">
        {resource.subcategory &&
          resource.subcategory.map((data, idx) => (
            <div className="row border border-white resources" key={idx}>
              <a href={validateURL(data.url)} target="_blank" rel="noreferrer" className="text-dark">
                <div className="d-flex justify-content-between align-items-center px-4">
                  <div className="font-weight-bold fsize">{data.name === "" || data.name === null || data.name.toLowerCase() === "null" ? "-" : data.name}</div>

                  <div>
                    <a href={validateURL(data.url)} alt={data.name} target="_blank" rel="noreferrer">
                      <img
                        align="right"
                        className=""
                        src={icon}
                        alt="icon"
                      />
                    </a>
                  </div>
                </div>
              </a>
            </div>
          ))}
      </div>
    </section>
  }


  return (
    <React.Fragment>
      <Header />
      {/* <div className="image-row-banner">
             <img className="banner_img" src={portimgab3} alt=""/>
             <img className="banner_img" src={portimgab2} alt=""/>
             <img className="banner_img" src={portimgab1} alt=""/>      
      </div> */}
      <div className="headerBannerMainDiv">
        <img className="banner_img" src={POAKPanorama} alt="" />
      </div>
      {
        resourceDataState && resourceDataState.length > 0 ? resourceDataState.map((ele, index) => {
          return renderResource(ele, index)
        }) : <section className="human-resources-section" >
          <div className="col-lg-12 col-md-12 col-md-12 m-5" >
            <div>
              {resourceDataState != null && resourceDataState && resourceDataState.length === 0 ? <DataNotFound /> : <SpinerLoader />}
            </div>
          </div>
        </section>}

      {/* -----------------------------------------------------------------------     */}
      <section className="resources-tolls">

        {/* TODO REMOVE Tolls 16 NOV 2023 */}
        {/* <div className="container p-0">
          <h2 className="mt-5 text-uppercase">  {languageJson.tolls_txt}</h2>
          <TollPointRoad />
        </div> */}
        <div className="text-center py-5">
          <>
            <Modal className="resourcesMapPopup" show={showModal} centered backdrop="static" onHide={() => closeModal()} animation={true}>
              <Modal.Header closeButton></Modal.Header>
              <form onSubmit={handleSubmit(handleSubmitContact)} autoComplete="off">
                <Modal.Body> <div className="row">
                  <div className="leftDivInput">
                    <input className={`form-control`}
                      name="first_name" id="first_name" type="text"
                      maxLength="25"
                      placeholder={languageJson.first_name_txt}
                      onClick={onClickField}
                      {...register('first_name')}
                    />
                    <p className="text-danger">{errors.first_name?.message}</p>
                  </div>
                  <div className="rightDivInput">
                    <input className={`form-control`}
                      name="last_name" id="last_name" type="text"
                      maxLength="25"
                      placeholder={languageJson.last_name_txt}
                      onClick={onClickField}
                      {...register('last_name')}
                    />
                    <p className="text-danger">{errors.last_name?.message}</p>
                  </div>
                  <div className="col-md-12 formemail">
                    <input className={`form-control`}
                      name="email" id="email" type="text"
                      placeholder={languageJson.email_address_txt}
                      onClick={onClickField}
                      autoComplete="off"
                      maxLength={50}
                      {...register('email')}
                    />
                    <p className="text-danger">{errors.email?.message}</p>
                  </div>
                </div>
                  <div className="col-md-12 formemail">
                    <select className="form-select Resource__form__select" name="contact_topic" id="contact_topic" {...register('contact_topic')} onClick={onClickField}>
                      <option value={languageJson.Feedback_txt} selected>{languageJson.Feedback_txt}</option>
                      <option value={languageJson.QueryQuestion_txt}>{languageJson.QueryQuestion_txt}</option>
                      <option value={languageJson.AddReview_txt}>{languageJson.AddReview_txt}</option>
                      <option value={languageJson.Rateus_txt}>{languageJson.Rateus_txt}</option>
                      <option value={languageJson.Other_txt}>{languageJson.Other_txt}</option>
                    </select>
                    <p className="text-danger">{errors.contact_topic?.message}</p>
                  </div>
                  <div className="form-group row textareaDivContactus">
                    <span className="resourceInnerForm">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="7"
                        placeholder={languageJson.write_your_message_txt}
                        onClick={onClickField}
                        autoComplete="off"
                        maxLength={1024}
                        {...register('description')}
                      ></textarea>
                    </span>
                    <p className="text-danger">{errors.description?.message}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary m-3 px-5 px-2 sendMsgBtn" type="submit" disabled={loadingState.isContactUsLoading ? true : false || update}>{loadingState.isContactUsLoading ? languageJson.processing_txt : languageJson.submit_txt}
                  </button>
                </Modal.Footer>
              </form>
            </Modal></>
          {messageState.contactUsMessage != null ? (
            <React.Fragment>
              <Backdrop onClick={() => closeModal()} />
              <ModalPopup text={messageState.contactUsMessage} btnColor={statusCodeState.contactUsStatusCode === 200 ? 'modal-btn-success' : 'modal-btn-warning'} icon={statusCodeState.contactUsStatusCode === 200 ? 'fa fa-check-circle' : 'fa fa-exclamation-circle'} status={statusCodeState.contactUsStatusCode === 200 ? 'bg-custom-success' : 'bg-warning'} onClose={() => closeModal()} />

            </React.Fragment>
          )
            : null
          }
          <button className="btn btn-primary btn-lg" onClick={() => openModal()}> {languageJson.addcomment_feedback_txt} </button>
        </div>
      </section>
    </React.Fragment>
  );

}

export default Resource;
