import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import readmoreButton from "../assets/read-more-button.png";
import { Link } from "react-router-dom";
import { getTotalWaitTimeColor } from "../Components/MarineTerminalsDetail/MarineTerminalDetail";
import { languageJson } from "../StringVariable";
import { useSelector, useDispatch } from "react-redux";
import { getMarineTerminalListData } from "src/redux/actions/MarineTerminalAction";
import oaklandlogo from "src/assets/new_logo.png";
import ship_bgDefaultBG from "src/assets/ship_bg.jpg";
import NoImage from "src/assets/no-image.png";

function MarineTerminal() {
  let dispatch = useDispatch();
  let { MarineTerminalData } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getMarineTerminalListData());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTheTotalAverageTimeColor = (time) => {
    let color = getTotalWaitTimeColor(time);
    return color;
  };

  const getTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }
    hours = `0${hours}`;
    if (hours === 0) {
      hours = "";
    }
    return [hours, minutes];
  };

  const getHoursText = (hours) => {
    hours = parseInt(hours);
    if (hours === 0) {
      return "";
    } else if (hours === 1) {
      return languageJson.hrtxt;
    } else if (hours <= 10) {
      return languageJson.hrs_txt;
    } else {
      return "";
    }
  };

  const getMinsText = (mins) => {
    if (mins <= 1) {
      return languageJson.mintxt;
    } else {
      return languageJson.mins_txt;
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(getMarineTerminalListData());
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {MarineTerminalData &&
        MarineTerminalData != null &&
        MarineTerminalData.marineTerminalLsit && (
          <section className="marineterminal-home">
            <div className="row">
              <div className="col-12 center-head-underline d-flex justify-content-center py-5">
                <h1 className="text-center  py-2s f-head">
                  {languageJson.sectionName.marineTerminals}
                </h1>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {MarineTerminalData.marineTerminalLsit.length > 0
                  ? MarineTerminalData.marineTerminalLsit.map(
                    (terminal, index) => (
                      <div
                        className="col-md-6 col-sm-12 col-lg-6 col-xl-3"
                        key={index}
                      >
                        <Link
                          to={{
                            pathname: `/marineTerminals/${terminal.id}`,
                            state: {
                              id: terminal.id,
                              district: terminal.district,
                            },
                          }}
                        >
                          <Card
                            style={{ width: "100%" }}
                            className="pb-2"
                            id="card-all"
                          >
                            <div className="read-more">
                              <img src={readmoreButton} alt="bottom-icon" />
                            </div>
                            <div className="marineimg-div">
                              <span className="imgDivBox">
                                <Card.Img
                                  variant="top"
                                  src={
                                    (terminal.background_image !== null &&
                                      terminal.background_image !== " " &&
                                      terminal.background_image !== "" && terminal.background_image !==
                                      "null") ? terminal.background_image
                                      : ship_bgDefaultBG
                                  }
                                  onError={(e) => {
                                    e.currentTarget.src = oaklandlogo;
                                  }}
                                  className="blogImg"
                                />
                              </span>
                            </div>
                            {/* {MarineImages} */}
                            <Card.Body id="card_body">
                              <div className="marine-card-icon d-flex justify-content-center align-items-center">
                                <img
                                  src={
                                    (terminal.terminal_logo !== null &&
                                      terminal.terminal_logo !== " " &&
                                      terminal.terminal_logo !== "" &&  terminal.terminal_logo !==
                                      "null") ? terminal.terminal_logo
                                      : NoImage
                                  }
                                  onError={(e) => {
                                    e.currentTarget.src = oaklandlogo;
                                  }}
                                  className="w-80 terminalLogoImage"
                                  alt="terminalLogo"
                                />
                              </div>
                              <div className="a">
                                <Card.Title className="text-center blogtime blog-time1">
                                  <div
                                    className={`time-underline ${getTheTotalAverageTimeColor(
                                      terminal.total_turn_time !==
                                        null &&
                                        terminal.total_turn_time !==
                                        " " &&
                                        terminal.total_turn_time !==
                                        "null"
                                        ? terminal.total_turn_time
                                        : 0
                                    )}`}
                                  >
                                    {
                                      getTime(
                                        terminal.total_turn_time !==
                                          null &&
                                          terminal.total_turn_time !==
                                          " " &&
                                          terminal.total_turn_time !==
                                          "null"
                                          ? terminal.total_turn_time
                                          : 0
                                      )[0]
                                    }
                                    <span className="blogmin">
                                      {getHoursText(
                                        getTime(
                                          terminal.total_turn_time !==
                                            null &&
                                            terminal.total_turn_time !==
                                            " " &&
                                            terminal.total_turn_time !==
                                            "null"
                                            ? terminal.total_turn_time
                                            : 0
                                        )[0]
                                      )}
                                      {" :"}
                                    </span>
                                    {
                                      getTime(
                                        terminal.total_turn_time !==
                                          null &&
                                          terminal.total_turn_time !==
                                          " " &&
                                          terminal.total_turn_time !==
                                          "null"
                                          ? terminal.total_turn_time
                                          : 0
                                      )[1]
                                    }
                                    <span className="blogmin">
                                      {getMinsText(
                                        getTime(
                                          terminal.total_turn_time !==
                                            null &&
                                            terminal.total_turn_time !==
                                            " " &&
                                            terminal.total_turn_time !==
                                            "null"
                                            ? terminal.total_turn_time
                                            : 0
                                        )[1]
                                      )}
                                    </span>
                                  </div>
                                </Card.Title>
                              </div>
                              <Card.Text className="text-center CurrentWaitingTimeTxt fontBlack">
                                {languageJson.current_waiting_time_txt}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </div>
                    )
                  )
                  : null}
              </div>
            </div>
          </section>
        )}
    </>
  );
}

export default MarineTerminal;
