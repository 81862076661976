import { ActionTypes } from "../constants/Types";
const initialState = {};

export const GuestUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GUEST_USER_ID_SUCCESS:
            return { state: action.payload }
        default:
            return state;
    }
}