import React, { useEffect, useState } from "react";
import {
   GET_MARINE_TERMINAL_URL,
 } from "../../url/Url";
 import { languageJson } from "../../StringVariable";

 import axios from "src/redux/actions/apis";
import moment from "moment";
import "./report.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faFileCode
} from "@fortawesome/free-solid-svg-icons";
import DataNotFound from "../DataNotFound/DataNotFound";
 import exportFromJSON from "export-from-json";
import { CSVLink } from "react-csv";
import "jspdf-autotable";
import PdfGenerater from "./PdfGenerater";
import { Modal } from "react-bootstrap";
import { JsonToExcel } from "react-json-to-excel";
  import SearchComponent from "./SearchComponent";
import { submitGAEvent } from "src/utils/GAEvents";
import SpinerLoader from "src/utils/SpinerLoader";
import NoteInstructionFull from "../MarineTerminalsDetail/NoteInstructionFull";
moment.suppressDeprecationWarnings = true;

function IngateOutgateReports() {
  const {
    export_PDF_txt, export_Excel_txt, export_CSV_txt, export_XML_txt
  } = languageJson
 
   const [marineList, setmarineList] = useState(null);
  const [marineListCopy, setmarineListCopy] = useState(null); 
  const [searchValue, setSearchValue] = useState("");


 
  const getMerineReport = async () => {
    submitGAEvent("REPORTS-Ingate/Outgate")
    setSearchValue("")
    try {
      const result = await axios
        .get(GET_MARINE_TERMINAL_URL)
        .then((res) => res.data);
      if (result.customcode === 200 && result.marineTerminals.length > 0) {
        let gateListArray = [];
       for(let i=0; i<result.marineTerminals.length; i++){
        gateListArray.push(...result.marineTerminals[i].gates)
       }
       gateListArray.sort((a, b) => a.terminal_gate_id - b.terminal_gate_id);
       setmarineList(gateListArray);
       setmarineListCopy(gateListArray);
      } 
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    getMerineReport();
    getTime();
  }, []); 

  const convertMetersToMiles = (value) => {
    const df = value * 0.00062137;
    return parseFloat(df).toFixed(2);
  }

  //searching functionlity
  const onClickSearch = (type) => {
    let value = searchValue.toLocaleLowerCase();
    switch (type) {
      case "marin": {
        let temp = marineListCopy
        let filterData = temp.filter((item) =>
          item["gate_name"].toLowerCase().includes(value) ? true : false
        );
        setmarineList(filterData);
        break;
      }
      default:
        break
    }
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length < 1) {
       setmarineList(marineListCopy);
     }
  };

  //For CSV generate From Terminal Data
  let terminalCSVData =
    marineList != null &&
    marineList.length > 0 &&
    marineList.map((elt) => {
      return {
        ...elt,
        current_street_wait_time: elt.current_street_wait_time !== null && elt.current_street_wait_time !==
        "null" && elt.current_street_wait_time && elt.current_street_wait_time !== ""
        ? elt.current_street_wait_time : 0,
        current_trucks_in_queue: elt.current_trucks_in_queue !== null && elt.current_trucks_in_queue !==
        "null" && elt.current_trucks_in_queue && elt.current_trucks_in_queue !== ""
        ? elt.current_trucks_in_queue : 0,
        current_queue_length: convertMetersToMiles(elt.current_queue_length),
        average_today_turn_time: elt.today_turn_time + elt.today_street_wait_time,
        today_volume: elt.today_volume !== null && elt.today_volume !==
        "null" && elt.today_volume && elt.today_volume !== ""
        ? elt.today_volume : 0,
        today_street_wait_time: elt.today_street_wait_time !== null && elt.today_street_wait_time !==
        "null" && elt.today_street_wait_time && elt.today_street_wait_time !== ""
        ? elt.today_street_wait_time : 0,
        today_turn_time: elt.today_turn_time !== null && elt.today_turn_time !==
        "null" && elt.today_turn_time && elt.today_turn_time !== ""
        ? elt.today_turn_time : 0,
      };
    });

    const terminealheaders = [
      { label: languageJson.Report_Export_IngateOutgate_Lable.Id, key: "terminal_gate_id"} ,
      { label: languageJson.Report_Export_IngateOutgate_Lable.Name, key: "gate_name"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TotalWaitTime, key: "current_street_wait_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TrucksInQueue, key: "current_trucks_in_queue"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.CurrentTrucks, key: "current_trucks_in_terminal"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.QueueLength, key: "current_queue_length"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.AvgTurnTime, key: "average_today_turn_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TotalTrucks, key: "today_volume"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.WaitTime, key: "today_street_wait_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.InOutTime, key: "today_turn_time"},
      { label: languageJson.Report_Export_IngateOutgate_Lable.TruckException, key: "-"}
    ];

  const terminealcsvReport = {
    data: terminalCSVData,
    headers: terminealheaders,
    filename: languageJson.terminal_reportCSV_txt,
  };

  //for PDF generate Terminal Data
  let headerMarine = [[
    "Id",
    "Terminal Name",
    "Total Wait Time (In Minute(s))",
    "Trucks in Street Queue",
    "Current Trucks in Terminal",
    "Street Queue Length (In Mile(s))",
    "Today’s Average Total Turn Time (In Minute(s))",
    "Total Trucks",
    "Street Wait Time (In Minute(s))",
    "Ingate/Outgate Time (In Minute(s))",
    "Truck in-gate out-gate exception",
  ]]
  let Terminaldata =
    marineList != null &&
    marineList.length &&
    marineList.map((elt) => [
      elt.terminal_gate_id,
      elt.gate_name,
      elt.current_street_wait_time !== null && elt.current_street_wait_time !== "null" && elt.current_street_wait_time && elt.current_street_wait_time !== "" ? elt.current_street_wait_time : 0, 
      elt.current_trucks_in_queue !== null && elt.current_trucks_in_queue !== "null" && elt.current_trucks_in_queue && elt.current_trucks_in_queue !== "" ? elt.current_trucks_in_queue : 0, 
      elt.current_trucks_in_terminal,
      convertMetersToMiles(elt.current_queue_length),
      elt.today_turn_time + elt.today_street_wait_time,
      elt.today_volume !== null && elt.today_volume !== "null" && elt.today_volume && elt.today_volume !== "" ? elt.today_volume : 0, 
      elt.today_street_wait_time !== null && elt.today_street_wait_time !== "null" && elt.today_street_wait_time && elt.today_street_wait_time !== "" ? elt.today_street_wait_time : 0, 
      elt.today_turn_time !== null && elt.today_turn_time !== "null" && elt.today_turn_time && elt.today_turn_time !== "" ? elt.today_turn_time : 0,
      "-"
    ]);

  // for CSV generate from  vessel operation data
  const [show, setShow] = useState(false);
  const [modal1, setModal1] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (type) => {
    setShow(true);
    switch (type) {
      case "modal1":
        setModal1(true);
        break;
      default:
        break;
    }
  };

 

  // xml file fomate dowload

  const downloadXml = (type) => {
    // xml file download in terminal data
    console.log("DataSetForTerminal", DataSetForTerminal)
    if (type === "marin") {
      exportFromJSON({
        data: DataSetForTerminal,
        fileName: languageJson.terminal_report_txt,
        exportType: "xml",
      });
    }    
  };

  //Excel formate download

  const DataSetForTerminal =
    marineList !== null &&
    marineList.map((data) => {
      return {
        [languageJson.Report_Export_IngateOutgate_Lable.Id] : data.terminal_gate_id,
        [languageJson.Report_Export_IngateOutgate_Lable.Name] : data.gate_name,
        [languageJson.Report_Export_IngateOutgate_Lable.TotalWaitTime] : data.current_street_wait_time !== null && data.current_street_wait_time !==
        "null" && data.current_street_wait_time && data.current_street_wait_time !== ""
        ? data.current_street_wait_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.TrucksInQueue] : data.current_trucks_in_queue !== null && data.current_trucks_in_queue !=="null" && data.current_trucks_in_queue && data.current_trucks_in_queue !== ""? data.current_trucks_in_queue : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.CurrentTrucks] : data.current_trucks_in_terminal,
        [languageJson.Report_Export_IngateOutgate_Lable.QueueLength] : convertMetersToMiles(data.current_queue_length),
        [languageJson.Report_Export_IngateOutgate_Lable.AvgTurnTime] : data.today_turn_time + data.today_street_wait_time,
        [languageJson.Report_Export_IngateOutgate_Lable.TotalTrucks] :data.today_volume !== null && data.today_volume !==
        "null" && data.today_volume && data.today_volume !== ""? data.today_volume : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.WaitTime] : data.today_street_wait_time !== null && data.today_street_wait_time !==
        "null" && data.today_street_wait_time && data.today_street_wait_time !== ""? data.today_street_wait_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.InOutTime] : data.today_turn_time !== null && data.today_turn_time !==
        "null" && data.today_turn_time && data.today_turn_time !== ""? data.today_turn_time : 0,
        [languageJson.Report_Export_IngateOutgate_Lable.TruckException] : "-"
      };
    });

 
    const getTime = () => {
      let time = new Date();
      let output = moment.tz(time,"America/Los_Angeles").format("MM/DD/YYYY, h:mm A")
      if (moment(output).format("h:mm A") !== "Invalid date") {
        return moment(output).format("L h:mm A");
      } else {
        return <b>{"-"}</b>;
      }
    };
  
  return (
    <div>
      

      <div className="reportTabsMainDiv">
        <div className="container">
         
          <div className="tab-content">

            <div>
          
            {marineList !== null && marineList.length > 0 && (
                <button
                  className="downloadBtnLeft"
                  onClick={() => handleShow("modal1")}
                >
                  {languageJson.download_txt}
                </button>
              )}
            

            { marineList != null && ((marineList.length && marineList.length > 0) || (searchValue.length && searchValue.length>0)) ? (
                    <SearchComponent
                    placeholder={languageJson.search_using_terminal_name}
                    onChange={handleChange}
                    onClickSearch={onClickSearch}
                    type={"marin"}
                    value={searchValue}
                  /> ) : (<></>)
             }
              
             
                
              {marineList !== null &&
                marineList.length &&
                marineList.length > 0 ? (
                <>
                  <div className="tableOverFlow">
                    <table>
                      <thead>
                        <tr>
                          <td>{languageJson.terminal_gate_name_txt}</td>
                          <td>{languageJson.total_wait_time_txt}</td>
                          <td>{languageJson.Average_Today_txt}</td>
                          <td>{languageJson.Street_Queue_Length_txt}</td>
                          <td>{languageJson.Trucks_in_Queue_txt}</td>
                          <td>{languageJson.Street_Wait_Time_txt}</td>
                          <td>{languageJson.Terminal_Turn_Time_txt}</td>
                          <td>{languageJson.total_trucks_txt}</td>
                          {/* <td>{languageJson.truck_inGate_outGate_txt}</td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {marineList.map((temp, index) => {
                          return (
                            <tr>
                              <td>
                                {temp.gate_name !== null &&
                                  temp.gate_name.toLowerCase() !== "null" &&
                                  temp.gate_name !== ""
                                  ? temp.gate_name
                                  : "-"}
                              </td>
                              <td>
                                {temp.current_street_wait_time !== null &&
                                  temp.current_street_wait_time !==
                                  "null" &&
                                  temp.current_street_wait_time !== ""
                                  ? temp.current_street_wait_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {(temp.today_turn_time !== null &&
                                  temp.today_turn_time !== "null" &&
                                  temp.today_turn_time !== ""
                                  ? temp.today_turn_time
                                  : 0) +
                                  (temp.today_street_wait_time !== null &&
                                    temp.today_street_wait_time !==
                                    "null" &&
                                    temp.today_street_wait_time !== ""
                                    ? temp.today_street_wait_time
                                    : 0)}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.current_queue_length !== null &&
                                  temp.current_queue_length !==
                                  "null" &&
                                  temp.current_queue_length !== ""
                                  ? convertMetersToMiles(temp.current_queue_length)
                                  : 0}{" "}
                                {languageJson.miles_txt}
                              </td>
                              <td>
                                {temp.current_trucks_in_queue !== null && temp.current_trucks_in_queue !==
                                  "null" &&
                                  temp.current_trucks_in_queue !== ""
                                  ? temp.current_trucks_in_queue
                                  : 0}{" "}{languageJson.trucks_txt}
                              </td>
                              <td>
                                {temp.today_street_wait_time !== null &&
                                  temp.today_street_wait_time !==
                                  "null" &&
                                  temp.today_street_wait_time !== ""
                                  ? temp.today_street_wait_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.today_turn_time !== null &&
                                  temp.today_turn_tim !== "null" &&
                                  temp.today_turn_time !== ""
                                  ? temp.today_turn_time
                                  : 0}{" "}
                                {languageJson.min_txt}
                              </td>
                              <td>
                                {temp.today_volume !== null &&
                                  temp.today_volume !== "null" &&
                                  temp.today_volume !== ""
                                  ? temp.today_volume
                                  : 0}
                              </td>
                              {/* <td>-</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  
                    <div className="container">
      
          <div>
          <h5 style={{
          fontSize:"20px",
          margin:"10px",
          padding:"10px",
          textAlign:"end"
          }}>
              {" "}
              {languageJson.last_updated_time_txt}:
              <span> {getTime()}</span>
            </h5>
          <NoteInstructionFull />
          </div>
          </div>
                  </div>
                </>
              ) : marineList != null && marineList.length === 0 ? (
                <DataNotFound />
              ) : (
                <SpinerLoader />
              )}

              <br />
              <br />
             
            </div>
           
           
          </div>
        </div>
      </div>
      <>
        {" "}
        <Modal
          show={show}
          centered
          backdrop="static"
          onHide={handleClose}
          animation={true}
          className="reportModalClass"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <h5>{languageJson.select_format_txt}</h5>
            <div className="downloadOptDiv">
              {modal1 && (
                <div className="downloadOptInnerList">
                  <span title={export_CSV_txt}>
                    <CSVLink
                      {...terminealcsvReport}
                      className="downloadBtny btn btn-primary downloadbutton"
                    >
                      <FontAwesomeIcon icon={faFileCsv} size="lg" />
                    </CSVLink>
                  </span>
                  <span title={export_PDF_txt}>
                    <PdfGenerater
                      data={Terminaldata}
                      buttonclassName={"pdfbutton"}
                      header={headerMarine}
                      buttonTitle={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                      title={languageJson.Terminal_Turn_Time_txt}
                      fileName={languageJson.terminal_report_txt}
                      size={'A1'}
                    />
                  </span>
                  <span title={export_Excel_txt}>
                    <JsonToExcel
                      title={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
                      data={DataSetForTerminal}
                      fileName={languageJson.terminal_report_txt}
                      btnClassName="downloadBtny btn btn-primary downloadbutton"
                    />
                  </span>
                  <div title={export_XML_txt}>
                    <button
                      className="downloadBtny btn btn-primary downloadbutton"
                      onClick={() => downloadXml("marin")}
                    >
                      <FontAwesomeIcon icon={faFileCode} size="lg" />
                    </button>
                  </div>
                </div>
              )}
              
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
      {/* <Findus /> */}
    </div>
  );
}

export default IngateOutgateReports;
