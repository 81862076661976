import React, { useState, useEffect } from "react";
import { languageJson } from '../../StringVariable';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
  TrafficLayer
} from "react-google-maps";
import axios from "src/redux/actions/apis";
import { GET_TOLL_LIST_API, GOOGLE_MAP_URL } from '../../url/Url';
import vesselIcone from "../../assets/toll.png";
import { submitGAEvent } from "src/utils/GAEvents";

function TollRoad() {
  const [selectedTollData, setSelectedTollData] = useState(null);
  const [Resources, setResources] = useState([]);
  const lat = 37.78929;
  const lng = -122.27405;

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedTollData(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    submitGAEvent("TOLLS");
  },[]);

  const getTollDataData = async () => {
    try {
      const result = await axios
        .get(GET_TOLL_LIST_API)
        .then((res) => res.data);
      setResources(result.tollList);
    } catch (err) {
    }
  };
  useEffect(() => {
    getTollDataData();
  }, []);

  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        options={{ streetViewControl: false }}
        defaultCenter={{ lat: lat, lng: lng }}
      >
        <TrafficLayer autoUpdate />
        {Resources.length > 0 &&
          Resources.map((park) => (
            <Marker
              key={park.toll_id}
              position={{
                lat: parseFloat(park.toll_lat),
                lng: parseFloat(park.toll_lng)
              }}
              onClick={() => {
                setSelectedTollData(park);
              }}
              icon={{
                url: vesselIcone,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />
          )
          )}

        {selectedTollData !== null && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedTollData(null);
            }}
            position={{
              lat: parseFloat(selectedTollData.toll_lat),
              lng: parseFloat(selectedTollData.toll_lng),
            }}
            defaultOptions={false}

          >
            <div className='mapPopup tollMapPopUpDiv' >
              <p><label>{languageJson.toll_name_txt} : </label><span> {selectedTollData.toll_name}</span></p>
              <p><label>{languageJson.toll_location_txt}: </label><span> {selectedTollData['toll_location']}</span></p>
              <p><label>{languageJson.toll_prices_txt}: </label>
                <div className="text">
                  <span>{languageJson.FasTrack_txt}: ${selectedTollData['toll_price']['fastrack']}</span>
                  <span>{languageJson.pay_by_plate_txt}: ${selectedTollData['toll_price']['pay_plate']} </span>
                  <span>{languageJson.when_txt}: {selectedTollData['toll_price']['when']} </span>
                </div>
              </p>

              <hr></hr>
              <button onClick={() => {
                setSelectedTollData(null);
              }}>{languageJson.close_txt}</button>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(TollRoad));

export default function TollPointRoad() {
  return (
    <div className="vesselMapTab" style={{ width: "100%", height: "100vh", border: '0' }}>
      {navigator.onLine && <MapWrapped
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%`, borderRadius: '10px', border: '1px solid #999' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '10px' }} />}
      />}
    </div>
  );
}
