import React, { useEffect, useState } from "react";
import "./ForgotPassword.css";
import Header from "../../Containers/Header";
import login_image from "../../assets/Loginbanner.png";
import Backdrop from "../../Containers/modal/BackDrop";
import Modal from "../../Containers/modal/Modal";
import { changePasswordSchema } from "../../utils/Validations";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  changePassword,
  forgotPassword,
  clearLoadingMessageStatus
} from "../../redux/actions/UserAccount";
import { clearData } from "../../redux/actions/TempStorageAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import OtpInput from "react-otp-input";

const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loadingState, messageState, statusCodeState, tempStorageState } =
    useSelector((state) => state);
  const formOptions = { resolver: yupResolver(changePasswordSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otpRequire, setOtpRequire] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [passwordDisable, setPasswordDisable] = useState(true);
  const [onModal, setOnModal] = useState(false);

  const handleSubmitChangePassword = (formData) => {
    if (!onModal) {
      if (otpValue && otpValue.length === 6) {
        const { password } = formData;
        dispatch(
          changePassword({
            email: tempStorageState.forgotPasswordEmail,
            newpassword: password,
            otp: otpValue,
          })
        );
        dispatch(clearLoadingMessageStatus());
      } else {
        setOtpRequire(true);
        dispatch(clearLoadingMessageStatus());
      }
    }
  };

  const closeModal = () => {
    dispatch(clearLoadingMessageStatus());
    setOnModal(false);
  };

  const redirectToLogin = () => {
    history.push("/userLogin");
    dispatch(clearData());
  };

  const handleChange = (e) => {
    dispatch(clearLoadingMessageStatus());
    setOtpRequire(false);
    setOtpValue(e);
    if (e.length < 6) {
      setPasswordDisable(true);
      reset();
    } else {
      setPasswordDisable(false);
      reset();
    }
  };
  const handleResendOTP = () => {
    setOtpRequire(false);
    dispatch(clearLoadingMessageStatus());
    let emailData = { email: tempStorageState.forgotPasswordEmail };
    dispatch(forgotPassword(emailData));
    setOtpValue();
    setSeconds(120);
  };
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  }, [seconds]);

  useEffect(() => {
    if (statusCodeState.changePasswordStatusCode !== null) {
      setOnModal(true);
    }
  }, [statusCodeState.changePasswordStatusCode])

  return (
    <div>
      <Header />
      <div className="headerBannerMainDiv"><img className="vessel_img" src={login_image} alt="vessel" /></div>
      <div className="center-underline text-center">
        <h2 className=" loginhead  pt-5">{languageJson.change_password_txt}</h2>
      </div>
      <div className="my-5"></div>
      <section className="loginsection pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 form-section forgotPasswordMainDiv">
              <form
                className="py-5 px-5 "
                onSubmit={handleSubmit(handleSubmitChangePassword)}
                autoComplete="off"
              >
                <div className="text-center formOTPMainDiv">
                  {messageState.forgotPasswordMessage !== null ? (
                    <p>{messageState.forgotPasswordMessage}</p>
                  ) : (
                    <p className="txtOTPDesp">
                      {languageJson.enter_OTP_email_txt}
                    </p>
                  )}
                  <div className="form-group row px-5 otpDivForm">
                    <label className="col-sm-12 col-form-label login-label otpTxtdiv">
                      {" "}
                      {languageJson.OTP_txt}
                    </label>
                    <div className="col-sm-12 emaildiv">
                      {" "}
                      <div
                        className="otpInput"
                        style={{ marginBottom: "10px" }}
                      >
                        <OtpInput
                          containerStyle={"otp__text-center"}
                          inputStyle={"otp__fs-25"}
                          value={otpValue}
                          isInputNum={true}
                          onChange={handleChange}
                          numInputs={6}
                          separator={<span>&nbsp;&nbsp;</span>}
                        />
                      </div>{" "}
                      {seconds === 0 ? (
                        <button
                          className={`btn btn-primary otpResendBtn login-btn btn-block`}
                          onClick={() => handleResendOTP()}
                          disabled={
                            loadingState.isOtpSendLoading ||
                              loadingState.isforgotPasswordLoading
                              ? true
                              : false
                          }
                        >
                          {loadingState.isOtpSendLoading ||
                            loadingState.isforgotPasswordLoading
                            ? languageJson.processing_txt
                            : languageJson.resend_txt}
                        </button>
                      ) : (
                        <b className="timerTxtDiv">{seconds}</b>
                      )}
                      <p className="text-danger">
                        {otpRequire ? (
                          <p>{languageJson.Please_OTP_txt}</p>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group row px-1 newpwdInputDiv">
                  <label className="col-sm-4 col-form-label login-label">
                    {languageJson.newTxt} {languageJson.password_txt}
                  </label>
                  <div className="col-sm-8 emaildiv">
                    <input
                      className={`form-control`}
                      name="password"
                      id="password"
                      type={passwordShown ? "text" : "password"}
                      placeholder={languageJson.enter_new_password_txt}
                      {...register("password")}
                      disabled={passwordDisable}
                    />
                    <span
                      toggle="#password-field"
                      className={`${passwordShown
                        ? "fa fa-fw fa-eye-slash"
                        : "fa fa-fw fa-eye"
                        } field-icon toggle-password forgeteyeicon`}
                      onClick={() => setPasswordShown(!passwordShown)}
                    ></span>
                    <p className="text-danger">{errors.password?.message}</p>
                  </div>
                </div>

                <div className="form-group row px-1 newpwdInputDiv">
                  <label className="col-sm-4 col-form-label login-label">
                    {languageJson.confirm_password_txt}{" "}
                  </label>
                  <div className="col-sm-8 emaildiv">
                    <input
                      className={`form-control`}
                      name="confirmPassword"
                      id="confirmPassword"
                      type={confirmPasswordShown ? "text" : "password"}
                      placeholder={languageJson.confirm_password_txt}
                      {...register("confirmPassword")}
                      disabled={passwordDisable}
                    />
                    <span
                      toggle="#password-field"
                      className={`${confirmPasswordShown
                        ? "fa fa-fw fa-eye-slash"
                        : "fa fa-fw fa-eye"
                        } field-icon toggle-password forgeteyeicon`}
                      onClick={() =>
                        setConfirmPasswordShown(!confirmPasswordShown)
                      }
                    ></span>
                    <p className="text-danger">
                      {errors.confirmPassword?.message}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-primary submitBtn login-btn btn-block my-5"
                    type="submit"
                    disabled={
                      (loadingState.isChangePasswordLoading ? true : false,
                        otpValue && otpValue.length === 6 ? false : true)
                    }
                  >
                    {loadingState.isChangePasswordLoading
                      ? languageJson.processing_txt
                      : languageJson.submit_txt}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {messageState.changePasswordMessage != null && (
          <React.Fragment>
            <Backdrop onClick={() => ""} />
            <Modal
              text={messageState.changePasswordMessage}
              status={
                statusCodeState.changePasswordStatusCode === 200
                  ? "bg-custom-success"
                  : "bg-warning"
              }
              onClose={() => closeModal()}
            />
            <Modal
              text={messageState.changePasswordMessage}
              btnColor={
                statusCodeState.changePasswordStatusCode === 200
                  ? "modal-btn-success"
                  : "modal-btn-warning"
              }
              icon={
                statusCodeState.changePasswordStatusCode === 200
                  ? "fa fa-check-circle"
                  : "fa fa-exclamation-circle"
              }
              status={
                statusCodeState.changePasswordStatusCode === 200
                  ? "bg-custom-success"
                  : "bg-warning"
              }
              onClose={() => closeModal()}
              otpButton={() =>
                statusCodeState.changePasswordStatusCode === 200
                  ? redirectToLogin()
                  : ""
              }
            />
          </React.Fragment>
        )}
      </section>
    </div>
  );
};

export default ChangePassword;
