import { ActionTypes } from "../constants/Types";

export const loginStatusCode = (code) => {
    return {
        type: ActionTypes.LOGIN_STATUS_CODE,
        payload: code
    }
}
export const otpSendStatusCode = (code) => {
    return {
        type: ActionTypes.OTP_SEND_STATUS_CODE,
        payload: code
    }
}

export const driverRegisterStatusCode = (code) => {
    return {
        type: ActionTypes.DRIVER_REGISTER_STATUS_CODE,
        payload: code
    }
}

export const otpVerifyStatusCode = (code) => {
    return {
        type: ActionTypes.OTP_VERIFY_STATUS_CODE,
        payload: code
    }
}

export const clearStatusCode = () => {
    return {
        type: ActionTypes.CLEAR_STATUS_CODE
    }
}

export const lmcRegisterStatusCode = (code) => {
    return {
        type: ActionTypes.LMC_REGISTER_STATUS_CODE,
        payload: code
    }
}

export const contactUsStatusCode = (code) => {
    return {
        type: ActionTypes.CONTACT_US_STATUS_CODE,
        payload: code
    }
}

export const forgotPasswordStatusCode = (code) => {
    return {
        type: ActionTypes.FORGOT_PASSWORD_STATUS_CODE,
        payload: code
    }
}

export const changePasswordStatusCode = (code) => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_STATUS_CODE,
        payload: code
    }
}
export const userDetalStatusCode = (code) => {
    return {
        type: ActionTypes.USER_DETAIL_STATUS_CODE,
        payload: code
    }
}

export const vasselAlertsDataStatusCode = (code) => {
    return {
        type: ActionTypes.VASSEL_ALERTS_DATA_STATUS_CODE,
        payload: code
    }
}

export const railCrossingAlertsDataStatusCode = (code) => {
    return {
        type: ActionTypes.RAIL_CROSSING_ALERTS_DATA_STATUS_CODE,
        payload: code
    }
}

export const alertsAnnouncementsDataStatusCode = (code) => {
    return {
        type: ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_STATUS_CODE,
        payload: code
    }
}

export const marineTerminalAlertsDataStatusCode = (code) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_ALERTS_DATA_STATUS_CODE,
        payload: code
    }
}

export const trafficAndCamerasAlertsDataStatusCode = (code) => {
    return {
        type: ActionTypes.TRAFFIC_AND_CAMERAS_DATA_STATUS_CODE,
        payload: code
    }
}
export const securityAndSafetyDataStatusCode = (code) => {
    return {
        type: ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_STATUS_CODE,
        payload: code
    }
}

export const marineTerninalListStatusCode = (code) => {
    return {
        type: ActionTypes.MARINE_TERMINAL_LIST_DATA_STATUS_CODE,
        payload: code
    }
}
export const resourceDataStatusCode = (code) => {
    return {
        type: ActionTypes.RESOURCE_DATA_STATUS_CODE,
        payload: code
    }
}

export const portMessageStatusCode = (code) => {
    return {
        type: ActionTypes.PORT_MESSAGE_STATUS_CODE,
        payload: code
    }
}

export const portConstructionEventsStatusCode = (code) => {
    return {
        type: ActionTypes.PORT_CONSTRUCTION_EVENTS_STATUS_CODE,
        payload: code
    }
}

export const portIncidentClosuresStatusCode = (code) => {
    return {
        type: ActionTypes.PORT_INCIDENT_CLOSURES_STATUS_CODE,
        payload: code
    }
}

export const regionalMessageSignsStatusCode = (code) => {
    return {
        type: ActionTypes.REGIONAL_MESSAGE_SIGNS_STATUS_CODE,
        payload: code
    }
}

export const regionalConstructionEventsStatusCode = (code) => {
    return {
        type: ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_STATUS_CODE,
        payload: code
    }
}

export const regionalIncidentClosuresStatusCode = (code) => {
    return {
        type: ActionTypes.REGIONAL_INCIDENT_CLOSURES_STATUS_CODE,
        payload: code
    }
}

