import React from 'react';
import { languageJson } from 'src/StringVariable';

export default function SearchComponentVessel({ onClickSearch, onChange, type, value,placeholder }) {
  return (
    <div className="reportSectionMainDiv">
      <div className="searchFilterDiv">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
        ></input>
        <div className="serachOption">
          <button
            onClick={() => onClickSearch(type)}
            disabled={value.length > 1 ? false : true}
          >
            <i className="fa fa-search" aria-hidden="true"></i>{languageJson.search_txt}
          </button>
        </div>
      </div>
    </div>
  )
}
