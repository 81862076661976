import React, { useEffect, useState } from "react";
import emailicon from "../assets/email-icon.png";
 import locationicon from "../assets/location-icon.png";
import facebookicon from "../assets/facebook-icon.png";
import linkedinicon from "../assets/linkedin-icon.png";
import twittericon from "../assets/twitter-icon.png";
import instagramIcon from "../assets/instagramIcon.png";
import youtubeIcon from "../assets/youtubeIcon.png";
import { languageJson } from "../StringVariable";
 import { useSelector } from "react-redux";
import powerImg from "../assets/seaportLogo.png";
import moment from "moment";
import { submitGAEvent } from "src/utils/GAEvents";
moment.suppressDeprecationWarnings = true;

const Footer = () => {
  let singleUrls = useSelector((state) => state.publicUrlState);
  let userData = useSelector((state) => state.auth);
  const [fbUrl, setFbUrl] = useState(null);
  const [linkedinUrl, setLinkedinUrl] = useState(null);
  const [twitterUrl, setTwitterUrl] = useState(null);
  // const [webEmail, setWebEmail] = useState(null);
  // const [webContact, setWebContact] = useState(null);
  const [webAddress, setWebAddress] = useState(null);
  const [isDashboard, setDashboard] = useState(false);
  const [instagramLink, setInstagramLink] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [footer_contact_url, setFooterContactLink] = useState(null);
  

  useEffect(() => {
    setInterval(() => {
       /******* If # is not in browser routes ************/
      //  let str = window.location.pathname;
      /******* If # is in browser routes ************/
       let str = window.location.hash;
    
      let list = str.split("/");
      list.includes("admin") ? setDashboard(false) : setDashboard(true);
    }, 500);
    return () => {
      clearInterval();
    };
  }, [window.location, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      singleUrls &&
      singleUrls.singleUrls &&
      singleUrls.singleUrls.length > 0
    ) {
      singleUrls.singleUrls.map((ele) => {
        switch (ele.url_id) {
          case "facebook_url":
            submitGAEvent("FACEBOOK");
            return setFbUrl(ele.url_link);
          case "linkedin_url":
            submitGAEvent("LINKEDIN");
            return setLinkedinUrl(ele.url_link);
          case "twitter_url":
            submitGAEvent("TWITTER");
            return setTwitterUrl(ele.url_link);
          // case "websiteMail":
          //   return setWebEmail(ele.url_link);
          // case "websiteContact":
          //   return setWebContact(ele.url_link);
          case "websiteAddress":
            return setWebAddress(ele.url_link);
          case "instagram":
            return setInstagramLink(ele.url_link);
          case "youtube":
            return setYoutubeLink(ele.url_link);
            case "footer_contact_url":
              return setFooterContactLink(ele.url_link);
          default:
            return null;
        }
      });
    }
  }, [singleUrls.singleUrls]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {languageJson && languageJson.footerList && isDashboard ? (
        <footer className="text-center">
          <section className="footer-sec">
                    <h6 className="contact-information" >
                      {languageJson.footerList.contactUs}
                    </h6>
           
              
                <div className="row" style={{padding:"20px",
                marginLeft:"20px",
                alignItems:"center"          
              }}>
                <div className="col-md-4 col-lg-4">
                  <div className="contactusDiv-footer">
                   
                    <p className="footer-links" style={{marginBottom:"0px"}}>
                      <img
                        className="footer-contact-icon"
                        alt="emailicon"
                        src={emailicon}
                      />
                      <span>
                    
                        <a
                        href={footer_contact_url}
                        target="_blank"
                        rel="noreferrer"                          
                      >
                      {languageJson.contact_us_footer_port}</a>
                      </span>
                    </p>                   
                  </div>
                </div>
                 <div className="col-md-4 col-lg-4" style={{
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                 }}>
                  <div>
                   
 
                    {fbUrl !== null && <a href={fbUrl} target="_blank" rel="noreferrer">
                      {" "}
                      <img
                        className="p-3"
                        src={facebookicon}
                        alt="facebok-icon"
                      />
                    </a>}
                    {linkedinUrl !== null && <a href={linkedinUrl} target="_blank" rel="noreferrer">
                      {" "}
                      <img
                        className="p-3"
                        src={linkedinicon}
                        alt="linkedin-icon"
                      />{" "}
                    </a>}
                    {twitterUrl !== null && <a href={twitterUrl} target="_blank" rel="noreferrer">
                      {" "}
                      <img
                        className="p-3"
                        src={twittericon}
                        alt="twitter-icon"
                      />
                    </a>}
                    {instagramLink !== null && <a href={instagramLink} target="_blank" rel="noreferrer">
                      {" "}
                      <img
                        className="p-3"
                        src={instagramIcon}
                        alt="instagram-icon"
                      />
                    </a>}
                    {youtubeLink !== null && <a href={youtubeLink} target="_blank" rel="noreferrer">
                      {" "}
                      <img
                        className="p-3"
                        src={youtubeIcon}
                        alt="twitter-icon"
                      />
                    </a>}
                    
                    
                  </div>
                </div>  
                
                <div className="col-md-4 col-lg-4">
                  <div className="portlink-div">
                  
                  <p className="footer-links" style={{marginBottom:"0px"}}>
                      <img
                        className="footer-contact-icon"
                        alt="locationicon"
                        src={locationicon}
                      />
                      <span className="addressTxt">
                        <a href={`https://maps.google.com/?q=${webAddress}`} 
                        target="_blank"
                        rel="noreferrer"
                         >
                        {webAddress}</a>
                      </span>
                    </p>
                
                  </div>
                </div>
              </div>
             
          </section>
          <p className="text-center text-white pb-3 policy-bg pt-3 mb-0" style={{background: "white"}}>
            <span style={{color: "black"}}>
              {" "}
              {languageJson.footerList.CopyrightTxt} {moment().year()} -{" "}
              {moment().year() + 1}&nbsp;&nbsp;|&nbsp;&nbsp;{" "}
              {languageJson.app_name_txt}{" "}
              <img
                className="powerImgFooterDiv"
                alt="powerImg"
                src={powerImg}
              ></img>{" "}
              &nbsp;&nbsp;| &nbsp;&nbsp;
              {languageJson.footerList.allrightsreserved} &nbsp;&nbsp;{" "}
            </span>
            {/* <b className="versionTxtDiv">Version-1.0.0</b> */}
          </p>
        </footer>
      ) : null}
    </React.Fragment>
  );
};

export default Footer;
