import React, { useEffect, useState } from "react";
import moment from "moment";
import { Accordion, Card } from "react-bootstrap";
import AccordionApp from "src/Components/TrafficCameras/Accordian";
import { useDispatch } from "react-redux";
import refreshIcon from "src/assets/refreshIcon.png";
import {
  vesselAlertsData,
  marineTerminalAlertsData,
  railCrossingAlertsData,
  trafficAndCamerasAlertsData,
  securityAndSafetyAlertsData
} from "src/redux/actions/AlertDataAction";
import { alertsAnnouncementsData } from "src/redux/actions/AlertAnnouncementsActions";
import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "src/firebaseConfig";
import { languageJson } from "src/StringVariable";
import SecuritySafetyAlerts from "./SecuritySafetyAlerts";
moment.suppressDeprecationWarnings = true;

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AlertsPage = () => {
  let dispatch = useDispatch();
  const [active, setActive] = useState();
  const [refreshButton, setRefreshButton] = useState(false);


  const listdata = [
    // {
    //   name: languageJson.marine_terminals_txt,
    //   id: "1",
    //   data: <MarinterminalAlert />,
    // },
    // {
    //   name: languageJson.vessel_operations_txt,
    //   id: "2",
    //   data: <VesselOperation />,
    // },
    {
      name: languageJson.traffic_cameras_txt,
      id: "33",
      data: <AccordionApp />,
    },
    {
      name: languageJson.safety_security_text_txt,
      id: "4",
      data: <SecuritySafetyAlerts />,
    },
    // {
    //   name: languageJson.rail_crossings_txt,
    //   id: "5",
    //   data: <RailCrossingAlert />,
    // },
  ];

  const decoratedOnClick = (id, active1) => {
    var element1 = document.getElementsByClassName("inner-acc collapse show");
    if (element1[0] && element1[0].classList) {
      element1[0].classList.remove("show");
    }
    if (active === active1) {
      setActive(null);
    } else {
      setActive(active1);
    }
    switch (id) {
      // case "1":
      //   dispatch(marineTerminalAlertsData());
      //   break;
      // case "2":
      //   dispatch(vesselAlertsData(1, 10));
      //   break;
      case "33":
        dispatch(trafficAndCamerasAlertsData());
        break;
      case "4":
        dispatch(securityAndSafetyAlertsData());
        break;
      // case "5":
      //   dispatch(railCrossingAlertsData(1, 10));
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    logEvent(analytics, "Alert page_view");
  });

  let renderAccordion = (team, index) => {
    return (
      <Card key={team + index}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={`${team + index}`}
          onClick={() => decoratedOnClick(team.id, index)}
          className={parseInt(active) === index ? "active active1" : ""}
        >
          <h5>{team.name}</h5>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${team + index}`}>
          <Card.Body>
            {team.id === "1" ? team.data : null}
            {team.id === "2" ? team.data : null}
            {team.id === "4" ? team.data : null}
            {team.id === "5" ? team.data : null}
            {team.id === "33" ? team.data : null}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };
  const getTime = () => {
    let time = new Date();
    let output = moment.tz(time,"America/Los_Angeles").format("MM/DD/YYYY, h:mm A")
    if (moment(output).format("h:mm A") !== "Invalid date") {
      return moment(output).format("L h:mm A");
    } else {
      return <b>{"-"}</b>;
    }
  };
  const onRefreshClick = async () => {
    setRefreshButton(true);
    await dispatch(vesselAlertsData(1, 10));
    await dispatch(marineTerminalAlertsData());
    await dispatch(railCrossingAlertsData(1, 10));
    await dispatch(trafficAndCamerasAlertsData);
    await dispatch(securityAndSafetyAlertsData());
    await dispatch(alertsAnnouncementsData());
    setRefreshButton(false);
  };

  return (
    <div>
      <div className="lastUpdateDiv">
        <h3>
          {" "}
          {languageJson.last_updated_time_txt}:<span> {getTime()}</span>
        </h3>
        <div className="listOptionDiv">
          <button onClick={onRefreshClick}>
            <img
              className={`${refreshButton ? "rotate" : ""}`}
              src={refreshIcon}
              alt="refresh"
            ></img>
            {languageJson.refresh_txt}
          </button>
        </div>
      </div>
      <Accordion>
        {listdata.map((item, index) => {
          return renderAccordion(item, index);
        })}
      </Accordion>
    </div>
  );
};

export default AlertsPage;
