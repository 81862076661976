import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DataNotFound from "../DataNotFound/DataNotFound";
import { useSelector, useDispatch } from "react-redux";
import { alertsAnnouncementsData } from "src/redux/actions/AlertAnnouncementsActions";
import SpinerLoader from "src/utils/SpinerLoader";
import { NotificationPopup } from "src/utils/Notifications";
import { languageJson } from "src/StringVariable";
import { submitGAEvent } from "src/utils/GAEvents";
 
const AnnouncementAlerts = (props) => {
  let dispatch = useDispatch();

  const [idx, setIdx] = useState(null);


  const showTooltip = (id) => {
    setIdx(id);
    const obj = {
      customcode: 200,
      message: languageJson.text_copied_txt
    }
    NotificationPopup(obj.customcode, obj.message);
  };
  let { alertDataState, loadingState } = useSelector((state) => state);
  useEffect(() => {
    dispatch(alertsAnnouncementsData());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    submitGAEvent("ANNOUNCEMENT");
  }, []);

  if (loadingState.isAlertsAnnouncementsDataLoading) {
    return <SpinerLoader />;
  } else if (!loadingState.isAlertsAnnouncementsDataLoading) {
    if (props.alertAnnouncement) {
      return <div className={`container alertAnnouncementsMain`}>
        {alertDataState &&
          alertDataState.alertAnnouncementsData != null && alertDataState.alertAnnouncementsData.data &&
          alertDataState.alertAnnouncementsData.data.length > 0 ?
          <div className="row railborder ">
            {alertDataState.alertAnnouncementsData.data.map((data, index) => {
              return <div className="col-md-12 border border-grey crossingsty">
                <div className="leftDescpDiv">
                  <span className={`${data.alert_priority}`}></span>
                  <p className="tcolor font-weight-bold">{`${data.alert_message}`}</p>
                  <p className="sty">
                    {" "}
                    <span className="catTitleTxt">{data.category_name || "-"}{" "}</span>
                    <span className="dateAlertTxt">{data.expiration_date || "-"}{" "}</span>
                  </p>
                </div>

                <div className="rightCopyDiv">
                  <CopyToClipboard
                    onCopy={() => showTooltip(index)}
                    text={data.alert_message}
                  >
                    {index === idx ? (
                      <>
                        <div className="buttonDiv">
                          <button title={languageJson.copy_button_txt}>
                            <i className="fa fa-clone" aria-hidden="true"></i>
                            <b>{languageJson.copy_button_txt}</b>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="buttonDiv">
                        <button title={languageJson.copy_button_txt}>
                          <i className="fa fa-clone" aria-hidden="true"></i>
                          <b>{languageJson.copy_button_txt}</b>
                        </button>
                      </div>
                    )}
                  </CopyToClipboard>
                </div>
              </div>
            })}
          </div> : (
            <DataNotFound />
          )}
      </div>
    }
  }
};

export default AnnouncementAlerts;
