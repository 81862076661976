import axios from "axios";
import store from "store";

const server = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT
})
server.interceptors.request.use(
    async config => {
        if (navigator.onLine) {
            let obj = {
                username: process.env.REACT_APP_USERNAME,
                password: process.env.REACT_APP_PASSWORD
            }
            config.headers.Authorization = "Basic " + window.btoa(obj.username + ':' + obj.password)
            let token = localStorage.getItem('token');
            config.headers['Content-Type'] = 'application/json';
            config.headers['devicetype'] = "web";
            config.headers['lang'] = ["es", "en", "pa"].includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en';
            config.headers['uuid'] = sessionStorage.getItem('uuid');
            if (token) config.headers['x-auth-token'] = JSON.parse(token);
            return config;
        }
        else {
            return Promise.reject("Offline");
        }
    },
    error => {
        return Promise.reject(error);
    },
);

server.interceptors.response.use(response => {
    let auth = localStorage.getItem("token");
    if (auth != null && (response.data.customcode === 401 || response.data.customcode === 402 || response.data.customcode === 201 ||  response.data.customcode === 203)) {
        store.clearAll();
        return window.location = '/#/userLogin';
    }

    return response;
}, error => {
    if (error.response && (error.response.status === 401 || error.response.status === 402 || error.response.status === 201)) {
    }
    return error;
});



export default server;