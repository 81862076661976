import React from 'react';
import { Chart } from "react-google-charts";
import { languageJson } from 'src/StringVariable';
export default function TruckTimeChart() {

  return (
    <div>
      <Chart
        width={'600px'}
        height={'300px'}
        chartType="PieChart"
        loader={<div>{languageJson.loading_chart_txt}</div>}
        data={[
          ['Task', 'Waiting time'],
          ['Less than 75 minutes', 55],
          ['75 to 90 minutes', 5],
          ['90+ minutes', 3],

        ]}
        options={{
          pieHole: 0.4,
          is3D: true,
          colors: ['#24C349', '#FEC404', 'red']
        }}
        rootProps={{ 'data-testid': '3' }}
      />
    </div>
  )
}
