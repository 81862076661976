import React, { useState, useEffect } from 'react';
import './ForgotPassword.css';
import Header from '../../Containers/Header';
import login_image from '../../assets/Loginbanner.png';
import Backdrop from '../../Containers/modal/BackDrop';
import Modal from '../../Containers/modal/Modal';
import { forgotSchema } from "../../utils/Validations";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPassword, clearLoadingMessageStatus } from "../../redux/actions/UserAccount";
import { useDispatch, useSelector } from "react-redux";
import { languageJson } from '../../StringVariable';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { loadingState, messageState, statusCodeState } = useSelector(state => state);
    const formOptions = { resolver: yupResolver(forgotSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const [onModal, setOnModal] = useState(false);
    const { errors } = formState;
    const handleSubmitForgot = (formData) => {
        if (!onModal) {
            const { email } = formData;
            dispatch(forgotPassword({ email }));
        }
    };

    const closeModal = () => {
        dispatch(clearLoadingMessageStatus())
        setOnModal(false);
    }

    useEffect(() => {
        if (statusCodeState.forgotPasswordStatusCode !== null) {
            setOnModal(true);
        }
    }, [statusCodeState.forgotPasswordStatusCode])

    return (
        <div>
            <Header />
            <div className="headerBannerMainDiv"><img className="vessel_img" src={login_image} alt="vessel" /></div>
            <div className="center-underline text-center">
                <h2 className=" loginhead  pt-5">{languageJson.forgotpassword_txt}</h2>
            </div>
            <div className="py-5">
                <p className="text-center logindesc">{languageJson.forgotPwd_descp_txt}</p>
            </div>
            <section className="loginsection pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6 form-section fp_mb">
                            <form className="py-5 px-5" onSubmit={handleSubmit(handleSubmitForgot)} autoComplete="off">
                                <div className="form-group row py-4 px-5">
                                    <label htmlFor='email' className="col-sm-2 col-form-label login-label">{languageJson.email_txt}</label>
                                    <div className="col-sm-10">
                                        <input className={`form-control`}
                                            name="email" id="email" type="text"
                                            placeholder={languageJson.enter_email_address_txt}
                                            {...register('email')}
                                        />
                                        <p className="text-danger">{errors.email?.message}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="btn btn-primary login-btn btn-block" type="submit" disabled={loadingState.isforgotPasswordLoading ? true : false}>
                                        {loadingState.isforgotPasswordLoading ? languageJson.processing_txt : languageJson.submit_txt}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {messageState.forgotPasswordMessage != null &&
                    <React.Fragment>
                        <Backdrop onClick={() => closeModal()} />
                        <Modal text={messageState.forgotPasswordMessage} status={statusCodeState.forgotPasswordStatusCode === 200 ? 'bg-custom-success' : 'bg-warning'} onClose={() => closeModal()} />
                        <Modal
                            text={messageState.forgotPasswordMessage}
                            btnColor={statusCodeState.forgotPasswordStatusCode === 200 ? 'modal-btn-success' : 'modal-btn-warning'}
                            icon={statusCodeState.forgotPasswordStatusCode === 200 ? 'fa fa-check-circle' : 'fa fa-exclamation-circle'}
                            status={statusCodeState.forgotPasswordStatusCode === 200 ? 'bg-custom-success' : 'bg-warning'}
                            onClose={() => closeModal()}
                            otpButton={() => statusCodeState.forgotPasswordStatusCode === 200 ? history.push('/changePassword') : ''}
                        />
                    </React.Fragment>
                }
            </section>
        </div>
    )
}

export default ForgotPassword;
