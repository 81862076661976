import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DataNotFound from "../DataNotFound/DataNotFound";
import { useSelector, useDispatch } from "react-redux";
import { railCrossingAlertsData } from "src/redux/actions/AlertDataAction";
import { DateTimeFormate } from "src/utils/DataFormate";
import SpinerLoader from "src/utils/SpinerLoader";
import Pagination from "src/admin/views/components/custome/Pagination";
import { languageJson } from "src/StringVariable";
import { NotificationPopup } from "src/utils/Notifications";

const RailCrossingAlert = (props) => {
  let dispatch = useDispatch();
  const limit = 10;

  const showTooltip = (id) => {
    const obj = {
      customcode: 200,
      message: languageJson.text_copied_txt
    }
    NotificationPopup(obj.customcode, obj.message);
  };

  let { alertDataState, loadingState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(railCrossingAlertsData(1, limit));
  }, [limit]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPage = (selected) => {
    let page = parseInt(selected.selected) + 1
    dispatch(railCrossingAlertsData(page, limit))
  }

  if (loadingState.isRailAlertsDataLoading) {
    return <SpinerLoader />;
  }

  else if (!loadingState.isRailAlertsDataLoading && (alertDataState &&
    alertDataState.railAlertsData === null)) {
    return <DataNotFound />
  }

  else if (!loadingState.isRailAlertsDataLoading) {
    if (props.railCrossing) {
      return <div className={`container`}>
        <div className="row railborder">
          {alertDataState &&
            alertDataState.railAlertsData != null && alertDataState.railAlertsData.railAlertList &&
            alertDataState.railAlertsData.railAlertList.length > 0 ?
            alertDataState.railAlertsData.railAlertList.map((data) => (
              <div className="col-md-12 border border-grey crossingsty">
                <span className={`${data.railroadColor}` || "-"}></span>
                <p className="tcolor font-weight-bold">{`${data.railroadName}` || "-"}</p>
                <p className="sty">
                  {" "}
                  <span>{data.railroadStatus || "-"} {languageJson.since_capital_txt}</span>{" "}
                  {DateTimeFormate(data.timestamp) || "-"}
                </p>
              </div>
            )) : ""}
        </div>
        {props.pagination && <div className="pagination-parent mt-2">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-4=12 d-flex justify-content-center  align-items-center viewAllBtn">
            {alertDataState &&
              alertDataState.railAlertsData != null && alertDataState.railAlertsData.railAlertList &&
              <Pagination public={true} total={alertDataState.railAlertsData.total} per_page={alertDataState.railAlertsData.limit} selectedPage={parseInt(alertDataState.railAlertsData.page)} setPage={setPage} />}
          </div>
        </div>}
      </div>
    }
    else
      return <ul className="newAlertVesselDiv alertInnerDivList removedOverlayTrigger">
        {alertDataState &&
          alertDataState.railAlertsData != null && alertDataState.railAlertsData.railAlertList &&
          alertDataState.railAlertsData.railAlertList.length > 0 ?
          alertDataState.railAlertsData.railAlertList.map((item, index) => {
            return <li key={index} className="aa">
              <span>
                <p>
                  <p className="sty2">
                    {`${item.alertMessage}, ${item.railroadName}` || "-"}
                  </p>
                  <p className="sty3 railCrossing__fs20">
                    {" "}
                    {DateTimeFormate(item.timestamp) || "-"}
                  </p>
                </p>
              </span>

              <CopyToClipboard
                onCopy={() => showTooltip(index)}
                text={`${item.alertMessage}, ${item.railroadName}`}
              >
                <div className="buttonDiv">
                  <button title={languageJson.copy_button_txt} >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    <b>{languageJson.copy_button_txt}</b>
                  </button>
                </div>
              </CopyToClipboard>
            </li>;
          })
          : (
            <DataNotFound />
          )}


        {props.pagination && <div className="pagination-parent mt-2">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-4=12 d-flex justify-content-center  align-items-center viewAllBtn">
            {alertDataState &&
              alertDataState.railAlertsData != null && alertDataState.railAlertsData.railAlertList &&
              <Pagination public={true} total={alertDataState.railAlertsData.total} per_page={alertDataState.railAlertsData.limit} selectedPage={parseInt(alertDataState.railAlertsData.page)} setPage={setPage} />}
          </div>
        </div>}
      </ul>

  }
};
export default RailCrossingAlert;
