/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { getTotalWaitTimeColor } from "../MarineTerminalsDetail/MarineTerminalDetail";

import Header from "../../Containers/Header";
import refreshIcon from "../../assets/refreshIcon.png";
import TTT_listIcon1 from "../../assets/TTT_listIcon1.png";
import TTT_listIcon2 from "../../assets/TTT_listIcon2.png";
import TTT_listIcon3 from "../../assets/TTT_listIcon3.png";
import TTT_listIcon4 from "../../assets/TTT_listIcon4.png";
import oaklandlogo from "src/assets/new_logo.png";
import moment from "moment";
import "./TruckTime.css";
import parkingBgImg from '../../assets/parkingBgImg.png'
import underConstructionIconImg from "../../assets/underConstructionIcon.png";
import { GET_MARINE_TERMINAL_URL, GET_MARINE_TERMINAL_DATA_BY_ID_URL } from '../../url/Url';
import axios from "src/redux/actions/apis";
import TruckTimeChart from "./TruckTimeChart";
import { languageJson } from '../../StringVariable';
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import NoteInstruction from "../MarineTerminalsDetail/NoteInstruction";
moment.suppressDeprecationWarnings = true;

function TruckTurnTime() {
  const [allMarineTerminals, setAllMarineTerminals] = useState(null);
  const [marineId, setMarineId] = useState();
  const [singleTerminalData, setSingleTerminalData] = useState(null);
  const [refreshButton, setRefreshButton] = useState(false);

  const getMarineDataList = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.get(GET_MARINE_TERMINAL_URL, requestObj).then((res) => {
      const { marineTerminals, customcode } = res.data;
      if (customcode === 200) {
        setAllMarineTerminals(marineTerminals);
      }
      else {
        setAllMarineTerminals([]);
      }
    });
  };

  const getTime = () => {
    let time = new Date();
    if (moment(time).format("h:mm A") !== "Invalid date") {
      return moment(time).format("L h:mm A");
    } else {
      return <b>{"-"}</b>;
    }
  };

  const getTerminalCurrentWaitTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }
    if (hours === 1 && minutes > 1) {
      return `${hours} ${languageJson.hrtxt}  ${minutes} ${languageJson.mins_txt}`;
    }
    if (hours === 0 && minutes > 1) {
      return `${minutes} ${languageJson.mins_txt}`;
    }
    if (hours === 0 && minutes === 1) {
      return `${minutes} ${languageJson.mintxt}`;
    }
    if (minutes === 0) {
      if (hours > 1) {
        return `${hours} ${languageJson.hrs_txt}`;
      } else {
        return `${hours} ${languageJson.hrtxt}`;
      }
    } else {
      return `${hours} ${languageJson.hrs_txt}  ${minutes} ${languageJson.mins_txt}`;
    }
  };

  const getSingleMarineData = async (id) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(`${GET_MARINE_TERMINAL_DATA_BY_ID_URL}${id}`, requestObj)
      .then((res) => {
        const { gateList } = res.data;
        setSingleTerminalData(gateList);
      });
  };

  const handleMarineChange = (e) => {
    setMarineId(parseInt(e.target.value));
    getSingleMarineData(e.target.value);
  };

  const onRefreshClick = () => {
    getMarineDataList();
    setMarineId(parseInt(marineId));
    getSingleMarineData(marineId);
    getTime();
    setRefreshButton(true);
  };
  const getTheColor = (time) => {
    const color = getTotalWaitTimeColor(time);
    return color;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getMarineDataList();
      getTime();
    }, 150000);
    return () => {
      clearInterval(interval);
      setRefreshButton(false);
    };
  }, [allMarineTerminals]);

  useEffect(() => {
    if (marineId !== 0) {
      const interval = setInterval(() => {
        getSingleMarineData(marineId);
        getTime();
      }, 150000);
      return () => {
        clearInterval(interval);
        setRefreshButton(false);
      };
    }
  }, [singleTerminalData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getMarineDataList();
    getTime();
  }, []);
  
  return (
    <div>
      <Header />

      <div className="col underContPageDiv d-none">
        <div className="container">
          <div className="underContMainDiv">
            <img src={underConstructionIconImg} alt="icon"></img>
          </div>
        </div>
      </div>
      <div className="headerBannerMainDiv">
        <img className="vessel_img" src={parkingBgImg} alt="vessel" />
      </div>
      <div className="truckTurnTimeMainDiv">
        <div className="container">
          <div className="titleDescpDiv">
            <h2>{languageJson.truckTurnTime_txt}</h2>
          </div>
          <div className="lastUpdateDiv">
            <h3>
              {" "}
              {languageJson.last_updated_time_txt}:
              <span> {getTime()}</span>
            </h3>
            <div className="listOptionDiv">
              <select
                onChange={handleMarineChange}
                value={marineId}
                className="browser-default custom-select"
              >
                <option value="0" selected>
                  {languageJson.all_txt}
                </option>
                {allMarineTerminals != null && allMarineTerminals &&
                  allMarineTerminals.map((terminal) => (
                    <option value={terminal.id}>
                      {terminal.terminal_name}
                    </option>
                  ))}
              </select>
              <button onClick={onRefreshClick}>
                <img
                  className={`${refreshButton ? "rotate" : ""}`}
                  src={refreshIcon}
                  alt="refreshIcon"
                ></img>
                {languageJson.refresh_txt}
              </button>
            </div>
          </div>
          <div className="truckTurnTimeListOpt">
            <ul id="accordion">
              {!marineId && allMarineTerminals !== null &&
                allMarineTerminals && allMarineTerminals.length > 0 ?
                allMarineTerminals.map((terminal) => (
                  <>
                    <li>
                      <div className="titleDiv">
                        <span>
                          <img
                            src={terminal.terminal_logo !== null && terminal.terminal_logo !== " " ? terminal.terminal_logo : oaklandlogo}
                            onError={e => { e.currentTarget.src = oaklandlogo }}
                            className=""
                            alt="logo"
                          ></img>
                        </span>
                        <a
                          role="button"
                          aria-expanded="false"
                          className="arrowDDBtn collapsed"
                          data-toggle="collapse"
                          href={`#collapse-${terminal.id}`}
                        ></a>
                      </div>
                      <div className="infoListDiv">
                        <span
                          className={`dynamicTxt ${getTheColor(
                            terminal.current_street_wait_time !== null && terminal.current_street_wait_time !== " " ? terminal.current_street_wait_time : 0
                          )}`}
                        >
                          {/* 0h 50m */}
                          {getTerminalCurrentWaitTime(
                            terminal.current_street_wait_time !== null && terminal.current_street_wait_time !== " " ? terminal.current_street_wait_time : 0
                          )}
                        </span>
                        <span className="fixedTxt">
                          <b className="pinkBgColor">
                            <img src={TTT_listIcon1} alt="icon"></img>
                          </b>
                          <label>
                            {languageJson.current_average_TT_txt}
                          </label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt">
                          {terminal.current_trucks_in_queue !== null && terminal.current_trucks_in_queue !== "" ? terminal.current_trucks_in_queue : 0}
                        </span>
                        <span className="fixedTxt">
                          <b className="blueBgColor">
                            <img src={TTT_listIcon2} alt="icon"></img>
                          </b>
                          <label>{languageJson.current_truck_count_txt}</label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt txtColorGreen">
                          {getTerminalCurrentWaitTime(
                            terminal.today_turn_time !== null && terminal.today_turn_time !== "" ? terminal.today_turn_time : 0

                          )}
                        </span>
                        <span className="fixedTxt">
                          <b className="redBgColor">
                            <img src={TTT_listIcon3} alt="icon"></img>
                          </b>
                          <label>{languageJson.daily_average_TT_txt}</label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt">
                          {terminal.today_volume !== null && terminal.today_volume !== "" ? terminal.today_volume : 0}
                        </span>
                        <span className="fixedTxt">
                          <b className="greenBgColor">
                            <img src={TTT_listIcon4} alt="icon"></img>
                          </b>
                          <label>{languageJson.daily_truck_count_txt}</label>
                        </span>
                      </div>

                      <div
                        id={`collapse-${terminal.id}`}
                        data-parent="#accordion"
                        className="collapse showGraphMainDiv"
                      >
                        <div className="innerGraphDiv">
                          <h2>
                            {terminal.terminal_name}  {languageJson.current_turn_time_txt}:{" "}
                            <span>
                              {getTerminalCurrentWaitTime(
                                terminal.today_turn_time
                              )}
                            </span>
                          </h2>
                          <span>
                            <TruckTimeChart />

                          </span>
                        </div>
                        <div className="innerGraphDiv">
                          <h2>
                            {terminal.terminal_name === "" || terminal.terminal_name.toLowerCase() === "null" || terminal.terminal_name === null ? "-" : terminal.terminal_name}  Daily Turn Time:{" "}
                            <span>
                              {" "}
                              {getTerminalCurrentWaitTime(
                                terminal.today_turn_time
                              )}
                            </span>{" "}
                          </h2>
                          <span> <TruckTimeChart /></span>
                        </div>
                      </div>
                    </li>
                  </>
                )) : allMarineTerminals != null && allMarineTerminals && allMarineTerminals.length === 0 ? !marineId && <DataNotFound /> : !marineId && <SpinerLoader />}
              {marineId &&
                singleTerminalData !== null &&
                singleTerminalData &&
                singleTerminalData?.gates &&
                singleTerminalData?.gates.length === 0 ? (
                <>
                  <li>
                    <div className="titleDiv">
                      <span>
                        <img
                          src={singleTerminalData.terminal_logo !== null && singleTerminalData.terminal_logo !== " " ? singleTerminalData.terminal_logo : oaklandlogo}
                          onError={e => { e.currentTarget.src = oaklandlogo }}
                          className=""
                          alt="logo"
                        />
                        <h2>
                          {singleTerminalData?.terminal_name}
                        </h2>
                      </span>
                      <a
                        role="button"
                        aria-expanded="false"
                        className="arrowDDBtn collapsed"
                        data-toggle="collapse"
                        href={`#collapse-${singleTerminalData?.id}`}
                      ></a>
                    </div>
                    <div className="infoListDiv">
                      <span className="dynamicTxt txtColorGreen">
                        {/* 0h 50m */}
                        {getTerminalCurrentWaitTime(
                          singleTerminalData.current_street_wait_time !== null && singleTerminalData.current_street_wait_time !== " " ? singleTerminalData.current_street_wait_time : 0
                        )}
                      </span>
                      <span className="fixedTxt">
                        <b className="pinkBgColor">
                          <img src={TTT_listIcon1} alt="icon"></img>
                        </b>
                        <label>{languageJson.current_average_TT_txt}</label>
                      </span>
                    </div>

                    <div className="infoListDiv">
                      <span className="dynamicTxt">
                        {singleTerminalData.current_trucks_in_queue !== null && singleTerminalData.current_trucks_in_queue !== "" ? singleTerminalData.current_trucks_in_queue : 0}
                      </span>
                      <span className="fixedTxt">
                        <b className="blueBgColor">
                          <img src={TTT_listIcon2} alt="icon"></img>
                        </b>
                        <label>{languageJson.current_truck_count_txt}</label>
                      </span>
                    </div>

                    <div className="infoListDiv">
                      <span className="dynamicTxt txtColorGreen">
                        {getTerminalCurrentWaitTime(
                          singleTerminalData.today_turn_time !== null && singleTerminalData.today_turn_time !== "" ? singleTerminalData.today_turn_time : 0
                        )}
                      </span>
                      <span className="fixedTxt">
                        <b className="redBgColor">
                          <img src={TTT_listIcon3} alt="icon"></img>
                        </b>
                        <label>{languageJson.daily_average_TT_txt}</label>
                      </span>
                    </div>

                    <div className="infoListDiv">
                      <span className="dynamicTxt">
                        {singleTerminalData.today_volume !== null && singleTerminalData.today_volume !== "" ? singleTerminalData.today_volume : 0}
                      </span>
                      <span className="fixedTxt">
                        <b className="greenBgColor">
                          <img src={TTT_listIcon4} alt="icon"></img>
                        </b>
                        <label>{languageJson.daily_truck_count_txt}</label>
                      </span>
                    </div>

                    <div
                      id={`collapse-${singleTerminalData?.id}`}
                      data-parent="#accordion"
                      className="collapse showGraphMainDiv"
                    >
                      <div className="innerGraphDiv">
                        <h2>
                          {languageJson.current_turn_time_txt}{" "}
                          <span>
                            {" "}
                            {getTerminalCurrentWaitTime(
                              singleTerminalData?.today_turn_time
                            )}
                          </span>{" "}
                        </h2>
                        <span>
                          <TruckTimeChart />


                        </span>
                      </div>
                      <div className="innerGraphDiv">
                        <h2>
                          {languageJson.daily_turn_time_txt}{" "}
                          <span>
                            {" "}
                            {getTerminalCurrentWaitTime(
                              singleTerminalData?.today_turn_time
                            )}
                          </span>{" "}
                        </h2>
                        <span>
                          <TruckTimeChart />


                        </span>
                      </div>
                    </div>
                  </li>
                </>
              ) : null}
              {marineId !== 0 &&
                singleTerminalData &&
                singleTerminalData?.gates
                ? singleTerminalData?.gates.map((gate, index) => (
                  <>
                    <li>
                      <div className="titleDiv">
                        <span>
                          <img
                            src={singleTerminalData.terminal_logo !== null && singleTerminalData.terminal_logo !== " " ? singleTerminalData.terminal_logo : oaklandlogo}
                            onError={e => { e.currentTarget.src = oaklandlogo }}
                            className=""
                            alt="logo"
                          ></img>
                          <h2>
                            <span>({gate.gate_name})</span>{" "}
                          </h2>
                        </span>
                        <a
                          role="button"
                          aria-expanded="false"
                          className="arrowDDBtn collapsed"
                          data-toggle="collapse"
                          href={`#collapse-${index}`}
                        ></a>
                      </div>
                      <div className="infoListDiv">
                        <span className="dynamicTxt txtColorGreen">
                          {getTerminalCurrentWaitTime(
                            gate.current_street_wait_time !== null && gate.current_street_wait_time !== " " ? gate.current_street_wait_time : 0
                          )}
                        </span>
                        <span className="fixedTxt">
                          <b className="pinkBgColor">
                            <img src={TTT_listIcon1} alt="icon"></img>
                          </b>
                          <label>{languageJson.current_average_TT_txt}</label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt">
                          {gate.current_trucks_in_queue !== null && gate.current_trucks_in_queue !== "" ? gate.current_trucks_in_queue : 0}
                        </span>
                        <span className="fixedTxt">
                          <b className="blueBgColor">
                            <img src={TTT_listIcon2} alt="icon"></img>
                          </b>
                          <label>{languageJson.current_truck_count_txt}</label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt txtColorGreen">
                          {getTerminalCurrentWaitTime(
                            gate.today_turn_time !== null && gate.today_turn_time !== "" ? gate.today_turn_time : 0
                          )}
                        </span>
                        <span className="fixedTxt">
                          <b className="redBgColor">
                            <img src={TTT_listIcon3} alt="icon"></img>
                          </b>
                          <label>{languageJson.daily_average_TT_txt}</label>
                        </span>
                      </div>

                      <div className="infoListDiv">
                        <span className="dynamicTxt">
                          {gate.today_volume !== null && gate.today_volume !== "" ? gate.today_volume : 0}
                        </span>
                        <span className="fixedTxt">
                          <b className="greenBgColor">
                            <img src={TTT_listIcon4} alt="icon"></img>
                          </b>
                          <label>{languageJson.daily_truck_count_txt}</label>
                        </span>
                      </div>

                      <div
                        id={`collapse-${index}`}
                        data-parent="#accordion"
                        className="collapse showGraphMainDiv"
                      >
                        <div className="innerGraphDiv">
                          <h2>
                            {languageJson.current_turn_time_txt}: {" "}
                            <span>
                              {" "}
                              {getTerminalCurrentWaitTime(
                                gate?.today_turn_time
                              )}
                            </span>
                          </h2>
                          <span>
                            <TruckTimeChart currentWaitTime={gate?.today_turn_time} />

                          </span>
                        </div>
                        <div className="innerGraphDiv">
                          <h2>
                            {languageJson.daily_turn_time_txt}:{" "}
                            <span>
                              {getTerminalCurrentWaitTime(
                                gate?.today_turn_time
                              )}
                            </span>
                          </h2>
                          <span>
                            <TruckTimeChart />

                          </span>
                        </div>
                      </div>
                    </li>
                  </>
                ))
                : null}
            </ul>
          </div>
        </div>
      </div>
      <NoteInstruction />
      {/* <Findus /> */}
    </div>
  );
}

export default TruckTurnTime;
