import React from 'react';
import Modal from 'react-modal';
import { languageJson } from './StringVariable';
import SelectLanguageDisplay from 'src/utils/SelectLanguageDisplay';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modal.setAppElement('#root');

function OfflineModal({ display }) {
const languageJson1 = localStorage.getItem('token') ? SelectLanguageDisplay(localStorage.getItem('lang')) :languageJson

    return (
        <Modal 
            isOpen={display}
            style={customStyles}
            contentLabel="Network lost" 
        >
            <div>{languageJson1.lost_network_connection_msg}</div>
        </Modal>
    );
}

export default OfflineModal;