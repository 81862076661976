import { ActionTypes } from "../constants/Types";
const initialState = {
  isLoginLoading: false,
  isOtpSendLoading: false,
  isOtpVerifyLoading: false,
  isDriverRegisterLoading: false,
  isLmcRegisterLoading: false,
  isContactUsLoading: false,
  isforgotPasswordLoading: false,
  isChangePasswordLoading: false,
  isVasselAlertsDataLoading: false,
  isRailAlertsDataLoading: false,
  isAlertsAnnouncementsDataLoading: false,
  isMarineTerminalAlertsDataLoading: false,
  isResourceDataLoading: false,
  isUserDataLoading: false,
  isPortMessageLoading: false,
  isPortConstructionEventsLoading: false,
  isPortIncidentClosuresLoading: false,
  isRegionalMessageSignsLoading: false,
  isRegionalConstructionEventsLoading: false,
  isRegionalIncidentClosuresLoading: false,
  isTrafficAndCamerasAlertsDataLoading: false,
  isMarineTerminalListDataLoading: false,
  isSecurityAndSafetyAlertsDataLoading: false,
  isReservationLoading: false
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.START_LOGIN_LOADING:
      return { ...state, isLoginLoading: true }
    case ActionTypes.OTP_SEND_LOADING:
      return { ...state, isOtpSendLoading: true }
    case ActionTypes.DRIVER_REGISTER_LOADING:
      return { ...state, isDriverRegisterLoading: true }
    case ActionTypes.OTP_VERIFY_LOADING:
      return { ...state, isOtpVerifyLoading: true }
    case ActionTypes.LMC_REGISTER_LOADING:
      return { ...state, isLmcRegisterLoading: true }
    case ActionTypes.CONTACT_US_LOADING:
      return { ...state, isContactUsLoading: true }
    case ActionTypes.FORGOT_PASSWORD_LOADING:
      return { ...state, isforgotPasswordLoading: true }
    case ActionTypes.CHANGE_PASSWORD_LOADING:
      return { ...state, isChangePasswordLoading: true }
    case ActionTypes.VASSEL_ALERTS_DATA_LOADING:
      return { ...state, isVasselAlertsDataLoading: true }
    case ActionTypes.TRAFFIC_AND_CAMERAS_ALERTS_DATA_LOADING:
      return { ...state, isTrafficAndCamerasAlertsDataLoading: true }
    case ActionTypes.RAIL_CROSSING_ALERTS_DATA_LOADING:
      return { ...state, isRailAlertsDataLoading: true }
    case ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_LOADING:
      return { ...state, isAlertsAnnouncementsDataLoading: true }
    case ActionTypes.MARINE_TERMINAL_ALERTS_DATA_LOADING:
      return { ...state, isMarineTerminalAlertsDataLoading: true }
    case ActionTypes.RESOURCE_DATA_LOADING:
      return { ...state, isResourceDataLoading: true }
    case ActionTypes.USER_DETAIL_LOADING:
      return { ...state, isUserDataLoading: true };
    case ActionTypes.PORT_MESSAGE_LOADING:
      return { ...state, isPortMessageLoading: true };
    case ActionTypes.PORT_CONSTRUCTION_EVENTS_LOADING:
      return { ...state, isPortConstructionEventsLoading: true };
    case ActionTypes.PORT_INCIDENT_CLOSURES_LOADING:
      return { ...state, isPortIncidentClosuresLoading: true };
    case ActionTypes.REGIONAL_MESSAGE_SIGNS_LOADING:
      return { ...state, isRegionalMessageSignsLoading: true };
    case ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_LOADING:
      return { ...state, isRegionalConstructionEventsLoading: true };
    case ActionTypes.REGIONAL_INCIDENT_CLOSURES_LOADING:
      return { ...state, isRegionalIncidentClosuresLoading: true };
    case ActionTypes.MARINE_TERMINAL_LIST_DATA_LOADING:
      return { ...state, isMarineTerminalListDataLoading: true };
    case ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_LOADING:
      return { ...state, isSecurityAndSafetyAlertsDataLoading: true };
    case ActionTypes.RESERVATION_BOOKING_LOADING:
      return { ...state, isReservationLoading: true };

    case ActionTypes.STOP_LOADING:
      return initialState
    default:
      return state;
  }
}
