import React, { useEffect } from 'react';
import Header from 'src/Containers/Header';
import { languageJson } from "src/StringVariable";
import { Link } from "react-router-dom";
import { submitGAEvent } from 'src/utils/GAEvents';

export default function Disclaimer() {
    useEffect(() => {
        submitGAEvent("DISCLAIMER");
    }, []);

    return (
        <div>
            <Header />
            <div className="vo-bg breadCumNewDiv">
                <div className="container-fluid row py-4 ">
                    <div>
                        <p className="vo-menu">
                            {" "}
                            <Link to="/">{languageJson.home_txt}</Link>
                            <span className="text-dark"> / {languageJson.footerList.disclaimer}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container disclaimerMainDiv">
                <h2>Discrimination Complaint and Compliance Review/Grievance Procedure</h2>
                <h4><a href="https://www.portofoakland.com/wp-content/uploads/FINAL-PO-Discrimination-Complaint-Form-06.28.18.pdf">Discrimination Complaint Form</a></h4>
                <p className="linkBlueDiv"><a href="http://www.portofoakland.com/files/PDF/Discrimination%20Complaint%20Form%202%20-%20English.pdf">Click here to download the Discrimination Complaint Form</a></p>
                <h4>Discrimination Complaint and Compliance Review/Grievance Procedure</h4>
                <p>It is the policy of the Port to ensure full compliance with federal nondiscrimination laws in all programs and activities. The Port will not discriminate on the basis of race, color, national origin, religion, ancestry, ethnic group identification, creed, sex (including actual or perceived sexual orientation or gender identity), disability, mental disability, physical disability, medical condition, genetic information, marital status, veteran’s status, or age in any Port programs, services, or activities.
                </p>
                <p>
                    Title VI of the Civil Rights Act of 1964 (“Title VI”) prohibits discrimination on the basis of race, color, and national origin in programs and activities receiving federal financial assistance. The Americans with Disabilities Act (“ADA”) and Section 504 of the Rehabilitation Act (“Section 504”) prohibit discrimination on the basis of disability by state or local government agencies and recipients of federal financial assistance.
                </p>
                <p>
                    Discrimination includes, but is not limited to, excluding an individual from participation in or denying an individual the benefits of any program or activity receiving Federal financial assistance due to the individual’s race, color, national origin, sex, age, or disability.
                </p>
                <p>
                    The Port of Oakland (“Port”) does not exclude, deny benefits to, or otherwise discriminate against any person on the basis of race, color, religion, ancestry, ethnic group identification, national origin, creed, disability, mental disability, physical disability, medical condition, genetic information, marital status, age, veteran’s status, or sex (including actual or perceived sexual orientation or gender identity) in the administration of its programs or activities, including the Seaport, Oakland International Airport, and Commercial Real Estate programs, whether carried out by the Port directly, through a contractor, or any other entity with whom the Port arranges to carry out its programs and activities.
                </p>
                <p>
                    The Port is committed to taking reasonable steps to provide timely and meaningful access for Limited English Proficient Persons coming into contact with the Port’s programs, services, and activities.  The Port will provide free language assistance to such persons.  For more information on language assistance, contact
                </p>
                <p>
                    Amy Tharpe, Port of Oakland Civil Rights Compliance Coordinator<br></br>
                    Social Responsibility Division<br></br>
                    530 Water Street<br></br>
                    Oakland, CA 94607<br></br>
                    (510) 627-1302<br></br>
                    atharpe@portoakland.com
                </p>
                <p>
                    This Complaint and Compliance Review/Grievance Procedure is established so that any member of the public who believes he or she has been subjected to discrimination in the receipt of benefits and/or services from the Port on the basis of race, color, religion, ancestry, ethnic group identification, national origin, creed, disability, mental disability, physical disability, medical condition, genetic information, marital status, age, veteran’s status, or sex (including actual or perceived sexual orientation or gender identity) and wishes to file a complaint may do so following the outline below. In addition, the Port’s Civil Rights Compliance Coordinator may, on her own initiative, undertake compliance reviews to investigate compliance of Port departments with Title VI, the ADA, Section 504, and other federal and state civil rights laws in the absence of a complaint on a periodic basis. The Port is prohibited from retaliating against any member of the public who files a complaint under these procedures and any retaliation will be handled promptly if it occurs.
                </p>
                <p>
                    The complaint shall be in writing and contain information about the complainant and the alleged discrimination such as:
                </p>
                <p className="listContentDiv">
                    1. The name, address, and phone number of complainant;<br></br>
                    2. The name of the Port department and/or employee(s) against whom the complaint is filed;<br></br>
                    3. The location, date, and description of the alleged violation; and<br></br>
                    4. The signature of the complainant or his or her designee.<br></br>
                </p>
                <p> Please see the <b>TITLE VI/504/ADA and Related Statutes Discrimination Complaint Form</b> at the end of this Procedure.
                </p>
                <p>
                    If the complainant is unable to submit the complaint in writing, he or she may call the Port of Oakland Civil Rights Compliance Coordinator to submit a verbal complaint.
                </p>
                <p>
                    The complaint shall be submitted by the complainant or his or her designee as soon as possible but no later than 180 calendar days after the alleged violation to:
                </p>
                <p>
                    Amy Tharpe, Port of Oakland Civil Rights Compliance Coordinator<br></br>
                    Social Responsibility Division<br></br>
                    530 Water Street<br></br>
                    Oakland, CA 94607<br></br>
                    (510) 627-1302<br></br>
                    atharpe@portoakland.com<br></br>
                </p>
                <p>
                    If a complaint is submitted directly to a Port Department, office, or staff member, the Port Department, office, or staff member shall forward the complaint to the Port Civil Rights Compliance Coordinator within 5 calendar days.
                </p>
                <p>
                    Within 10 calendar days after receipt of the complaint:
                </p>
                <p className="listContentDiv">
                    1. the Port Civil Rights Compliance Coordinator will make a determination of whether the Port has jurisdiction over the complaint and send the complainant an acknowledgment letter informing her/him whether the complaint will be investigated. This letter will also inform the complainant of his/her right to file directly with the federal agency;
                    <br></br>
                    2. for complaints regarding the Oakland International Airport, the Port Civil Rights Compliance Coordinator will forward a copy of the complaint to the appropriate federal agency as required, along with a statement describing all actions taken to resolve the matter and the results thereof, within 15 days of receipt;
                    <br></br>
                    3. if the Port Civil Rights Compliance Coordinator finds jurisdiction, s/he will notify the Port Department that is the subject of the complaint and request a response to the complaint and will begin an investigation. The investigation may include interviews of the complainant, Port employees, contractors, subcontractors, subgrantees, and witnesses to the alleged discrimination, as well as review of any physical or written evidence.
                </p>
                <p>The Port Civil Rights Compliance Coordinator may attempt to conciliate and resolve the complaint through a mutually agreeable solution. Any such informal resolution must be signed by both the Port Department that is the subject of the complaint and the complainant. Absent extenuating circumstances, the Port Civil Rights Compliance Coordinator will complete her investigation and resolution efforts within 30 days after beginning the investigation.
                </p>
                <p>
                    An appropriate, prompt, and impartial investigation of any allegations filed under federal non-discrimination statutes will be conducted. A preponderance of the evidence standard will be applied during the analysis of the complaint.
                </p>
                <p>
                    When the Port Civil Rights Compliance Coordinator determines that discrimination has occurred, and an informal resolution is not reached, the Port Civil Rights Compliance Coordinator shall determine any necessary remedial actions and order the complained-of Port Department to implement the accepted recommendations.
                </p>
                <p>
                    Absent extenuating circumstances, the Port Civil Rights Compliance Coordinator will provide a written response to the complaint within 45 calendar days after beginning the investigation. The Coordinator will issue one of three letters:
                </p>
                <p className="listContentDiv">
                    1. a closure letter summarizing the allegations and stating that there was not a violation and that the case will be closed; or
                    <br></br>
                    2. a letter of resolution summarizing the allegations and describing the informal resolution mutually agreed to by the complainant and the Port Department about which the complaint was submitted; or
                    <br></br>
                    3. a letter of finding (“LOF”) summarizing the allegations and the investigation of the alleged complaint and explaining any remedial action to be taken by the Port.
                </p>
                <p>If the response does not satisfactorily resolve the issue, the complainant or the Port Department may appeal the decision to the Port’s Executive Director within 15 calendar days after receipt of the response from the Port Civil Rights Compliance Coordinator. Within 15 calendar days after receipt of the appeal, the Port Executive Director or his or her designee will meet with the complainant and the affected Port Department to discuss the complaint and possible resolutions. The Port Executive Director of his or her designee may also interview witnesses and review any physical or written evidence. Within 15 calendar days after the meeting with the complainant and Port Department, the Port Executive Director or his or her designee will respond in writing to the complainant, with a final resolution of the complaint.

                </p>
                <p>If at any time a delay is expected, the Port Civil Rights Compliance Coordinator will notify the complainant in writing of the reasons(s) for the delay and the expected date for a response.
                </p>
                <p>
                    The Port Civil Rights Compliance Coordinator shall maintain records of complaints received, informal resolutions, investigation findings, appeals, and appeal decisions. The Port Civil Rights Compliance Coordinator shall document actions taken to resolve each complaint, communicate complaint activity to the appropriate federal agency as required, and maintain copies of complaints and documentation of their resolution for a period of not less than two (2) years.
                </p>
                <p>
                    The Port Civil Rights Compliance Coordinator (through the Executive Director) shall furnish a report to the Board of Port Commissioners at least annually regarding the number, nature, and status of complaints.
                </p>
                <p>
                    These procedures do not deny the right of the complainant to file a complaint with state or federal agencies, or to pursue litigation for complaints alleging discrimination, intimidation, or retaliation of any kind that is prohibited by law.
                </p>
            </div>
        </div>
    )
}
