import { ActionTypes } from "../../constants/Types";
const initialState = {};

export const districtListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_DISTRICT_LIST_URL:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}
