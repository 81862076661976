import React, { useState, useEffect } from "react";
import OtpModal from "../../Containers/otp/OtpModal";
import Header from "../../Containers/Header";
import login_image from "../../assets/Loginbanner.png";
import "./UserLogin.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { loginSchema } from "../../utils/Validations"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { userLogin, otpSend, clearLoadingMessageStatus } from "../../redux/actions/UserAccount"
import Backdrop from "../../Containers/modal/BackDrop";
import Modal from "../../Containers/modal/Modal";
import { languageJson } from '../../StringVariable';

function UserLogin() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loadingState, messageState, statusCodeState } = useSelector(state => state);
    const formOptions = { resolver: yupResolver(loginSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const [passwordShown, setPasswordShown] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [emailId, setEmail] = useState('');
    const [onModal, setOnModal] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const handleSubmitLogin = (loginData, e) => {
        if (!onModal) {
            const { email, password } = loginData;
            dispatch(userLogin({ email, password }, history));
            setEmail(email)
        }
    };

    const closeModalHandler = () => {
        dispatch(clearLoadingMessageStatus())
        setOnModal(false);
        setEmail('')
    }
    const closeModal = () => {
        dispatch(clearLoadingMessageStatus())
        setOnModal(false);
        setEmail('')
        setShowOTP(false)
    }

    useEffect(() => {
        if (statusCodeState.loginStatusCode === 210) {
            setShowOTP(true);
        }
        if (statusCodeState.loginStatusCode !== null) {
            setOnModal(true);
        }
    }, [statusCodeState.loginStatusCode])

    const getEmailOTP = () => {
        const emailData = { 'email': emailId }
        dispatch(otpSend(emailData))
    }

    return (
        <div>
            <Header />
            <div className="headerBannerMainDiv"><img className="vessel_img" src={login_image} alt="vessel" /></div>
            <div className="center-underline text-center" id="loginpage">
                <h2 className=" loginhead  pt-5">{languageJson.user_txt} {languageJson.login_txt}</h2>
            </div>
            <div className="py-5">
                <p className="text-center logindesc">
                    {languageJson.userlogin_Descp_txt}
                </p>
            </div>
            <section className="loginsection pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6 form-section">
                            <form className="py-5 px-5" onSubmit={handleSubmit(handleSubmitLogin)} autoComplete="off">
                                <div className="form-group row py-4 px-5">
                                    <label className="col-sm-2 col-form-label login-label">
                                        {languageJson.email_txt}
                                    </label>
                                    <div className="col-sm-10">
                                        <input className={`form-control`}
                                            name="email" id="email" type="text"
                                            placeholder={languageJson.enter_email_address_txt}
                                            {...register('email')}
                                        />
                                        <p className="text-danger">{errors.email?.message}</p>
                                    </div>
                                </div>
                                <div className="form-group row px-5" id="password-wrapper">
                                    <label name="password" className="col-sm-2 col-form-label login-labels">
                                        {languageJson.password_txt}
                                    </label>
                                    <div className="col-sm-10 emaildiv">
                                        <input className={`form-control`}
                                            name="password"
                                            id="password"
                                            type={passwordShown ? "text" : "password"}
                                            autoComplete="new-password"
                                            placeholder={languageJson.enter_pwd_txt}
                                            {...register('password')}
                                        />
                                        <span
                                            id="togglePassword"
                                            toggle="#password-field"
                                            className={`${passwordShown
                                                ? "fa fa-fw fa-eye-slash"
                                                : "fa fa-fw fa-eye"
                                                } field-icon toggle-password eyeiconspan`}
                                            onClick={togglePassword}
                                        ></span>
                                        <p className="text-danger">{errors.password?.message}</p>
                                    </div>
                                </div>
                                <div className="forgotBtn d-flex justify-content-end align-items-center mb-4 px-5">
                                    <Link to="/forgotPassword"><span className="darkblue text-decoration-underline cursor-pointer">{languageJson.forgot_password_txt}</span></Link>
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button type="submit" className="btn btn-primary login-btn btn-block" disabled={loadingState.isLoginLoading ? true : false}>
                                        {loadingState.isLoginLoading ? languageJson.processing_txt : languageJson.userAuth.login}
                                    </button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center my-4">
                                    <p className="text-center  mx-3 mb-0 text-dark">
                                        {languageJson.dont_account_txt} {" "}
                                        <Link to="/selectusertype#selectusertype">
                                            {" "}
                                            <span className="darkblue text-decoration-underline cursor-pointer">
                                                {languageJson.userAuth.register}
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                    {statusCodeState.loginStatusCode != null && statusCodeState.loginStatusCode !== 210 && <React.Fragment>
                        <Backdrop onClick={() => closeModalHandler()} />
                        <Modal text={messageState.loginMessage}
                            btnColor="modal-btn-warning"
                            icon="fa fa-exclamation-circle"
                            status="bg-warning"
                            onClose={closeModalHandler}
                        />
                    </React.Fragment>
                    }
                    {showOTP ? (
                        <React.Fragment>
                            <Backdrop onClick={() => closeModal()} />
                            <OtpModal text={messageState.loginMessage ? messageState.loginMessage : languageJson.enter_OTP_email_txt} resendOTP={() => getEmailOTP()} email={emailId} status={'bg-custom-success'} btnColor={'modal-btn-success'} icon={'fa fa-check-circle'} onClose={() => closeModal()} />
                        </React.Fragment>
                    ) : null
                    }
                </div>
            </section>
        </div>
    );
}

export default UserLogin;
