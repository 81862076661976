import { ActionTypes } from "../constants/Types";
const initialState = {};

export const publicUrlReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PUBLIC_URL_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}
