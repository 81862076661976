import React, { useEffect } from "react";
import DataNotFound from "../DataNotFound/DataNotFound";
import { useSelector, useDispatch } from "react-redux";
import { DateTimeFormate } from "src/utils/DataFormate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NotificationPopup } from "src/utils/Notifications";
import { securityAndSafetyAlertsData } from "src/redux/actions/AlertDataAction";
import SpinerLoader from "src/utils/SpinerLoader";
import { languageJson } from "src/StringVariable";

const SecuritySafetyAlerts = () => {
  let dispatch = useDispatch();
  let { alertDataState, loadingState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(securityAndSafetyAlertsData());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showTooltip = () => {
    const obj = {
      customcode: 200,
      message: languageJson.text_copied_txt
    }
    NotificationPopup(obj.customcode, obj.message);
  };

  if (loadingState.isSecurityAndSafetyAlertsDataLoading) {
    return <div className="mt-4 mb-4"><SpinerLoader /></div>;
  } else if (!loadingState.isSecurityAndSafetyAlertsDataLoading) {
    return (
      <div>
        <div className="card-body ">
          <div className="flex flex-column mb-5 mt-4 faq-section">
            <div className="row">
              <div className="col-md-12">
                <div id="accordion">
                  {alertDataState &&
                    alertDataState.securityAndSafetyAlertsData != null &&
                    alertDataState.securityAndSafetyAlertsData.length > 0 ? (
                    alertDataState.securityAndSafetyAlertsData.map(
                      (hiddenText, index) => {
                        return hiddenText.IncidentList.length > 0 &&
                          <div className="card" key={index}>
                            <div
                              className="card-header second"
                              id={`heading-11`}
                            >
                              <h5 className="mb-0">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href={`#collapse-11${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse-11${index}`}
                                >
                                  {hiddenText.category || "-"}
                                </a>
                              </h5>
                            </div>
                            <div
                              id={`collapse-11${index}`}
                              className="inner-acc collapse"
                              data-parent="#accordion"
                              aria-labelledby={`heading-11 ${index}`}
                            >
                              <div className="card-body">
                                {hiddenText.IncidentList.length ? (
                                  <ul className="newAlertVesselDiv alertInnerDivList removedOverlayTrigger">
                                    {hiddenText.IncidentList.map(
                                      (alert, index1) => {
                                        return (
                                          <li key={alert.id}>
                                            <span>
                                              <p>
                                                {alert.Description || "-"} -{" "}
                                                {alert.Location || "-"}
                                              </p>
                                              <label>
                                                {DateTimeFormate(
                                                  alert.Lastupdated
                                                ) || "-"}
                                              </label>
                                            </span>
                                            <CopyToClipboard
                                              text={`${alert.Description} - ${alert.Location}`}
                                              onCopy={() => showTooltip(index1)}
                                            >
                                              <div className="buttonDiv"><button title={languageJson.copy_button_txt}><i className="fa fa-clone" aria-hidden="true"></i><b>{languageJson.copy_button_txt}</b></button></div>
                                            </CopyToClipboard>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  <DataNotFound />
                                )}
                              </div>
                            </div>
                          </div>
                      }
                    )
                  ) : (
                    <DataNotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SecuritySafetyAlerts;
