import { ActionTypes } from "../constants/Types";
const initialState = [];
export const resourceDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.RESOURCE_DATA_SUCCESS:
            return action.payload
        default:
            return state;
    }
}
