/* eslint-disable no-unused-vars*/
/* eslint-disable no-lone-blocks*/
/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../../Containers/Header";
 import { Link } from "react-router-dom";
import trafficCamera_image from "../../assets/road_construction.png";
import weather_image from "../../assets/weather-image.png";
import tollsicon from "../../assets/tollsblue.png";
import openWeatherLogoImage from "../../assets/openweatherLogo.png";
import mapNavIcon from "../../assets/mapNavIcon.png";
import weatherCloudImage from "../../assets/weatherCloudImage.png";
import percentDropImage from "../../assets/percentDropImage.png";
import airBlowImage from "../../assets/airBlowImage.png";
import sunRiseImage from "../../assets/sunRiseImage.png";
import sunSetImage from "../../assets/sunSetImage.png";
import moment from "moment";
import { STATES_LIST } from "../../url/Url";
import "./TrafficCameras.css";
import CamraAndTrafficOnMap from "./CamraAndTrafficOnMap";
import { WEATHER_API_KEY } from "../../url/Url";
 import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "src/firebaseConfig";
import { languageJson } from "src/StringVariable";
import { submitGAEvent } from "src/utils/GAEvents";
import Backdrop from "src/Containers/modal/BackDrop";
import Modal from "src/Containers/modal/Modal";

import TollPointRoad from "../Resources/TollPointOnRoad";
 import portimg1 from "../../assets/Weather/1.jpg";
import portimg2 from "../../assets/Weather/2.jpg";
import portimg3 from "../../assets/Weather/3.jpg";

import portimg11 from "../../assets/TrafficInformation/1.jpg";
import portimg22 from "../../assets/TrafficInformation/2.jpg";
import portimg33 from "../../assets/TrafficInformation/3.jpg";
import DataNotFound from "../DataNotFound/DataNotFound";

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
moment.suppressDeprecationWarnings = true;

function TrafficCameras(props) {
  const [vesselOperation, setVesselOperations] = useState(true);
  const [VesselLocations, setVesselLocations] = useState(false);
  const [ViewTolls, setViewTolls] = useState(false);
  const [mainWeatherReport, setMainWeatherReport] = useState(null);
  const [weatherList, setWeatherList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityName, setCityName] = useState("94607");
  const [filteredCities, setFilteredCities] = useState([{}]);
  const [showModal, setShowModal] = useState(false);

  const onVesselLocations = () => {
    submitGAEvent("WEATHER")
    setVesselLocations(true);
    setVesselOperations(false);
    setViewTolls(false);
  };
  const locationSearch = useRef();

  const onVesselOperation = () => {
    submitGAEvent("ROAD CONDITIONS AND CAMERAS")
    setVesselLocations(false);
    setVesselOperations(true);
    setViewTolls(false);
  };
  const onAlerts = () => {
    submitGAEvent("TOLLS")
    setVesselLocations(false);
    setVesselOperations(false);
    setViewTolls(true);
  };

  const getTheDataFromTheStateList = (cityName) => {
    // debugger;
    if (cityList.length > 0) {
      const afterFirstLetterCapital =
        cityName.charAt(0).toUpperCase() + cityName.slice(1);
      let filterMyCities = [];
      cityList.filter((state) => {
        if (state?.cities?.length) {
          let { cities } = state;
          let { name } = state;
          cities.map((city) => {
            if (city.name.startsWith(afterFirstLetterCapital)) {
              filterMyCities.push({ city, name });
            }
            return city
          });
        }
        return state
      });
      setFilteredCities(filterMyCities);
    }


  };

  const handleChange = (e) => {
    if (e.target.value.length >= 3) {
      getTheDataFromTheStateList(e.target.value);
    }
    if (e.target.value <= 0) {
      setCityName("94607")
      setFilteredCities([{}]);
    }
    locationSearch.current.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        setCityName(locationSearch.current.value);
        setFilteredCities([{}]);

      }
    });
  };

  const getTheWeatherList = async () => {
    const API = `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&mode=json&units=imperial&appid=${WEATHER_API_KEY}`;
    try {
      await axios.get(API).then((res) => {
       const { list } = res.data;
        setWeatherList(list);
        setMainWeatherReport(res.data)
      });
    } catch (err) {
      setWeatherList([]);
      setMainWeatherReport(null)
     }
  };

  const getTheCityList = async () => {
    try {
      await axios.get(STATES_LIST).then((res) => {
        setCityList(res.data[2].states);
      });
    } catch (err) { }
  };

  {/* convert temp celsius to feranite */ }
  const getTheTempInFarh = (kel) => {
    const decreaseValue = 273.15;

    const farh = ((parseInt(kel) - decreaseValue) * 9) / 5 + 32;
    return Math.round(farh);
  };

  const getPerfectTime = (unixTime, timezone) => {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unixTime * 1000 + timezone * 1000);
    date = date.toUTCString();
    let hours = date.toString().slice(17, 19);
    let mins = date.toString().slice(20, 22);
    const AmOrPm = parseInt(hours) >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;

    return `${hours}:${mins} ${AmOrPm}`;
  };
  const getDateAndTime = (unixTime) => {
    var date = new Date(unixTime * 1000 + mainWeatherReport?.city?.timezone * 1000);

    return date.toString().slice(0, 16);
  };

  const getTheDay = (unixTime, timezone) => {
    var datenew = new Date(unixTime * 1000 + timezone * 1000).toUTCString();
    let day = datenew.toString().slice(0, 3);
    switch (day) {
      case "Sun":
        return "Sunday";
      case "Mon":
        return "Monday";
      case "Tue":
        return "Tuesday";
      case "Wed":
        return "Wednesday";
      case "Thu":
        return "Thursday";
      case "Fri":
        return "Friday";
      case "Sat":
        return "Saturday";
      default:
        return;
    }
  };

  const getTheTimeIn = (time) => {
    if (time) {
      const customDate = new Date(time);
      let hours = customDate.getHours();
      const AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:0${customDate.getMinutes()} ${AmOrPm}`;
    }
  };
  const handleCity = (city) => {
    let cityCliked = city.target.innerHTML.split(",")[0];
    setCityName(cityCliked);
    setFilteredCities([]);
    locationSearch.current.value = "";
  };

  useEffect(() => {
    async function fetchData() {
      await getTheWeatherList();
      if (props.location.state === "weather") {
        onVesselLocations();
      }
      await getTheCityList()
    }
    fetchData();

  }, [props.location, cityName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    logEvent(analytics, "Traffic Camera_view");
  }, []);

  /**
   * @description Method called when click on close modal
   */
  const closeModal = () => {
    setCityName("94607")
    setFilteredCities([{}]);
    setShowModal(false);
    locationSearch.current.value = "";
  };


  return (
    <div>
      <Header />
      {props.location.state === "weather" 
      ? <>
        <div className="image-row-banner">
         <img className="banner_img" src={portimg1} alt=""/>
         <img className="banner_img" src={portimg3} alt=""/> 
         <img className="banner_img" src={portimg2} alt=""/>      
     </div>
      </>
        
      :<>
        <div className="image-row-banner">
          <img className="banner_img" src={portimg11} alt=""/>
          <img className="banner_img" src={portimg33} alt=""/> 
          <img className="banner_img" src={portimg22} alt=""/>      
        </div>
      </>}
      {/* <div className="headerBannerMainDiv">
        {" "}
        <img className="vessel_img" src={trafficCameraBanner} alt="vessel" />
      </div> */}

      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.Traffic_Information_txt}</span>
            </p>
          </div>
        </div>
      </div>
      {/* #0d6efd */}
      <div className="container vesselMainPage trafficCameraMainDiv newTrafficCameraTabMainDiv">
        <div className="row container-fluid py-5 tabVesselDiv">
          <div className="col-4 center-head-underline titleTabOption">
            <a
              onClick={() => {
                onVesselOperation();
              }}
              className={`text-decoration-none ${vesselOperation
                ? "center-head-underline-clr vo-headtxt py-2 "
                : ""
                } cursor-pointer vesselOptDiv`}
            >
              <h2
                className={`${vesselOperation ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={trafficCamera_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b> {languageJson.Road_Conditions_txt} </b>
              </h2>{" "}
            </a>
          </div>

          <div className="col-4 titleTabOption">
            <a
              onClick={() => {
                onAlerts();
              }}
              className={`text-decoration-none   ${ViewTolls
                ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } text-dark cursor-pointer alertTxtDiv`}
            >
              <h2 className={`${ViewTolls ? "text-primary" : "text-dark"}`}>
                <span>
                  <img
                    src={tollsicon}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.tolls_txt}</b>
              </h2>{" "}
            </a>
          </div>



          <div className="col-4 titleTabOption">
            <a
              onClick={() => {
                onVesselLocations();
              }}
              className={`text-decoration-none text-dark ${VesselLocations
                ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } cursor-pointer`}
            >
              <h2
                className={`${VesselLocations ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={weather_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.Weather_forecast_txt}</b>
              </h2>{" "}
            </a>
          </div>
          {/* <div className="col-3 titleTabOption">
            <a
              onClick={() => {
                onAlerts();
              }}
              className={`text-decoration-none   ${VesselAlerts
                ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                : ""
                } text-dark cursor-pointer alertTxtDiv`}
            >
              <h2 className={`${VesselAlerts ? "text-primary" : "text-dark"}`}>
                <span>
                  <img
                    src={bluealarm}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.alerts_txt}</b>
              </h2>{" "}
            </a>
          </div> */}
        </div>

        {/* <Switch> */}
        {/* <Route path="/vesselOperation/" exact> */}
        {vesselOperation && vesselOperation ? (
          <div className="container-fluid vesselTabOptionDiv trafficCameraDiv p-0">
            <div className="trafficCameraInnerDiv">
              <CamraAndTrafficOnMap />

            </div>
          </div>
        ) : null}

        {VesselLocations && VesselLocations ? (
          <div className="vesselMapTab weatherDetailDiv border-0 ">
            <div className="weatherInnerDiv human-resources-section border-0  ">
              <div className={`bgOverlay ${mainWeatherReport !== null ? "" : "weatherOpacityClass"
                    }`}></div>
              <div className="searchInputDiv">
                <i className="fa fa-search"></i>
                <input
                  type="text"
                  onChange={handleChange}
                  ref={locationSearch}
                  placeholder={languageJson.search_using_loaction_name}
                  disabled={mainWeatherReport !== null ? "":"true"}
                ></input>
              </div>
              <div>
                <div
                  className={`wetherresults ${filteredCities.length > 1 ? "" : "d-none"
                    } ${filteredCities.length > 8 ? "overflowCss" : ""
                  }`}
                >
                  {filteredCities &&
                    filteredCities.map((city, index) => (
                      <p
                        key={index}
                        onClick={(city) => {
                          handleCity(city);
                        }}
                      >
                        {" "}
                        {city?.city?.name}, <span>{city.name}</span>
                      </p>
                    ))}
                </div>
              </div>
              {mainWeatherReport !== null ? <>
                <div className="weatherTitleDiv">
                  <span>
                    <img src={mapNavIcon} alt="icon"></img>
                    <b>
                      {mainWeatherReport?.city?.name}, {mainWeatherReport?.city?.country}
                    </b>
                    <b className="timeUpdateTxt">
                      {languageJson.last_updated_value_txt}
                      <br /> {getDateAndTime(mainWeatherReport?.list[0]?.dt)}
                    </b>
                    {/* <b className="timeUpdateTxt">Updated on {moment.unix(mainWeatherReport.dt).format("MM/DD/YYYY hh:mm")}</b> */}
                  </span>
                </div>
                <div className="weatherTempInfoDiv">
                  <h1>
                    {/* 58 */}
                    {Math.round(mainWeatherReport?.list[0]?.main?.temp)}
                    {/* (276K − 273.15) × 9/5 + 32 */}
                    <span>&#176;</span>
                    <b>F</b>
                    <img
                      src={`https://openweathermap.org/img/wn/${mainWeatherReport?.list[0].weather[0].icon}@2x.png`}
                      alt="icon"
                    ></img>
                  </h1>
                  <div className="currentWeatherStatus">
                    <span>
                      <img src={percentDropImage} alt="percentDrop"></img>
                      <b>{mainWeatherReport?.list[0]?.main?.humidity}% {languageJson.humidity_txt}</b>
                    </span>
                    <span>
                      <img src={weatherCloudImage} alt="weatherCloud"></img>
                      <b className="textCapitalize">
                        {mainWeatherReport?.list[0]?.weather[0]?.description}
                      </b>
                    </span>
                    <span>
                      <img src={airBlowImage} style={{ transform: `rotate(-${mainWeatherReport?.list[0]?.wind?.deg}deg)` }} alt="airBlow"></img>
                      <b>{mainWeatherReport?.list[0]?.wind?.speed} mph</b>
                    </span>
                  </div>
                </div>
                <div className="todaySunStatus">
                  <ul>
                    <li>
                      <img src={sunRiseImage} alt="sunRise"></img>
                      <b>
                        {" "}
                        {moment().format("dddd")} <br />{" "}
                        <span className="textUpperCase">
                          {getPerfectTime(
                            mainWeatherReport?.city?.sunrise,
                            mainWeatherReport?.city?.timezone
                          )}
                        </span>
                      </b>
                      {/* <b>{moment.utc(mainWeatherReport?.sys?.sunrise).toDate()}</b> */}
                    </li>
                    <li>
                      <img src={sunSetImage} alt="sunSet"></img>
                      {/* sunset */}
                      <b>
                        {moment().format("dddd")} <br />{" "}
                        <span className="textUpperCase">
                          {getPerfectTime(
                            mainWeatherReport?.city?.sunset,
                            mainWeatherReport?.city?.timezone
                          )}
                        </span>
                      </b>
                    </li>
                  </ul>
                </div>
              </> : <React.Fragment>
                <Backdrop onClick={() => setShowModal(true)} />
                <Modal
                  text={languageJson.city_not_found}
                  btnColor={"modal-btn-warning"}
                  icon={"fa fa-exclamation-circle"}
                  status={"bg-warning"}
                  onClose={() => closeModal()}
                />
               </React.Fragment>}
            </div>

            {weatherList && weatherList.length > 0  && <React.Fragment>
            <div className="weatherForecastDailyDiv">
              <h1>
                <img src={weather_image} alt="weather"></img>{languageJson.Weather_forecast_txt}
              </h1>
              <ul>
                {
                  weatherList.map((list) => (
                    <li key={list.dt}>
                      <div>
                        <span>
                          <b className="dayTxt">
                            {getTheDay(list.dt, mainWeatherReport?.city?.timezone)}

                          </b>
                          <b className="dateTxt" style={{ textTransform: "uppercase" }}>
                            {getPerfectTime(
                              list.dt,
                              mainWeatherReport?.city?.timezone
                            )}
                            {/* {getTheTimeIn(list.dt_txt)} */}
                          </b>
                        </span>
                        <span>
                          <img
                            className="imgIcon"
                            alt="icon"
                            src={`https://openweathermap.org/img/wn/${list.weather[0].icon}@2x.png`}
                          />
                        </span>
                        <span>
                          <b className="highTempTxt">
                            {Math.round(list?.main?.temp_max)}&#176;
                          </b>
                          <b className="lowTempTxt">
                            <i>/</i>
                            {Math.round(list?.main?.temp_min)}&#176;
                            {/* temp_min */}
                          </b>
                        </span>
                        <span>
                          <b className="txtDescp">
                            {list.weather[0].description}
                          </b>
                        </span>
                      </div>
                    </li>
                  ))
                  }
              </ul> 
            </div>
            <div className="openWeatherTxtDiv">
            <span>{languageJson.Data_Provided_in_part_by_OpenWeather_txt} </span>
            <img src={openWeatherLogoImage} alt="logo"></img>
            </div>

            </React.Fragment>
            
           }
            
           
          </div>
        ) : null}


        {ViewTolls && <TollPointRoad />}

      </div>
    </div>
  );
}

export default TrafficCameras;
