import React, { useState, useEffect } from 'react';
import { languageJson } from '../../StringVariable';
import { useSelector } from "react-redux";
import LiveStream from "../../utils/LiveStream";
import {
  FAQFILE_MARINE_TERMINAL
} from "../../url/Url";
export default function NoteInstructionFull() {
  let singleUrls = useSelector(state => state.publicUrlState);
  const [userCondition, setUserCondition] = useState(null)
  useEffect(() => {
    if (singleUrls && singleUrls.singleUrls && singleUrls.singleUrls.length > 0) {
      let userCondition = singleUrls.singleUrls.find(({ url_id }) => url_id === 'faq_url');
      setUserCondition(userCondition ? userCondition.url_link : FAQFILE_MARINE_TERMINAL)
    }
  }, [singleUrls]);
  return (
    <div>
      <div className="TTTNoteDiv">
        <div className="container ">
          <div className="row mt-2 mb-2">
            <p className="note__heading text-danger terminalNoticeDespDiv"><LiveStream /> {languageJson.Note_txt}:</p>
            <ul className="note__unorderlist">
            <li>
                <span className="note__title">
                  {languageJson.current_waiting_time_txt}:
                </span>{" "}
                {languageJson.current_waiting_time_desp_txt}
              </li>
              <li>
                <span className="note__title">
                  {languageJson.Average_Today_txt}:
                </span>{" "}
                {languageJson.Average_Today_desp_txt}
              </li>

              <li>
                <span className="note__title">
                  {" "}
                  {languageJson.Street_Wait_Time_txt}:{" "}
                </span>
                {languageJson.Street_Wait_Time_desp_txt}
              </li>
              <li>
                <span className="note__title">
                  {" "}
                  {languageJson.Terminal_IngateOutgate_txt}:
                </span>{" "}
                {languageJson.Terminal_Turn_Time_desp_txt}
              </li>
              <li>
                <span className="note__title">
                  {" "}
                  {languageJson.Street_Queue_Length_txt}:
                </span>
                {languageJson.Street_Queue_Length_desp_txt}
              </li>
              <li>
                <span className="note__title">
                  {" "}
                  {languageJson.Trucks_in_Queue_txt}:
                </span>
                {languageJson.Trucks_in_Queue_desp_txt}
              </li>
              <li>
                <span className="note__title">
                  {" "}
                  {languageJson.Berths_txt}:
                </span>
                {languageJson.Berths_desp_txt}
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={userCondition}
                >
                  {languageJson.Truck_Turn_Time_FAQs_txt}
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
