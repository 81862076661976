import { ActionTypes } from "../constants/Types";
const initialState = {
  vasselAlertsData: null,
  railAlertsData: null,
  marineAlertsData: null,
  alertAnnouncementsData:null,
  portMessageSigns: null,
  portConstructionEvents: null,
  portIncidentClosures: null,
  regionalMessageSigns: null,
  regionalConstructionEvents: null,
  regionalIncidentClosures: null,
  trafficAndCamerasAlertsData: null,
  securityAndSafetyAlertsData:null,
  districtMsgSign:null 
};

export const alertDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VESSEL_ALERTS_DATA_SUCCESS:
      return { ...state, vasselAlertsData: action.payload };
    case ActionTypes.RAIL_CROSSING_ALERTS_DATA_SUCCESS:
      return { ...state, railAlertsData: action.payload };
      case ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_SUCCESS:
      return { ...state, alertAnnouncementsData: action.payload };
    case ActionTypes.MARINE_TERMINAL_ALERTS_DATA_SUCCESS:
      return { ...state, marineAlertsData: [...action.payload] };
    case ActionTypes.TRAFFIC_AND_CAMERAS_DATA_SUCCESS:
      return { ...state, trafficAndCamerasAlertsData: [...action.payload] };
      case ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_SUCCESS:
        return { ...state, securityAndSafetyAlertsData: [...action.payload] };
    case ActionTypes.PORT_MESSAGE_SUCCESS:
      return { ...state, portMessageSigns: [...action.payload] };
    case ActionTypes.PORT_CONSTRUCTION_EVENTS_SUCCESS:
      return { ...state, portConstructionEvents: [...action.payload] };
    case ActionTypes.PORT_INCIDENT_CLOSURES_SUCCESS:
      return { ...state, portIncidentClosures: [...action.payload] };
    case ActionTypes.REGIONAL_MESSAGE_SIGNS_SUCCESS:
      return { ...state, regionalMessageSigns: [...action.payload] };
    case ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_SUCCESS:
      return { ...state, regionalConstructionEvents: [...action.payload] };
    case ActionTypes.REGIONAL_INCIDENT_COUSURES_SUCCESS:
      return { ...state, regionalIncidentClosures: [...action.payload] };
      case ActionTypes.DISTRICT_MESSAGE_SIGNS_SUCCESS:
      return { ...state, districtMsgSign: [...action.payload] };

    default:
      return state;
  }
};
