import React, { useState } from "react";
import { otherLMCSchema } from "../../utils/Validations";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import editIcon from "../../assets/edit-icon@2x.webp";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { languageJson } from '../../StringVariable';

const OtherInformation = (props) => {
    let { loadingState } = useSelector(state => state);
    let { setShowPrimaryform, setShowOtherform, setFormData, formData, showOtherButton, setShowOtherButton, setShowPrimaryButton, setImage, image, handleSubmitLMC } = props;
    const formOptions = { resolver: yupResolver(otherLMCSchema), defaultValues: { ...formData } };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const [showImageError, setImageError] = useState(false);
    const { errors } = formState;
    const documentsList = [
        {
            label: languageJson.USDOTNumber_Motor_txt,
            value: "U.S. Dept. of Transportation (DOT) Number/Motor",
            isChecked: false,
            name: "first",
            id: 1,
        },
        {
            label: languageJson.CarrierNumber_SC_Motor_txt,
            value: "Carrier (MC) Number/State of California Motor",
            isChecked: false,
            name: "second",
            id: 2,
        },
        {
            label: languageJson.Carrier_MC_Number_txt,
            value: "Carrier (MC) Number",
            isChecked: false,
            name: "third",
            id: 3,
        }
    ]

    const onReadOnlyClick = () => {
        setShowPrimaryButton(true)
        setShowOtherButton(true)
        setShowPrimaryform(false)
        setShowOtherform(true)
    };

    const handleSubmitRegister = async (registerData) => {
        if (image.preview === '') {
            setImageError(true)
        } else {
            setFormData({ ...formData, ...registerData })
            setShowPrimaryform(true)
            setShowOtherform(true)
            setShowPrimaryButton(false)
            setShowOtherButton(false)
        }
    };

    const handleImageChange = (e) => {
        if (e) {

            if (e.target.files.length) {
                setImage({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                });
                setImageError(false)
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };
    return (
        <form onSubmit={handleSubmit(handleSubmitRegister)} autoComplete="off">
            <div className="otherInformationForm ">
                <div className="headingTxt d-flex justify-content-between">
                    <h1>{languageJson.OtherInformation_txt}</h1>
                    {!showOtherButton && (
                        <img
                            className="float-end lmc__edit__icon cursor-pointer"
                            onClick={() => {
                                onReadOnlyClick();
                            }}
                            src={editIcon}
                            alt="Edit-icon"
                        />
                    )}
                </div>

                <div className="formMainDiv">
                    <div className="formFieldDiv">
                        <label>{languageJson.ContactPersonName_txt}</label>
                        <span>
                            <input readOnly={!showOtherButton} className={!showOtherButton ? "input__readonly" : ""}
                                name="contact_person_name" id="contact_person_name" type="text"
                                placeholder={languageJson.enter_Name_txt}
                                maxLength="50"
                                {...register('contact_person_name')}
                            />
                            <p className="text-danger">{errors.contact_person_name?.message}</p>
                        </span>
                    </div>
                    <div className="formFieldDiv">
                        <label>{languageJson.ContactPersonPhone_txt}</label>
                        <span>
                            <InputMask
                                readOnly={!showOtherButton}
                                className={!showOtherButton ? "input__readonly" : ""}
                                name="contact_person_number"
                                id="contact_person_number"
                                type="text"
                                defaultValue={formData.contact_person_number ? formData.contact_person_number : ''}
                                placeholder={languageJson.mobile_number_error_txt}
                                mask="(999) 999-9999"
                                maskChar=" "
                                onKeyDown={handleKeyDown}
                                {...register('contact_person_number')}
                            />
                            <p className="text-danger">{errors.contact_person_number?.message}</p>
                        </span>
                    </div>
                    <div className="formFieldDiv">
                        <label>{languageJson.USDOT_Number_txt} </label>
                        <span>
                            <input readOnly={!showOtherButton} className={!showOtherButton ? "input__readonly" : ""}
                                name="dot_number" id="dot_number" type="text" maxLength="25"
                                placeholder={languageJson.EnterDOT_Number_txt}
                                {...register('dot_number')}
                                onKeyDown={handleKeyDown}
                            />
                            <p className="text-danger">{errors.dot_number?.message}</p>
                        </span>
                    </div>
                    <div className="formFieldDiv">
                        <label>{languageJson.USMC_Number_txt}</label>
                        <span>
                            <input readOnly={!showOtherButton} className={!showOtherButton ? "input__readonly" : ""}
                                name="us_mc_number" id="us_mc_number" type="text" maxLength="25"
                                placeholder={languageJson.EnterCarrierNumber_txt}
                                onKeyDown={handleKeyDown}
                                {...register('us_mc_number')}
                            />
                            <p className="text-danger">{errors.us_mc_number?.message}</p>
                        </span>
                    </div>
                    <div className="formFieldDiv">
                        <label>{languageJson.StateOfCalifornia_MC_txt}</label>
                        <span>
                            <input readOnly={!showOtherButton} className={!showOtherButton ? "input__readonly" : ""}
                                name="state_mc_number" id="state_mc_number" type="text" maxLength="25"
                                placeholder={languageJson.EnterMCNumber_txt}
                                onKeyDown={handleKeyDown}
                                {...register('state_mc_number')}
                            />
                            <p className="text-danger">{errors.state_mc_number?.message}</p>
                        </span>
                    </div>
                    <div className="formFieldDiv">
                        <label>{languageJson.SCAC_txt}</label>
                        <span>
                            <input readOnly={!showOtherButton} className={!showOtherButton ? "input__readonly" : ""}
                                name="standard_carrier_alpha_code" id="standard_carrier_alpha_code" type="text" maxLength="25"
                                placeholder={languageJson.EnterCode_txt}
                                {...register('standard_carrier_alpha_code')}
                                onKeyDown={handleKeyDown}
                            />
                            <p className="text-danger">{errors.standard_carrier_alpha_code?.message}</p>
                        </span>
                    </div>
                </div>
                <div className="uploadDocumentMainDiv">
                    <h2>{languageJson.UploadPhotoDocument_txt}</h2>
                    <div className="attachmentListDescp row">
                        <div className="formFieldDiv col-sm-6">
                            {documentsList &&
                                documentsList.map((document, index) => (
                                    <p key={index}>
                                        <input
                                            disabled={!showOtherButton}
                                            type="radio"
                                            name="document_name"
                                            id={`${document.id}`}
                                            value={document.value}
                                            {...register('document_name')}
                                        />
                                        {document.label}
                                    </p>
                                ))}
                            <p className="text-danger ">{errors.document_name?.message}</p>
                        </div>
                        <div className="formFieldDiv col-sm-6">
                            {image.preview !== '' ?
                                <div className="uploadImgDiv"> <img className="imgCameraBig" alt="img" src={image.preview} /> </div> : null}
                            {showOtherButton && <div className="text-center">
                                <label htmlFor="upload-button">
                                    <h5 className="saveBtn lmc_uploadbtn">
                                        {languageJson.Upload_txt}
                                    </h5>
                                </label>
                                {showImageError && <p className="text-danger text-center">{languageJson.Photo_document_required_txt}</p>}
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    name="document_image"
                    onChange={handleImageChange}
                />
            </div>
            {showOtherButton ? <div className="text-center mt-4 ">
                <button className="btn btn-primary submitform" type="submit">{languageJson.SaveContinue_txt}</button>
            </div> :
                <div className="text-center mt-4 ">
                    <button className="btn btn-primary submitform" onClick={() => handleSubmitLMC()} disabled={loadingState.isLmcRegisterLoading ? true : false}>
                        {loadingState.isLmcRegisterLoading ? languageJson.processing_txt : languageJson.submit_txt}
                    </button>
                </div>
            }
        </form>
    );
}

export default OtherInformation;
