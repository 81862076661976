import React, { useState, useEffect } from 'react';
import { languageJson } from '../../StringVariable';
import { useSelector } from "react-redux";
 
import {
  FAQFILE_MARINE_TERMINAL
} from "../../url/Url";
export default function NoteInstruction() {
  let singleUrls = useSelector(state => state.publicUrlState);
  const [userCondition, setUserCondition] = useState(null)
  useEffect(() => {
    if (singleUrls && singleUrls.singleUrls && singleUrls.singleUrls.length > 0) {
      let userCondition = singleUrls.singleUrls.find(({ url_id }) => url_id === 'faq_url');
      setUserCondition(userCondition ? userCondition.url_link : FAQFILE_MARINE_TERMINAL)
    }
  }, [singleUrls]);
  return (
    <div>
      <div className="TTTNoteDiv">
        <div className="container ">
          <div className="row mt-2 mb-2">
                 <a
                  target="_blank"
                  rel="noreferrer"
                  href={userCondition}
                >
                  {languageJson.Truck_Turn_Time_FAQs_txt}
                </a>
          </div>
        </div>
      </div>
    </div>
  )
}
