/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { GOOGLE_MAP_URL } from "../../url/Url";
import menuLeftIcon from "../../assets/menuLeftBtnBg.png";
import menuLeftIconArrow from "../../assets/menuLeftBtnIcon.png";
import regionalIncidentIcon from "../../assets/chainControl1@2x.png";
import portIncidentIcon from "../../assets/chainControl3@2x.png";
import regionalMessageIcon from "../../assets/message1@2x.png";
import portMessageIcon from "../../assets/message3@2x.png";
import regionalConstructionIcon from "../../assets/snowPlow1@2x.png";
// import portConstructionIcon from "../../assets/snowPlow3@2x.png";
// import cmsIcon from "../../assets/messageIconCopy4@2x.png";
import "./CamraAndTrafficOnMap.css";
import { useSelector, useDispatch } from "react-redux";
import {
  portMessageSigns,
 // portConstructionEvents,
  //portIncidentClosures,
  regionalMessageSigns,
  regionalConstructionEvents,
  regionalIncidentClosures,
 // districtMessageSigns11
} from "../../redux/actions/AlertDataAction";
import { DateTimeFormate } from "../../utils/DataFormate";

import { languageJson } from "src/StringVariable";

function TrafficCameraOnMap(props) {
  let dispatch = useDispatch();
  let { alertDataState } = useSelector((state) => state);
  const lat = 37.78929;
  const lng = -122.27405;
  const [portMessage, setPortMessage] = useState(null);
  // const [portConstruction, setPortConstruction] = useState(null);
  // const [portIncident, setPortIncident] = useState(null);
  // const [districtmsgSign, setDistrictmsgSign] = useState(null);
  const [regionalMessage, setRegionalMessage] = useState(null);
  const [regionalConstruction, setRegionalConstruction] = useState(null);
  const [regionalIncident, setRegionalIncident] = useState(null);
  
// TODO Multiple changes DONE
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);
// TODO //dispatch(portConstructionEvents());
   // dispatch(portIncidentClosures());
  useEffect(() => {
    dispatch(portMessageSigns());
    //dispatch(portConstructionEvents());
   // dispatch(portIncidentClosures());
    dispatch(regionalMessageSigns());
    dispatch(regionalConstructionEvents());
    dispatch(regionalIncidentClosures());
   // dispatch(districtMessageSigns11())
    const interval = setInterval(() => {
      dispatch(portMessageSigns());
  //    dispatch(portConstructionEvents());
    //  dispatch(portIncidentClosures());
      dispatch(regionalMessageSigns());
      dispatch(regionalConstructionEvents());
      dispatch(regionalIncidentClosures());
 //     dispatch(districtMessageSigns11())
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const onClickIcon = (type, property) => {
    if (type === "portMessage") {
      setPortMessage(property);
      // setPortConstruction(null);
      // setPortIncident(null);
      setRegionalMessage(null);
      setRegionalConstruction(null);
      setRegionalIncident(null);
      // setDistrictmsgSign(null);
    }
    // if (type === "portConstruction") {
    //   setPortMessage(null);
    //   setPortConstruction(property);
    //   setPortIncident(null);
    //   setRegionalMessage(null);
    //   setRegionalConstruction(null);
    //   setRegionalIncident(null);
    //   setDistrictmsgSign(null);
    // }
    // if (type === "portIncident") {
    //   setPortMessage(null);
    //   setPortConstruction(null);
    //   setPortIncident(property);
    //   setRegionalMessage(null);
    //   setRegionalConstruction(null);
    //   setRegionalIncident(null);
    //   setDistrictmsgSign(null);
    // }
    if (type === "regionalMessage") {
      setPortMessage(null);
      // setPortConstruction(null);
      // setPortIncident(null);
      setRegionalMessage(property);
      setRegionalConstruction(null);
      setRegionalIncident(null);
      // setDistrictmsgSign(null);
    }
    if (type === "regionalConstruction") {
      setPortMessage(null);
      // setPortConstruction(null);
      // setPortIncident(null);
      setRegionalMessage(null);
      setRegionalConstruction(property);
      setRegionalIncident(null);
      // setDistrictmsgSign(null);
    }
    if (type === "regionalIncident") {
     setPortMessage(null);
      // setPortConstruction(null);
      // setPortIncident(null);
      setRegionalMessage(null);
      setRegionalConstruction(null);
      setRegionalIncident(property);
      // setDistrictmsgSign(null);
    }
    // if (type === "district") {
    //   setPortMessage(null);
    //   setPortConstruction(null);
    //   setPortIncident(null);
    //   setRegionalMessage(null);
    //   setRegionalConstruction(null);
    //   setRegionalIncident(null);
    //   setDistrictmsgSign(property);
    // }
    if (type === "close") {
      setPortMessage(null);
      // setPortConstruction(null);
      // setPortIncident(null);
      setRegionalMessage(null);
      setRegionalConstruction(null);
      setRegionalIncident(null);
      // setDistrictmsgSign(null);
    } else {
      return;
    }
  };

  // function DateConvert(inStr){
  //   if (inStr) { 
  //                 var year = inStr.substring(0, 4);
  //                 var month = inStr.substring(5, 7);
  //                 var day = inStr.substring(8, 10);
  //                 return  day +'/'+ month + '/' + year;
  //               } 
  // }
  // function TimeConvert (time) {
  //   // Check correct time format and split into components
  //   time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    
  //   if (time.length > 1) { // If time format correct
  //     time = time.slice (1);  // Remove full string match value
  //     time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
  //     time[0] = +time[0] % 12 || 12; // Adjust hours
  //   }
    
  //   return time.join (''); // return adjusted time or original string
  // }
  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: lat, lng: lng }}
        options={{ streetViewControl: false }}
      >
        {/*CMS  Signs start*/}
        {/* {alertDataState &&
          alertDataState.districtMsgSign != null &&
          alertDataState.districtMsgSign.map((portMessage) => {
            return (
              portMessage['cms'].message['phase1'].phase1Line1 !== "" && portMessage['cms'].message['phase1'].phase1Line2 !== "" && portMessage['cms'].message['phase1'].phase1Line3 !== "" &&
              portMessage['cms'].message['phase1'].phase1Line1 !== "Not Reported" && portMessage['cms'].message['phase1'].phase1Line2 !== "Not Reported" && portMessage['cms'].message['phase1'].phase1Line3 !== "Not Reported"
              && <Marker
                key={portMessage['cms'].index}
                position={{
                  lat: parseFloat(portMessage['cms']['location'].latitude),
                  lng: parseFloat(portMessage['cms']['location'].longitude),
                }}
                onClick={() => {
                  onClickIcon("district", portMessage['cms']);
                }}
                icon={{
                  url: cmsIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(44) ? true : false}
              />
            );
          })}
         {districtmsgSign !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("district");
            }}
            position={{
              lat: parseFloat(districtmsgSign['location'].latitude),
              lng: parseFloat(districtmsgSign['location'].longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="locationTxt">
                      {districtmsgSign.location['locationName'] || "_"}
                    </label>
                  </span>
                  <span className="msgDataDiv">
                    {districtmsgSign.message['phase1'].phase1Line1 !== "" ? (
                      <label
                      > {districtmsgSign.message['phase1'].phase1Line1} <br />{districtmsgSign.message['phase1'].phase1Line2} <br />{districtmsgSign.message['phase1'].phase1Line3}</label>
                    ) : (
                      "Not Reported"
                    )}
                    <br />
                  </span>
                  <span className="dateTimeTxt">
                    <label>Updated : {DateConvert(districtmsgSign.recordTimestamp.recordDate)} {TimeConvert(districtmsgSign.recordTimestamp.recordTime)}</label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )} */}
        {/*CMS Status signs close */}
        {/*Port Message Signs*/}
        {alertDataState &&
          alertDataState.portMessageSigns != null &&
          alertDataState.portMessageSigns.map((portMessage) => {
            return (
              <Marker
                key={portMessage.camera_id}
                position={{
                  lat: parseFloat(portMessage.latitude),
                  lng: parseFloat(portMessage.longitude),
                }}
                onClick={() => {
                  onClickIcon("portMessage", portMessage);
                }}
                icon={{
                  url: portMessageIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(1) ? true : false}
              />
            );
          })}
        {portMessage !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("portMessage");
            }}
            position={{
              lat: parseFloat(portMessage.latitude),
              lng: parseFloat(portMessage.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="locationTxt">
                      {portMessage.location || "_"}
                    </label>
                  </span>
                  <span className="msgDataDiv">
                    {portMessage.description !== "" ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: portMessage.description,
                        }}
                      ></label>
                    ) : (
                      "Not Reported"
                    )}
                    <br />
                  </span>
                  <span className="dateTimeTxt">
                    <label>Updated :</label>
                    <label>{DateTimeFormate(portMessage.last_updated)}</label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
         {/* TODO HIDE  Under Traffic Information: hide port construction and events, port incidents and closures. 16 NOV 2023 */}
       
        {/*Port Construction Events*/}
        {/* {alertDataState &&
          alertDataState.portConstructionEvents != null &&
          alertDataState.portConstructionEvents.map((portConstruction) => {
            return (
              <Marker
                key={portConstruction.camera_id}
                position={{
                  lat: parseFloat(portConstruction.latitude),
                  lng: parseFloat(portConstruction.longitude),
                }}
                onClick={() => {
                  onClickIcon("portConstruction", portConstruction);
                }}
                icon={{
                  url: portConstructionIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(2) ? true : false}
              />
            );
          })}

        {portConstruction !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("portConstruction");
            }}
            position={{
              lat: parseFloat(portConstruction.latitude),
              lng: parseFloat(portConstruction.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="labelTxt">
                      {languageJson.location_txt} :
                    </label>
                    <label className="locationTxt">
                      {portConstruction.location || "_"}
                    </label>
                  </span>
                  <span>
                    <label className="labelTxt">
                      {languageJson.description_txt} :
                    </label>
                    {portConstruction.description ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: portConstruction.description,
                        }}
                      ></label>
                    ) : (
                      "_"
                    )}
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.start_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(portConstruction.start_time)}
                    </label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.end_time_txt} :
                    </label>
                    <label>{DateTimeFormate(portConstruction.end_time)}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.actual_end_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(portConstruction.actual_end_time)}
                    </label>
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.corridor_area_txt} :
                    </label>
                    <label>{portConstruction.corridor || "_"}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.last_updated_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(portConstruction.last_updated)}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
        {/*Port Incident Closures*/}
        {/* {alertDataState &&
          alertDataState.portIncidentClosures != null &&
          alertDataState.portIncidentClosures.map((portIncident) => {
            return (
              <Marker
                key={portIncident.camera_id}
                position={{
                  lat: parseFloat(portIncident.latitude),
                  lng: parseFloat(portIncident.longitude),
                }}
                onClick={() => {
                  onClickIcon("portIncident", portIncident);
                }}
                icon={{
                  url: portIncidentIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(3) ? true : false}
              />
            );
          })}

         {portIncident !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("portIncident");
            }}
            position={{
              lat: parseFloat(portIncident.latitude),
              lng: parseFloat(portIncident.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="labelTxt">
                      {languageJson.location_txt} :
                    </label>
                    <label className="locationTxt">
                      {portIncident.location || "_"}
                    </label>
                  </span>
                  <span>
                    <label className="labelTxt">
                      {languageJson.description_txt} :
                    </label>
                    {portIncident.description ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: portIncident.description,
                        }}
                      ></label>
                    ) : (
                      "_"
                    )}
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.start_time_txt} :
                    </label>
                    <label>{DateTimeFormate(portIncident.start_time)}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.end_time_txt} :
                    </label>
                    <label>{DateTimeFormate(portIncident.end_time)}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.actual_end_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(portIncident.actual_end_time)}
                    </label>
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.corridor_area_txt} :
                    </label>
                    <label>{portIncident.corridor || "-"}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.last_updated_txt} :
                    </label>
                    <label>{DateTimeFormate(portIncident.last_updated)}</label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}  */}
        {/*regional message sign*/}
        {alertDataState &&
          alertDataState.regionalMessageSigns != null &&
          alertDataState.regionalMessageSigns.map((regionalMessage) => {
            return (
              <Marker
                key={regionalMessage.camera_id}
                position={{
                  lat: parseFloat(regionalMessage.latitude),
                  lng: parseFloat(regionalMessage.longitude),
                }}
                onClick={() => {
                  onClickIcon("regionalMessage", regionalMessage);
                }}
                icon={{
                  url: regionalMessageIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(11) ? true : false}
              />
            );
          })}

        {regionalMessage !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("regionalMessage");
            }}
            position={{
              lat: parseFloat(regionalMessage.latitude),
              lng: parseFloat(regionalMessage.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="locationTxt">
                      {regionalMessage.location || "_"}
                    </label>
                  </span>
                  <span className="msgDataDiv">
                    {regionalMessage.description ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: regionalMessage.description,
                        }}
                      ></label>
                    ) : (
                      "_"
                    )}
                  </span>
                  <span className="dateTimeTxt">
                    <label>{languageJson.last_updated_txt} :</label>
                    <label>
                      {DateTimeFormate(regionalMessage.last_updated)}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
        {/*Regional Construction Events*/}
        {alertDataState &&
          alertDataState.regionalConstructionEvents != null &&
          alertDataState.regionalConstructionEvents.map(
            (regionalConstruction) => {
              return (
                <Marker
                  key={regionalConstruction.camera_id}
                  position={{
                    lat: parseFloat(regionalConstruction.latitude),
                    lng: parseFloat(regionalConstruction.longitude),
                  }}
                  onClick={() => {
                    onClickIcon("regionalConstruction", regionalConstruction);
                  }}
                  icon={{
                    url: regionalConstructionIcon,
                    scaledSize: new window.google.maps.Size(25, 25),
                  }}
                  visible={props.values.includes(22) ? true : false}
                />
              );
            }
          )}

        {regionalConstruction !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("regionalConstruction");
            }}
            position={{
              lat: parseFloat(regionalConstruction.latitude),
              lng: parseFloat(regionalConstruction.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain portMessageSignsMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>

              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="labelTxt">
                      {languageJson.location_txt} :
                    </label>
                    <label className="locationTxt">
                      {regionalConstruction.location || "_"}
                    </label>
                  </span>
                  <span>
                    <label className="labelTxt">
                      {languageJson.description_txt} :
                    </label>
                    {regionalConstruction.description ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: regionalConstruction.description,
                        }}
                      ></label>
                    ) : (
                      ""
                    )}
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.start_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalConstruction.start_time)}
                    </label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.end_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalConstruction.end_time)}
                    </label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.actual_end_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalConstruction.actual_end_time)}
                    </label>
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.corridor_area_txt} :
                    </label>
                    <label>{regionalConstruction.corridor || "-"}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.last_updated_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalConstruction.last_updated)}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
        {/*Regional Incident Closures*/}
        {alertDataState &&
          alertDataState.regionalIncidentClosures != null &&
          alertDataState.regionalIncidentClosures.map((regionalIncident) => {
            return (
              <Marker
                key={regionalIncident.camera_id}
                position={{
                  lat: parseFloat(regionalIncident.latitude),
                  lng: parseFloat(regionalIncident.longitude),
                }}
                onClick={() => {
                  onClickIcon("regionalIncident", regionalIncident);
                }}
                icon={{
                  url: regionalIncidentIcon,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                visible={props.values.includes(33) ? true : false}
              />
            );
          })}

        {regionalIncident !== null && (
          <InfoWindow
            onCloseClick={() => {
              onClickIcon("regionalIncident");
            }}
            position={{
              lat: parseFloat(regionalIncident.latitude),
              lng: parseFloat(regionalIncident.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  onClickIcon("close");
                }}
              >
                X
              </button>
              <div className="portMessageSignsMain popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label className="labelTxt">
                      {languageJson.location_txt} :
                    </label>
                    <label className="locationTxt">
                      {regionalIncident.location || "_"}
                    </label>
                  </span>
                  <span>
                    <label className="labelTxt">
                      {languageJson.description_txt} :
                    </label>
                    {regionalIncident.description ? (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: regionalIncident.description,
                        }}
                      ></label>
                    ) : (
                      "-"
                    )}
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.start_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalIncident.start_time)}
                    </label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.end_time_txt} :
                    </label>
                    <label>{DateTimeFormate(regionalIncident.end_time)}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.actual_end_time_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalIncident.actual_end_time)}
                    </label>
                  </span>

                  <span className="">
                    <label className="labelTxt">
                      {languageJson.corridor_area_txt} :
                    </label>
                    <label>{regionalIncident.corridor || "-"}</label>
                  </span>
                  <span className="">
                    <label className="labelTxt">
                      {languageJson.last_updated_txt} :
                    </label>
                    <label>
                      {DateTimeFormate(regionalIncident.last_updated)}
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(TrafficCameraOnMap));

export default function CamraAndTrafficOnMap() {
  const [newClass, setNewClass] = useState(false);
  const [activeList, setActiveList] = useState([1]);
  const filterList = [
    { name: "Port Message Signs", id: 1 },
    // { name: "Port Construction and Events", id: 2 },
    // { name: "Port Incident and Closures", id: 3 },
    { name: "Regional Message Signs", id: 11 },
    { name: "Regional Construction and Events", id: 22 },
    { name: "Regional Incident and Closures", id: 33 },
 //   { name: "District Message Signs", id: 44 },
  ];

  const [dummyState, rerender] = useState(1);

  useEffect(() => {
   }, [dummyState, activeList.length]);

  const onClick = () => {
    rerender(dummyState + 1);
  };

  const setNewClick = () => {
    setNewClass(!newClass);
  };

  const toggleChange = (item) => {
    onClick();
    let list = [...activeList];
    if (list.includes(item)) {
      const index = list.indexOf(item);
      list.splice(index, 1);
    } else {
      list.push(item);
    }
    setActiveList([...list]);
  };

  const selectAll = () => {
    onClick();
    let list = [...activeList];
    if (list.length < 4) {
      filterList.map((item) => {
        if (!list.includes(item.id)) {
          list.push(item.id);
        }
        return item;
      });
    } else {
      list = [];
    }
    setActiveList([...list]);
    onClick();
  };

  return (
    <div
      className="vesselMapTab trafficCameraMapDivTab"
      style={{ width: "100%", height: "75vh", position: "relative" }}
    >
      <div
        id="drawer"
        className={`filterMapMainDiv  ${newClass ? "openDiv" : ""}`}
      >
        <button
          onClick={() => {
            setNewClick();
          }}
          id="drawer-handle"
          className="filterBtn"
        >
          <img src={menuLeftIconArrow} alt="icon" className="imgBtnIcon"></img>
          <img src={menuLeftIcon} alt="icon"></img>
        </button>
        <div id="drawer-content" className="filterOptDiv">
          {filterList.map((item) => {
            return (
              <label className="checkbox">
                <input
                  key={item.id}
                  type="checkbox"
                  id={item.id}
                  checked={activeList.includes(item.id) ? true : false}
                  onChange={() => toggleChange(item.id)}
                />
                {item.name}
                <i></i>
              </label>
            );
          })}
          {/* TODO CHANGE LENGTH */}
          <button onClick={() => selectAll()}>
            {" "}
            {activeList.length < 4
              ? languageJson.select_all_txt
              : languageJson.deselect_all_txt}
          </button>
        </div>
      </div>
      {navigator.onLine && dummyState && (
        <MapWrapped
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `100%`, borderRadius: "20px" }} />
          }
          mapElement={<div style={{ height: `100%`, borderRadius: "20px" }} />}
          values={activeList}
        />
      )}
    </div>
  );
}
