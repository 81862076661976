import { ActionTypes } from "../constants/Types";

export const forgotPasswordEmail = (email) => {
    return {
        type: ActionTypes.FORGOT_PASSWORD_EMAIL,
        payload:email
    }
}

export const clearData = () => {
    return {
        type: ActionTypes.CLEAR_DATA
    }
}




