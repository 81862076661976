import './imagePop.css';
import { languageJson } from '../../StringVariable';
import icon from "../../assets/icon.png";

function ImagePop(props) {
  return (
    <div className='custom-modal driverregistration-popup cameraModalDiv pb-3'>
      <div className={`${props.status} driverregistration-popup-head`}>
      </div>
      <div className="innerImgDiv">
        {
          props.imageDelete ? (
            <p>{languageJson.ButtonNeedsDisplayHere_txt}</p>
          ) : null
        }
        {
          props.customImage !== '0' ?
            <img className="imgCameraBig" alt='img' src={props.customImage} /> : (
              <>
                <div className="h-25 bg-warning">

                </div>
                <h2 className="text-center mt-5">{languageJson.warning_txt}</h2>
                <p className="mt-5">{languageJson.CameraPreview_unavaliable_txt}</p>
              </>
            )
        }
      </div>

      <button className={`modal-btn-warning`} onClick={props.onClose}>
        {languageJson.close_txt}
      </button>
      {
          props.customImage !== '0' ?
          <a href={props.customImage} title={languageJson.titleForRedirect} target="_blank" rel="noreferrer" className={`modal-btn-warning`} >
          <img
            className=""
            src={icon}
            alt="icon"
          />
        </a> : (<></>)
        }
                    
    </div>
  );
}

export default ImagePop;
