import React, { useState } from "react";
import { primaryLMCSchema } from "../../utils/Validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import editIcon from "../../assets/edit-icon@2x.webp";
import moment from "moment";
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
moment.suppressDeprecationWarnings = true;

const PrimaryInformation = (props) => {
  let {
    setShowPrimaryform,
    setShowOtherform,
    setFormData,
    formData,
    showPrimaryButton,
    setShowOtherButton,
    setShowPrimaryButton,
    showOtherButton
  } = props;
  const location = useLocation();
  const { pathname } = location;
  const formOptions = {
    resolver: yupResolver(primaryLMCSchema),
    defaultValues: {
      ...formData,
      userAgreement: pathname === "/aggrement/lmcregister" ? true : false,
    },
  };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [company_established_date1, setcompany_established_date] = useState(
    formData.company_established_date ? new Date(formData.company_established_date) : new Date()
  );

  const onchangeDate = (date) => {
    setcompany_established_date(date);
  };

  const onReadOnlyClick = () => {
    setShowPrimaryButton(true);
    setShowOtherButton(true);
    setShowPrimaryform(true);
    setShowOtherform(false);
  };

  const handleSubmitRegister = async (registerData) => {
    let company_established_date = moment(
      company_established_date1
    ).format("YYYY-MM-DD");
    setFormData({ ...formData, ...registerData, company_established_date });
    setShowPrimaryform(false);
    setShowOtherform(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
  };
  const DatePickerCustomInput = () => <div className="calendar_icon"><i className="fa fa-calendar" aria-hidden="true"></i></div>

  return (
    <form onSubmit={handleSubmit(handleSubmitRegister)} autoComplete="off">
      <div className="primaryInformationForm">
        <div className="headingTxt d-flex justify-content-between">
          <h1>{languageJson.primary_information_txt}</h1>
          {!showPrimaryButton && (
            <img
              className="float-end lmc__edit__icon cursor-pointer"
              onClick={() => {
                onReadOnlyClick();
              }}
              src={editIcon}
              alt="Edit-icon"
            />
          )}
        </div>
        <div className="formMainDiv">
          <div className="formFieldDiv">
            <label>{languageJson.LMCName_txt}</label>
            <span>
              <input
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="lmc_name"
                id="lmc_name"
                maxLength="100"
                type="text"
                placeholder={languageJson.enter_LMCName_txt}
                {...register("lmc_name")}
              />
              <p className="text-danger">{errors.lmc_name?.message}</p>
            </span>
          </div>
          <div className="formFieldDiv CompanyEstablishedDateInputDiv">
            <label>{languageJson.CompanyEstablishedDate_txt}</label>
            <span className="input-group">
              <DatePicker
                className={!showOtherButton ? "input__readonly" : ""}
                selected={company_established_date1}
                onChange={(date) => onchangeDate(date)}
                selectsStart
                onKeyDown={handleKeyPress}
                maxDate={new Date()}
                disabled={!showOtherButton}
              />
              <DatePickerCustomInput />
              <p className="text-danger">
                {errors.company_established_date?.message}
              </p>
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.LMCType_txt}</label>
            <span>
              <select
                disabled={!showOtherButton}
                className={
                  !showOtherButton
                    ? "custom-select input__readonly"
                    : "custom-select"
                }
                name="lmc_type"
                id="lmc_type"
                {...register("lmc_type")}
              >
                <option selected value="">
                  {languageJson.SelectLMCType_txt}
                </option>
                <option value="Corporation">
                  {languageJson.Corporation_txt}
                </option>
                <option value="Partnership Limited">
                  {languageJson.ParternshipLimited_txt}
                </option>
                <option value="Liability Company (LLC)">
                  {languageJson.LiabilityCompany_txt}
                </option>
                <option value="Sole proprietorship">
                  {languageJson.SoleProprietorship_txt}
                </option>
                <option value="Limited Liability">
                  {languageJson.LimitedLiability_txt}
                </option>
                <option value="Partnership (LLP)">
                  {languageJson.Partnership_LLP_txt}
                </option>
              </select>
              <p className="text-danger">{errors.lmc_type?.message}</p>
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.CompanyOfficeAddress_txt} </label>
            <span>
              <input
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="company_office_address"
                id="company_office_address"
                type="text"
                placeholder={languageJson.EnterAddress_txt}
                {...register("company_office_address")}
              />
              <p className="text-danger">
                {errors.company_office_address?.message}
              </p>
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.BusinessPhone_txt}</label>
            <span>
              <InputMask
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="business_phone_number"
                id="business_phone_number"
                type="text"
                placeholder={languageJson.mobile_number_error_txt}
                mask="(999) 999-9999"
                maskChar=" "
                onKeyDown={handleKeyDown}
                {...register("business_phone_number")}
              />
              <p className="text-danger">
                {errors.business_phone_number?.message}
              </p>
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.Extension_txt}</label>
            <span>
              <InputMask
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="extention_number"
                id="extention_number"
                type="text"
                mask="99999"
                maskChar=" "
                onKeyDown={handleKeyDown}
                placeholder={languageJson.EnterContactNumber_txt}
                {...register("extention_number")}
              />
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.BusinessEmail_txt}</label>
            <span>
              <input
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="business_email"
                id="business_email"
                type="text"
                maxLength="50"
                placeholder={languageJson.enter_email_address_txt}
                {...register("business_email")}
              />
              <p className="text-danger">{errors.business_email?.message}</p>
            </span>
          </div>
          <div className="formFieldDiv">
            <label>{languageJson.SecondaryEmail_txt}</label>
            <span>
              <input
                readOnly={!showOtherButton}
                className={!showOtherButton ? "input__readonly" : ""}
                name="secondary_email"
                id="secondary_email"
                type="text"
                placeholder={languageJson.admin_panel_placeholder.secondary_email_address_placeholder}
                {...register("secondary_email")}
              />
              <p className="text-danger">{errors.secondary_email?.message}</p>
            </span>
          </div>
          <div className="formFieldDiv pwdInputFormDiv">
            <label>{languageJson.password_small_txt}</label>
            <span className="positionRelative">
              <input
                className={!showOtherButton ? "input__readonly" : ""}
                readOnly={!showOtherButton}
                name="password"
                id="password"
                type={passwordShown ? "text" : "password"}
                placeholder={languageJson.enter_pwd_txt}
                autoComplete="off"
                onKeyDown={handleKeyDown}
                {...register("password")}
              />
              <i
                toggle="#password-field"
                className={`${passwordShown ? "fa fa-fw fa-eye-slash" : "fa fa-fw fa-eye"
                  } field-icon toggle-password `}
                onClick={() => setPasswordShown(!passwordShown)}
              ></i>
              <p className="text-danger">{errors.password?.message}</p>
            </span>
          </div>
          <div className="formFieldDiv pwdInputFormDiv">
            <label>{languageJson.confirm_password_txt}</label>
            <span className="positionRelative">
              <input
                className={!showOtherButton ? "input__readonly" : ""}
                name="confirmPassword"
                readOnly={!showOtherButton}
                id="confirmPassword"
                type={confirmPasswordShown ? "text" : "password"}
                placeholder={languageJson.confirm_password_txt}
                {...register("confirmPassword")}
                onKeyDown={handleKeyDown}
              />
              <i
                id="togglePassword"
                toggle="#password-field"
                className={`${confirmPasswordShown
                  ? "fa fa-fw fa-eye-slash"
                  : "fa fa-fw fa-eye"
                  } field-icon toggle-password eyeiconspan`}
                onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
              ></i>
              <p className="text-danger">{errors.confirmPassword?.message}</p>
            </span>
          </div>
        </div>
        <div className="formMainDiv">
          <div className="form-check">
            <input
              disabled={!showOtherButton}
              type="checkbox"
              className={`form-check-input is invalid`}
              name="userAgreement"
              id="userAgreement"
              {...register("userAgreement")}
            />
            <label htmlFor="userAgreement" className="form-check-label formlabeltxt">
              {languageJson.driver_registration_clickAgree_txt}{" "}
              <span>
                <a
                  href="/#/userAgreement"
                  rel="noreferrer"
                  className="aggrementLink"
                >
                  {" "}
                  {languageJson.registerOption.UserAgreement_txt}
                </a>
              </span>
            </label>
            <p className="text-danger">{errors.userAgreement?.message}</p>
          </div>
        </div>
      </div>
      {showPrimaryButton && (
        <div className="text-center mt-4 ">
          <button className="btn btn-primary submitform" type="submit">
            {languageJson.SaveContinue_txt}
          </button>
        </div>
      )}
    </form>
  );
};

export default PrimaryInformation;
