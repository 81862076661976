/* eslint-disable no-unused-vars */
import { ActionTypes } from "../constants/Types";
import server from "./apis";
import store from "store";
import { loginSuccessMessage, loginErrorMessage, otpSendMessage, driverRegisterMessage, lmcRegisterMessage, clearMessage, otpVerifyMessage, contactUsMessage, forgotPasswordMessage, changePasswordMessage,deleteAccountMsg, cancelDeleteAccountMsg } from "./MessageAction";
import { stopLoading, startLoginLoading, otpSendLoading, driverRegisterLoading, lmcRegisterLoading, otpVerifyLoading, contactUsLoading, forgotPasswordLoading, changePasswordLoading, userDetailLoading,deleteAccountLoading,cancelDeleteAccountLoading } from "./LoadingAction";
import { loginStatusCode, otpSendStatusCode, driverRegisterStatusCode, lmcRegisterStatusCode, clearStatusCode, otpVerifyStatusCode, contactUsStatusCode, forgotPasswordStatusCode, changePasswordStatusCode, userDetalStatusCode } from "./StatusCodeAction";
import { USER_AUTH_API, UPDATE_USER_SETTING_API, USER_SETTING_API, GET_LOGIN_OTP, PUBLIC_REGISTRATION_API, VERIFY_OTP, LMC_REGISTRATION_API, SAVE_CONTACT_US_API, FORGOT_PASSWORD, RESET_PASSWORD, USER_DETAIL_API,DELETE_USER_ACCOUNT_API, CANCEL_DELETE_USER_ACCOUNT_API } from "../../url/Url";
import { forgotPasswordEmail } from "./TempStorageAction"
import { NotificationPopup } from "src/utils/Notifications";
import { languageJson } from "src/StringVariable";
import { submitGAEvent } from "src/utils/GAEvents";

const loginSuccess = (user) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        payload: user
    }
}

// Login
export const userLogin = (data, history) => {
    return (dispatch) => {
        dispatch(startLoginLoading());
        server.post(`${USER_AUTH_API}`, { ...data })
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    store.set('token', response.data.token);
                    store.set('firstName', response.data.user.firstName);
                    store.set('lastName', response.data.user.lastName);
                    store.set('gender', response.data.user.gender);
                    store.set('profile_pic', response.data.user.profile_pic);
                    store.set('user_type', response.data.user.user_type);
                    store.set('user_type_value', response.data.user.user_type_value);
                    if (response.data.user.user_type !== 'Admin' || response.data.user.user_type !== 'SuperAdmin') {
                        dispatch(getUserSetting())
                    }
                    store.set('userid', response.data.user.userid);

                    dispatch(loginSuccess(response.data));
                    dispatch(userDetailByUserId(response.data.user.userid))
                    history.push("/admin/dashboard");
                } else {
                    dispatch(loginStatusCode(response.data.customcode));
                    dispatch(loginSuccessMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(loginErrorMessage(message))
            });;
    };
};

// Logout 
const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
        payload: null
    }
}

export const userLogout = (history) => {
    submitGAEvent("LOGOUT");
    return (dispatch) => {
        dispatch(logoutSuccess());
        store.remove('token');
        store.remove('firstName');
        store.remove('lastName');
        store.remove('gender');
        store.remove('profile_pic');
        store.remove('user_type');
        store.remove('user_type_value');
        store.remove('userid');
        store.remove('persist:auth')
        store.clearAll();
        history.push("/userLogin");
        localStorage.setItem('lang', "en");
        window.location.reload();

    };
};

export const otpSend = (data) => {
    return (dispatch) => {
        dispatch(otpSendLoading());
        server.post(`${GET_LOGIN_OTP}`, { ...data })
            .then((response) => {
                dispatch(stopLoading());
                dispatch(forgotPasswordEmail(data.email))
                dispatch(otpSendStatusCode(response.data.customcode));
                dispatch(otpSendMessage(response.data.message))
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(otpSendMessage(message))
            });;
    };
};

export const otpVerify = (data, history) => {
    return (dispatch) => {
        dispatch(otpVerifyLoading());
        server.post(`${VERIFY_OTP}`, { ...data })
            .then((response) => {
                dispatch(stopLoading());
                let isforgotRoute = false
                let str = history.location.pathname
                let list = str.split('/')
                isforgotRoute = list.includes('forgotPassword') ? true : false

                if (isforgotRoute && response.data.customcode === 200) {
                    dispatch(forgotPasswordEmail(data.email))
                    history.push('/changePassword')
                } else {
                    dispatch(otpVerifyStatusCode(response.data.customcode));
                    dispatch(otpVerifyMessage(response.data.message))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(otpVerifyMessage(message))
            });;
    };
};

export const driverRegistration = (data) => {
    return (dispatch) => {
        dispatch(driverRegisterLoading());
        server.post(`${PUBLIC_REGISTRATION_API}`, { ...data })
            .then((response) => {
                dispatch(stopLoading());
                dispatch(driverRegisterStatusCode(response.data.customcode));
                dispatch(driverRegisterMessage(response.data.message))
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
                dispatch(driverRegisterMessage(message))
            });;
    };
};

export const clearLoadingMessageStatus = () => {
    return (dispatch) => {
        dispatch(clearMessage())
        dispatch(clearStatusCode())
    };
};


export const lmcRegistration = (data) => {
    return (dispatch) => {
        dispatch(lmcRegisterLoading());
        server.post(`${LMC_REGISTRATION_API}`, data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(lmcRegisterStatusCode(response.data.customcode));
                dispatch(lmcRegisterMessage(response.data.message));
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(lmcRegisterMessage(message))
            });;
    };
};

export const conctactUs = (data) => {
    return (dispatch) => {
        dispatch(contactUsLoading());
        dispatch(contactUsMessage(null))
        server.post(`${SAVE_CONTACT_US_API}`, data)
            .then((response) => {

                dispatch(contactUsStatusCode(response.data.customcode));
                dispatch(contactUsMessage(response.data.message))
                dispatch(stopLoading());
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(contactUsMessage(message))
            });;
    };
};

export const forgotPassword = (data) => {
    return (dispatch) => {
        dispatch(forgotPasswordLoading());
        server.post(`${FORGOT_PASSWORD}`, data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(forgotPasswordEmail(data.email))
                dispatch(forgotPasswordStatusCode(response.data.customcode));
                dispatch(forgotPasswordMessage(response.data.message))
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(forgotPasswordMessage(message))
            });;
    };
};

export const changePassword = (data) => {
    return (dispatch) => {
        dispatch(changePasswordLoading());
        server.post(`${RESET_PASSWORD}`, data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(changePasswordStatusCode(response.data.customcode));
                dispatch(changePasswordMessage(response.data.message))
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(changePasswordMessage(message))
            });;
    };
};


const userDetailbyID = (user) => {
    return {
        type: ActionTypes.USER_DETAIL_LOADING,
        payload: user
    }
}

export const userDetailByUserId = () => {
    return (dispatch) => {
        dispatch(userDetailLoading());
        server.get(`${USER_DETAIL_API}`)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(userDetalStatusCode(response.data.customcode));
                dispatch(userDetailbyID(response.data.profileData))
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(changePasswordMessage(message))
            });;
    };
}

const userSetting = (data) => {
    return {
        type: ActionTypes.USER_SETTING,
        payload: data
    }
}
export const getUserSetting = (popup) => {
    return (dispatch) => {
        server.get(`${USER_SETTING_API}`)
            .then((response) => {
                dispatch(stopLoading());
                if (response.data.customcode === 200) {
                    dispatch(userSetting(response.data.settingData))
                    if (localStorage.getItem('lang') !== response.data.settingData.language) {
                        store.set('lang', response.data.settingData.language);
                        localStorage.setItem('lang', response.data.settingData.language)
                        let customcode = 200
                        NotificationPopup(customcode, languageJson.language_notification_msg_txt)
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);
                    }
                } else {
                    dispatch(userSetting(null))
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
            });;
    };
}

export const UpdateUserSetting = (data, languageChange) => {
    return (dispatch) => {
        server.post(`${UPDATE_USER_SETTING_API}`, data)
            .then((response) => {
                dispatch(stopLoading());
                NotificationPopup(response.data.customcode, response.data.message)
                if (response.data.customcode === 200) {
                    dispatch(getUserSetting())
                    languageChange && window.location.reload(true);
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
            });;
    };
}

export const deleteAccount = (history) => {
    return (dispatch) => {
        dispatch(deleteAccountLoading());
        server.get(`${DELETE_USER_ACCOUNT_API}`)
            .then((response) => {
                dispatch(stopLoading());
                NotificationPopup(response.data.customcode, response.data.message)
                if (response.data.customcode === 200) {
                    // dispatch(logoutSuccess());
                    // store.remove('token');
                    // store.remove('firstName');
                    // store.remove('lastName');
                    // store.remove('gender');
                    // store.remove('profile_pic');
                    // store.remove('user_type');
                    // store.remove('user_type_value');
                    // store.remove('userid');
                    // store.remove('persist:auth')
                    // store.clearAll();
                    // history.push("/userLogin");
                    // history.push("/");

                    // localStorage.setItem('lang', "en");
                    // window.location.reload();
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(deleteAccountMsg(message))
            });;
    };
}

export const cancelDeleteAccountRequest = (history) => {
    return (dispatch) => {
        dispatch(cancelDeleteAccountLoading());
        server.get(`${CANCEL_DELETE_USER_ACCOUNT_API}`)
            .then((response) => {
                dispatch(stopLoading());
                NotificationPopup(response.data.customcode, response.data.message)
                if (response.data.customcode === 200) {
                    // dispatch(logoutSuccess());
                    // store.remove('token');
                    // store.remove('firstName');
                    // store.remove('lastName');
                    // store.remove('gender');
                    // store.remove('profile_pic');
                    // store.remove('user_type');
                    // store.remove('user_type_value');
                    // store.remove('userid');
                    // store.remove('persist:auth')
                    // store.clearAll();
                    // history.push("/");
                    // localStorage.setItem('lang', "en");
                    // window.location.reload();
                }
            })
            .catch((error) => {
                dispatch(stopLoading());
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else if (error.response.message) {
                    message = error.response.message
                }
                dispatch(cancelDeleteAccountMsg(message))
            });;
    };
}
