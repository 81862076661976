export const ActionTypes = {
    LOGIN_STATUS_CODE: "LOGIN_STATUS_CODE",
    STOP_LOADING: "STOP_LOADING",
    LOAD_INTIAL_STATE: "LOAD_INTIAL_STATE",
    CLEAR_STATUS_CODE: "CLEAR_STATUS_CODE",
    LOGIN_SUCCESS_MESSAGE: "LOGIN_SUCCESS_MESSAGE",
    LOGIN_ERROR_MESSAGE: "LOGIN_ERROR_MESSAGE",
    START_LOGIN_LOADING: "START_LOGIN_LOADING",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    PUBLIC_URL_SUCCESS: "PUBLIC_URL_SUCCESS",
    OTP_SEND_LOADING: "OTP_SEND_LOADING",
    OTP_SEND_STATUS_CODE: "OTP_SEND_STATUS_CODE",
    OTP_SEND_MESSAGE: "OTP_SEND_MESSAGE",
    DRIVER_REGISTER_LOADING: "DRIVER_REGISTER_LOADING",
    DRIVER_REGISTER_STATUS_CODE: "DRIVER_REGISTER_STATUS_CODE",
    DRIVER_REGISTER_MESSAGE: "DRIVER_REGISTER_MESSAGE",
    OTP_VERIFY_LOADING: "OTP_VERIFY_LOADING",
    OTP_VERIFY_STATUS_CODE: "OTP_VERIFY_STATUS_CODE",
    OTP_VERIFY_MESSAGE: "OTP_VERIFY_MESSAGE",
    LMC_REGISTER_MESSAGE: "LMC_REGISTER_MESSAGE",
    LMC_REGISTER_STATUS_CODE: "LMC_REGISTER_STATUS_CODE",
    LMC_REGISTER_LOADING: "LMC_REGISTER_LOADING",
    CONTACT_US_LOADING: "CONTACT_US_LOADING",
    CONTACT_US_STATUS_CODE: "CONTACT_US_STATUS_CODE",
    CONTACT_US_MESSAGE: "CONTACT_US_MESSAGE",
    FORGOT_PASSWORD_LOADING: "FORGOT_PASSWORD_LOADING",
    FORGOT_PASSWORD_STATUS_CODE: "FORGOT_PASSWORD_STATUS_CODE",
    FORGOT_PASSWORD_MESSAGE: "FORGOT_PASSWORD_MESSAGE",
    FORGOT_PASSWORD_EMAIL: "FORGOT_PASSWORD_EMAIL",
    CLEAR_DATA: "CLEAR_DATA",
    CHANGE_PASSWORD_MESSAGE: "CHANGE_PASSWORD_MESSAGE",
    CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_STATUS_CODE: "CHANGE_PASSWORD_STATUS_CODE",
    VESSEL_ALERTS_DATA_SUCCESS: "VESSEL_ALERTS_DATA_SUCCESS",
    VASSEL_ALERTS_DATA_MESSAGE: "VASSEL_ALERTS_DATA_MESSAGE",
    VASSEL_ALERTS_DATA_LOADING: "VASSEL_ALERTS_DATA_LOADING",
    VASSEL_ALERTS_DATA_STATUS_CODE: "VASSEL_ALERTS_DATA_STATUS_CODE",
    RAIL_CROSSING_ALERTS_DATA_SUCCESS: "RAIL_CROSSING_ALERTS_DATA_SUCCESS",
    RAIL_CROSSING_ALERTS_DATA_STATUS_CODE: "RAIL_CROSSING_ALERTS_DATA_STATUS_CODE",
    RAIL_CROSSING_ALERTS_DATA_LOADING: "RAIL_CROSSING_ALERTS_DATA_LOADING",
    RAIL_CROSSING_ALERTS_DATA_MESSAGE: "RAIL_CROSSING_ALERTS_DATA_MESSAGE",
    ALERTS_ANNOUNCEMENTS_DATA_LOADING: "ALERTS_ANNOUNCEMENTS_DATA_LOADING",
    ALERTS_ANNOUNCEMENTS_DATA_SUCCESS:"ALERTS_ANNOUNCEMENTS_DATA_SUCCESS",
    ALERTS_ANNOUNCEMENTS_DATA_STATUS_CODE:"ALERTS_ANNOUNCEMENTS_DATA_STATUS_CODE",
    ALERTS_ANNOUNCEMENTS_DATA_MESSAGE:"ALERTS_ANNOUNCEMENTS_DATA_MESSAGE",
    MARINE_TERMINAL_ALERTS_DATA_SUCCESS: "MARINE_TERMINAL_ALERTS_DATA_SUCCESS",
    MARINE_TERMINAL_ALERTS_DATA_MESSAGE: "MARINE_TERMINAL_ALERTS_DATA_MESSAGE",
    MARINE_TERMINAL_ALERTS_DATA_LOADING: "MARINE_TERMINAL_ALERTS_DATA_LOADING",
    MARINE_TERMINAL_ALERTS_DATA_STATUS_CODE: "MARINE_TERMINAL_ALERTS_DATA_STATUS_CODE",
    RESOURCE_DATA_SUCCESS: "RESOURCE_DATA_SUCCESS",
    RESOURCE_DATA_LOADING: "RESOURCE_DATA_LOADING",
    RESOURCE_DATA_MESSAGE: "RESOURCE_DATA_MESSAGE",
    RESOURCE_DATA_STATUS_CODE: "RESOURCE_DATA_STATUS_CODE",

    TRAFFIC_AND_CAMERAS_DATA_SUCCESS: "TRAFFIC_AND_CAMERAS_DATA_SUCCESS",
    TRAFFIC_AND_CAMERAS_ALERTS_DATA_LOADING: "TRAFFIC_AND_CAMERAS_ALERTS_DATA_LOADING",
    TRAFFIC_AND_CAMERAS_DATA_MESSAGE: "TRAFFIC_AND_CAMERAS_DATA_MESSAGE",
    TRAFFIC_AND_CAMERAS_DATA_STATUS_CODE: "TRAFFIC_AND_CAMERAS_DATA_STATUS_CODE",

    MARINE_TERMINAL_LIST_DATA_SUCCESS: "MARINE_TERMINAL_LIST_DATA_SUCCESS",
    MARINE_TERMINAL_LIST_DATA_MESSAGE: "MARINE_TERMINAL_LIST_DATA_MESSAGE",
    MARINE_TERMINAL_LIST_DATA_LOADING: "MARINE_TERMINAL_LIST_DATA_LOADING",
    MARINE_TERMINAL_LIST_DATA_STATUS_CODE: "MARINE_TERMINAL_LIST_DATA_STATUS_CODE",

    SECURITY_AND_SAFETY_ALERT_DATA_SUCCESS: "SECURITY_AND_SAFETY_ALERT_DATA_SUCCESS",
    SECURITY_AND_SAFETY_ALERT_DATA_MESSAGE: "SECURITY_AND_SAFETY_ALERT_DATA_MESSAGE",
    SECURITY_AND_SAFETY_ALERT_DATA_LOADING: "SECURITY_AND_SAFETY_ALERT_DATA_LOADING",
    SECURITY_AND_SAFETY_ALERT_DATA_STATUS_CODE: "SECURITY_AND_SAFETY_ALERT_DATA_STATUS_CODE",


    USER_DETAIL_LOADING: "USER_DETAIL_LOADING",
    USER_DETAIL_STATUS_CODE: "CHANGE_PASSWORD_STATUS_CODE",
    PORT_MESSAGE_SUCCESS: "PORT_MESSAGE_SUCCESS",
    PORT_CONSTRUCTION_EVENTS_SUCCESS: "PORT_CONSTRUCTION_EVENTS_SUCCESS",
    PORT_INCIDENT_CLOSURES_SUCCESS: "PORT_INCIDENT_CLOSURES_SUCCESS",
    REGIONAL_MESSAGE_SIGNS_SUCCESS: "REGIONAL_MESSAGE_SIGNS_SUCCESS",
    REGIONAL_CONSTRUCTION_EVENTS_SUCCESS: "REGIONAL_CONSTRUCTION_EVENTS_SUCCESS",
    REGIONAL_INCIDENT_COUSURES_SUCCESS: "REGIONAL_INCIDENT_COUSURES_SUCCESS",
    DISTRICT_MESSAGE_SIGNS_SUCCESS: "DISTRICT_MESSAGE_SIGNS_SUCCESS",
    DISTRICT_MESSAGE_SIGNS_LOADING: "DISTRICT_MESSAGE_SIGNS_LOADING",
    PORT_MESSAGE: "PORT_MESSAGE",
    PORT_CONSTRUCTION_EVENTS_MESSAGE: "PORT_CONSTRUCTION_EVENTS_MESSAGE",
    PORT_INCIDENT_CLOSURES_MESSAGE: "PORT_INCIDENT_CLOSURES_MESSAGE",
    REGIONAL_MESSAGE_SIGNS_MESSAGE: "REGIONAL_MESSAGE_SIGNS_MESSAGE",
    REGIONAL_CONSTRUCTION_EVENTS_MESSAGE: "REGIONAL_CONSTRUCTION_EVENTS_MESSAGE",
    REGIONAL_INCIDENT_CLOSURES_MESSAGE: "REGIONAL_INCIDENT_CLOSURES_MESSAGE",
    DISTRICT_MESSAGE_SIGNS__MESSAGE: "DISTRICT_MESSAGE_SIGNS__MESSAGE",
    PORT_MESSAGE_LOADING: "PORT_MESSAGE_LOADING",
    PORT_CONSTRUCTION_EVENTS_LOADING: "PORT_CONSTRUCTION_EVENTS_LOADING",
    PORT_INCIDENT_CLOSURES_LOADING: "PORT_INCIDENT_CLOSURES_LOADING",
    REGIONAL_MESSAGE_SIGNS_LOADING: "REGIONAL_MESSAGE_SIGNS_LOADING",
    REGIONAL_CONSTRUCTION_EVENTS_LOADING: "REGIONAL_CONSTRUCTION_EVENTS_LOADING",
    REGIONAL_INCIDENT_CLOSURES_LOADING: "REGIONAL_INCIDENT_CLOSURES_LOADING",
    PORT_MESSAGE_STATUS_CODE: "PORT_MESSAGE_STATUS_CODE",
    PORT_CONSTRUCTION_EVENTS_STATUS_CODE: "PORT_CONSTRUCTION_EVENTS_STATUS_CODE",
    PORT_INCIDENT_CLOSURES_STATUS_CODE: "PORT_INCIDENT_CLOSURES_STATUS_CODE",
    REGIONAL_MESSAGE_SIGNS_STATUS_CODE: "REGIONAL_MESSAGE_SIGNS_STATUS_CODE",
    REGIONAL_CONSTRUCTION_EVENTS_STATUS_CODE: "REGIONAL_CONSTRUCTION_EVENTS_STATUS_CODE",
    REGIONAL_INCIDENT_CLOSURES_STATUS_CODE: "REGIONAL_INCIDENT_CLOSURES_STATUS_CODE",
    PARKING_LIST_BY_USER: "PARKING_LIST_BY_USER",
    SEARCH_PARKING_ZONE: "SEARCH_PARKING_ZONE",
    GET_DISTRICT_LIST_URL: "GET_DISTRICT_LIST_URL",
    PARKING_WAITING_LIST_BY_USER: "PARKING_WAITING_LIST_BY_USER",
    PARKING_ACTIVITY_LIST_BY_USER:"PARKING_ACTIVITY_LIST_BY_USER",
    PARKING_INVENTORY_LIST_BY_USER:"PARKING_INVENTORY_LIST_BY_USER",
    PARKING_HISTORY_LIST_BY_USER:"PARKING_HISTORY_LIST_BY_USER",


    SEARCH_PARKING_ZONE_SUCCESS: "SEARCH_PARKING_ZONE_SUCCESS",
    SEARCH_PARKING_ZONE_MESSAGE: "SEARCH_PARKING_ZONE_MESSAGE",
    SEARCH_PARKING_ZONE_LOADING: "SEARCH_PARKING_ZONE_LOADING",
    SEARCH_PARKING_ZONE_STATUS_CODE: "SEARCH_PARKING_ZONE_STATUS_CODE",

    RESERVATION_BOOKING_SUCCESS: "RESERVATION_BOOKING_SUCCESS",
    RESERVATION_BOOKING_MESSAGE: "RESERVATION_BOOKING_MESSAGE",
    RESERVATION_BOOKING_LOADING: "RESERVATION_BOOKING_LOADING",
    RESERVATION_BOOKING_STATUS_CODE: "RESERVATION_BOOKING_STATUS_CODE",

    GUEST_USER_ID_SUCCESS: "GUEST_USER_ID_SUCCESS",
    GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
    GET_DASHBOARD_DATA_SUCCESS1: "GET_DASHBOARD_DATA_SUCCESS1",
    GET_DASHBOARD_DATA_SUCCESS2: "GET_DASHBOARD_DATA_SUCCESS2",
    GET_DASHBOARD_LMC_DATA_SUCCESS:"GET_DASHBOARD_LMC_DATA_SUCCESS",
    USER_SETTING:"USER_SETTING",

    DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
    DELETE_ACCOUNT_MESSAGE: "DELETE_ACCOUNT_MESSAGE",
    DELETE_ACCOUNT_LOADING: "DELETE_ACCOUNT_LOADING",
    DELETE_ACCOUNT_STATUS_CODE: "DELETE_ACCOUNT_STATUS_CODE",
    CANCEL_DELETE_ACCOUNT_SUCCESS: "CANCEL_DELETE_ACCOUNT_SUCCESS",
    CANCEL_DELETE_ACCOUNT_MESSAGE: "CANCEL_DELETE_ACCOUNT_MESSAGE",
    CANCEL_DELETE_ACCOUNT_LOADING: "CANCEL_DELETE_ACCOUNT_LOADING",
    CANCEL_DELETE_ACCOUNT_STATUS_CODE: "CANCEL_DELETE_ACCOUNT_STATUS_CODE",
}