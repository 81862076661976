 
import Header from "src/Containers/Header";
import Aboutusbanner from "src/assets/aboutus-banner.png";
import { Link } from "react-router-dom";
  
import { languageJson } from "src/StringVariable"; 
import portimg1 from "../../assets/1.jpg"; 
import portimg5 from "../../assets/5.jpg";
import everport from "../../assets/Terminal_Gate_Hours/EverPort_Gate.png";
import matson from "../../assets/Terminal_Gate_Hours/Matson_Gate.png";
import ssa from "../../assets/Terminal_Gate_Hours/SSA_Gate.png";
import trapac from "../../assets/Terminal_Gate_Hours/TraPac_Gate.png";
function ViewImage() {
   return (
    <div>
      <Header />
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg5} alt=""/> 
             <img className="banner_img" src={Aboutusbanner} alt=""/>      
      </div>
      {/* <div className="headerBannerMainDiv">
        <img className="banner_img" src={Aboutusbanner} alt=""/> 
      </div> */}
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.frequently_Asked_Questions_txt}</span>
            </p>
          </div>
        </div>
      </div>
      <section className="faqs-section">
        <div className="container">
          <div className="row ">
            <div className="row postionRelative ">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 titleTxtDiv center-head-underline d-flex justify-content-center py-4">
                <h1 className="text-center  py-2 f-head">
                  {languageJson.sectionName.frequentlyAskedQuestions}{" "}
                </h1>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 viewBtnDiv  d-flex justify-content-end  align-items-center"></div>
            </div>
          </div>

          {/* first row */}
          <div className="row">
          <div className="image-row-banner">
             <img className="banner_img" src={everport} alt=""/>
             <img className="banner_img" src={matson} alt=""/> 
             <img className="banner_img" src={ssa} alt=""/>    
             <img className="banner_img" src={trapac} alt=""/>      
      </div>
          </div>
        </div>
      </section>
      {/* <Findus /> */}
    </div>
  );
}

export default ViewImage;
