/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Header from "../../Containers/Header";
import { useParams, Redirect } from "react-router-dom";
import cameraIcon from "../../assets/camera-icon.png";
import portimg0 from "../../assets/marinebanner.png";
import portimg1 from "../../assets/1.jpg";
import portimg2 from "../../assets/2.jpg";
import portimg3 from "../../assets/3.jpg";
import portimg4 from "../../assets/4.jpg";
import portimg5 from "../../assets/5.jpg";
import portimg6 from "../../assets/6.jpg";
import oict1 from "../../assets/OICT/2.jpg";
import oict2 from "../../assets/OICT/3.jpg";
import trapac1 from "../../assets/Trapac/4.png";
import trapac2 from "../../assets/Trapac/2.jpg";
import matson1 from "../../assets/Matson/1.jpg";
import matson2 from "../../assets/Matson/5.jpg";
import everport1 from "../../assets/EverPort/1.jpg";
import everport2 from "../../assets/EverPort/2.png";
 
 
import bluecalender from "../../assets/dateholiday.png";
import bluehours from "../../assets/hours_image.png";
import blueaddress from "../../assets/address_image.png";
import trafficCamera_image from "../../assets/trafficcamera-image.png";
import weather_image from "../../assets/blueship1.png";
import tollsicon from "../../assets/graphblue.png";
import axios from "src/redux/actions/apis";
import "./MarineTerminalDetail.css";
import ImagePop from "../../Containers/image/ImagePop";
import OtpBackdrop from "../../Containers/otp/OtpBackDrop";
import Speedometer from "../../Containers/Speedometer";
import MarineTerminalGraph from "./MarineTerminalGraph";
import oaklandlogo from "../../assets/new_logo.png";
import parkingBgImg  from "../../assets/parkingBgImg.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  GET_MARINE_TERMINAL_DATA_BY_ID_URL,
  MARINE_CAMERAS_LIST_BY_DISTRICT,
  MARINE_ALERTS_BY_DISTRICT,
  VESSEL_OPERATIONS_URL,FAQFILE_MARINE_TERMINAL,
  GET_HOLIDAYS_FOR_PUBLIC
} from "../../url/Url";
import DataNotFound from "../DataNotFound/DataNotFound";
import MaponModal from "./MaponModal";
import { DateTimeFormate } from "../../utils/DataFormate";
import { languageJson } from "../../StringVariable";
import SpinerLoader from "src/utils/SpinerLoader";
import Pagination from "src/admin/views/components/custome/Pagination";
import LiveStream from "../../utils/LiveStream";
import { NotificationPopup } from "src/utils/Notifications";
import { Link } from "react-router-dom";
import { submitGAEvent } from "src/utils/GAEvents";
import DetailVesselOperation from "../Vessel_Operations/DetailVesselOperation";
import "../Vessel_Operations/VesselOperations.css";
import { useSelector } from "react-redux";
import MarineTerminalCalendar from "../Vessel_Operations/MarineTerminalCalendar";
// import VesselScheduleAdvent from "../Vessel_Operations/VesselScheduleAdvent";

export const getTotalWaitTimeColor = (time) => {
  if (time < 75) {
    return `terminal__total__wait__time__green`;
  } else if (time >= 75 && time <= 120) {
    return `terminal__total__wait__time__yellow`;
  } else {
    return `terminal__total__wait__time__red`;
  }
};

function MerineTerminal() {
  const [marineTerminalData, setMarineTerminalData] = useState(null);
  const [completMarineData, setCompleteMarineData] = useState();
  const [cameraList, setCameraList] = useState(null);
  const [cameraListShow, setCameraListShow] = useState(true);
  const [alertListShow, setAlertListShow] = useState(false);
  const [visible, setVisible] = useState(8);
  const [singleImage, setSingleImage] = useState();
  const [showPopUp, setShowPopUp] = useState(false);
  const [allAlerts, setAllAlerts] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [gatesData, setGatesData] = useState([{}]);
  const [currentGate, setCurrentGate] = useState({
    OICT_Back_Gate: false,
    OICT_Front_Gate: false,
    OICT_West_Gate: false,
    OICT_East_Gate: false,
  });
  const [pageDetails, setpageDetails] = useState({});
  const [gateId, setGateId] = useState(0);
  const [marineGateName, setMarineGateName] = useState(null);
  const [mapModal, setMapModal] = useState(false);
  const [marinMapDetail, setMarinMapDetail] = useState({});
  const limit = 10;
  const [district, setDistrict] = useState("");

  const marineGate = useRef(null);

  const [terminalGraph, setTerminalGraphShow] = useState(false);
  const [redirectState, setRedirect] = useState(false);
  const { id } = useParams();
  const [imageVersion, setImageVersion] = useState(0);
  const [vessalSchedules, setVessalSchedulesShow] = useState(false);
  const [VesselOperationsList, setVesselOperationsList] = useState();
  const [mapVessalModal, setMapVessalModal] = useState(false);
  const [marinMapVessalDetail, setMarinMapVessalDetail] = useState({});
  // const [advantVessalSchedules, setAdvantVessalSchedulesShow] = useState(false);


  let singleUrls = useSelector(state => state.publicUrlState);
  const [userCondition, setUserCondition] = useState(null)
  useEffect(() => {
    if (singleUrls && singleUrls.singleUrls && singleUrls.singleUrls.length > 0) {
      let userCondition = singleUrls.singleUrls.find(({ url_id }) => url_id === 'faq_url');
      setUserCondition(userCondition ? userCondition.url_link : FAQFILE_MARINE_TERMINAL)
    }
  }, [singleUrls]);


  const [gateHoursData, setGateHoursData] = useState(false);
  const [holidaysData, setHolidaysData] = useState(true);
  const [holidaysJSONData, setHolidaysJSONData] = useState([]);
  const [addressData, setAddressData] = useState(false);
  
  
  const onHolidaysData = () => {
        getHolidayCalender();
        setGateHoursData(false);
        setHolidaysData(true);
        setAddressData(false)
  };
  const onGateHours = () => {
        setGateHoursData(true);
        setHolidaysData(false);
        setAddressData(false)
 };
 const onAddress = () => {
        setGateHoursData(false);
        setHolidaysData(false);
        setAddressData(true)
};



  const handleRefreshImages = () => {
    setImageVersion((prevVersion) => prevVersion + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshImages();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [cameraList]); 

  const setPage = ({ selected }) => {
    setSelectedPage(selected + 1);
  };

  useEffect(() => {
    if (marineTerminalData != null) {
      const terminalName =
        marineTerminalData && marineTerminalData.terminal_name;
      submitGAEvent(`VIEW MARINE TERMINAL- ${terminalName}`);
    }
  }, [marineTerminalData]);

  const [idx, setIdx] = useState(null);
  const showTooltip = (id) => {
    setIdx(id);
    const obj = {
      customcode: 200,
      message: languageJson.text_copied_txt,
    };
    NotificationPopup(obj.customcode, obj.message);
  };

  //get marine data by Id
  const getMarineDataById = async (id) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(GET_MARINE_TERMINAL_DATA_BY_ID_URL + id, requestObj)
        .then((res) => {
          const { gateList, customcode } = res.data;
          if (customcode === 200 && Object.keys(gateList).length > 0) {
            setCompleteMarineData(gateList);
            setMarineTerminalData(gateList);
            setDistrict(gateList.district);
            if (marineGateName === null) {
              setMarineGateName(gateList.gates[0].gate_name);
            }
          } else if (customcode === 213) {
            setRedirect(true);
          } else {
            setMarineTerminalData({});
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMarineDataById(id);
    if (district !== "") {
      getCameraList(district);
    }
  }, [district]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCurrentGate(idx);
  }, [marineGateName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setShowPopUp(false);
  };

  const getTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }
    if (hours === 1 && minutes > 1) {
      return `${hours} ${languageJson.hour_txt}  ${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes > 1) {
      return `${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes === 1) {
      return `${minutes} ${languageJson.minute_txt}`;
    }
    if (minutes === 0) {
      if (hours > 1) {
        return `${hours} ${languageJson.hours_txt}`;
      } else {
        return `${hours} ${languageJson.hours_txt}`;
      }
    } else {
      return `${hours} ${languageJson.hours_txt}  ${minutes} ${languageJson.minutes_txt}`;
    }
  };

  const getStreetWaitTime = (time) => {
    if (time < 15) {
      return `street__wait__time__green`;
    } else if (time >= 15 && time <= 30) {
      return `street__wait__time__yellow`;
    } else {
      return `street__wait__time__red`;
    }
  };

  const getTerminalTrunTime = (time) => {
    if (time < 60) {
      return `terminal__turn__time__green`;
    } else if (time >= 60 && time <= 90) {
      return `terminal__turn__time__yellow`;
    } else {
      return `terminal__turn__time__red`;
    }
  };

  const getCameraList = async (district) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(`${MARINE_CAMERAS_LIST_BY_DISTRICT}${district}`, requestObj)
        .then((res) => {
          const { cameras, customcode } = res.data;
          if (customcode === 200 && cameras.length > 0) {
            setCameraList(cameras);
          } else {
            setCameraList([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAlertList = async (district, page, limit) => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(
          `${MARINE_ALERTS_BY_DISTRICT}/${district}/${page}/${limit}`,
          requestObj
        )
        .then((res) => {
          const { alerts, customcode, limit, page, total } = res.data;
          if (customcode === 200 && alerts.length > 0) {
            setAllAlerts(alerts);
            setpageDetails({ per_page: limit, page, total });
          } else {
            setAllAlerts([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const loadMore = (e) => {
    setVisible(visible + 8);
  };

  const getTheImage = (img) => {
    setSingleImage(img);
    setShowPopUp(true);
  };

  const handleAlertClick = () => {
    setAlertListShow(true);
    setCameraListShow(false);
    setTerminalGraphShow(false);
    setVessalSchedulesShow(false);
    // setAdvantVessalSchedulesShow(false)

    //getAlertList(district, 1, limit);
  };

  const handleCameraClick = () => {
    submitGAEvent("MARINE TERMINAL CAMERAS");
    setAlertListShow(false);
    setCameraListShow(true);
    setTerminalGraphShow(false);
    setVessalSchedulesShow(false);
    // setAdvantVessalSchedulesShow(false)

  };

  const handleTerminalGraph = () => {
    setAlertListShow(false);
    setCameraListShow(false);
    setTerminalGraphShow(true);
    setVessalSchedulesShow(false);
    // setAdvantVessalSchedulesShow(false)


  };

  const handleVessalSchedules = () => {
    getVesselOperationsData();
    setAlertListShow(false);
    setCameraListShow(false);
    setTerminalGraphShow(false);
    setVessalSchedulesShow(true);
    // setAdvantVessalSchedulesShow(false)

  };
  // const handleAdavntVessalSchedules = () => {
  //   getVesselOperationsData();
  //   setAlertListShow(false);
  //   setCameraListShow(false);
  //   setTerminalGraphShow(false);
  //   setVessalSchedulesShow(false);
  //   setAdvantVessalSchedulesShow(true)
  // };

  const getSpeedoMeterTime = (time) => {
    if (time <= 150) {
      return time;
    } else {
      return 150;
    }
  };

  const getTheCurrentGate = (e) => {
    const currentElement = e.target;
    const marineGate = currentElement.closest(".marine").id;
    const myState = Object.keys(currentGate).reduce((result, k) => {
      return { ...result, [k]: marineGate === k };
    }, {});
    setCurrentGate(myState);
    Object.keys(completMarineData).map((data) => {
      if (data === "gates") {
        completMarineData[data].map((gatesData) => {
          Object.keys(myState).map((gates) => {
            if (myState[gates] === true) {
              const myCustomCurrentGate = gates.replaceAll("_", " ");

              if (gatesData.gate_name === myCustomCurrentGate) {
                const {
                  current_queue_length,
                  current_street_wait_time,
                  total_turn_time,
                  current_trucks_in_queue,
                  current_trucks_in_terminal,
                  today_street_wait_time,
                  today_turn_time,
                  gate_id,
                  gate_name,
                } = gatesData;
                setGateId(gate_id);
                setMarineGateName(gate_name);
                setMarineTerminalData({
                  ...marineTerminalData,
                  current_queue_length,
                  current_street_wait_time,
                  total_turn_time,
                  current_trucks_in_queue,
                  current_trucks_in_terminal,
                  today_street_wait_time,
                  today_turn_time,
                });
              }
            }
            return false;
          });
          return false;
        });
      }
      return false;
    });
  };
  const getTheGateName = (gateName) => {
    if (gateName) {
      return gateName.replaceAll(" ", "_");
    }
  };
  const getGatesData = () => {
    if (completMarineData) {
      Object.keys(completMarineData).map((key) => {
        if (key === "gates") {
          setGatesData(completMarineData[key]);
        }
        return false;
      });
    }
  };
  const getMyCurrentGate = (gateName) => {
    if (gateName) {
      const changedGateName = gateName.replaceAll(" ", "_");
      return currentGate[changedGateName];
    }
  };
  const getCurrentGate = (idx) => {
    if (currentGate) {
      let gatesCount = Object.keys(currentGate).length;
      let count = 0;
      Object.keys(currentGate).map((key) => {
        if (currentGate[key] === false) {
          count++;
        }
        return false;
      });
      return gatesCount === count && idx === 0;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getMarineDataById(id);
    }, 15000);

    getGatesData();
    return () => {
      clearInterval(interval);
    };
  }, [completMarineData]); // eslint-disable-line react-hooks/exhaustive-deps
  

  useEffect(() => {
    getCurrentGate();
  }, [currentGate]); // eslint-disable-line react-hooks/exhaustive-deps

  const setModalOpen = (item) => {
    setMarinMapDetail(item);
    setMapModal(true);
  };
  const setModalClose = () => {
    setMapModal(false);
  };

  const convertMetersToMiles = (value) => {
    const df = value * 0.00062137;
    return parseFloat(df).toFixed(2);
  };
  const filterGateDate = () => {
    let filterdata = marineTerminalData.gates.filter(
      (temp) => temp.gate_name === marineGateName
    );
    return filterdata.length > 0 ? filterdata[0] : {};
  };
  
  const getBannerImg = () => {
    let imageUrl = portimg0;
    let randomNum =  parseInt(id)
    imageUrl = randomNum === 0 ? portimg0 : randomNum===1 ? portimg1 : randomNum===2 ? portimg2 : randomNum===3 ? portimg3 : randomNum===4 ? portimg4 : randomNum===5 ? portimg5 : portimg6;
   return imageUrl; 
  }

// Vessal Schedules
const getVesselOperationsData = async () => {
  const requestObj = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios
      .get(VESSEL_OPERATIONS_URL, requestObj)
      .then((res) => {
        const { vessels, customcode } = res.data;
        if (customcode === 200 && vessels.length > 0) {
          setVesselOperationsList(vessels);
        } else {
          setVesselOperationsList([])
        }
      });
  } catch (err) {
    console.log(err);
  }
};

const onSort = (event, sortKey, index) => {
  const data = VesselOperationsList[index].vesselsList;
  if (
    JSON.stringify(VesselOperationsList[index].vesselsList) ===
    JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
  ) {
    data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
  } else {
    data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
  }
  setVesselOperationsList([...VesselOperationsList]);
};

const onSortDate = (event, sortKey, index) => {
  const data = VesselOperationsList[index].vesselsList;
  if (
    JSON.stringify(VesselOperationsList[index].vesselsList) ===
    JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
  ) {
    data.sort((a, b) => a[sortKey] - (b[sortKey]));
  } else {
    data.sort((a, b) => b[sortKey] - (a[sortKey]));
  }
  setVesselOperationsList([...VesselOperationsList]);
};
const setModalVessalClose = () => {
  setMapVessalModal(false);
};

const setModalVessalOpen = (item) => {
  setMarinMapVessalDetail(item);
  setMapVessalModal(true);
};

 //get marine data by Id
 const getHolidayCalender = async () => {
  const requestObj = {
    headers: {
      "Content-Type": "application/xml",
    },
  };
  try {
    await axios
      .get(GET_HOLIDAYS_FOR_PUBLIC, requestObj)
      .then((res) => {
        const { data, customcode } = res.data;
        if (customcode === 200 && Object.keys(data).length > 0) {
          setHolidaysJSONData(data);

        } else {
          setHolidaysJSONData([]);
        }
      });
  } catch (err) {
    console.log(err);
  }
};

useEffect(() => {
  getHolidayCalender()
}, []); 

console.log("holidaysData",holidaysData)





  if (redirectState) return <Redirect to="/pageNotFound" />;
  return (
    <div>
      <Header />
      {marineTerminalData != null ? (
        <div className="image-row-banner">
          <img
            className="banner_img"
            src={
              marineTerminalData.terminal_name === "OICT"
                ? oict1
                : marineTerminalData.terminal_name === "Matson"
                ? matson1
                : marineTerminalData.terminal_name === "TraPac"
                ? trapac1
                : marineTerminalData.terminal_name === "Everport"
                ? everport1
                : portimg1
            }
            alt=""
          />
          <img
            className="banner_img"
            src={
              marineTerminalData.background_image === null ||
              marineTerminalData.background_image === "" ||
              marineTerminalData.background_image === undefined
                ? parkingBgImg
                : marineTerminalData.background_image
            }
            alt="banner"
          />
          <img
            className="banner_img"
            src={
              marineTerminalData.terminal_name === "OICT"
                ? oict2
                : marineTerminalData.terminal_name === "Matson"
                ? matson2
                : marineTerminalData.terminal_name === "TraPac"
                ? trapac2
                : marineTerminalData.terminal_name === "Everport"
                ? everport2
                : portimg2
            }
            alt=""
          />
        </div>
      ) : (
        <div className="headerBannerMainDiv">
          <img className="banner_img" src={getBannerImg()} alt="" />
        </div>
      )}
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark">
                {" "}
                / {languageJson.marine_terminals_txt}
              </span>
            </p>
          </div>
        </div>
      </div>
      <section className="marineterminal-head-section">
        {marineTerminalData != null &&
          marineTerminalData.hasOwnProperty("terminal_name") && (
            <div className="container pt-5">
              <div className="row">
                <div className="col-md-12 text-center terminalImgDiv sm-text-start">
                  <img
                    src={
                      marineTerminalData.terminal_logo !== null &&
                      marineTerminalData.terminal_logo !== ""
                        ? marineTerminalData.terminal_logo
                        : oaklandlogo
                    }
                    onError={(e) => {
                      e.currentTarget.src = oaklandlogo;
                    }}
                    className="marineterminal-img"
                    alt="SSA-terminal"
                  />
                </div>
                <div className="topNoticeHeaderMain">
                  <div className="col-md-12 m-text-start text-danger">
                    {marineTerminalData.notice === "null" ||
                    marineTerminalData.notice == null ? (
                      ""
                    ) : (
                      <span className="terminalNoticeDespDiv">
                        {" "}
                        <LiveStream />
                        {languageJson.terminalNotices_txt}:{" "}
                        <p>
                          {" "}
                          {marineTerminalData.notice !== null ||
                          marineTerminalData.notice.toLowerCase() !== "null"
                            ? marineTerminalData.notice
                            : "-"}
                        </p>
                      </span>
                    )}
                  </div>
                  {/* {marineTerminalData !== null &&
                    Object.keys(marineTerminalData).length > 0
                    ? marineTerminalData.hasOwnProperty(
                      "total_turn_time"
                    ) && (
                      <div className="col-md-12  lastUpdatedTxt ">
                        <span className="lastUpdatedSpan">
                          {" "}
                          {languageJson.last_updated_value_txt}:{" "}
                          {DateTimeFormate(filterGateDate().timestamp)}
                        </span>
                      </div>
                    )
                    : ""} */}
                </div>
              </div>
            </div>
          )}
      </section>

      <section className="marineterminal-nav-section">
        <div className="container mt-3">
          {marineTerminalData !== null && marineGate !== null && (
            <div className="col-md-12 text-center  border-grey merine">
              <div
                className={`row rowTabOptDiv gateTab_${gatesData.length}`}
                ref={marineGate}
              >
                {gatesData &&
                  gatesData.length > 0 &&
                  gatesData.map((gate, idx) => (
                    <div
                      key={idx}
                      className={`col-md-3 marine cursor-pointer ${
                        getCurrentGate(idx) ? "text-white terminal" : ""
                      } ${
                        getMyCurrentGate(gate.gate_name)
                          ? "text-white terminal"
                          : ""
                      }`}
                      id={`${getTheGateName(gate.gate_name)}`}
                      name={`${getTheGateName(gate.gate_name)}`}
                      onClick={getTheCurrentGate}
                      style={{
                        display:
                          marineTerminalData.terminal_name === gate.gate_name
                            ? "none"
                            : "block",
                      }}
                    >
                      <p className="pt-2">
                        {gate.gate_name}
                        {/* {marineTerminalData.terminal_name === gate.gate_name
                          ? "-InboundLanes"
                          : ""} */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {/* style={{display: marineTerminalData.terminal_name === gate.gate_name ? "none":"block"}} */}
        {/* ------------------meter section start------------------------------ */}
        {marineTerminalData !== null &&
        Object.keys(marineTerminalData).length > 0 ? (
          marineTerminalData.hasOwnProperty("total_turn_time") && (
            <div className="container mt-5 speedometerMainDiv">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-12 speedometerInnerDiv ">
                  <div className="row pb-3">
                    <Speedometer
                      time={[
                        getSpeedoMeterTime(
                          filterGateDate().total_turn_time !== null &&
                            filterGateDate().total_turn_time !== ""
                            ? filterGateDate().total_turn_time
                            : 0
                        ),
                      ]}
                    />
                  </div>
                  <div>
                    <h3 className="mt-1 currentwaittime-head">
                      <label>{languageJson.current_waiting_time_txt}: </label>{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {getTime(
                          filterGateDate().total_turn_time !== null &&
                            filterGateDate().total_turn_time !== ""
                            ? filterGateDate().total_turn_time
                            : 0
                        )}
                      </span>
                    </h3>
                  </div>
                </div>

                {/* ----------------------------------------------- */}
                <div className="col-md-8 col-lg-8 col-sm-12 terminalTimeDetailDiv">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6
                        style={{ textTransform: "capitalize" }}
                        className={`marinesty ${getStreetWaitTime(
                          filterGateDate().current_street_wait_time !== null &&
                            filterGateDate().current_street_wait_time !== ""
                            ? filterGateDate().current_street_wait_time
                            : 0
                        )}`}
                      >{`${getTime(
                        filterGateDate().current_street_wait_time !== null &&
                          filterGateDate().current_street_wait_time !== ""
                          ? filterGateDate().current_street_wait_time
                          : 0
                      )}`}</h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {languageJson.street_wait_time_txt}
                      </h6>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6 className="marinesty">{`${
                        filterGateDate().current_trucks_in_queue !== null &&
                        filterGateDate().current_trucks_in_queue !== ""
                          ? filterGateDate().current_trucks_in_queue
                          : 0
                      } ${languageJson.trucks_txt}`}</h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {languageJson.trucks_in_queue_txt}
                      </h6>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6
                        style={{ textTransform: "capitalize" }}
                        className={`marinesty ${getTerminalTrunTime(
                          filterGateDate().current_turn_time !== null &&
                            filterGateDate().current_turn_time !== ""
                            ? filterGateDate().current_turn_time
                            : 0
                        )}`}
                      >{`${getTime(
                        filterGateDate().current_turn_time !== null &&
                          filterGateDate().current_turn_time !== ""
                          ? filterGateDate().current_turn_time
                          : 0
                      )} `}</h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {languageJson.Terminal_Turn_Time_txt}
                      </h6>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6 className="marinesty">{`${
                        filterGateDate().current_trucks_in_terminal !== null &&
                        filterGateDate().current_trucks_in_terminal !== ""
                          ? filterGateDate().current_trucks_in_terminal
                          : 0
                      } ${languageJson.trucks_txt}`}</h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {
                          languageJson.Report_Export_IngateOutgate_Lable
                            .CurrentTrucks
                        }
                      </h6>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6
                        style={{ textTransform: "capitalize" }}
                        className={`marinesty ${getTotalWaitTimeColor(
                          (filterGateDate().today_turn_time !== "" &&
                          filterGateDate().today_turn_time !== null
                            ? filterGateDate().today_turn_time
                            : 0) +
                            (filterGateDate().today_street_wait_time !== "" &&
                            filterGateDate().today_street_wait_time !== null
                              ? filterGateDate().today_street_wait_time
                              : 0)
                        )}`}
                      >{`${getTime(
                        (filterGateDate().today_turn_time !== "" &&
                        filterGateDate().today_turn_time !== null
                          ? filterGateDate().today_turn_time
                          : 0) +
                          (filterGateDate().today_street_wait_time !== "" &&
                          filterGateDate().today_street_wait_time !== null
                            ? filterGateDate().today_street_wait_time
                            : 0)
                      )}`}</h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {languageJson.average_today_txt}
                      </h6>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 px-5 py-4">
                      <h6 className="marinesty">
                        {(filterGateDate().today_volume !== null &&
                        filterGateDate().today_volume !== ""
                          ? filterGateDate().today_volume
                          : 0) +
                          ` ` +
                          languageJson.trucks_txt}
                      </h6>
                      <div className="borderclr"></div>
                      <h6 className="meter-bottom-text text-center py-2">
                        {languageJson.trucks_exited_terminal}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : marineTerminalData !== null &&
          Object.keys(marineTerminalData).length === 0 ? (
          <div className="  text-center border-0">
            {" "}
            <DataNotFound />
          </div>
        ) : (
          <SpinerLoader />
        )}
      </section>

      {/* ------------------meter section end ------------------------------ */}
      {marineTerminalData !== null &&
      Object.keys(marineTerminalData).length > 0 ? (
        <div
          className="col-md-12 m-text-start text-danger"
          style={{
            margin: "20px",
            display:
              filterGateDate().current_turn_time ||
              filterGateDate().current_street_wait_time ||
              filterGateDate().current_trucks_in_queue ||
              filterGateDate().current_queue_length ||
              filterGateDate().today_street_wait_time ||
              filterGateDate().today_turn_time ||
              filterGateDate().total_turn_time
                ? "none"
                : "block",
          }}
        >
          <p style={{ color: "red" }}>
            <center>
              <h5>
                {" "}
                <b>{languageJson.terminal_closed_txt}</b>
              </h5>
            </center>
          </p>
        </div>
      ) : (
        <span></span>
      )}
      <section className="currentWaitingTimeDiv" style={{ margin: "20px" }}>
        <div className="container">
          <div className="row">
            <div style={{ width: "50%", float: "left", fontSize: "larger" }}>
              <p style={{ textAlign: "left" }}>
                <a target="_blank" rel="noreferrer" href={userCondition}>
                  {languageJson.Truck_Turn_Time_FAQs_txt}
                </a>
              </p>
            </div>

            <div style={{ width: "50%", float: "right", fontSize: "larger" }}>
              <p style={{ textAlign: "right" }}>
                {" "}
                {marineTerminalData !== null &&
                Object.keys(marineTerminalData).length > 0
                  ? marineTerminalData.hasOwnProperty("total_turn_time") && (
                      <div className="col-md-12 lastUpdatedTxt ">
                        <span className="lastUpdatedSpan">
                          {" "}
                          {languageJson.last_updated_value_txt}:{" "}
                          {DateTimeFormate(filterGateDate().timestamp)}
                        </span>
                      </div>
                    )
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* -----------------------current waiting time section------------------------ */}
      <div className="container vesselMainPage trafficCameraMainDiv newTrafficCameraTabMainDiv">
        <div className="row container-fluid py-5 tabVesselDiv">
          <div className="col-4 center-head-underline titleTabOption">
            <a
              onClick={() => {
                handleCameraClick();
              }}
              className={`text-decoration-none ${
                cameraListShow
                  ? "center-head-underline-clr vo-headtxt py-2 "
                  : ""
              } cursor-pointer vesselOptDiv`}
            >
              <h2
                className={`${cameraListShow ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={trafficCamera_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b> {languageJson.Cameras_txt} </b>
              </h2>{" "}
            </a>
          </div>

          <div className="col-4 titleTabOption">
            <a
              onClick={() => {
                handleTerminalGraph();
              }}
              className={`text-decoration-none   ${
                terminalGraph
                  ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                  : ""
              } text-dark cursor-pointer alertTxtDiv`}
            >
              <h2 className={`${terminalGraph ? "text-primary" : "text-dark"}`}>
                <span>
                  <img
                    src={tollsicon}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.Terminal_Graph_txt}</b>
              </h2>{" "}
            </a>
          </div>

          <div className="col-4 titleTabOption">
            <a
              onClick={() => {
                handleVessalSchedules();
              }}
              className={`text-decoration-none text-dark ${
                vessalSchedules
                  ? "center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                  : ""
              } cursor-pointer`}
            >
              <h2
                className={`${vessalSchedules ? "text-primary" : "text-dark"}`}
              >
                <span>
                  <img
                    src={weather_image}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b>{languageJson.vessel_schedules_txt}</b>
              </h2>{" "}
            </a>
          </div>
        </div>

        <div className="container">
          <div className="marine-container">
            <div className="row pt-2">
              {terminalGraph && (
                <span>
                  {" "}
                  <MarineTerminalGraph
                    gate_id={gateId}
                    terminalName={
                      marineTerminalData && marineTerminalData.terminal_name
                    }
                    marineGateName={marineGateName}
                  />{" "}
                </span>
              )}
              {cameraListShow ? (
                cameraList != null &&
                cameraListShow &&
                cameraList &&
                cameraList.length > 0 ? (
                  cameraList.slice(0, visible).map((camera, idx) => (
                    <div
                      key={camera.idx}
                      className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mainCardDiv fade-in"
                    >
                      <div className="custom-card marine-card my-2">
                        <img
                          onClick={() => getTheImage(camera.camera_preview_url)}
                          className={
                            camera.camera_thumbnail !== ""
                              ? "card-img-top marine-gallery-img "
                              : "card-img-top marine-gallery-img noImgDiv"
                          }
                          src={
                            camera.camera_thumbnail !== ""
                              ? `${camera.camera_thumbnail}&t=${imageVersion}`
                              : oaklandlogo
                          }
                          onError={(e) => {
                            e.currentTarget.src = oaklandlogo;
                          }}
                          alt="Card"
                        />

                        <div className="card-body" style={{ padding: "5px" }}>
                          <div className="d-flex justify-content-between">
                            <p className="camera-txt">{camera.location_name}</p>
                            <img
                              src={cameraIcon}
                              className="location-icon"
                              alt="marineterminal-gallery"
                              onClick={() => setModalOpen(camera)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : cameraList != null &&
                  cameraList &&
                  cameraList.length === 0 ? (
                  <div className="  text-center border-0">
                    {" "}
                    <DataNotFound />
                  </div>
                ) : (
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  fade-in">
                    <SpinerLoader />
                  </div>
                )
              ) : (
                ""
              )}
              {cameraList !== null &&
                cameraList &&
                cameraListShow &&
                visible < cameraList.length && (
                  <div className="row mb-4">
                    <button
                      onClick={loadMore}
                      type="button"
                      className="load-more cursor-pointer"
                    >
                      {languageJson.LoadMore_txt}
                    </button>
                  </div>
                )}
              <>
                {alertListShow && (
                  <section className="railways-cross-section m-0">
                    <div className="container">
                      {alertListShow &&
                      allAlerts != null &&
                      allAlerts.length > 0 ? (
                        <div className="row py-2 railalert_radius alertListShowDiv ">
                          {allAlerts.map((alert, index) => {
                            return (
                              <div
                                className="col-md-12 alertListMainDiv border border-grey"
                                key={index}
                              >
                                <span className="alertListOpt">
                                  <p className="sty2">
                                    {alert.description} - {alert.location}
                                  </p>
                                  <p className="sty3 dateAlert">
                                    {DateTimeFormate(alert.last_updated)}{" "}
                                  </p>
                                </span>

                                <CopyToClipboard
                                  text={alert.description}
                                  onCopy={() => showTooltip(index)}
                                >
                                  {index === idx ? (
                                    <>
                                      <div className="buttonDiv">
                                        <button
                                          title={languageJson.copy_button_txt}
                                        >
                                          <i
                                            className="fa fa-clone"
                                            aria-hidden="true"
                                          ></i>
                                          <b>{languageJson.copy_button_txt}</b>
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="buttonDiv">
                                      <button
                                        title={languageJson.copy_button_txt}
                                      >
                                        <i
                                          className="fa fa-clone"
                                          aria-hidden="true"
                                        ></i>
                                        <b>{languageJson.copy_button_txt}</b>
                                      </button>
                                    </div>
                                  )}
                                </CopyToClipboard>
                              </div>
                            );
                          })}
                        </div>
                      ) : allAlerts != null &&
                        allAlerts &&
                        allAlerts.length === 0 ? (
                        <div className="  text-center border-0">
                          {" "}
                          <DataNotFound />
                        </div>
                      ) : (
                        <SpinerLoader />
                      )}
                    </div>
                  </section>
                )}
              </>
              {alertListShow &&
              alertListShow &&
              allAlerts != null &&
              allAlerts.length > 0 ? (
                <Pagination
                  public={true}
                  total={pageDetails.total}
                  per_page={pageDetails.per_page}
                  selectedPage={selectedPage}
                  setPage={setPage}
                />
              ) : (
                ""
              )}
              {vessalSchedules &&
              vessalSchedules &&
              VesselOperationsList &&
              VesselOperationsList.length > 0 ? (
                <div className="container-fluid vesselTabOptionDiv marineDetailPage">
                  {/* ---------------------------------------------------------------------------------------------- */}
                  <div className="innerVesselDiv">
                    {/* ------------------------------------------------------------------------------- */}
                    {VesselOperationsList &&
                      VesselOperationsList.length > 0 &&
                      VesselOperationsList.map((vessels, vesselsindex) => {
                        if (
                          vessels["category"] === "In Port" &&
                          vessels["vesselsList"].length
                        ) {
                          return (
                            <div
                              className="vesselestimationdiv"
                              key={vesselsindex}
                            >
                              <div className="vessel_estimated row">
                                <h2 className="py-3"> {vessels["category"]}</h2>
                              </div>
                              <div className="row vo-namecard-head ">
                                <div
                                  className="col-3 text-white   text-left cursor-pointer"
                                  onClick={(e) =>
                                    onSort(e, "vesselName", vesselsindex)
                                  }
                                >
                                  <b className="thTxt">
                                    {languageJson.name_txt}{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer "
                                  onClick={(e) =>
                                    onSort(e, "terminal", vesselsindex)
                                  }
                                >
                                  <b className="thTxt">
                                    {" "}
                                    {languageJson.Terminal_Name_txt}{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light  cursor-pointer  "
                                  onClick={(e) =>
                                    onSortDate(
                                      e,
                                      "estimatedArrival",
                                      vesselsindex
                                    )
                                  }
                                >
                                  <b className="thTxt">
                                    {" "}
                                    {
                                      languageJson.vessel_estimated_time_on_arrival_txt
                                    }{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer "
                                  onClick={(e) =>
                                    onSort(
                                      e,
                                      "estimatedDeparture",
                                      vesselsindex
                                    )
                                  }
                                >
                                  <b className="thTxt">
                                    {" "}
                                    {languageJson.erd_date_time_txt}{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </b>
                                </div>
                              </div>

                              {vessels["vesselsList"].map((item, index) => {
                                return (
                                  <React.Fragment>
                                    <div
                                      className="row borderDashed rowDivData cursor-pointer"
                                      key={index}
                                      onClick={() => setModalVessalOpen(item)}
                                    >
                                      <div className="col-3">
                                        {item.vesselName === "" ||
                                        item.vesselName.toLowerCase() ===
                                          "null" ||
                                        item.vesselName === null
                                          ? "-"
                                          : item.vesselName}
                                      </div>
                                      <div className="col-3 text-left text-danger ">
                                        {item.terminal === "" ||
                                        item.terminal.toLowerCase() ===
                                          "null" ||
                                        item.terminal === null
                                          ? "-"
                                          : item.terminal}
                                      </div>
                                      <div className="col-3 ">
                                        {item.estimatedArrival === "" ||
                                        item.estimatedArrival.toLowerCase() ===
                                          "null" ||
                                        item.estimatedArrival === null
                                          ? "-"
                                          : DateTimeFormate(
                                              item.estimatedArrival
                                            )}
                                      </div>
                                      <div className="col-3 ">
                                        {item.estimatedDeparture === "" ||
                                        item.estimatedDeparture.toLowerCase() ===
                                          "null" ||
                                        item.estimatedDeparture === null
                                          ? "-"
                                          : DateTimeFormate(
                                              item.estimatedDeparture
                                            )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          );
                        } else if (
                          vessels["category"] === "Due to Depart" &&
                          vessels["vesselsList"].length
                        ) {
                          return (
                            <div
                              className="vesselinportclass"
                              key={vesselsindex}
                            >
                              <div className="vessel_estimated zeroBorderRadius row">
                                <h2 className="py-3">{vessels["category"]}</h2>
                              </div>

                              <div className="row vo-namecard-head ">
                                <div
                                  className="col-3 text-left text-white cursor-pointer"
                                  onClick={(e) =>
                                    onSort(e, "vesselName", vesselsindex)
                                  }
                                >
                                  <b className="thTxt">
                                    {languageJson.name_txt}{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer "
                                  onClick={(e) =>
                                    onSort(e, "terminal", vesselsindex)
                                  }
                                >
                                  <b className="thTxt">
                                    {languageJson.Terminal_Name_txt}{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer"
                                  onClick={(e) =>
                                    onSortDate(e, "cutoffDate", vesselsindex)
                                  }
                                >
                                  <b className="thTxt">
                                    {" "}
                                    {languageJson.cut_of_date_time_txt}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer "
                                  onClick={(e) =>
                                    onSortDate(
                                      e,
                                      "estimatedArrival",
                                      vesselsindex
                                    )
                                  }
                                >
                                  <b className="thTxt">
                                    {
                                      languageJson.vessel_estimated_time_on_arrival_txt
                                    }{" "}
                                    <i
                                      className="fa fa-sort"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </div>
                              </div>
                              {vessels["vesselsList"].map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      className="row  borderDashed rowDivData cursor-pointer"
                                      onClick={() => setModalVessalOpen(item)}
                                    >
                                      <div className="col-3 text-left">
                                        {item.vesselName === "" ||
                                        item.vesselName.toLowerCase() ===
                                          "null" ||
                                        item.vesselName === null
                                          ? "-"
                                          : item.vesselName}
                                      </div>
                                      <div className="col-3 text-left text-danger ">
                                        {item.terminal === "" ||
                                        item.terminal.toLowerCase() ===
                                          "null" ||
                                        item.terminal === null
                                          ? "-"
                                          : item.terminal}
                                      </div>
                                      <div className="col-3">
                                        {item.cutoffDate === "" ||
                                        item.cutoffDate.toLowerCase() ===
                                          "null" ||
                                        item.cutoffDate === null
                                          ? "-"
                                          : DateTimeFormate(item.cutoffDate)}
                                      </div>
                                      <div className="col-3  ">
                                        {item.estimatedArrival === "" ||
                                        item.estimatedArrival.toLowerCase() ===
                                          "null" ||
                                        item.estimatedArrival === null
                                          ? "-"
                                          : DateTimeFormate(
                                              item.estimatedArrival
                                            )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          );
                        } else if (vessels["vesselsList"].length) {
                          return (
                            <div className="vesselinportclass">
                              <div className="vessel_estimated zeroBorderRadius row">
                                <h2 className="py-3">{vessels["category"]}</h2>
                              </div>

                              <div className="row vo-namecard-head ">
                                <div
                                  className="col-4 text-left text-white cursor-pointer pr-3"
                                  onClick={(e) =>
                                    onSort(e, "vesselName", vesselsindex)
                                  }
                                >
                                  {languageJson.name_txt}{" "}
                                  <i
                                    className="fa fa-sort"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div
                                  className="col-5 text-left  text-light  cursor-pointer"
                                  onClick={(e) =>
                                    onSort(e, "terminal", vesselsindex)
                                  }
                                >
                                  {languageJson.Terminal_Name_txt}{" "}
                                  <i
                                    className="fa fa-sort"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div
                                  className="col-3 text-left  text-light cursor-pointer"
                                  onClick={(e) =>
                                    onSortDate(
                                      e,
                                      "estimatedArrival",
                                      vesselsindex
                                    )
                                  }
                                >
                                  {
                                    languageJson.vessel_estimated_time_on_arrival_txt
                                  }{" "}
                                  <i
                                    className="fa fa-sort"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              {vessels["vesselsList"].map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      className="row  borderDashed rowDivData cursor-pointer"
                                      onClick={() => setModalVessalOpen(item)}
                                    >
                                      <div className="col-4 text-left pr-3">
                                        {item.vesselName === "" ||
                                        item.vesselName.toLowerCase() ===
                                          "null" ||
                                        item.vesselName === null
                                          ? "-"
                                          : item.vesselName}
                                      </div>
                                      <div className="col-5 text-left text-danger pr-3 ">
                                        {item.terminal === "" ||
                                        item.terminal.toLowerCase() ===
                                          "null" ||
                                        item.terminal === null
                                          ? "-"
                                          : item.terminal}
                                      </div>
                                      <div className="col-3 pr-3 ">
                                        {item.estimatedArrival === "" ||
                                        item.estimatedArrival.toLowerCase() ===
                                          "null" ||
                                        item.estimatedArrival === null
                                          ? "-"
                                          : DateTimeFormate(
                                              item.estimatedArrival
                                            )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          );
                        }
                        return false;
                      })}
                    <br></br>
                  </div>
                </div>
              ) : vessalSchedules &&
                VesselOperationsList != null &&
                VesselOperationsList.length === 0 ? (
                <div className="">
                  <div className="  text-center border-0">
                    {" "}
                    <DataNotFound />
                  </div>
                </div>
              ) : (
                vessalSchedules && (
                  <div className="  text-center border-0">
                    <SpinerLoader />
                  </div>
                )
              )}
              {mapVessalModal && (
                <DetailVesselOperation
                  open={mapVessalModal}
                  closeModal={setModalVessalClose}
                  marinMapDetail={marinMapVessalDetail}
                />
              )}
              {/* 
        {advantVessalSchedules && <React.Fragment> <VesselScheduleAdvent terminalName={marineTerminalData.terminal_name}/> </React.Fragment> }
 */}
            </div>
          </div>
        </div>

        {/* -------------------------new gallery section end------------------------- */}

        <div className="container vesselMainPage">
          <div className="row container-fluid py-5 tabVesselDiv vesselTableMainDiv newVesselTabMainDiv">
            <div className="col-lg-4 col-md-4 col-sm-4 center-head-underline titleTabOption">
              <a
                onClick={() => {
                  onHolidaysData();
                }}
                className={`text-decoration-none   ${
                  holidaysData
                    ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
                    : ""
                } text-dark cursor-pointer alertTxtDiv`}
              >
                <h2
                  className={`${holidaysData ? "text-primary" : "text-dark"}`}
                >
                  <span>
                    <img
                      src={bluecalender}
                      className="operationicon-img"
                      alt="bluelocation-img"
                    />
                  </span>
                  <b>{languageJson.Holiday_Calendar.holiday_tab}</b>
                </h2>{" "}
              </a>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 center-head-underline titleTabOption">
              <a
                onClick={() => {
                  onGateHours();
                }}
                className={`text-decoration-none ${
                  gateHoursData
                    ? "center-head-underline-clr vo-headtxt py-2 "
                    : ""
                } cursor-pointer vesselOptDiv`}
              >
                <h2
                  className={`${gateHoursData ? "text-primary" : "text-dark"}`}
                >
                  <span>
                    <img
                      src={bluehours}
                      className="operationicon-img"
                      alt="bluelocation-img"
                    />
                  </span>
                  <b>{languageJson.Holiday_Calendar.gate_hours_tab}</b>
                </h2>{" "}
              </a>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 center-head-underline titleTabOption">
              <a
                onClick={() => {
                  onAddress();
                }}
                className={`text-decoration-none ${
                  addressData
                    ? "center-head-underline-clr vo-headtxt py-2 "
                    : ""
                } cursor-pointer vesselOptDiv`}
              >
                <h2 className={`${addressData ? "text-primary" : "text-dark"}`}>
                  <span>
                    <img
                      src={blueaddress}
                      className="operationicon-img"
                      alt="bluelocation-img"
                    />
                  </span>
                  <b>{languageJson.Holiday_Calendar.address_tab}</b>
                </h2>{" "}
              </a>
            </div>
          </div>
        </div>


        <div className="container">
          {holidaysData && (
            <span>
              {" "}
              <MarineTerminalCalendar data={holidaysJSONData}/>{" "}
            </span>
          )}

          {gateHoursData && (
            <section className="gatehours-section marine-container">
              <div className="p-4">
                <p className="text-center mt-4">
                  {marineTerminalData && marineTerminalData.hours ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: marineTerminalData.hours,
                      }}
                    ></span>
                  ) : (
                    "--"
                  )}
                </p>
              </div>
            </section>
          )}

          {addressData && (
            <section className="terminal-address-section marine-container">
               <div className="p-4">
              <div className="text-center terminalAddressDiv">
                <p className="letter2">
                  {marineTerminalData?.address
                    ? marineTerminalData?.address
                    : "----"}
                </p>
              </div>
              </div>
            </section>
          )}
        </div>

        <section className="gateinformation-section">
          <div>
            <div className="container mt-4">
              <h3 className="text-center mb-5">
                {languageJson.Contact_Details_txt}
              </h3>
              <div className="row contactDetailsDiv">
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <h6>{languageJson.Customer_Service_txt}</h6>
                  <p>
                    {marineTerminalData?.customer_service
                      ? marineTerminalData?.customer_service
                      : "-"}
                  </p>
                </div>
                <div className="col-md-6 col-lg-2 col-sm-6">
                  <h6>{languageJson.Phone_txt}</h6>
                  <p>
                    {marineTerminalData?.phone
                      ? marineTerminalData?.phone
                      : "-"}
                  </p>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6">
                  <h6>{languageJson.Gate_Office_Phone_txt}</h6>
                  <p>
                    {marineTerminalData?.gate_office_phone
                      ? marineTerminalData?.gate_office_phone
                      : "-"}
                  </p>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6">
                  <h6>{languageJson.Yard_Gate_Manager_Phone_txt}</h6>
                  <p>
                    {marineTerminalData?.yard_and_gate_manager_phone
                      ? marineTerminalData?.yard_and_gate_manager_phone
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {/* ---------------------------------------------------------------- */}
          </div>
        </section>

        {showPopUp && showPopUp ? (
          <>
            <ImagePop
              customImage={
                singleImage !== ""
                  ? `${singleImage}&t=${imageVersion}`
                  : oaklandlogo
              }
              onClose={handleClose}
            />
            <OtpBackdrop onClick={handleClose} />
          </>
        ) : null}
        {mapModal && (
          <MaponModal
            open={mapModal}
            closeModal={setModalClose}
            marinMapDetail={marinMapDetail}
          />
        )}
      </div>
    </div>
  );
}
export default MerineTerminal;
