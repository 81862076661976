import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'src/redux/actions/apis';
import { GET_FAQ_DATA_API } from '../url/Url';
import { languageJson } from '../StringVariable';
import SpinerLoader from "src/utils/SpinerLoader";
import DataNotFound from "src/Components/DataNotFound/DataNotFound";

function FrequentlyAskedQuestions() {
  const faqsShow = 4;
  const [faqsData, setFaqsData] = useState();
  const getFaqData = async () => {
    const requestObj = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      await axios.get(GET_FAQ_DATA_API, requestObj).then(res => {
        const { customcode, faqList } = res.data
        if (customcode === 200) {
          setFaqsData(faqList);
        }
        if (customcode === 213) {
          setFaqsData([])
        }
      })
    } catch (err) {
    }
  }

  useEffect(() => {
    getFaqData();
  }, [])

  return (
    <>
      {/* =======================================new code start===================================== */}
      <section className="faqs-section">
        <div className="container">
          <div className="row ">
            <div className="row postionRelative ">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-8 col-sm-8 col-xs-8 titleTxtDiv center-head-underline d-flex justify-content-center py-4">
                <h1 className="text-center  py-2 f-head">
                  {languageJson.sectionName.frequentlyAskedQuestions} {" "}
                </h1>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4 viewBtnDiv  d-flex justify-content-end  align-items-center">
                <div>
                  {faqsData != null && faqsData.length && faqsData.length > 0 ? <Link className="viewall-clr text-end cursor-pointer" to={{
                    pathname: '/faqs'
                  }} >{languageJson.viewall_txt} &#62;</Link> : ""}
                </div>
              </div>
            </div>
          </div>

          {/* first row */}
          <div className="row">
            {faqsData != null && faqsData.length && faqsData.length > 0 ? faqsData.slice(0, faqsShow).map((faq, index) => (
              <div className="col-lg-6 col-md-6 col-md-12 mb-3" key={index}>
                <div className="row container d-flex align-items-start">
                  <div className="col-lg-4 col-xl-3 divBoxNumber">
                    <p className="faqs-num text-center "> {(index + 1) < 10 ? `0${index + 1}` : index + 1}</p>
                  </div>
                  <div className="col-lg-8 col-xl-9">
                    <p className="faqs-head">
                      {faq.question !== null || faq.question.toLowerCase() !== "null" ? faq.question : "-"}
                    </p>

                    <p>
                      {faq.answer !== null || faq.answer.toLowerCase() !== "null" ? faq.answer : "-"}{faq.url && <a className="" target="_blank" rel="noreferrer" href={faq.url}>{languageJson.click_here_txt}</a>}
                    </p>

                  </div>
                </div>
              </div>
            )) : <div className="col-lg-12 col-md-12 col-md-12 mb-3" >
              {faqsData != null && faqsData && faqsData.length === 0 ? <DataNotFound /> : <SpinerLoader />}
            </div>}
          </div>
        </div>
      </section>
    </>
  );
}

export default FrequentlyAskedQuestions;
