import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { GOOGLE_MAP_URL } from "../../url/Url";
import vesselIcone from "../../assets/vessel-icon@2x.webp";
import { Modal } from 'react-bootstrap';
import { TimeFormate, DataFormate } from "src/utils/DataFormate";
import { languageJson } from '../../StringVariable';

function CameraModal(props) {
  const [selectedCamera, setSelectedCamera] = useState(null);
 
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedCamera(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [selectedCamera]);


  useEffect(() => {

  }, []);

  return (
    <div>

      <GoogleMap
        defaultZoom={14}
        defaultCenter={{lat: parseFloat(props.marinMapDetail.latitude),
          lng: parseFloat(props.marinMapDetail.longitude) }}
        options={{ streetViewControl: false }}
      >
        <Marker
          position={{
            lat: parseFloat(props.marinMapDetail.latitude),
            lng: parseFloat(props.marinMapDetail.longitude)
          }}
          onClick={() => {
            setSelectedCamera(props.marinMapDetail);
          }}
          icon={{
            url: vesselIcone,
            scaledSize: new window.google.maps.Size(25, 25),
          }}
        />
        {selectedCamera !== null && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedCamera(null);
            }}
            position={{
              lat: parseFloat(selectedCamera.latitude),
              lng: parseFloat(selectedCamera.longitude),
            }}
            defaultOptions={false}

          >

            <div className="mapPopup popupCaltransCamerasMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  setSelectedCamera(null);
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">

                <div className="dataDescpTxt">
                  <span>
                    <label> {selectedCamera.vesselName} </label>
                  </span>
                </div>

              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(CameraModal));

export default function DetailVesselOperation(props) {
  const { marinMapDetail } = props
  return (
    <Modal
      className="marineCameraModal"
      show={props.open}
      onHide={props.closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
    >
      <Modal.Body className="vesselOperationMainDetail">
        <button className="closeBtnPopup" onClick={() => { props.closeModal() }}>X</button>
        <div className="vesselInfoDiv " style={{ width: "100%" }}>
          <div className="row m-4">
            <label>  <b>{languageJson.Vessel_Name_txt} : </b> {marinMapDetail.vesselName || "-"}</label>
            <label>  <b>{languageJson.ShipType_txt} : </b> {marinMapDetail.vesselType || "-"}</label>
            <label>  <b>{languageJson.Flag_txt} : </b> {marinMapDetail.flag || "-"}</label>
            <label>  <b>{languageJson.Lloyds_Number_txt} : </b> {marinMapDetail.lloydsNumber || "-"}</label>
            <label>  <b>{languageJson.IMO_MMSI_txt} : </b> {marinMapDetail.mmsi || "-"}</label>
            <label>  <b>{languageJson.Call_Sign_txt} : </b> {marinMapDetail.callSign || "-"}</label>
            <label>  <b>{languageJson.Registered_Owner_txt} : </b> {marinMapDetail.owner || "-"}</label>
            <label>  <b>{languageJson.Draft_txt} : </b> {marinMapDetail.draft || "-"}</label>
            <label>  <b>{languageJson.Net_Weight_txt} : </b> {marinMapDetail.netWeight || "-"}</label>
            <label>  <b>{languageJson.Length_Overall_txt} : </b> {marinMapDetail.grossWeight || "-"}</label>
            <label>  <b>{languageJson.Breadth_txt} : </b> {marinMapDetail.breadth || "-"}</label>
            <label>  <b>{languageJson.Summer_Dead_Weight_txt} : </b> {marinMapDetail.netWeight || "-"}</label>
            <label>  <b>{languageJson.Port_Of_Registry_txt} : </b> {marinMapDetail.portOfRegistry || "-"}</label>
            <label>  <b>{languageJson.Year_of_Buil_txt} : </b> {marinMapDetail.yearBuilt || "-"}</label>
            <label>  <b>{languageJson.Berths_txt} : </b> {marinMapDetail.berth || "-"}</label>
            <label>  <b>{languageJson.Terminal_Name_txt} : </b> {marinMapDetail.terminal || "-"}</label>
            <label>  <b>{languageJson.From_Port_txt} : </b> {marinMapDetail.fromPort || "-"}</label>
            <label>  <b>{languageJson.To_Port_txt} : </b> {marinMapDetail.toPort || "-"}</label>
            <label>  <b>{languageJson.From_Berth_txt} : </b> {marinMapDetail.fromBerth || "-"}</label>
            <label>  <b>{languageJson.To_Berth_txt} : </b> {marinMapDetail.toBerth || "-"}</label>
            <label>  <b>{languageJson.Actual_Arrival_txt} : </b> {TimeFormate(marinMapDetail.actualArrival) || "-"}</label>
            <label>  <b>{languageJson.Actual_Departure_txt} : </b> {TimeFormate(marinMapDetail.actualDeparture) || "-"}</label>
            <label>  <b>{languageJson.vessel_estimated_time_on_arrival_txt} : </b> {TimeFormate(marinMapDetail.estimatedArrival) || "-"}</label>
            <label>  <b>{languageJson.erd_date_time_txt} : </b> {TimeFormate(marinMapDetail.estimatedDeparture) || "-"}</label>
            <label>  <b>{languageJson.Earliest_Receiving_Date_txt} : </b> {DataFormate(marinMapDetail.earliestReceivingDate) || "-"}</label>
            <label>  <b>{languageJson.cut_of_date_time_txt} : </b> {DataFormate(marinMapDetail.cutoffDate) || "-"}</label>
            <label>  <b>{languageJson.Draft_In_txt} : </b> {marinMapDetail.draftIn || "-"}</label>
            <label>  <b>{languageJson.Draft_Out_txt} : </b> {marinMapDetail.draftOut || "-"}</label>
            <label>  <b>{languageJson.First_Line_txt} : </b> {marinMapDetail.firstLine || "-"}</label>
            <label>  <b>{languageJson.Last_Line_txt} : </b> {marinMapDetail.lastLine || "-"}</label>
          </div>
          <div className="vesselMapTab " style={{ width: "100%" }}>
            {navigator.onLine && <MapWrapped
              {...props}
              googleMapURL={GOOGLE_MAP_URL}
              loadingElement={<div style={{ height: `100` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%`, borderRadius: '0px 0px 10px 10px' }} />}
            />}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
