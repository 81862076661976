import { ActionTypes } from "../constants/Types";
const initialState = {
    marineTerminalLsit:null
};

export const MarineTerminalReducer = (state = initialState, action) => {
    switch (action.type) {
            case ActionTypes.MARINE_TERMINAL_LIST_DATA_SUCCESS:
            return { marineTerminalLsit: action.payload }
        default:
            return state;
    }
}
