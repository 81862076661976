// FloatingButton.js

import React from 'react';
import "./Resources.css";
import { languageJson } from "../../StringVariable";
const FloatingButton = ({ onClick, url }) => {
  return (
    <a className="floating-button" href={url} target="_blank" rel="noreferrer" onClick={onClick}>
      {/* You can put any content inside the button */}
      {languageJson.contact_us_footer_port}
    </a>
  );
};

export default FloatingButton; 