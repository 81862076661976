import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import vessel_image from "../../assets/aboutus-banner.png";
import Backdrop from "../../Containers/modal/BackDrop";
import Modal from "../../Containers/modal/Modal";
import OtpModal from "../../Containers/otp/OtpModal";
import "./LMCRegisterPage.css";
import underConstructionIconImg from "../../assets/underConstructionIcon.png";
import PrimaryInformation from "./PrimaryInformation";
import OtherInformation from "./OtherInformation";
import { useSelector, useDispatch } from "react-redux";
import { lmcRegistration, clearLoadingMessageStatus, otpSend } from "../../redux/actions/UserAccount";
import { languageJson } from "src/StringVariable";

function LMCRegisterPage() {
    let dispatch = useDispatch();
    let { messageState, statusCodeState } = useSelector(state => state);
    const [showPrimaryform, setShowPrimaryform] = useState(true);
    const [showOtherform, setShowOtherform] = useState(false);
    const [showPrimaryButton, setShowPrimaryButton] = useState(true);
    const [showOtherButton, setShowOtherButton] = useState(true);
    const [formData, setFormData] = useState({});
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [showOTP, setShowOTP] = useState(false)
    const [onModal, setOnModal] = useState(false);

    const handleSubmitLMC = async () => {
        if (!onModal) {
            const fd = new FormData();
            fd.append("lmc_name", formData.lmc_name);
            fd.append("lmc_type", formData.lmc_type);
            fd.append("business_phone_number", formData.business_phone_number);
            fd.append("business_email", formData.business_email);
            fd.append("password", formData.password);
            fd.append("company_established_date", formData.company_established_date);
            fd.append("company_office_address", formData.company_office_address);
            fd.append("extention_number", formData.extention_number);
            fd.append("secondary_email", formData.secondary_email);
            fd.append("contact_person_number", formData.contact_person_number);
            fd.append("contact_person_name", formData.contact_person_name);
            fd.append("us_mc_number", formData.us_mc_number);
            fd.append("state_mc_number", formData.state_mc_number);
            fd.append("dot_number", formData.dot_number);
            fd.append("standard_carrier_alpha_code", formData.standard_carrier_alpha_code);
            fd.append("document_name", formData.document_name);
            fd.append("document_image", image.raw);
            dispatch(lmcRegistration(fd));
        }
    };

    const closeModal = () => {
        dispatch(clearLoadingMessageStatus())
        setOnModal(false);
    }

    useEffect(() => {
        if (statusCodeState && statusCodeState.lmcRegisterCode !== null) {
            setOnModal(true);
        }
    }, [statusCodeState.lmcRegisterCode]); // eslint-disable-line react-hooks/exhaustive-deps


    const getEmailOTP = () => {
        const emailData = { 'email': formData.business_email }
        dispatch(otpSend(emailData))
    }

    return (
        <div>
            <Header />
            <div className="headerBannerMainDiv"> <img className="vessel_img drivenRegistrationBannerImg " src={vessel_image} alt="vessel" /></div>
            <div className="col underContPageDiv d-none">
                <div className="container">
                    <div className="underContMainDiv">
                        <img src={underConstructionIconImg} alt="underConstruction"></img>
                    </div>
                </div>
            </div>
            <div className="lmcRegisterMainDiv" id="lmcregister">
                <div className="container my-5">
                    <div className="left-underline text-left titleTxtDiv">
                        <h2>{languageJson.lmc_registration_txt}</h2>
                    </div>
                    {showPrimaryform ? <PrimaryInformation setShowPrimaryform={setShowPrimaryform} setShowOtherform={setShowOtherform} formData={formData} setFormData={setFormData} showPrimaryButton={showPrimaryButton} showOtherButton={showOtherButton} setShowPrimaryButton={setShowPrimaryButton} setShowOtherButton={setShowOtherButton} /> : null}
                    {showOtherform ? <OtherInformation setShowPrimaryform={setShowPrimaryform} setShowOtherform={setShowOtherform} formData={formData} setFormData={setFormData} showPrimaryButton={showPrimaryButton} setShowPrimaryButton={setShowPrimaryButton} showOtherButton={showOtherButton} setShowOtherButton={setShowOtherButton} image={image} setImage={setImage} handleSubmitLMC={handleSubmitLMC} /> : null}

                    {messageState.lmcRegisterMessage != null ? (
                        <React.Fragment>
                            <Backdrop onClick={() => closeModal()} />
                            <Modal text={messageState.lmcRegisterMessage} otpButton={() => statusCodeState.lmcRegisterCode === 200 ? setShowOTP(true) : ''} btnColor={statusCodeState.lmcRegisterCode === 200 ? 'modal-btn-success' : 'modal-btn-warning'} icon={statusCodeState.lmcRegisterCode === 200 ? 'fa fa-check-circle' : 'fa fa-exclamation-circle'} status={statusCodeState.lmcRegisterCode === 200 ? 'bg-custom-success' : 'bg-warning'} onClose={() => closeModal()} />
                        </React.Fragment>
                    )
                        : null
                    }
                    {showOTP ? (
                        <React.Fragment>
                            <Backdrop onClick={() => closeModal()} />
                            <OtpModal text={languageJson.please_enter_otp_txt} resendOTP={() => getEmailOTP()} email={formData.business_email} status={'bg-custom-success'} btnColor={'modal-btn-success'} icon={'fa fa-check-circle'} onClose={() => closeModal()} />
                        </React.Fragment>
                    ) : null
                    }
                </div>
            </div>
        </div>
    );
}

export default LMCRegisterPage;
