import React, { useState, useEffect } from "react";
import Header from "src/Containers/Header"; 
import portimg1 from "../../assets/AboutView/1.jpg";
import portimg2 from "../../assets/AboutView/2.jpg";
import portimg3 from "../../assets/AboutView/3.jpg"; 
import "./Aboutus.css";
import { Link } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "src/firebaseConfig";
import MarineTerminalCalendar from "../Vessel_Operations/MarineTerminalCalendar";
import axios from "src/redux/actions/apis";
import { GET_HOLIDAYS_FOR_PUBLIC } from "../../url/Url";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function PortSecurityandSafety() {
  const [holidaysData, setHolidaysData] = useState(null);

  useEffect(() => {
    logEvent(analytics, "Port and Security Page");
  }, []);
   const getHolidayCalendar = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      const res = await axios.get(GET_HOLIDAYS_FOR_PUBLIC, requestObj);
      const { data, customcode } = res.data;
      if (customcode === 200 && Object.keys(data).length > 0) {
        setHolidaysData(data);
      } else {
        setHolidaysData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getHolidayCalendar();
  }, []);

 console.log("holidaysData",holidaysData)

  return (
    <>
      <Header />
      <div className="image-row-banner">
        <img className="banner_img" src={portimg1} alt=""/>
        <img className="banner_img" src={portimg3} alt=""/>
        <img className="banner_img" src={portimg2} alt=""/>
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4">
          <div>
            <Link to="/">
              <span className="vo-menu pe-2">{languageJson.home_txt}</span>
            </Link>
            <span className="text-dark ps-2"> / &nbsp; {languageJson.port_holiday_calendar}</span>
          </div>
        </div>
      </div>
      <div className="railCrossinglRoutesMainDiv">
        <section className="railcrossing-head container">
          <div className="row container-fluid py-5 tabVesselDiv">
          <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
            <div className="container">
          <div className="titleDescpDiv">
            <h2>{languageJson.port_holiday_calendar}</h2>
          </div>
          </div>
          </div>
            <div >
              <div>
              
                <div className="container" >
                {holidaysData && (
            <span>
              {" "}
              <MarineTerminalCalendar data={holidaysData}/>{" "}
            </span>
          )}
                  {holidaysData &&
                    holidaysData !== null &&
                    holidaysData.length > 0 ? (
                      <div className="col-lg-12 col-md-12">
                      <div className="container">
                      
                        {/* <span>
                          <MarineTerminalCalendar data={holidaysData} />
                        </span> */}
                      </div>
                      </div>
                    ) : holidaysData &&
                    holidaysData !== null &&
                    holidaysData.length === 0 ? (
            <DataNotFound />
          ) : (
            <SpinerLoader />
          )}
                </div>
                </div>
                </div>
        
                </div>
                </section>
                </div>
                
    </>
  );
}

export default PortSecurityandSafety;
