/* eslint-disable no-unused-vars */
import { ActionTypes } from "../constants/Types";
import server from "./apis";
import { PUBLIC_URL_API } from "../../url/Url";
import { languageJson } from "src/StringVariable";

const publicUrlSuccess = (data) => {
    return {
        type: ActionTypes.PUBLIC_URL_SUCCESS,
        payload: data
    }
}

// Public URL
export const publicUrl = () => {
    return (dispatch) => {
        server.get(`${PUBLIC_URL_API}`)
            .then((response) => {
                dispatch(publicUrlSuccess(response.data));
            })
            .catch((error) => {
                let message = languageJson.something_went_wrong_txt
                if (!error.response) {
                    message = languageJson.network_error_txt
                } else {
                    message = error.response.message
                }
            });;
    };
};

