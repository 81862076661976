import { ActionTypes } from "../constants/Types";
const initialState = {
  loginStatusCode: null,
  otpSendStatusCode: null,
  driverRegisterCode: null,
  lmcRegisterCode: null,
  otpVerifyStatusCode: null,
  contactUsStatusCode: null,
  forgotPasswordStatusCode: null,
  changePasswordStatusCode: null,
  vasselAlertsDataStatusCode: null,
  railAlertsDataStatusCode: null,
  alertsAnnouncementsStatusCode:null,
  marineTerminalAlertsDataStatusCode: null,
  resourceDataStatusCode: null,
  portMessageStatusCode: null,
  portConstructionEventsStatusCode: null,
  portIncidentClosuresStatusCode: null,
  regionalMessageSignsStatusCode: null,
  regionalConstructionEventsStatusCode: null,
  regionalIncidentClosuresStatusCode: null,
  trafficAndCamerasAlertsDataStatusCode: null,
 marineTerminalListStatusCode: null,
 securityAndSafetyAlertsDataStatusCod:null

};

export const statusCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_STATUS_CODE:
      return { ...state, loginStatusCode: action.payload };
    case ActionTypes.OTP_SEND_STATUS_CODE:
      return { ...state, otpSendStatusCode: action.payload };
    case ActionTypes.DRIVER_REGISTER_STATUS_CODE:
      return { ...state, driverRegisterCode: action.payload };
    case ActionTypes.LMC_REGISTER_STATUS_CODE:
      return { ...state, lmcRegisterCode: action.payload };
    case ActionTypes.OTP_VERIFY_STATUS_CODE:
      return { ...state, otpVerifyStatusCode: action.payload };
    case ActionTypes.CONTACT_US_STATUS_CODE:
      return { ...state, contactUsStatusCode: action.payload };
    case ActionTypes.FORGOT_PASSWORD_STATUS_CODE:
      return { ...state, forgotPasswordStatusCode: action.payload };
    case ActionTypes.CHANGE_PASSWORD_STATUS_CODE:
      return { ...state, changePasswordStatusCode: action.payload };
    case ActionTypes.VASSEL_ALERTS_DATA_STATUS_CODE:
      return { ...state, vasselAlertsDataStatusCode: action.payload };
    case ActionTypes.RAIL_CROSSING_ALERTS_DATA_STATUS_CODE:
      return { ...state, railAlertsDataStatusCode: action.payload };
      case ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_STATUS_CODE:
      return { ...state, alertsAnnouncementsStatusCode: action.payload };
    case ActionTypes.MARINE_TERMINAL_ALERTS_DATA_STATUS_CODE:
      return { ...state, marineTerminalAlertsDataStatusCode: action.payload };
    case ActionTypes.RESOURCE_DATA_STATUS_CODE:
      return { ...state, resourceDataStatusCode: action.payload };
    case ActionTypes.PORT_MESSAGE_STATUS_CODE:
      return { ...state, portMessageStatusCode: action.payload };
    case ActionTypes.PORT_CONSTRUCTION_EVENTS_STATUS_CODE:
      return { ...state, portConstructionEventsStatusCode: action.payload };
    case ActionTypes.PORT_INCIDENT_CLOSURES_STATUS_CODE:
      return { ...state, portIncidentClosuresStatusCode: action.payload };
    case ActionTypes.REGIONAL_MESSAGE_SIGNS_STATUS_CODE:
      return { ...state, regionalMessageSignsStatusCode: action.payload };
    case ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_STATUS_CODE:
      return { ...state, regionalConstructionEventsStatusCode: action.payload };
    case ActionTypes.REGIONAL_INCIDENT_CLOSURES_STATUS_CODE:
      return { ...state, regionalIncidentClosuresStatusCode: action.payload };
    case ActionTypes.TRAFFIC_AND_CAMERAS_DATA_STATUS_CODE:
      return {
        ...state,
        trafficAndCamerasAlertsDataStatusCode: action.payload,
      };
      case ActionTypes.MARINE_TERMINAL_LIST_DATA_STATUS_CODE:
      return {
        ...state,
        marineTerminalListStatusCode: action.payload,
      };
      case ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_STATUS_CODE:
      return {
        ...state,
        securityAndSafetyAlertsDataStatusCod: action.payload,
      };
    case ActionTypes.CLEAR_STATUS_CODE:
      return initialState;
    default:
      return state;
  }
};
