/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./accordion.css";
import DataNotFound from "../DataNotFound/DataNotFound";
import { DateTimeFormate } from '../../utils/DataFormate';
import { useSelector, useDispatch } from "react-redux";
import { trafficAndCamerasAlertsData } from "../../redux/actions/AlertDataAction"
import SpinerLoader from "src/utils/SpinerLoader";
import { languageJson } from "src/StringVariable";
import { submitGAEvent } from "src/utils/GAEvents";
const AccordionApp = () => {
  let dispatch = useDispatch();
  let { alertDataState, loadingState } = useSelector(state => state);

  useEffect(() => {
    submitGAEvent("ALERT - TRAFFIC & CAMERAS")
    dispatch(trafficAndCamerasAlertsData())
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingState.isTrafficAndCamerasAlertsDataLoading) {
    return <SpinerLoader />;
  } else if (!loadingState.isTrafficAndCamerasAlertsDataLoading) {
    return (
      <div className="">
        <div className="card-body">
          <div className="flex flex-column mb-5 mt-4 faq-section">
            <div className="row">
              <div className="col-md-12">
                <div id="accordion" className="trafficAlertDDown">
                  {alertDataState && alertDataState.trafficAndCamerasAlertsData != null && alertDataState.trafficAndCamerasAlertsData.length > 0 ? alertDataState.trafficAndCamerasAlertsData.map((hiddenText, index) => {
                    if (hiddenText.IncidentList.length > 0 &&
                      (hiddenText.category.toLowerCase() === "port message signs" ||
                        hiddenText.category.toLowerCase() === "regional message signs")) {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading-1`}>
                            <h5 className="mb-0">
                              <a
                                role="button"
                                data-toggle="collapse"
                                href={`#collapse-1${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse-1${index}`}
                              >
                                {hiddenText.category === null || hiddenText.category === "" || hiddenText.category.toLowerCase() === "null" ? "-" : hiddenText.category}
                              </a>
                            </h5>
                          </div>
                          <div
                            id={`collapse-1${index}`}
                            className="inner-acc collapse"
                            data-parent="#accordion"
                            aria-labelledby={`heading-1 ${index}`}
                          >
                            <div className="card-body">
                              {hiddenText.IncidentList.length ? (
                                <Table1
                                  list={hiddenText.IncidentList}
                                  index={index}
                                />
                              ) : (
                                <DataNotFound />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (hiddenText.IncidentList.length > 0 &&
                      (hiddenText.category.toLowerCase() ===
                        "port construction and events" ||
                        hiddenText.category.toLowerCase() ===
                        "regional construction and events")
                    ) {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading-1`}>
                            <h5 className="mb-0">
                              <a
                                role="button"
                                data-toggle="collapse"
                                href={`#collapse-1${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse-1${index}`}
                              >
                                {hiddenText.category === null || hiddenText.category === "" || hiddenText.category.toLowerCase() === "null" ? "-" : hiddenText.category}
                              </a>
                            </h5>
                          </div>
                          <div
                            id={`collapse-1${index}`}
                            className="inner-acc collapse "
                            data-parent="#accordion"
                            aria-labelledby={`heading-1 ${index}`}
                          >
                            <div className="card-body">
                              {hiddenText.IncidentList.length ? (
                                <Table2
                                  list={hiddenText.IncidentList}
                                  index={index}
                                />
                              ) : (
                                <DataNotFound />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (hiddenText.IncidentList.length > 0 &&
                      (hiddenText.category.toLowerCase() ===
                        "port incident and closures")
                    ) {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading-1`}>
                            <h5 className="mb-0">
                              <a
                                role="button"
                                data-toggle="collapse"
                                href={`#collapse-1${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse-1${index}`}
                              >
                                {hiddenText.category}
                              </a>
                            </h5>
                          </div>
                          <div
                            id={`collapse-1${index}`}
                            className="inner-acc collapse "
                            data-parent="#accordion"
                            aria-labelledby={`heading-1 ${index}`}
                          >
                            <div className="card-body">
                              {hiddenText.IncidentList.length ? (
                                <Table3
                                  list={hiddenText.IncidentList}
                                  index={index}
                                />
                              ) : (
                                <DataNotFound />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (hiddenText.IncidentList.length > 0 &&
                      (hiddenText.category.toLowerCase() ===
                        "regional incident and closures")
                    ) {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading-1`}>
                            <h5 className="mb-0">
                              <a
                                role="button"
                                data-toggle="collapse"
                                href={`#collapse-1${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse-1${index}`}
                              >
                                {hiddenText.category}
                              </a>
                            </h5>
                          </div>
                          <div
                            id={`collapse-1${index}`}
                            className="inner-acc collapse "
                            data-parent="#accordion"
                            aria-labelledby={`heading-1 ${index}`}
                          >
                            <div className="card-body">
                              {hiddenText.IncidentList.length ? (
                                <Table4
                                  list={hiddenText.IncidentList}
                                  index={index}
                                />
                              ) : (
                                <DataNotFound />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    else return;
                  }) : <DataNotFound />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const Table1 = (props) => {
  const [list, setList] = useState(props.list);
  const onSort = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) === JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setList([...data]);

  };

  const onSortDate = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
    ) {
      data.sort((a, b) => a[sortKey] - (b[sortKey]));
    } else {
      data.sort((a, b) => b[sortKey] - (a[sortKey]));

    }
    setList([...data]);
  };
  return (
    <div className="overflowDiv" key={props.index}>
      <div className="vesselestimationdiv">
        <div className="row vo-namecard-head ">
          <div
            className="col-4 text-white   text-left cursor-pointer"
            onClick={(e) => onSort(e, "Description")}
          >
            {languageJson.message_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-5 text-left  text-light cursor-pointer "
            onClick={(e) => onSort(e, "Location")}
          >
            {languageJson.sign_languages_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>

          <div
            className="col-3 text-left  text-light cursor-pointer "
            onClick={(e) => onSortDate(e, "Lastupdated")}
          >
            {languageJson.last_updated_value_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
        </div>

        {list.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <div className="row borderDashed rowDivData">
                <div className="col-4"><div className="alignCenterDivTableTitle" dangerouslySetInnerHTML={{ __html: item.Description }}></div></div>
                <div className="col-5 "><div className="alignCenterDivTable">{item.Location}</div></div>
                <div className="col-3 text-left  "><div className="alignCenterDivTable">
                  {DateTimeFormate(item.Lastupdated)}</div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
const Table2 = (props) => {
  const [list, setList] = useState(props.list);
  const onSort = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) === JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setList([...data]);
  };

  const onSortDate = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
    ) {
      data.sort((a, b) => a[sortKey] - (b[sortKey]));
    } else {
      data.sort((a, b) => b[sortKey] - (a[sortKey]));
    }
    setList([...data]);
  };
  return (
    <div className="overflowDiv" key={props.index}>
      <div className="vesselestimationdiv">
        <div className="row vo-namecard-head ">
          <div
            className="col-9 text-white text-left cursor-pointer"
            onClick={(e) => onSort(e, "Description")}
          >
            {languageJson.description_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-3 dateDivCol3 text-left  text-light cursor-pointer "
            onClick={(e) => onSortDate(e, "Lastupdated")}
          >
            {languageJson.last_updated_value_txt}<i className="fa fa-sort" aria-hidden="true"></i>
          </div>
        </div>
        {list.map((item, index) => {
          return (
            <React.Fragment>
              <div className="row borderDashed rowDivData" key={item.id}>
                <div className="col-9 "><div className="alignCenterDivTable">{item.Description || <b className="text-center">-</b>}</div></div>
                <div className="col-3 dateDivCol3 text-left"><div className="alignCenterDivTable">
                  {DateTimeFormate(item.Lastupdated)}</div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
const Table3 = (props) => {
  const [list, setList] = useState(props.list);
  const onSort = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) === JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setList([...data]);
  };

  const onSortDate = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
    ) {
      data.sort((a, b) => a[sortKey] - (b[sortKey]));
    } else {
      data.sort((a, b) => b[sortKey] - (a[sortKey]));
    }
    setList([...data]);
  };
  return (
    <div className="overflowDiv" key={props.index}>
      <div className="vesselestimationdiv">
        <div className="row vo-namecard-head ">
          <div
            className="col-4 text-left  text-light cursor-pointer "
            onClick={(e) => onSort(e, "Location")}
          >
            {languageJson.location_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-5 text-white   text-left cursor-pointer"
            onClick={(e) => onSort(e, "Description")}
          >
            {languageJson.description_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-3 text-left  text-light cursor-pointer "
            onClick={(e) => onSortDate(e, "Lastupdated")}
          >
            {languageJson.last_updated_value_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
        </div>

        {list.map((item, index) => {
          return (
            <React.Fragment>
              <div className="row borderDashed rowDivData" key={item.id}>
                <div className="col-4 "><div className="alignCenterDivTable">{item.Location}</div></div>
                <div className="col-5"><div className="alignCenterDivTable">{item.Description || <b className="text-center">-</b>}</div></div>
                <div className="col-3 text-left  "><div className="alignCenterDivTable">
                  {DateTimeFormate(item.Lastupdated)}</div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
const Table4 = (props) => {
  const [list, setList] = useState(props.list);
  const onSort = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) === JSON.stringify(data.sort((a, b) => a[sortKey].localeCompare(b[sortKey])))
    ) {
      data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    } else {
      data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    setList([...data]);
  };

  const onSortDate = (event, sortKey) => {
    const data = list;
    if (
      JSON.stringify(list) ===
      JSON.stringify(data.sort((a, b) => b[sortKey] - (a[sortKey])))
    ) {
      data.sort((a, b) => a[sortKey] - (b[sortKey]));
    } else {
      data.sort((a, b) => b[sortKey] - (a[sortKey]));
    }
    setList([...data]);
  };
  return (
    <div className="overflowDiv" key={props.index}>
      <div className="vesselestimationdiv">
        <div className="row vo-namecard-head ">
          <div
            className="col-4 text-white   text-left cursor-pointer"
            onClick={(e) => onSort(e, "Description")}
          >
            {languageJson.route_no_colon_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-5 text-white   text-left cursor-pointer"
            onClick={(e) => onSort(e, "Description")}
          >
            {languageJson.description_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
          <div
            className="col-3 text-left  text-light cursor-pointer "
            onClick={(e) => onSortDate(e, "Lastupdated")}
          >
            {languageJson.last_updated_value_txt} <i className="fa fa-sort" aria-hidden="true"></i>
          </div>
        </div>
        {list.map((item, index) => {
          return (
            <React.Fragment>
              <div className="row borderDashed rowDivData" key={item.id}>
                <div className="col-4"><div className="alignCenterDivTable">{item.Location}</div></div>
                <div className="col-5"><div className="alignCenterDivTable">{item.Description || <b className="text-center">-</b>}</div> </div>
                <div className="col-3 text-left  "><div className="alignCenterDivTable">{DateTimeFormate(item.Lastupdated)} </div></div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>);
};
export default AccordionApp;
