import "./ReleaseNote.css";
import React, {useState,useEffect} from "react";
import Ios from "./Ios";
import Android from "./Android";
import Website from "./Website";
import Header from "../../Containers/Header";
import {
  GET_RELEASE_NOTES_FOR_PUBLIC
} from "../../url/Url";
import axios from "src/redux/actions/apis";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdKeyboardArrowUp } from "react-icons/md";

export default function ReleaseNote() {
  const [isIosOpen, setIosOpen] = useState(true);
  const [isAndroidOpen, setIsAndroidOpen] = useState(false);
  const [isWebsiteOpen, setIsWebsiteOpen] = useState(false);
  const [releaseText, setReleaseText] = useState('iOS');
  const [dataList, setDataList] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  
  const handleIosClick = () => {
    setReleaseText('iOS');
    setIosOpen(true);
    setIsAndroidOpen(false);
    setIsWebsiteOpen(false);
    getReleaseNotes();
  };

  const handleAndroidClick = () => {
    getReleaseNotes();
    setReleaseText('Android');
    setIosOpen(false);
    setIsAndroidOpen(true);
    setIsWebsiteOpen(false);
  };

  const handleWebsiteClick = () => {
    getReleaseNotes();
    setReleaseText('Website');
    setIosOpen(false);
    setIsAndroidOpen(false);
    setIsWebsiteOpen(true);
  };

  
  const getReleaseNotes = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/xml",
      },
    };
    try {
      await axios
        .get(`${GET_RELEASE_NOTES_FOR_PUBLIC}`, requestObj)
        .then((res) => {
          let {customcode} =  res.data
          if (customcode === 200) {
            setDataList(res.data.data);
          } else {
            setDataList(null);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getReleaseNotes();
  }, []);


  return (
    <React.Fragment>
      <div>
        <Header />
        <div className="Appclass">
          <div className="dropdownMobile">
            <button onClick={() => setIsopen(!isOpen)}>
              {" "}
              Release notes
              {isOpen ? <MdKeyboardArrowUp /> : <RiArrowDropDownLine />}
            </button>
            {isOpen && (
              <div>
                <p>
                  <span onClick={() => (handleIosClick(), setIsopen(!isOpen))}>
                    iOS Release Note
                  </span>
                </p>
                <p>
                  <span
                    onClick={() => (handleAndroidClick(), setIsopen(!isOpen))}
                  >
                    Android Release Note
                  </span>
                </p>
                <p>
                  <span
                    onClick={() => (handleWebsiteClick(), setIsopen(!isOpen))}
                  >
                    Website Release Note
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="addedDiv"></div>
        </div>

        <div className="releaseheader">
          Oakland Portal for {releaseText} - release notes
        </div>
        <div className="releasebody">
          <div className="classesnav">
            <ul>
              <li onClick={() => handleIosClick()}>
                <span className={isIosOpen ? "activeLinkClass" : ""}>
                  iOS Release Note
                </span>
              </li>
              <li onClick={() => handleAndroidClick()}>
                <span className={isAndroidOpen ? "activeLinkClass" : ""}>
                  Android Release Note
                </span>
              </li>
              <li onClick={() => handleWebsiteClick()}>
                <span className={isWebsiteOpen ? "activeLinkClass" : ""}>
                  Website Release Note
                </span>
              </li>
            </ul>
          </div>
          {isIosOpen && <Ios data={dataList != null ? dataList.ios : []} />}
          {isAndroidOpen && (
            <Android data={dataList != null ? dataList.android : []} />
          )}
          {isWebsiteOpen && (
            <Website data={dataList != null ? dataList.web : []} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
