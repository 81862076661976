import React, { useRef } from 'react';
import ReactPaginate from 'react-paginate';
import SelectLanguageDisplay from 'src/utils/SelectLanguageDisplay';
import { languageJson } from "src/StringVariable";

export default function Pagination(props) {
  const languageAdmin = props.public ? languageJson : SelectLanguageDisplay(localStorage.getItem('lang'));
  const { preview_txt, next_txt } = languageAdmin.adminLang;
  const pagination = useRef();
  
  return (
    <div>
      <div className="pagination-parent mt-2">
        {(Math.ceil(props.total / parseInt(props.per_page)) <= 1 ? "" : <ReactPaginate
          ref={pagination}
          pageCount={Math.ceil(props.total / parseInt(props.per_page))}
          forcePage={props.selectedPage - 1}
          pageRangeDisplayed={2}
          marginPagesDisplayed={3}
          onPageChange={props.setPage}
          containerClassName="pagination"
          activeClassName="active"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          nextLinkClassName="page-link"
          previousLinkClassName="page-link"
          pageClassName="page-item"
          breakClassName="page-item"
          nextClassName="page-item"
          previousClassName="page-item"
          previousLabel={<>&laquo;{preview_txt}</>}
          nextLabel={<>{next_txt}&raquo;</>}
        />)}
      </div>
    </div>
  )
}
