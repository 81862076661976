/* eslint-disable*/
import React, { useState, useEffect } from "react";
import Header from "../../Containers/Header";
import POAKPanorama from "../../assets/POAKPanorama.jpg";
import { Link } from "react-router-dom";
import bluetruck from "../../assets/bluetruck.png";
import bluehistory from "../../assets/bluehistory.png";
import "./VesselOperations.css"; 
import { languageJson } from '../../StringVariable'; 
import { submitGAEvent } from "src/utils/GAEvents"; 
import HistoricalTruckTurnTimeHead from "../HistoricalTruckTurnTimeFilter/HistoricalTruckTurnTimeHead";
import IngateOutgateReports from "../Reports/IngateOutgateReports";
  
// import portimg1 from "../../assets/1.jpg";
//  import portimg3 from "../../assets/3.jpg";
// import portimg4 from "../../assets/4.jpg";
 function HistoricalTurnTimes(props) {
  const [inGateoutGateData, setIngateOutgateData] = useState(true);
  const [historicalTurnTimeData, sethistoricalTurnTimeData] = useState(false);
  
  
  const onInGateoutGate = () => {
    submitGAEvent("Reports-InGate/OutGate Turn Time");
      setIngateOutgateData(true);
    sethistoricalTurnTimeData(false);
  };
  const onHistoricalTurnTime = () => {
    submitGAEvent("Reports-Historical Turn Time");
    setIngateOutgateData(false);
    sethistoricalTurnTimeData(true);
  };
 
 
  useEffect(() => {
       if (props.location.state === "reports") {
        onInGateoutGate();
       }
       if  (props.location.state === "historicalTruckTurnTime") {
        onHistoricalTurnTime();        
       } else {
        onInGateoutGate();
       }
  }, []);

 

  return (
    <div>
      <Header tab={props.location.state}/>
     <div className="headerBannerMainDiv">
        <img className="banner_img" src={POAKPanorama} alt=""/> 
      </div>  
      {/* <div className="image-row-banner">
             <img className="banner_img" src={portimg3} alt=""/>
             <img className="banner_img" src={portimg4} alt=""/> 
             <img className="banner_img" src={portimg1} alt=""/>      
      </div> */}
      {/* <div className="headerBannerMainDiv"> <img className="vessel_img" src={parkingBgImg} alt="vessel" /></div>
       */}

      {props.location.state === 'reports' ? <></>: <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark"> / {languageJson.Historical_truckTurnTime_txt}</span>
            </p>
          </div>
        </div>
      </div>}
       
      <div className="container vesselMainPage">
        <div className="row container-fluid py-5 tabVesselDiv vesselTableMainDiv newVesselTabMainDiv">

             {/* <div className="col-lg-4 col-md-4 col-sm-4 titleTabOption"> */}
          <div className="col-lg-6 col-md-6 col-sm-6 center-head-underline titleTabOption">

<a
  onClick={() => {
    onHistoricalTurnTime();
  }}
  className={`text-decoration-none   ${historicalTurnTimeData
    ? "  center-head-underline-clr vo-headtxt py-2 vessel__text__color"
    : ""
    } text-dark cursor-pointer alertTxtDiv`}
>
  <h2 className={`${historicalTurnTimeData ? "text-primary" : "text-dark"}`}>
    <span>
      <img
        src={bluehistory}
        className="operationicon-img"
        alt="bluelocation-img"
      />
    </span>
    <b>{languageJson.Historical_truckTurnTime_txt}</b>
  </h2>{" "}
</a>
</div>  
          <div className="col-lg-6 col-md-6 col-sm-6 center-head-underline titleTabOption">
            <a
              onClick={() => {
                onInGateoutGate();
              }}
              className={`text-decoration-none ${inGateoutGateData
                ? "center-head-underline-clr vo-headtxt py-2 "
                : ""
                } cursor-pointer vesselOptDiv`}
            >
              
              <h2 className={`${inGateoutGateData ? "text-primary" : "text-dark"}`} >
                <span>
                  <img
                    src={bluetruck}
                    className="operationicon-img"
                    alt="bluelocation-img"
                  />
                </span>
                <b> {languageJson.terminal_turn_time_txt} </b>
              </h2>{" "}
            </a>
          </div>

         
       
    
          
        </div>
 
        {/* ---------vessel Location ----------------- */}
        {inGateoutGateData && <IngateOutgateReports />}

     


        {/* -------------------------vessel alert------------------------- */}
        {historicalTurnTimeData && <HistoricalTruckTurnTimeHead/>}

      </div>
    </div>
  );
}

export default HistoricalTurnTimes;
