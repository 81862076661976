import React, { useState, useEffect } from "react";

import Header from "../../Containers/Header";
import everport from "../../assets/port-logo-1.png";
import trapac from "../../assets/port-logo-2.png";
import matson from "../../assets/port-logo-3.png";
import ssa from "../../assets/port-logo-4.png";
import oaklandlogo from "src/assets/new_logo.png";
import { Link } from "react-router-dom";
import moment from "moment";
import "./historical.css";
import parkingBgImg from "../../assets/parkingBgImg.png";
import { GET_MARINE_TERMINAL_URL, GET_HISTORICAL_DATA_API } from "../../url/Url";
import axios from "src/redux/actions/apis";
import { languageJson } from "../../StringVariable";
import DataNotFound from "../DataNotFound/DataNotFound";
import SpinerLoader from "src/utils/SpinerLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationPopup } from "src/utils/Notifications";
import ExportHistoricalData from "./ExportHistoricalData";
import { submitGAEvent } from "src/utils/GAEvents";
moment.suppressDeprecationWarnings = true;

function HistoricalTruckTurnTime() {
  const aggregateList = [
    { id: languageJson.year_txt, value: "Year" },
    { id: languageJson.month_txt, value: "Month" },
    { id: languageJson.week_txt, value: "Week" },
    { id: languageJson.day_txt, value: "Day" },
    { id: languageJson.Hour_txt, value: "Hour" },
  ];

  const [allMarineTerminals, setAllMarineTerminals] = useState(null);
  const [gate_name, setMarineGateName] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [aggregate, setAggregate] = useState("Year");
  const [aggregateValue, setAggregateValue] = useState("Year");
  const [terminalsData, setTerminalsData] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    submitGAEvent("HISTORICAL TRUCK TURN TIMES");
  });

  const getMarineDataList = async () => {
    const requestObj = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .get(GET_MARINE_TERMINAL_URL, requestObj)
      .then((res) => {
        const { marineTerminals, customcode } = res.data;
        if (customcode === 200) {
          setAllMarineTerminals(marineTerminals);
        } else {
          setAllMarineTerminals([]);
        }
      })
      .catch(function (error) {
        NotificationPopup(403, error.message);
      });
  };

  const getTime = () => {
    let time = new Date();
    if (moment(time).format("h:mm A") !== "Invalid date") {
      return moment(time).format("L h:mm A");
    } else {
      return <b>{"-"}</b>;
    }
  };

  const onchangeDate = (date) => {
    setStartDate(date);
  };

  const onSearchClick = async () => {
    setLoader(true);
    // let year = startDate.getFullYear();
    // let month = startDate.getMonth() + 1;
    // let day=startDate.getDay

    let fromdate=startDate.toISOString().slice(0, 10);
    
// Split the date string at '-' char
const arr = fromdate.split('-');
// Concatenate each part in reverse order
const finalFromDate = arr[1]+'-'+arr[2]+'-'+arr[0];
    setTerminalsData(null)
    let gateName
     if(gate_name === ""){
      gateName="All"
    }else{
      gateName=gate_name
    }
    
    await axios
      .get(
        GET_HISTORICAL_DATA_API(
        
          aggregate.toLocaleLowerCase(),
          gateName,
          finalFromDate
        )
      )
      .then((res) => {
        const { customcode, data } = res.data;
        if (customcode === 200) {
          setTerminalsData(data.data);
          setAggregateValue(aggregate);
          setLoader(false);
        } else {
          setTerminalsData([]);
        }
        setLoader(false);
      })
      .catch(function (error) {
        NotificationPopup(403, error.message);
      });
  };
  const getStreetWaitTime = (time) => {
    if (time < 15) {
      return `street__wait__time__green`;
    } else if (time >= 15 && time <= 30) {
      return `street__wait__time__yellow`;
    } else {
      return `street__wait__time__red`;
    }
  };

  const getTerminalTrunTime = (time) => {
    if (time < 60) {
      return `terminal__turn__time__green`;
    } else if (time >= 60 && time <= 90) {
      return `terminal__turn__time__yellow`;
    } else {
      return `terminal__turn__time__red`;
    }
  };
  const selectLogo = (value) => {
    switch (value) {
      case "matson":
        return matson;
      case "everport":
        return everport;
      case "trapac":
        return trapac;
      case "oict":
        return ssa;
      default:
        return oaklandlogo;
    }
  };
  const getTerminalCurrentWaitTime = (time) => {
    time = parseInt(time);
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    if (minutes.toString().length === 1) {
      minutes = minutes.toString().replace(minutes, `0${minutes}`);
    }

    if (hours === 1 && minutes > 1) {
      return `${hours} ${languageJson.hour_txt}   ${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes > 1) {
      return `${minutes} ${languageJson.minutes_txt}`;
    }
    if (hours === 0 && minutes === 1) {
      return `${minutes} ${languageJson.minutes_txt}`;
    }
    if (minutes === 0) {
      if (hours > 1) {
        return `${hours} ${languageJson.hours_txt}`;
      } else {
        return `${hours} ${languageJson.hour_txt}`;
      }
    } else {
      return `${hours} ${languageJson.hours_txt}  ${minutes} ${languageJson.minutes_txt}`;
    }
  };

  useEffect(() => {
    getMarineDataList();
    getTime();
  }, []);

  const handleKeyPress = (e) => {
    e.preventDefault();
  };

  const convertMetersToMiles = (value) => {
    const df = value * 0.00062137;
    return parseFloat(df).toFixed(2);
  };

  const pad2 = (number) => {
    let number1 = parseInt(number)
    return (number < 10 ? '0' : '') + number1
  }

  const checkString = (terminal) => {
    switch (aggregateValue) {
      case "Year": {
        return <>{languageJson.dayOfYear_txt} {pad2(terminal.daystamp_year)}</>;
      }
      case "Month": {
        return (
          <>
            {languageJson.the_data_for_month_txt} {pad2(terminal.daystamp_month)}  {languageJson.of_txt}{" "}
            {pad2(terminal.daystamp_year)}
          </>
        );
      }
      case "Week": {
        return (
          <>
            {languageJson.the_data_for_week_txt} {pad2(terminal.week_of_year)} {languageJson.of_txt}{" "}
            {pad2(terminal.daystamp_year)}
          </>
        );
      }
      case "Day": {
        return (
          <>
            {languageJson.the_data_for_day_txt} {pad2(terminal.daystamp_month)}{"/"}{pad2(terminal.day_of_month)}  {languageJson.of_txt}{" "}
            {pad2(terminal.daystamp_year)}
          </>
        );
      }
      case "Hour": {
        return (
          <>
            {languageJson.the_data_for_hour_txt} {pad2(terminal.hour_of_day)} {languageJson.on}
            {pad2(terminal.daystamp_month)}{"/"}{pad2(terminal.day_of_month)} 
            {languageJson.of_txt} {pad2(terminal.daystamp_year)}
          </>
        );
      }
      default : {
        return <></>
      }
    }
  };

  // function getMonthName(monthNumber) {
  //   const date = new Date();
  //   date.setMonth(monthNumber - 1);
  
  //   return date.toLocaleString('en-US', {
  //     month: 'long',
  //   });
  // }
  const DatePickerCustomInput = () => (
    <div className="calendar_icon newCalendarIcon">
      <i className="fa fa-calendar" aria-hidden="true"></i>
    </div>
  );
  return (
    <div>
      <Header />
      <div className="headerBannerMainDiv">
        <img className="vessel_img" src={parkingBgImg} alt="vessel" />
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <p className="vo-menu">
              {" "}
              <Link to="/">{languageJson.home_txt}</Link>
              <span className="text-dark">
                {" "}
                / {languageJson.Historical_truckTurnTime_txt}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
        <div className="container">
          <div className="titleDescpDiv">
            <h2>{languageJson.Historical_truckTurnTime_txt}</h2>
          </div>
          <div className="col-12 lastUpdateDiv">
            <div className="col-12 lastUpdateTitleDiv">
              <h3 className="col-12"> </h3>
            </div>

            <div className="col-12 listOptionDiv">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <div className="DatePickerCustomDiv">
                    <label>{languageJson.select_date_txt}</label>
                    <div className="input-group mb-3">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => onchangeDate(date)}
                        selectsStart
                        onKeyDown={handleKeyPress}
                        maxDate={new Date()}
                      />
                      <DatePickerCustomInput />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {" "}
                  <label>{languageJson.select_gate_txt}</label>
                  <select
                   selected={"All"}
                  onChange={(e)=>setMarineGateName(e.target.value)}
                    // onChange={(e) => setMarineGateId(e.target.value)}
                  //defaultValue={gate_name}
              
                    className="browser-default custom-select"
                  >
                    <option value="">{languageJson.all_txt}</option>

                    {allMarineTerminals != null &&
                      allMarineTerminals &&
                      allMarineTerminals.map(
                        (terminal1) =>
                          terminal1.gates.length > 0 &&
                          terminal1.gates.map((terminal, index) => {
                            return (
                              <option value={terminal.gate_name} key={index}>
                                {terminal.gate_name}{" "}
                                {/* {terminal1.terminal_name === terminal.gate_name
                                  ? "- InboundLanes"
                                  : ""} */}
                              </option>
                            );
                          })
                      )}
                  </select>
                </div>
                <div className="col-md-6">
                  {" "}
                  <label>{languageJson.select_aggregate_txt}</label>
                  <select
                    onChange={(e) => setAggregate(e.target.value)}
                    value={aggregate}
                    className="browser-default custom-select"
                  >
                    {aggregateList.length > 0 &&
                      aggregateList.map((elm, index) => {
                        return (
                          <option
                            key={index}
                            style={{ textTransform: "capitalize" }}
                            value={elm.value}
                          >
                            {elm.id}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col-md-6">
                  <button className="btn btn-primary" onClick={onSearchClick}>
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    {languageJson.search_txt}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="truckTurnTimeListOpt">
            {terminalsData !== null && terminalsData.length > 0 && (
              <div>
                {" "}
                <ExportHistoricalData
                  getTerminalCurrentWaitTime={getTerminalCurrentWaitTime}
                  data={terminalsData}
                  aggregate={aggregate}
                />{" "}
                <span className="resultsFoundDiv">
                  {languageJson.total_txt} <b>{terminalsData.length}</b>{" "}
                  {languageJson.results_found_txt}
                </span>{" "}
              </div>
            )}
            {terminalsData != null && terminalsData.length > 0 ? (
              <div className="historicalSearchList">
                {terminalsData.map((terminal, index) => {
                  return (
                    <div className="card" key={index}>
                      <div className="row">
                        <div className="leftImgMainDiv">
                          <div className="col-md-2 infoListDiv imgLogoDiv">
                            <img
                              className="img-fluid m-2"
                              alt="logo"
                              src={selectLogo(
                                terminal.terminal_name.toLowerCase()
                              )}
                            />{" "}
                          </div>
                        </div>

                        <div className="rightContentMainDiv">
                          <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {terminal.gate_name}
                              {/* {terminal.terminal_name === terminal.gate_name
                                ? "-InboundLanes"
                                : ""} */}
                            </span>
                            <label> {languageJson.terminal_gate_txt}</label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span
                              style={{ textTransform: "capitalize" }}
                              className={`marinesty ${getTerminalTrunTime(
                                terminal.terminal_turn_time !== null &&
                                  terminal.terminal_turn_time !== ""
                                  ? terminal.terminal_turn_time
                                  : 0
                              )}`}
                            >
                              {terminal.terminal_turn_time !== null &&
                                terminal.terminal_turn_time !== ""
                                ? getTerminalCurrentWaitTime(
                                  terminal.terminal_turn_time
                                )
                                : 0 + languageJson.minute_txt}
                            </span>
                            <label>
                              {languageJson.avg_txt}{" "}
                              {languageJson.Terminal_Turn_Time_txt}{" "}
                            </label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span
                              style={{ textTransform: "capitalize" }}
                              className={`marinesty ${getStreetWaitTime(
                                terminal.street_wait_time !== null &&
                                  terminal.street_wait_time !== ""
                                  ? terminal.street_wait_time
                                  : 0
                              )}`}
                            >
                              {terminal.street_wait_time !== null &&
                                terminal.street_wait_time !== ""
                                ? getTerminalCurrentWaitTime(
                                  terminal.street_wait_time
                                )
                                : 0 + " " + languageJson.minute_txt}
                            </span>
                            <label>
                              {languageJson.avg_txt}{" "}
                              {languageJson.Street_Wait_Time_txt}{" "}
                            </label>
                          </div>
                          <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {terminal.volume !== null &&
                                terminal.volume !== ""
                                ? terminal.volume
                                : 0}
                            </span>
                            <label>
                              {languageJson.trucks_txt} {languageJson.count_txt}{" "}
                            </label>
                          </div>
                          {/* <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {terminal.avg_trucks_in_queue !== null &&
                                terminal.avg_trucks_in_queue !== ""
                                ? terminal.avg_trucks_in_queue
                                : 0}{" "}
                              {languageJson.trucks_txt}
                            </span>
                            <label>{languageJson.avg_truck_in_que} </label>
                          </div> */}
                          <div className="col-md-3 infoListDiv">
                            <span>
                              {" "}
                              {convertMetersToMiles(
                                terminal.avg_queue_length !== null &&
                                  terminal.avg_queue_length !== ""
                                  ? terminal.avg_queue_length
                                  : 0
                              )}{" "}
                              {languageJson.miles_txt}
                            </span>
                            <label>{languageJson.avg_queue_length} </label>
                          </div>
                        </div>
                        <div className="col-md-12 terminalDateMonthYearTxt" >
                          {checkString(terminal)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : terminalsData !== null && terminalsData.length === 0 ? (
              <DataNotFound />
            ) : (
              loader && <SpinerLoader />
            )}
          </div>
        </div>
      </div>

      {/* <Findus /> */}
    </div>
  );
}

export default HistoricalTruckTurnTime;
