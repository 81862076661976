import React, { useEffect } from 'react';
import Header from 'src/Containers/Header';
import { languageJson } from "src/StringVariable";
import { Link } from "react-router-dom";
import { submitGAEvent } from 'src/utils/GAEvents';

export default function ConditionsofUse() {
    useEffect(() => {
        submitGAEvent("CONDITIONS OF USE");
    }, []);

    return (
        <div>
            <Header />
            <div className="vo-bg breadCumNewDiv">
                <div className="container-fluid row py-4 ">
                    <div>
                        <p className="vo-menu">
                            {" "}
                            <Link to="/">{languageJson.home_txt}</Link>
                            <span className="text-dark"> / {languageJson.footerList.conditionsOfUse}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container disclaimerMainDiv">
                <h1>CONDITIONS OF USE</h1>
                <p>We may change or update these Terms of Use, and will post the changes to the Website to notify you. We encourage you to visit this page periodically to remain informed of any changes. If you continue to use the Website after those changes are in effect, you agree to the revised Terms of Use. If the changes are significant, we may provide more prominent notice or get your consent as required by law.</p>
            </div>
        </div>
    )
}
