import React, { useState, useEffect } from "react";
import { TimeFormate } from "src/utils/DataFormate";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import axios from "src/redux/actions/apis";
import { GET_VESSEL_LOCATION_API, GOOGLE_MAP_URL } from '../../url/Url';
import vesselIcone from "../../assets/vessel-icon@2x.webp";
import './VesselOperations.css';
import { languageJson } from '../../StringVariable';

function Vessel() {
  const [selectedVessel, setSelectedVessel] = useState(null);
  const lat = 37.78929;
  const lng = -122.27405;
  const [Resources, setResources] = useState([]);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedVessel(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [selectedVessel]);

  const getVesselData = async () => {
    try {
      const result = await axios
        .get(GET_VESSEL_LOCATION_API)
        .then((res) => res.data);
      const { customcode } = result;
      if (customcode === 200) {
        setResources(result.listLocation);
      } else {
        setResources([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getVesselData();
  }, []);

  return (
    <div>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: lat, lng: lng }}
        options={{ streetViewControl: false }}
      >
        {Resources.length > 0 &&
          Resources.map((park) => (
            <Marker
              key={park.mmsi}
              position={{
                lat: parseFloat(park.latitude),
                lng: parseFloat(park.longitude)
              }}
              onClick={() => {
                setSelectedVessel(park);
              }}
              icon={{
                url: vesselIcone,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />
          )
          )}

        {selectedVessel !== null && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedVessel(null);
            }}
            position={{
              lat: parseFloat(selectedVessel.latitude),
              lng: parseFloat(selectedVessel.longitude),
            }}
            defaultOptions={false}

          >
            <div className='mapPopup vesselLocationInfo' >
              <button className="closeBtnPopup" onClick={() => {
                setSelectedVessel(null);
              }}>X</button>
              <h4 className="d-none">{selectedVessel.vesselName}</h4>
              <div className="row innerInfoDiv m-4">
                <label>  <b>{languageJson.Vessel_Name_txt} : </b>{selectedVessel.vesselName || "-"}</label>
                <label>  <b>{languageJson.ShipType_txt} : </b> {selectedVessel.vesselType || "-"}</label>
                <label>  <b>{languageJson.IMO_MMSI_txt} : </b>{selectedVessel.mmsi || "-"}</label>
                <label>  <b>{languageJson.Call_Sign_txt} : </b>{selectedVessel.callSign || "-"}</label>
                <label>  <b>{languageJson.Course_Over_Ground_txt} : </b>{selectedVessel.courseOverGround || "-"}</label>
                <label>  <b>{languageJson.enter_destination_txt} : </b>{selectedVessel.destination || "-"}</label>
                <label>  <b>{languageJson.Distance_To_Bow_txt} : </b>{selectedVessel.distanceToBow || "-"}</label>
                <label>  <b>{languageJson.Distance_To_Port_txt} : </b>{selectedVessel.distanceToPort || "-"}</label>
                <label>  <b>{languageJson.Distance_To_Starboard_txt} : </b>{selectedVessel.distanceToStarboard || "-"}</label>
                <label>  <b>{languageJson.Distance_To_Stern_txt} : </b>{selectedVessel.distanceToStern || "-"}</label>
                <label>  <b>{languageJson.Draft_txt} : </b>{selectedVessel.draft || "-"}</label>
                <label>  <b>{languageJson.vessel_estimated_time_on_arrival_txt} : </b>{TimeFormate(selectedVessel.estimatedArrival) || "-"}</label>
                <label>  <b>{languageJson.IMO_txt} : </b>{selectedVessel.imo || "-"}</label>
                <label>  <b>{languageJson.Navigation_Status_txt} : </b>{selectedVessel.navigationStatus || "-"}</label>
                <label>  <b>{languageJson.Rate_Of_Turn_txt} : </b>{selectedVessel.rateOfTurn || "-"}</label>
                <label>  <b>{languageJson.Speed_Over_Ground_txt} : </b>{selectedVessel.speedOverGround || "-"}</label>
                <label>  <b>{languageJson.True_Heading_txt} : </b>{selectedVessel.trueHeading || "-"}</label>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Vessel));

export default function VESSEL_ON_MAP() {
  return (
    <div className="vesselMapTab" style={{ width: "100%", height: "100vh", border: '0' }}>
      {navigator.onLine && <MapWrapped
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%`, borderRadius: '10px', border: '1px solid #999' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '10px' }} />}
      />}
    </div>
  );
}
