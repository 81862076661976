import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import axios from "src/redux/actions/apis";
import { MARINE_CAMERAS_LIST_BY_DISTRICT, GOOGLE_MAP_URL } from 'src/url/Url';
import oaklandlogo from "src/assets/new_logo.png";
import Opcamera30 from "src/assets/30.png";
import Opcamera60 from "src/assets/60.png";
import Opcamera90 from "src/assets/90.png";
import Opcamera120 from "src/assets/120.png";
import Opcamera150 from "src/assets/150.png";
import Opcamera180 from "src/assets/180.png";
import Opcamera210 from "src/assets/210.png";
import Opcamera240 from "src/assets/240.png";
import Opcamera270 from "src/assets/270.png";
import Opcamera300 from "src/assets/300.png";
import Opcamera330 from "src/assets/330.png";
import Opcamera360 from "src/assets/360.png";
import { submitGAEvent } from "src/utils/GAEvents";
import icon from "../../assets/icon.png";
import { languageJson } from '../../StringVariable';

function Camera() {
  const [selectedVessel, setSelectedVessel] = useState(null);
  const lat = 37.797237;
  const lng = -122.288246;
  const [Resources, setResources] = useState([]);
  const [imageVersion, setImageVersion] = useState(0);
  const handleRefreshImages = () => {
    setImageVersion((prevVersion) => prevVersion + 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshImages();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [Resources]);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedVessel(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [selectedVessel]);


  const getVesselData = async () => {
    try {
      await axios
        .get(`${MARINE_CAMERAS_LIST_BY_DISTRICT}${"all"}`)
        .then((res) => {
          const { cameras, customcode } = res.data;
          if (customcode === 200 && cameras.length > 0) {
            setResources(cameras);
          } else {
            setResources([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVesselData();
  }, []);

  useEffect(() => {
    submitGAEvent("OAKLAND PORTAL CAMERAS");
  }, []);

  let imagewithAngle = (a)=> {
    a += 105;  if (a>360) a-= 360
    let cameraImage = (a>0 && a<30) ? Opcamera360 : (a>=30 && a<60) ? Opcamera30 : (a>=60 && a<90) ? Opcamera60 : (a>=90 && a<120) ? Opcamera90 : (a>=120 && a<150) ? Opcamera120 : (a>=150 && a<180) ? Opcamera150 : (a>=180 && a<210) ? Opcamera180 : (a>=210 && a<240) ? Opcamera210 : (a>=240 && a<270) ? Opcamera240 : (a>=270 && a<300) ? Opcamera270 : (a>=300 && a<330) ? Opcamera300 : (a>=330 && a<360) ? Opcamera330 : Opcamera360;
    return cameraImage
      
  }

  let imagePopups = (value) => {
    setSelectedVessel(null);
    setSelectedVessel(value);
  }

  return (
    <div>
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: lat, lng: lng }}
        options={{ streetViewControl: false }}
      >
        {Resources.length > 0 &&
          Resources.map(
            (park, index) =>
              park.camera_preview_url !== "" && (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(park.latitude),
                    lng: parseFloat(park.longitude),
                  }}
                  onClick={() => {
                    imagePopups(park);
                  }}
                  icon={{
                    url: imagewithAngle(park.orientation),
                    scaledSize: new window.google.maps.Size(25, 25),
                    anchor: new window.google.maps.Point(
                      // -Math.round(park.length * Math.sin((park.angle-90)*Math.PI/180.0) + 17),
                      // -Math.round(park.length * Math.cos((park.angle-90)*Math.PI/180.0) + 17)
                      12+Math.round(park.length* Math.cos(park.angle* Math.PI/180.0)),
                      12+Math.round(park.length* Math.sin(park.angle* Math.PI/180.0))
                    ),                    
                  }}
                  title={park.location_name}
                />
              )
          )}
        {selectedVessel !== null && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedVessel(null);
            }}
            position={{
              lat: parseFloat(selectedVessel.latitude),
              lng: parseFloat(selectedVessel.longitude),
            }}
          >
            <div className="mapPopup popupCaltransCamerasMain">
              <button
                className="closeBtnPopup"
                onClick={() => {
                  setSelectedVessel(null);
                }}
              >
                X
              </button>
              <div className="popupCaltransCameras">
                <div className="dataDescpTxt">
                  <span>
                    <label>{selectedVessel.location_name} </label>
                  </span>
                  <span className="cameraLiveImg">
                    <img
                      src={`${selectedVessel.camera_preview_url}&t=${imageVersion}`}
                      alt="img"
                      onError={(e) => {
                        e.currentTarget.src = oaklandlogo;
                      }}
                    />
                  </span>
                </div>
                <a
                  title={languageJson.titleForRedirect}
                  href={selectedVessel.camera_preview_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="googleMapImageRedirect" src={icon} alt="icon" />
                </a>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Camera));

export default function OaklandCamera() {
  return (
    <div className="vesselMapTab oaklandPortalCamerasMap" style={{ width: "100%", height: "100vh" }}>
      {navigator.onLine && <MapWrapped
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className="borderLine" style={{ height: `100%`, borderRadius: '10px' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '10px' }} />}
      />}
    </div>
  );
}
