import React, { useEffect } from 'react';
import Header from 'src/Containers/Header';
import { languageJson } from "src/StringVariable";
import { Link } from "react-router-dom";
import { submitGAEvent } from 'src/utils/GAEvents';

export default function UserAgreement() {
    useEffect(() => {
        submitGAEvent("USER AGREEMENT");
        window.scrollTo(0,0)
    }, []);

    return (
        <div>
            <Header />
            <div className="vo-bg breadCumNewDiv">
                <div className="container-fluid row py-4 ">
                    <div>
                        <p className="vo-menu">
                            {" "}
                            <Link to="/">{languageJson.home_txt}</Link>
                            <span className="text-dark"> / {languageJson.footerList.userAgreement}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container disclaimerMainDiv">
                <h2>User Agreement</h2>
                <p>This Privacy Policy applies to the Port of Oakland websites ("Port of Oakland," "we," "our," or "us"). We respect your privacy and are committed to protecting it. We provide this privacy policy (“Privacy Policy”) to help you ("you," "your," or "User") understand how we collect, use, protect, and disclose information and data when you use the Port of Oakland websites (the "Website"). This Privacy Policy also explains how you can manage the use of certain information we collect. By accessing, browsing, or otherwise using the Website, you agree to be bound by this Privacy Policy.</p>
            </div>
        </div>
    )
}
